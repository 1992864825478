import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {useMutation, usePaginationFragment} from "react-relay";
import {useNavigate} from "react-router-dom";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {
    UnpublishedCourseTable_UnpublishedCourseFragment$key
} from "../../../__generated__/UnpublishedCourseTable_UnpublishedCourseFragment.graphql";
import {UnpublishedCourseTable_Refetch} from "../../../__generated__/UnpublishedCourseTable_Refetch.graphql";
import {
    UnpublishedCourseTable_DeleteCourseMutation
} from "../../../__generated__/UnpublishedCourseTable_DeleteCourseMutation.graphql";
import {ProgressBar} from "primereact/progressbar";
import {PublishedCourseButton} from "../buttons/PublishedCourseButton";

const UNPUBLISHED_COURSE_FRAGMENT = graphql`
    fragment UnpublishedCourseTable_UnpublishedCourseFragment on Query @refetchable(queryName: "UnpublishedCourseTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
    ){
        Admin {
            Course {
                unpublishedCourses(first: $first, after: $after) @connection(key: "UnpublishedCourseTable_unpublishedCourses"){
                    __id
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            name
                            secondName
                        }
                    }
                }
            }
        }
    }
`

const DELETE_MUTATION = graphql`
    mutation UnpublishedCourseTable_DeleteCourseMutation($input: DeleteCourseInput!, $connections: [ID!]!){
        Admin {
            Course {
                deleteCourse(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`


interface OwnProps {
    unpublishedCourseFragment: UnpublishedCourseTable_UnpublishedCourseFragment$key
    publishedCoursesConnectionId: string
}

export const UnpublishedCourseTable = ({unpublishedCourseFragment, publishedCoursesConnectionId}: OwnProps) => {
    const navigate = useNavigate();
    const {
        data,
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext,
    } = usePaginationFragment<UnpublishedCourseTable_Refetch, UnpublishedCourseTable_UnpublishedCourseFragment$key>(UNPUBLISHED_COURSE_FRAGMENT, unpublishedCourseFragment)

    const [deleteCourse, isDeleting] = useMutation<UnpublishedCourseTable_DeleteCourseMutation>(DELETE_MUTATION)


    const {showDialog, dialogComponent} = useDialogLogic();

    const unpublishedCourses = data.Admin.Course.unpublishedCourses.edges?.filter(l => !!l).map(l => l?.node)
    const connectionId = data.Admin.Course.unpublishedCourses.__id

    return <>
        {dialogComponent}
        <DataTable className="mb-3" value={unpublishedCourses as any[]}>
            <Column header="Name" body={row => {
                return <div className={row.isMarkedForDeletion ? "line-through" : ""}> {row.name}</div>
            }}/>
            <Column header="Zweitname" body={row => {
                return <div className={row.isMarkedForDeletion ? "line-through" : ""}> {row.secondName}</div>
            }}/>
            <Column header="Aktionen" style={{width: "200px"}} body={row => {
                return <div>
                    <Button
                        className="mr-2"
                        tooltip="Bearbeiten"
                        onClick={() => {
                            navigate(`/courses/${row.id}/edit`)
                        }} icon={"pi pi-pencil"}/>
                    <PublishedCourseButton
                        courseId={row.id}
                        publishedCoursesConnectionId={publishedCoursesConnectionId}/>
                    <Button
                        disabled={isDeleting}
                        tooltip="Löschen"
                        onClick={() => {
                            showDialog({
                                title: "Lehrgang löschen",
                                content: "Möchten Sie diesen Lehrgang wirklich löschen? Das kann nicht rückgängig gemacht werden.",
                                dialogCallback: result => {
                                    if (result === "Accept") {
                                        deleteCourse({
                                            variables: {
                                                input: {
                                                    ids: [row.id]
                                                },
                                                connections: [connectionId]
                                            }
                                        })
                                    }
                                }
                            })
                        }} icon={"pi pi-trash"}/>
                </div>
            }}/>
        </DataTable>
        {isDeleting ?
            <div className="mt-5 mb-5">
                <ProgressBar mode="indeterminate"/>
            </div> : null
        }
        <div className="flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>
}
