import {Dialog} from "primereact/dialog";
import {FilesTable} from "../tables/FilesTable";
import {useLazyLoadQuery} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {FileSelectionField_Query} from "../../../__generated__/FileSelectionField_Query.graphql";
import {RenderConfig} from "./ValidatedField";

const QUERY = graphql`
    query FileSelectionField_Query($fileId: ID!, $skip: Boolean!, $filterByFileTypes: [String!]) {
        ...FilesTable_FilesListFragment @arguments(filterByFileTypes:$filterByFileTypes)

        node(id: $fileId) @skip(if: $skip) {
            ...on File {
                name
            }
        }
    }
`;

interface OwnProps {
    name: string
    selectedFileId: string | undefined
    setSelectedFileId: (fileId: string | undefined) => void
    filterByFileTypes?: string[]
}

export const FileSelectionField = ({name, selectedFileId, setSelectedFileId, filterByFileTypes}: OwnProps) => {
    const data = useLazyLoadQuery<FileSelectionField_Query>(QUERY, {
        fileId: selectedFileId || "",
        skip: !selectedFileId,
        filterByFileTypes: filterByFileTypes
    })

    const [selection, setSelection] = useState<any>(data.node)
    const [isModalOpen, setModalOpen] = useState<boolean>(false)

    return <div className="flex">
        <InputText
            onClick={() => {
                setModalOpen(true)
            }}
            className="mr-2 flex-grow-1 w-auto"
            name={name} disabled={true} value={data.node?.name || "Bitte Datei auswählen"}/>
        <Button
            className="w-auto p-button-secondary p-2"
            type={"button"}
            disabled={selectedFileId === undefined}
            label={""}
            icon="pi pi-times"
            onClick={() => {
                setSelectedFileId(undefined)
            }}/>
        <Button
            className="w-auto ml-2"
            type={"button"}
            onClick={() => {
                setModalOpen(true)
            }}>Datei auswählen</Button>

        <Dialog header="Datei auswählen"
                onHide={() => {
                    setModalOpen(false)
                }}
                footer={<div>
                    <Button
                        label={"Auswählen"}
                        icon="pi pi-check"
                        type="button"
                        onClick={() => {
                            setSelectedFileId(selection?.id)
                            setModalOpen(false)
                        }}
                        autoFocus/>
                </div>}
                visible={isModalOpen}>
            <FilesTable filesFragmentRef={data} selectionSettings={{
                selection: selection,
                onSelectionChange: e => setSelection(e.value)
            }}/>
        </Dialog>
    </div>
}

export const DefaultFileSelectionField = ({fieldName, fieldValue, updateField}: RenderConfig<string>) => {
    return <FileSelectionField
        name={fieldName}
        selectedFileId={fieldValue}
        setSelectedFileId={updateField}
    />
}
