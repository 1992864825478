import {useEffect, useMemo, useState} from "react";
import {debounce} from "lodash";
import {FetchPolicy} from "relay-runtime";

type RefetchFunction<T> = (
    params: T & { first: number },
    options: { fetchPolicy: FetchPolicy | undefined },
) => void;

export function useDebounceFilters<T>(filterState: T, refetch: RefetchFunction<T>) {
    const [initialRender, setInitialRender] = useState(true);

    const debouncedRefetch = (filters: T) =>
        refetch({...filters, first: 20}, {fetchPolicy: "network-only"});

    const isFilterEmpty = Object.keys(filterState).length === 0;

    /* eslint-disable react-hooks/exhaustive-deps */
    const debouncedEventHandler = useMemo(() => debounce(debouncedRefetch, 1000), []);

    useEffect(() => {
        if (!initialRender || !isFilterEmpty) {
            debouncedEventHandler(filterState);
        } else {
            setInitialRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterState]);
}
