import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import React, { Suspense } from "react";
import {Button} from "primereact/button";
import {useNavigate} from "react-router-dom";
import {FaqsTable} from "../../components/relay/tables/FaqsTable";
import {FaqsScreen_FaqQuery} from "../../__generated__/FaqsScreen_FaqQuery.graphql";
import {FaqSearchContainer} from "../../components/relay/filter/container/FaqSearchContainer";
import {FaqFilter} from "../../components/relay/filter/FaqFilter";

const QUERY = graphql`
    query FaqsScreen_FaqQuery {
        ...FaqsTable_Faqs
    }
`

export const FaqsScreen = () => {
    const navigate = useNavigate();
    const faqs = useLazyLoadQuery<FaqsScreen_FaqQuery>(QUERY, {
        first: 20
    }, {fetchPolicy: "network-only"})

    return <div>
        <h1 className="mb-3">FAQS</h1>

        <div className="flex justify-content-end mb-3">
            <Button onClick={_ => navigate("/faqs/new/edit")}>
                Neu
            </Button>
        </div>
        <FaqSearchContainer>
            <FaqFilter />
            <Suspense fallback={<div>Lade...</div>}>
                <FaqsTable faqsFragmentRef={faqs}/>
            </Suspense>
        </FaqSearchContainer>
    </div>

}
