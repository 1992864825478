import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useFormik} from "formik";
import React from "react";
import * as Yup from "yup";
import {TkDialog} from "../../ui/TkDialog";
import {TkButtonLink} from "../../ui/TkButtonLink";
import {ValidatedField} from "../../ui/ValidatedField";
import {
    ChangeUserGroupsModal_UserInAccountFragment$key
} from "../../../__generated__/ChangeUserGroupsModal_UserInAccountFragment.graphql";
import {ChangeUserGroupsModal_ChangeMutation} from "../../../__generated__/ChangeUserGroupsModal_ChangeMutation.graphql";
import {GroupsSelectField} from "../fields/GroupsSelectField";


const FRAGMENT = graphql`
    fragment ChangeUserGroupsModal_UserInAccountFragment on UserInAccount {
        id
        user {
            id
        }
        groups {
            id
        }
    }
`


const CHANGE_MUTATION = graphql`
    mutation ChangeUserGroupsModal_ChangeMutation($input: ChangeUserGroupsInput!) {
        Management {
            changeUserGroups(input: $input) {
                edge {
                    node {
                        id
                        ...ChangeUserGroupsModal_UserInAccountFragment
                    }
                }
            }
        }
    }
`


interface OwnProps {
    userInAccountFragmentRef: ChangeUserGroupsModal_UserInAccountFragment$key
    onCompleted?: () => void

    isVisible: boolean
    onHide: () => void
}


interface FormState {
    groups?: string[]
}

export const filterGroups = (groups: ReadonlyArray<{ id: string }>) => {
    return groups.filter(g => {
        const parsedId = atob(g.id).split(":")[1]
        return parsedId !== "root-account"
    })
}

export const ChangeUserGroupsModal = ({
                                          userInAccountFragmentRef,
                                          onCompleted,
                                          isVisible,
                                          onHide
                                      }: OwnProps) => {
    const userInAccount = useFragment<ChangeUserGroupsModal_UserInAccountFragment$key>(FRAGMENT, userInAccountFragmentRef || null)

    const [change] = useMutation<ChangeUserGroupsModal_ChangeMutation>(CHANGE_MUTATION)

    const formik = useFormik<FormState>({
        initialValues: {
            groups: filterGroups(userInAccount.groups || []).map(g => g.id)
        },
        validationSchema: Yup.object().shape({
            groups: Yup
                .array()
                .required("Groups are required")
        }),
        onSubmit: (values, {setSubmitting}) => {
            change({
                variables: {
                    input: {
                        userId: userInAccount.user.id,
                        groupRefs: values.groups!
                    },
                },
                onCompleted: () => {
                    setSubmitting(false)
                    onCompleted && onCompleted()
                },
                onError: e => {
                    console.error(e)
                    setSubmitting(false)
                }
            })

        }
    })

    return <TkDialog
        header={<h1>Nutzergruppe ändern</h1>} visible={isVisible}
        onHide={() => onHide()}
        footer={<div className="flex">
            <TkButtonLink disabled={formik.isSubmitting}
                          type="button"
                          onClick={() => onHide()} label={"Zurück"}
                          className="m-auto w-auto"/>
            <TkButtonLink disabled={formik.isSubmitting}
                          onClick={() => formik.handleSubmit()}
                          label={"Change groups"}
                          className="m-auto w-auto"
            />
        </div>}
    >
        <form onSubmit={formik.handleSubmit} className="p-fluid mb-5">
            <ValidatedField<FormState, string[]>
                className="mb-4"
                name={"groups"}
                label={"Groups"}
                required={true}
                formikConfig={formik}
                component={GroupsSelectField}/>
        </form>
    </TkDialog>


}
