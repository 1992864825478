import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import React, {useRef} from "react";
import {TkDialog} from "../../ui/TkDialog";
import {TkButtonLink} from "../../ui/TkButtonLink";
import {RegistrationForm, RegistrationFormState} from "../../ui/RegistrationForm";
import {FormikProps} from "formik/dist/types";
import {toast} from "react-toastify";
import {ErrorMessageI18n} from "../../../i18n/errorMessage.i18n";
import {CreateUserModal_CreateUserMutation} from "../../../__generated__/CreateUserModal_CreateUserMutation.graphql";

const CREATE_USER_MUTATION = graphql`
    mutation CreateUserModal_CreateUserMutation($input: CreateUserInput!, $connections: [ID!]!) {
        Admin {
            User{
                createUser(input: $input) {
                    user @appendEdge(connections: $connections){
                        node {
                            id
                            email,
                            isDeleted
                            name
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    connectionId: string
    onCompleted?: () => void

    isVisible: boolean
    onHide: () => void
}


export const CreateUserModal = ({
                                    connectionId,
                                    onCompleted,
                                    isVisible,
                                    onHide
                                }: OwnProps) => {
    const [create] = useMutation<CreateUserModal_CreateUserMutation>(CREATE_USER_MUTATION)

    const formik = useRef<FormikProps<RegistrationFormState>>()

    return <TkDialog
        header={<h1>Nutzer erstellen</h1>} visible={isVisible}
        onHide={() => onHide()}
        footer={<div className="flex">
            <TkButtonLink disabled={formik?.current?.isSubmitting}
                          type="button"
                          onClick={() => onHide()} label={"Zurück"}
                          className="m-auto w-auto"/>
            <TkButtonLink disabled={formik?.current?.isSubmitting}
                          onClick={() => formik?.current?.handleSubmit()}
                          label={"Erstellen"}
                          type="submit"
                          className="m-auto w-auto"
            />
        </div>}
    >
        <RegistrationForm
            ref={formik as any}
            buttonLabel={"Create user"}
            onSuccess={(values, onSuccess) => {
                create({
                    variables: {
                        input: {
                            salutation: values.salutation,
                            email: values.email,
                            name: values.name,
                            password: values.password
                        },
                        connections: [connectionId]
                    },
                    onCompleted: () => {
                        toast("Der Benutzer wurde erfolgreich angelegt", {type: "success"})
                        onSuccess && onSuccess()
                        onCompleted && onCompleted()
                    },
                    onError: (e: Error) => {
                        toast.error(ErrorMessageI18n[e.message])
                    }
                })
            }}/>
    </TkDialog>


}
