import React, {useEffect, useState} from 'react';
import {BreadCrumb} from "primereact/breadcrumb";
import {MenuItem} from "primereact/menuitem";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useLazyLoadQuery} from "react-relay";
import {TemplateHeader_ModuleTemplateFragment$key} from "../../../__generated__/TemplateHeader_ModuleTemplateFragment.graphql";
import {TemplateHeader_CourseTemplateFragment$key} from "../../../__generated__/TemplateHeader_CourseTemplateFragment.graphql";
import {TemplateHeader_TrainingDayTemplateFragment$key} from "../../../__generated__/TemplateHeader_TrainingDayTemplateFragment.graphql";
import {TemplateHeader_CourseTemplateQuery} from "../../../__generated__/TemplateHeader_CourseTemplateQuery.graphql";
import {TemplateHeader_ModuleTemplateQuery} from "../../../__generated__/TemplateHeader_ModuleTemplateQuery.graphql";

const COURSE_TEMPLATE_QUERY = graphql`
    query TemplateHeader_CourseTemplateQuery($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on CourseTemplate {
                ...TemplateHeader_CourseTemplateFragment
            }
        }
    }
`

const MODULE_TEMPLATE_QUERY = graphql`
    query TemplateHeader_ModuleTemplateQuery($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on ModuleTemplate {
                ...TemplateHeader_ModuleTemplateFragment
            }
        }
    }
`
const COURSE_TEMPLATE_FRAGMENT = graphql`
    fragment TemplateHeader_CourseTemplateFragment on CourseTemplate {
        id
        name
    }
`

const MODULE_TEMPLATE_FRAGMENT = graphql`
    fragment TemplateHeader_ModuleTemplateFragment on ModuleTemplate {
        id
        shortTitle
    }
`

const TRAINING_DAY_TEMPLATE_FRAGMENT = graphql`
    fragment TemplateHeader_TrainingDayTemplateFragment on TrainingDayTemplate {
        id
        internalTitle
    }
`

interface OwnProps {
    courseTemplateId?: string
    moduleTemplateId?: string
    courseTemplateFragment?: TemplateHeader_CourseTemplateFragment$key | null
    moduleTemplateFragment?: TemplateHeader_ModuleTemplateFragment$key | null
    trainingDayTemplateFragment?: TemplateHeader_TrainingDayTemplateFragment$key | null
}

export const TemplateHeader = ({
                                   courseTemplateId,
                                   moduleTemplateId,
                                   courseTemplateFragment,
                                   moduleTemplateFragment,
                                   trainingDayTemplateFragment
                               }: OwnProps) => {
    const baseUrl = process.env.REACT_APP_ADMIN_URL

    const courseQuery = useLazyLoadQuery<TemplateHeader_CourseTemplateQuery>(COURSE_TEMPLATE_QUERY, {
        id: courseTemplateId || "",
        skip: !courseTemplateId
    }, {fetchPolicy: "network-only"})

    const moduleQuery = useLazyLoadQuery<TemplateHeader_ModuleTemplateQuery>(MODULE_TEMPLATE_QUERY, {
        id: moduleTemplateId || "",
        skip: !moduleTemplateId
    }, {fetchPolicy: "network-only"})

    const course = useFragment<TemplateHeader_CourseTemplateFragment$key>(COURSE_TEMPLATE_FRAGMENT, courseTemplateFragment || courseQuery.node!)
    const module = useFragment<TemplateHeader_ModuleTemplateFragment$key>(MODULE_TEMPLATE_FRAGMENT, moduleTemplateFragment || moduleQuery.node!)
    const trainingDay = useFragment<TemplateHeader_TrainingDayTemplateFragment$key>(TRAINING_DAY_TEMPLATE_FRAGMENT, trainingDayTemplateFragment || null)

    const [items, setItems] = useState<MenuItem[]>([])

    useEffect(() => {
        if (course && module && trainingDay) {
            setItems([{
                label: `Lehrgang ${course.name}`,
                url: `${baseUrl}/course-templates/${course.id}/edit`
            }, {
                label: `Modul ${module.shortTitle}`,
                url: `${baseUrl}/course-templates/${course.id}/${module.id}/edit`
            }, {
                label: `Ausbildungstag ${trainingDay.internalTitle}`,
                url: `${baseUrl}/course-templates/${course.id}/${module.id}/${trainingDay.id}/edit`
            }])
        } else if (course && module) {
            setItems([{
                label: `Lehrgang ${course.name}`,
                url: `${baseUrl}/course-templates/${course.id}/edit`
            }, {
                label: `Modul ${module.shortTitle}`,
                url: `${baseUrl}/course-templates/${course.id}/${module.id}/edit`
            }])
        } else if (course) {
            setItems([{
                label: `Lehrgang ${course.name}`,
                url: `${baseUrl}/course-templates/${course.id}/edit`
            }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, module, trainingDay])

    const home: MenuItem = {icon: 'pi pi-home', url: baseUrl + '/course-templates'}

    return <BreadCrumb model={items} home={home}/>
}
