import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import React, {useRef} from "react";
import {TkDialog} from "../../ui/TkDialog";
import {TkButtonLink} from "../../ui/TkButtonLink";
import {
    CreateUserInAccountModal_CreateMutation
} from "../../../__generated__/CreateUserInAccountModal_CreateMutation.graphql";
import {RegistrationForm, RegistrationFormState} from "../../ui/RegistrationForm";
import {Message} from "primereact/message";
import {FormikProps} from "formik/dist/types";


const CREATE_MUTATION = graphql`
    mutation CreateUserInAccountModal_CreateMutation($input: CreateUserInAccountTmsInput!, $connections: [ID!]!) {
        Admin {
            Management {
                createUserInAccountTms(input: $input) {
                    edge @appendEdge(connections: $connections){
                        node {
                            id
                            user {
                                id
                                email
                                name
                            }
                            groups {
                                id
                                name
                            }
                            ...ChangeUserGroupsAdminButton_UserInAccountFragment
                        }
                    }
                }
            }
        }
    }
`


interface OwnProps {
    connectionId?: string
    accountId: string
    onCompleted?: () => void

    isVisible: boolean
    onHide: () => void
}


export const CreateUserInAccountModal = ({
                                             connectionId,
                                             accountId,
                                             onCompleted,
                                             isVisible,
                                             onHide
                                         }: OwnProps) => {
    const [create] = useMutation<CreateUserInAccountModal_CreateMutation>(CREATE_MUTATION)

    const formik = useRef<FormikProps<RegistrationFormState>>()

    return <TkDialog
        header={<h1>Nutzer erstellen</h1>} visible={isVisible}
        onHide={() => onHide()}
        footer={<div className="flex">
            <TkButtonLink disabled={formik?.current?.isSubmitting}
                          type="button"
                          onClick={() => onHide()} label={"Zurück"}
                          className="m-auto w-auto"/>
            <TkButtonLink disabled={formik?.current?.isSubmitting}
                          onClick={() => formik?.current?.handleSubmit()}
                          label={"Erstellen"}
                          type="submit"
                          className="m-auto w-auto"
            />
        </div>}
    >
        <Message className="mb-2" severity={"info"}
                 content={"Sollte der Nutzer bereits existieren, wird er dem Account hinzugefügt."}/>
        <RegistrationForm
            ref={formik as any}
            buttonLabel={"Create user"}
            onSuccess={(data, onSuccess) => {
                create({
                    variables: {
                        input: {
                            accountId: accountId,
                            name: data.name,
                            email: data.email,
                            password: data.password
                        },
                        connections: connectionId ? [connectionId] : []
                    },
                    onCompleted: () => {
                        onSuccess && onSuccess()
                        onCompleted && onCompleted()
                    },
                })
            }}/>
    </TkDialog>


}
