import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {TrainersScreen} from "../screens/trainer/TrainersScreen";

export const EDIT_TRAINER_PATH = "/trainers/:trainerId/edit";

export const TrainerRoutes: SecureRouteDefinition[] = [
    {
        path: "/trainers",
        element: <TrainersScreen/>,
        requiredPermissions: ["Sales_Sales"]
    }
]
