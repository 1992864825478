import { FormikState } from "formik";
import React, { ReactNode } from "react";

// @ts-ignore
import { classNames } from "primereact/utils";

export interface RenderConfig<FieldType> {
	fieldValue: FieldType | undefined;
	updateField: (newValue: FieldType | undefined) => void;
	fieldName: string;
	required: boolean;
	isValid: boolean;
	disabled: boolean;
	noLineBreak?: boolean;
	onChange?: () => void;
}

interface ValidatedFieldPropsV2<State, FieldType> {
	label?: string;
	name: keyof State & string;
	required?: boolean;
	disabled?: boolean;

	formikConfig: FormikState<State> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};

	iconClass?: string;
	className?: string;
	helpText?: ReactNode;
	onChange?: () => void;
	component: (renderConfig: RenderConfig<FieldType>) => ReactNode;
}

export function ValidatedField<State, FieldType>({
	className,
	iconClass,
	name,
	label,
	formikConfig,
	helpText,
	component,
	required,
	disabled,
	onChange,
}: ValidatedFieldPropsV2<State, FieldType>) {
	const hasError = formikConfig.errors[name] && formikConfig.touched[name];

	const value = formikConfig.values[name] as unknown as FieldType;

	const updateValue = (updatedValue: FieldType | undefined) => {
		formikConfig.setFieldTouched(name, true);
		formikConfig.setFieldValue(name, updatedValue);
	};

	const FieldContent = (
		<>
			{iconClass && <i className={`pi ${iconClass}`} />}

			{component({
				fieldValue: value,
				isValid: !hasError,
				fieldName: name,
				updateField: updateValue,
				required: required || false,
				disabled: disabled || false,
				onChange: onChange || undefined,
			})}
		</>
	);

	return (
		<div className={`field ${className ? className : ""}`}>
			{label ? (
				<label htmlFor={name} className={classNames({ "p-error": hasError })}>
					{label} {required ? "*" : ""}
				</label>
			) : null}

			{iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

			{helpText ? <div className="text-xs mt-1">{helpText}</div> : null}

			{hasError ? (
				<div className="p-error text-xs mt-1">{(formikConfig.errors as any)[name]}</div>
			) : null}
		</div>
	);
}
