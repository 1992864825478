import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key } from "../../../../__generated__/DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment.graphql";
import { DiscountUsagePriceDisplay } from "../DiscountUsagePriceDisplay";
import { DownloadUsageCsvButton } from "../buttons/DownloadUsageCsvButton";
import { DiscountUsageFilter } from "../filters/DiscountUsageFilter";

const SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT = graphql`
	fragment DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment on SearchDiscountCodeUsagesResult {
		...DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment
		...DownloadUsageCsvButton_SearchDiscountUsageResultFragment
	}
`;

interface OwnProps {
	searchDiscountUsageResultFragmentRef: DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key;
}

export const DiscountCodeUsageTableHeader = ({
	searchDiscountUsageResultFragmentRef,
}: OwnProps) => {
	const searchDiscountUsageResult =
		useFragment<DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key>(
			SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT,
			searchDiscountUsageResultFragmentRef,
		);

	return (
		<div className="w-full flex flex-row justify-content-between align-items-center flex-wrap">
			<DiscountUsagePriceDisplay
				searchDiscountUsageResultFragmentRef={searchDiscountUsageResult}
			/>
			<DownloadUsageCsvButton
				searchDiscountUsageResultFragmentRef={searchDiscountUsageResult}
			/>
			<DiscountUsageFilter />
		</div>
	);
};
