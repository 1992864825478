import {configureStore} from '@reduxjs/toolkit'
import {AuthSliceReducer} from "./slices/AuthSlice";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";

export const ReduxStore = configureStore({
    reducer: {
        auth: AuthSliceReducer,
    },
})
export type ReduxState = ReturnType<typeof ReduxStore.getState>
export type TypedDispatch = typeof ReduxStore.dispatch

export const useTypedDispatch: () => TypedDispatch = useDispatch
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector
