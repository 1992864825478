import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import React, {useState} from "react";
import {CourseStatesTable_UserFragment$key} from "../../../__generated__/CourseStatesTable_UserFragment.graphql";
import {DataTable, DataTableRowToggleParams} from "primereact/datatable";
import {Button} from "primereact/button";
import {CancelCourseForUserButton} from "../buttons/CancelCourseForUserButton";
import {Column} from "primereact/column";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";
import moment from "moment-timezone";
import {ModuleState, TrainingDayState} from "../../../model/CourseState";
import {PublishedCourse} from "../../../model/PublishedCourse";
import {AttendanceTypeI18n} from "../../../i18n/attendanceType.i18n";
import {Card} from "primereact/card";
import {
    CourseStatesTable_UserHistoryFragment$key
} from "../../../__generated__/CourseStatesTable_UserHistoryFragment.graphql";
import {TabMenu} from "primereact/tabmenu";
import {HistoryItem} from "../HistoryItem";
import {StringInputWithSave} from "../../ui/StringInputWithSave";
import {
    CourseStatesTable_CourseStateFragment$key
} from "../../../__generated__/CourseStatesTable_CourseStateFragment.graphql";
import {CourseStatesTable_SetNoteMutation} from "../../../__generated__/CourseStatesTable_SetNoteMutation.graphql";
import {FoodCreditStateInputWithSave} from "../../ui/FoodCreditStateInputWithSave";
import {
    CourseStatesTable_SetAttendanceStatusMutation
} from "../../../__generated__/CourseStatesTable_SetAttendanceStatusMutation.graphql";
import {
    CourseStatesTable_SetFoodCreditStateMutation
} from "../../../__generated__/CourseStatesTable_SetFoodCreditStateMutation.graphql";
import {toast} from "react-toastify";
import {AttendanceStatusInputWithSave} from "../../ui/AttendanceStatusInputWithSave";

const USER_HISTORY_FRAGMENT = graphql`
    fragment CourseStatesTable_UserHistoryFragment on UserHistoryEdge{
        node {
            historyEntries {
                ...HistoryItem_EntryFragment
            }
            courseState {
                ...CourseStatesTable_CourseStateFragment
            }
            order {
                cart {
                    selection {
                        createdByUser{
                            name
                        }
                    }
                }
                history {
                    edges {
                        node {
                            createdAt
                            status
                        }
                    }
                }
                invoiceData {
                    invoiceDataBase64
                    invoiceNumber
                }
                creditNoteData {
                    creditNoteDataBase64
                    creditNoteNumber
                }
            }
        }
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment CourseStatesTable_CourseStateFragment on CourseState {
        id
        moduleStates {
            moduleId
            trainingDayStates {
                trainingDayId
                attendanceListAdmin {
                    attendanceStatus
                    attendanceType
                    foodCreditState
                    note
                }
            }
        }
        publishedCourse {
            name
            secondName
            modulesAdmin {
                id
                title
                startDate
                endDate
                trainingDays {
                    id
                    date
                }
            }
        }
    }
`

const SET_NOTE_MUTATION = graphql`
    mutation CourseStatesTable_SetNoteMutation($input: SetNoteForParticipantInput!) {
        Admin {
            States {
                setNoteForParticipant(input: $input) {
                    courseState {
                        node {
                            ...CourseStatesTable_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

const SET_ATTENDANCE_STATUS_MUTATION = graphql`
    mutation CourseStatesTable_SetAttendanceStatusMutation($input: SetAttendanceStatusForParticipantInput!) {
        Admin {
            States {
                setAttendanceStatusForParticipant(input: $input) {
                    courseState {
                        node {
                            ...CourseStatesTable_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

const SET_FOOD_CREDIT_STATE_MUTATION = graphql`
    mutation CourseStatesTable_SetFoodCreditStateMutation($input: SetFoodCreditStateForParticipantInput!) {
        Admin {
            States {
                setFoodCreditStateForParticipant(input: $input) {
                    courseState {
                        node {
                            ...CourseStatesTable_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

const USER_FRAGMENT = graphql`
    fragment CourseStatesTable_UserFragment on User {
        id
    }
`

interface OwnProps {
    userFragmentRef: CourseStatesTable_UserFragment$key | null
    userHistoryFragmentRef: CourseStatesTable_UserHistoryFragment$key
}

export const CourseStatesTable = ({userFragmentRef, userHistoryFragmentRef}: OwnProps) => {
    const user = useFragment<CourseStatesTable_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const userHistories = useFragment<CourseStatesTable_UserHistoryFragment$key>(USER_HISTORY_FRAGMENT, userHistoryFragmentRef)
    const courseState = useFragment<CourseStatesTable_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, userHistories.node.courseState)
    const [setNote, isSettingNote] = useMutation<CourseStatesTable_SetNoteMutation>(SET_NOTE_MUTATION)
    const [setAttendanceStatus, isSettingAttendanceStatus] = useMutation<CourseStatesTable_SetAttendanceStatusMutation>(SET_ATTENDANCE_STATUS_MUTATION)
    const [setFoodCredit, isSettingFoodCredit] = useMutation<CourseStatesTable_SetFoodCreditStateMutation>(SET_FOOD_CREDIT_STATE_MUTATION)
    const [expandedRows, setExpandedRows] = useState<any>(null);

    const history = userHistories.node


    const downloadInvoicePDF = (invoiceData: { readonly invoiceDataBase64: string, readonly invoiceNumber: string }) => {
        if (invoiceData?.invoiceDataBase64) {
            const link = document.createElement("a");
            link.href = "data:application/octet-stream;base64," + invoiceData?.invoiceDataBase64;
            link.download = invoiceData.invoiceNumber + ".pdf";
            link.click();
        }
    }

    const downloadCreditNotePDF = (creditNoteData: { readonly creditNoteDataBase64: string, readonly creditNoteNumber: string }) => {
        if (creditNoteData?.creditNoteDataBase64) {
            const link = document.createElement("a");
            link.href = "data:application/octet-stream;base64," + creditNoteData.creditNoteDataBase64;
            link.download = creditNoteData.creditNoteNumber + ".pdf";
            link.click();
        }
    }

    const rowExpansionTemplate = (data: ModuleState, publishedCourse: PublishedCourse) => {
        return (
            <div className="orders-subtable">
                <h5>Ausbildungstage</h5>
                <DataTable value={data.trainingDayStates} responsiveLayout="stack">
                    <Column
                        header="Datum"
                        body={(data: TrainingDayState) => {
                            const date = publishedCourse.modulesAdmin.flatMap(k => k.trainingDays).find(l => l.id === data.trainingDayId)?.date || ""
                            return <DateTimeDisplay value={date} hideTimezone/>
                        }}/>
                    <Column
                        header="Art"
                        body={(data: TrainingDayState) => {
                            return AttendanceTypeI18n[data.attendanceListAdmin[0].attendanceType]
                        }}/>
                    <Column
                        header="Teilnahmestatus"
                        body={(data: TrainingDayState) => {
                            return <AttendanceStatusInputWithSave
                                attendanceStatus={data.attendanceListAdmin[0].attendanceStatus}
                                disabled={isSettingAttendanceStatus}
                                onSave={attendanceStatus => {
                                    if (user) {
                                        setAttendanceStatus({
                                            variables: {
                                                input: {
                                                    userId: user.id,
                                                    trainingDayId: data.trainingDayId,
                                                    attendanceStatus: attendanceStatus
                                                }
                                            },
                                            onCompleted: () => {
                                                toast.success("Status erfolgreich gespeichert")
                                            }
                                        })
                                    }
                                }}/>
                        }}/>
                    <Column
                        header="Essenskredit"
                        body={(data: TrainingDayState) => {
                            return <FoodCreditStateInputWithSave
                                selectedStatus={data.attendanceListAdmin[0].foodCreditState}
                                disabled={isSettingFoodCredit}
                                onSave={foodCreditState => {
                                    if (user) {
                                        setFoodCredit({
                                            variables: {
                                                input: {
                                                    userId: user.id,
                                                    trainingDayId: data.trainingDayId,
                                                    foodCreditState: foodCreditState
                                                }
                                            },
                                            onCompleted: () => {
                                                toast.success("Essenskredit erfolgreich gespeichert")
                                            }
                                        })
                                    }
                                }
                                }/>
                        }}/>
                    <Column
                        header="Notiz"
                        body={(data: TrainingDayState) => {
                            return <StringInputWithSave
                                value={data.attendanceListAdmin[0].note}
                                disabled={isSettingNote}
                                onSave={(note: string | undefined) => {
                                    if (user) {
                                        setNote({
                                            variables: {
                                                input: {
                                                    note: note,
                                                    trainingDayId: data.trainingDayId,
                                                    userId: user.id
                                                }
                                            },
                                            onCompleted: () => {
                                                toast.success("Notiz erfolgreich gespeichert")
                                            }
                                        })
                                    }
                                }}/>
                        }}/>
                </DataTable>
            </div>
        );
    }

    const [activeIndex, setActiveIndex] = useState(0);

    const items = [
        {label: 'Status', icon: 'pi pi-fw pi-home'},
        {label: 'Historie', icon: 'pi pi-fw pi-calendar'}
    ];

    const {historyEntries, order} = history
    const {id, publishedCourse, moduleStates} = courseState

    const salesPerson = order?.cart?.selection.createdByUser?.name
    const boughtAt = order?.history.edges?.find(history => history?.node.status === "Purchased")?.node.createdAt || ""
    const cancellationPeriod = moment(boughtAt.replace("[UTC]", "")).add(14, "days").tz(moment.tz.guess()).format("DD.MM.YYYY HH:mm")

    let activeTab;
    switch (activeIndex) {
        case 0:
            activeTab = (
                <>
                    <div className="flex flex-column">
                        <div className="flex mt-3 mb-3 align-items-center flex-wrap">
                            <h3>{publishedCourse.name} - {publishedCourse.secondName} {salesPerson && `(Ausbildungsberater: ${salesPerson})`}</h3>
                            {order?.invoiceData &&
                                <Button
                                    className="ml-2 h-2rem"
                                    label={"Rechnung"}
                                    onClick={() => downloadInvoicePDF(order?.invoiceData!)}/>}
                            {order?.creditNoteData &&
                                <Button
                                    className="ml-2  h-2rem"
                                    label={"Rechnungskorrektur"}
                                    onClick={() => downloadCreditNotePDF(order?.creditNoteData!)}/>}
                            {(order?.invoiceData && !order.creditNoteData) &&
                                <CancelCourseForUserButton
                                    className="ml-2  h-2rem"
                                    userId={user?.id!}
                                    courseStateId={id}/>}
                        </div>
                        {boughtAt && <>
                            <div className="flex mb-2 surfaced-card">
                                <span className="mr-2">Gekauft am:</span>
                                <DateTimeDisplay value={boughtAt} hideTimezone showTime/>
                            </div>
                            <div className="flex mb-2">
                                <span className="mr-2">Widerrufsfrist am:</span>
                                {cancellationPeriod}
                            </div>
                        </>}
                    </div>
                    <div>
                        <DataTable
                            value={moduleStates.map(k => k)}
                            responsiveLayout="stack"
                            expandedRows={expandedRows || []}
                            onRowToggle={(e: DataTableRowToggleParams) => setExpandedRows(e.data)}
                            rowExpansionTemplate={(data) => rowExpansionTemplate(data, publishedCourse)}>
                            <Column expander={true}/>
                            <Column
                                header="Modul"
                                body={data => {
                                    return publishedCourse.modulesAdmin.find(l => l.id === data.moduleId)?.title
                                }}/>
                            <Column
                                header="Modulstart"
                                body={data => {
                                    const startDate = publishedCourse.modulesAdmin.find(l => l.id === data.moduleId)?.startDate || ""
                                    return <DateTimeDisplay
                                        value={startDate}
                                        hideTimezone/>
                                }}/>
                            <Column
                                header="Modulende"
                                body={data => {
                                    const endDate = publishedCourse.modulesAdmin.find(l => l.id === data.moduleId)?.endDate || ""
                                    return <DateTimeDisplay value={endDate} hideTimezone/>
                                }}/>
                        </DataTable>
                    </div>
                </>
            )
            break;
        case 1:
            activeTab = historyEntries.map(entry => <HistoryItem entryFragmentRef={entry}/>)
            break;
    }

    return user && <>
        <Card className="mb-8">
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={tab => setActiveIndex(tab.index)}/>
            {activeTab}
        </Card>
    </>
}

