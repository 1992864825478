/**
 * @generated SignedSource<<a531f41851c710d3a0678febc12f5834>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceDataForm_OrderFragment$data = {
  readonly invoiceData: {
    readonly invoiceDataBase64: string;
    readonly invoiceId: string;
    readonly invoiceNumber: string;
  } | null;
  readonly " $fragmentType": "InvoiceDataForm_OrderFragment";
};
export type InvoiceDataForm_OrderFragment$key = {
  readonly " $data"?: InvoiceDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceData",
      "kind": "LinkedField",
      "name": "invoiceData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invoiceDataBase64",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "9378512b530fbe40994d39ca16d8e47a";

export default node;
