import React from 'react'
import {useFormik} from "formik";
import styled from 'styled-components'
import {ValidatedField} from "../fields/ValidatedField";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {DefaultEditorComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {OrderList} from "primereact/orderlist";
import {useNavigate} from "react-router-dom";
import {graphql} from 'babel-plugin-relay/macro'
import {RemoveModuleButton} from "../buttons/RemoveModuleButton";
import {useFragment, useMutation} from "react-relay";
import {AddModuleButton} from "../buttons/AddModuleButton";
import {FileSelectionField} from "../fields/FileSelectionField";
import * as Yup from "yup";
import {TaxPriceInput} from "../../ui/TaxPriceInput";
import {DefaultNumberComponent} from "../../ui/DefaultNumberInput";
import {EditCourseForm_CourseFragment$key} from "../../../__generated__/EditCourseForm_CourseFragment.graphql";
import {
    EditCourseForm_ChangeModuleOrderMutation
} from "../../../__generated__/EditCourseForm_ChangeModuleOrderMutation.graphql";
import {toast} from "react-toastify";
import {LocationInput} from "./LocationInput";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";

const COURSE_FRAGMENT = graphql`
    fragment EditCourseForm_CourseFragment on Course {
        id
        ...AddModuleButton_CourseFragment
        ...RemoveModuleButton_CourseFragment
    }
`

const CHANGE_MODULE_ORDER_MUTATION = graphql`
    mutation EditCourseForm_ChangeModuleOrderMutation($input: ChangeModuleOrderInput!){
        Admin {
            Course {
                changeModuleOrder(input: $input) {
                    course {
                        node {
                            ...CourseScreen_CourseFragment
                        }
                    }
                }
            }
        }
    }
`

export interface FormState {
    id: string
    name: string
    secondName: string
    createdAt?: string
    description?: string | null
    internalInformation?: string | null
    amountOfRegulatedParticipant: number,
    amountOfRebookedParticipant: number
    price: Price
    iconRef?: string | null
    locationRef?: string
    modules: ModulesDisplay[]
}

export interface ModulesDisplay {
    id: string,
    internalTitle: string
    startDate: string
    endDate: string
    trainer: string
}

export interface Price {
    netPrice: number
    grossPrice: number
    taxRatePercentage: number
}

interface OwnProps {
    loading?: boolean
    initialValues: FormState
    onSubmit: (values: FormState) => void

    courseFragmentRef: EditCourseForm_CourseFragment$key | null
}

export const EditCourseForm = ({loading, initialValues, onSubmit, courseFragmentRef}: OwnProps) => {
    const course = useFragment<EditCourseForm_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const [changeOrder] = useMutation<EditCourseForm_ChangeModuleOrderMutation>(CHANGE_MODULE_ORDER_MUTATION)

    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name wird benötigt")
        }),
        initialValues: {
            id: initialValues.id,
            name: initialValues.name,
            secondName: initialValues.secondName,
            amountOfRegulatedParticipant: initialValues.amountOfRegulatedParticipant,
            amountOfRebookedParticipant: initialValues.amountOfRebookedParticipant,
            locationRef: initialValues.locationRef,
            createdAt: initialValues.createdAt,
            description: initialValues.description,
            internalInformation: initialValues.internalInformation,
            price: initialValues.price,
            iconRef: initialValues.iconRef,
            modules: initialValues.modules.map(l => ({
                id: l.id,
                internalTitle: l.internalTitle,
                startDate: l.startDate,
                endDate: l.endDate,
                trainer: l.trainer
            }))
        },
        onSubmit: (values: FormState) => onSubmit(values)
    })

    if (loading) return <ProgressSpinner/>

    return <div className="position-relative">
        <FormContainer onSubmit={() => formik.handleSubmit()} className={"p-fluid"}>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="name"
                    label="Name"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="secondName"
                    label="Zweiter Name"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="iconRef"
                    label="Icon"
                    component={({fieldValue, updateField, fieldName}) => {
                        return <FileSelectionField
                            name={fieldName}
                            selectedFileId={fieldValue}
                            setSelectedFileId={updateField}
                            filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
                        />
                    }}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="locationRef"
                    label="Standort"
                    component={({fieldValue, updateField, fieldName}) => {
                        return <LocationInput
                            selectedLocation={fieldValue}
                            onSelect={updateField}
                        />
                    }}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, number>
                    name="amountOfRegulatedParticipant"
                    label="Verfügbare Plätze (Geregelt)"
                    component={DefaultNumberComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, number>
                    name="amountOfRebookedParticipant"
                    label="Verfügbare Plätze (Umbuchend)"
                    component={DefaultNumberComponent}
                    formikConfig={formik}/>
            </div>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, Price>
                    name="price"
                    label="Preis"
                    required={true}
                    component={({fieldValue, updateField, fieldName}) => {
                        return <TaxPriceInput
                            taxRate={fieldValue?.taxRatePercentage || 0}
                            netValue={fieldValue?.netPrice || 0}
                            grossValue={fieldValue?.grossPrice || 0}
                            onBlur={formik.handleBlur}
                            updatePrice={(netPrice, grossPrice) => updateField(
                                {
                                    netPrice: netPrice,
                                    grossPrice: grossPrice,
                                    taxRatePercentage: 0
                                }
                            )}/>
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="description"
                    label="Beschreibung"
                    component={DefaultEditorComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>

            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="internalInformation"
                    label="Interne Informationen"
                    component={DefaultEditorComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>

            <Button type={"button"}
                    onClick={() => formik.handleSubmit()}
                    style={{marginTop: "50px"}}>
                Lehrgang Speichern
            </Button>

            <WholeGridSpaceContainer>
                <ValidatedField<FormState, ModulesDisplay[]>
                    name="modules"
                    label="Module"
                    required={true}
                    component={({fieldValue, updateField}) => {
                        return (
                            <div className="flex flex-column">
                                <AddModuleButton courseFragmentRef={course}/>
                                <OrderList
                                    dragdrop={false}
                                    onChange={e => {
                                        if (course && e.value.length > 0 && !e.value.some((l: any) => l === undefined)) {
                                            changeOrder({
                                                variables: {
                                                    input: {
                                                        courseId: course.id,
                                                        moduleRefs: e.value.map((m: ModulesDisplay) => m.id)
                                                    }
                                                },
                                                onCompleted: () => {
                                                    toast.success("Reihenfolge erfolgreich gespeichert")
                                                }
                                            })
                                        }
                                    }}
                                    itemTemplate={(item) => {
                                        return <div className="flex align-items-center" onDoubleClick={() => {
                                            navigate(`/courses/${course?.id}/${item.id}/edit`)
                                        }}>
                                            <div className="grid w-full">
                                                <div
                                                    className="col md:col lg:col flex align-items-center">{item.internalTitle}</div>
                                                <div
                                                    className="col md:col lg:col flex align-items-center">{item.trainer}</div>
                                                <div className="col-2 md:col-6 lg:col-5  flex align-items-center">
                                                    <span className="mr-2">Vom: </span>
                                                    <DateTimeDisplay value={item.startDate}
                                                                     hideTimezone
                                                                     showTime={false}/>
                                                    <div className="ml-2 mr-2">Bis:</div>
                                                    <DateTimeDisplay value={item.endDate}
                                                                     hideTimezone
                                                                     showTime={false}/>
                                                </div>
                                                <div className="col-fixed">
                                                    <Button
                                                        className="ml-auto"
                                                        tooltip="Bearbeiten"
                                                        type={"button"}
                                                        icon="pi pi-pencil"
                                                        onClick={() => {
                                                            navigate(`/courses/${course?.id}/${item.id}/edit`)
                                                        }}
                                                    />
                                                    <RemoveModuleButton courseFragmentRef={course}
                                                                        moduleId={item.id}/>
                                                </div>
                                            </div>
                                        </div>
                                    }}
                                    value={fieldValue}/>
                            </div>
                        )
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
        </FormContainer>
    </div>
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const WholeGridSpaceContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 1rem 0;
`
