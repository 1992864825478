import React, {useEffect, useState} from 'react'
import {Button} from "primereact/button";
import {useAuthedAxios} from "../../../hooks/useAuthedAxios";
import {csvDownloader} from "../../../utils/course-template-utils";
import {LoginData} from "../../../slices/AuthSlice";

interface OwnProps {
    publishedCourseIds: string[],
}

interface State {
    publishedCourseCsv: {
        filename: string
    }
    payload: {
        publishedCourseIds: string[]
    }
    isDownloading: boolean
}

export const CreateCSVButton = ({publishedCourseIds}: OwnProps) => {
    const loginData: LoginData = localStorage.getItem("tkt-login-data") ? JSON.parse(localStorage.getItem("tkt-login-data")!) : undefined;

    const axios = useAuthedAxios(
        `${process.env.REACT_APP_API_BASE}`,
        {
            token: loginData.token,
            currentAccountId: loginData.groupAssociations.find(_ => true)!.account.id!
        }
    )

    const [state, setState] = useState<State>({
            publishedCourseCsv: {
                filename: "Termine.csv"
            },
            payload: {
                publishedCourseIds: [],
            },
            isDownloading: false
        }
    )

    useEffect(() => setState({
        ...state, payload: {
            publishedCourseIds: publishedCourseIds
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [publishedCourseIds])

    const downloadCsv = () => {
        setState({...state, isDownloading: true})
        csvDownloader(
            axios,
            `/api/published-courses`,
            state.publishedCourseCsv.filename,
            state.payload,
            () => setState({...state, isDownloading: false})
        )
    }

    return <div className={"flex justify-content-end mb-2"}>
        <Button label="CSV herunterladen" onClick={() => downloadCsv()}/>
    </div>
}
