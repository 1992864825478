import React from "react";
import { InputText } from "primereact/inputtext";

interface OwnProps {
	label: string;
	value?: string;
}

export const TextDisplayField = ({ label, value }: OwnProps) => {
	return (
		<div className="field col">
			<label htmlFor={value} className="col-fixed">
				{label}
			</label>
			<InputText
				id={value}
				tooltip={value}
				tooltipOptions={{ position: "top" }}
				className="w-full"
				value={value}
			/>
		</div>
	);
};
