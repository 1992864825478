/**
 * @generated SignedSource<<78f0c8730351856de4c8d061abc98102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type LoginInput = {
  clientMutationId?: string | null;
  email: string;
  password: string;
};
export type LoginScreen_LoginMutation$variables = {
  input: LoginInput;
};
export type LoginScreen_LoginMutation$data = {
  readonly Auth: {
    readonly login: {
      readonly groupAssociations: ReadonlyArray<{
        readonly account: {
          readonly id: string;
        } | null;
      }>;
      readonly token: string;
      readonly userId: string;
    } | null;
  };
};
export type LoginScreen_LoginMutation = {
  response: LoginScreen_LoginMutation$data;
  variables: LoginScreen_LoginMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AuthMutations",
    "kind": "LinkedField",
    "name": "Auth",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
          }
        ],
        "concreteType": "LoginPayload",
        "kind": "LinkedField",
        "name": "login",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserGroupAssociation",
            "kind": "LinkedField",
            "name": "groupAssociations",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Account",
                "kind": "LinkedField",
                "name": "account",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginScreen_LoginMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LoginScreen_LoginMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ce369c0ee0275030c3ee42f37e91cff6",
    "id": null,
    "metadata": {},
    "name": "LoginScreen_LoginMutation",
    "operationKind": "mutation",
    "text": "mutation LoginScreen_LoginMutation(\n  $input: LoginInput!\n) {\n  Auth {\n    login(input: $input) {\n      userId\n      token\n      groupAssociations {\n        account {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "dec42bacba78c9c24a41b867a63a7909";

export default node;
