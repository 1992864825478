import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { PrivateBillingDetailsForm_BillingDetailsFragment$key } from "../../../../__generated__/PrivateBillingDetailsForm_BillingDetailsFragment.graphql";
import { TextDisplayField } from "../../ui/TextDisplayField";
import { DateTimeDisplayField } from "../../ui/DateTimeDisplayField";

const PRIVATE_BILLING_DETAILS_FRAGMENT = graphql`
	fragment PrivateBillingDetailsForm_BillingDetailsFragment on PrivateBillingDetails {
		invoiceAddress {
			firstName
			lastName
			postalCode
			city
			country
			houseNumber
			street
			companyDetails
			companyName
		}
		dateOfBirth
		invoiceEmail
		houseNumber
		salutation
		postalCode
		firstName
		lastName
		country
		street
		city
	}
`;

interface OwnProps {
	privateBillingDetailsFragmentRef: PrivateBillingDetailsForm_BillingDetailsFragment$key;
}

export const PrivateBillingDetailsForm = ({ privateBillingDetailsFragmentRef }: OwnProps) => {
	const privateBillingDetails = useFragment<PrivateBillingDetailsForm_BillingDetailsFragment$key>(
		PRIVATE_BILLING_DETAILS_FRAGMENT,
		privateBillingDetailsFragmentRef,
	);

	return (
		<div>
			<div className="formgrid grid">
				<TextDisplayField label="Vorname" value={privateBillingDetails.firstName} />
				<TextDisplayField label="Nachname" value={privateBillingDetails.lastName} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Straße" value={privateBillingDetails.street} />
				<TextDisplayField label="Hausnummer" value={privateBillingDetails.houseNumber} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Stadt/Ort" value={privateBillingDetails.city} />
				<TextDisplayField label="Postleitzahl" value={privateBillingDetails.postalCode} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Land" value={privateBillingDetails.country} />
				<TextDisplayField label="Anrede" value={privateBillingDetails.salutation} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Rechnungs E-Mail"
					value={privateBillingDetails.invoiceEmail}
				/>
				<DateTimeDisplayField
					label="Geburtstag"
					value={privateBillingDetails.dateOfBirth}
					hideTimeZone
				/>
			</div>
			{privateBillingDetails.invoiceAddress && (
				<div>
					<h4>Rechnungsadresse</h4>
					<div className="formgrid grid">
						<TextDisplayField
							label="Vorname"
							value={privateBillingDetails.invoiceAddress.firstName}
						/>
						<TextDisplayField
							label="Nachname"
							value={privateBillingDetails.invoiceAddress.lastName}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Straße"
							value={privateBillingDetails.invoiceAddress.street}
						/>
						<TextDisplayField
							label="Hausnummer"
							value={privateBillingDetails.invoiceAddress.houseNumber}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Stadt/Ort"
							value={privateBillingDetails.invoiceAddress.city}
						/>
						<TextDisplayField
							label="Postleitzahl"
							value={privateBillingDetails.invoiceAddress.postalCode}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Land"
							value={privateBillingDetails.invoiceAddress.country}
						/>
						<TextDisplayField
							label="Unternehmensname"
							value={privateBillingDetails.invoiceAddress.companyName}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Unternehmensdetails"
							value={privateBillingDetails.invoiceAddress.companyDetails || undefined}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
