/**
 * @generated SignedSource<<df52f30d33c8cb578447c52b082dc1b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ParticipantsInformation_CourseStateFragment$data = {
  readonly moduleStates: ReadonlyArray<{
    readonly amountOfRebookedParticipants: number;
    readonly amountOfRegularParticipants: number;
    readonly amountOfWaitingListParticipants: number;
    readonly moduleId: string;
  }>;
  readonly publishedCourse: {
    readonly amountOfRebookedParticipant: number;
    readonly amountOfRegulatedParticipant: number;
  };
  readonly " $fragmentType": "ParticipantsInformation_CourseStateFragment";
};
export type ParticipantsInformation_CourseStateFragment$key = {
  readonly " $data"?: ParticipantsInformation_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantsInformation_CourseStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantsInformation_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleState",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountOfRebookedParticipants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountOfRegularParticipants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountOfWaitingListParticipants",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishedCourse",
      "kind": "LinkedField",
      "name": "publishedCourse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountOfRegulatedParticipant",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amountOfRebookedParticipant",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};

(node as any).hash = "7feec0c2a35daed23e823f163fa0dfe4";

export default node;
