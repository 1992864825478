import {graphql} from "babel-plugin-relay/macro";
import {TkButtonLink} from "../../ui/TkButtonLink";
import {EditGroupModal} from "../modals/EditGroupModal";
import {useFragment} from "react-relay";
import {useState} from "react";
import {EditGroupButton_GroupFragment$key} from "../../../__generated__/EditGroupButton_GroupFragment.graphql";


const FRAGMENT = graphql`
    fragment EditGroupButton_GroupFragment on Group {
        ...EditGroupModal_GroupFragment
    }
`

interface OwnProps {
    className?: string
    groupFragmentRef: EditGroupButton_GroupFragment$key
}


export const EditGroupButton = ({className, groupFragmentRef}: OwnProps) => {
    const [isVisible, setVisible] = useState(false)
    const group = useFragment<EditGroupButton_GroupFragment$key>(FRAGMENT, groupFragmentRef)
    return <>
        <TkButtonLink className={className} icon="pi pi-pencil" iconPos="left" label="Edit"
                      onClick={() => setVisible(true)}/>

        {isVisible && <EditGroupModal
            isVisible={isVisible}
            onHide={() => setVisible(false)}
            onCompleted={() => setVisible(false)}
            groupFragmentRef={group}
        />}
    </>

}
