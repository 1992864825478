/**
 * @generated SignedSource<<702b0188317d600bfc88daf6d04411a1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserHistoryScreen_UserFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_UserFragment">;
  readonly " $fragmentType": "UserHistoryScreen_UserFragment";
};
export type UserHistoryScreen_UserFragment$key = {
  readonly " $data"?: UserHistoryScreen_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserHistoryScreen_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserHistoryScreen_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseStatesTable_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "82deb2d9a2ec95461744756282d5171e";

export default node;
