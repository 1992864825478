/**
 * @generated SignedSource<<d3ec2b8e176bbd14e523fcadf1e35627>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DiscountCodeTable_Refetch$variables = {
  after?: string | null;
  first: number;
  id: string;
};
export type DiscountCodeTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DiscountCodeTable_QueryFragment">;
};
export type DiscountCodeTable_Refetch = {
  response: DiscountCodeTable_Refetch$data;
  variables: DiscountCodeTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "id",
  "variableName": "id"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  (v1/*: any*/),
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountCodeTable_Refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DiscountCodeTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountCodeTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": [
          (v3/*: any*/)
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v6/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "discountCodes",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "numUsages",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v6/*: any*/),
                "filters": null,
                "handle": "connection",
                "key": "DiscountCodeTable_discountCodes",
                "kind": "LinkedHandle",
                "name": "discountCodes"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "discountCodesCSV",
                "storageKey": null
              }
            ],
            "type": "DiscountAction",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4194a6f477b9200a617b699c310a38f6",
    "id": null,
    "metadata": {},
    "name": "DiscountCodeTable_Refetch",
    "operationKind": "query",
    "text": "query DiscountCodeTable_Refetch(\n  $after: String\n  $first: Int!\n  $id: ID!\n) {\n  ...DiscountCodeTable_QueryFragment_XKRaI\n}\n\nfragment CodeActionColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeCodeColumn_DiscountCodeFragment on DiscountCode {\n  code\n}\n\nfragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCode {\n  createdAt\n}\n\nfragment CodeIdColumn_DiscountCodeFragment on DiscountCode {\n  id\n}\n\nfragment CodeNumUsagesColumn_DiscountCodeFragment on DiscountCode {\n  numUsages\n}\n\nfragment CreateRandomCodesButton_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment CreateSingleCodeButton_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment DiscountCodeCodesTableHeader_DiscountActionFragment on DiscountAction {\n  ...CreateRandomCodesButton_DiscountActionFragment\n  ...CreateSingleCodeButton_DiscountActionFragment\n  ...DownloadCodesCsvButton_DiscountActionFragment\n}\n\nfragment DiscountCodeTable_DiscountCodeFragment on DiscountCode {\n  ...CodeIdColumn_DiscountCodeFragment\n  ...CodeCodeColumn_DiscountCodeFragment\n  ...CodeNumUsagesColumn_DiscountCodeFragment\n  ...CodeCreatedAtColumn_DiscountCodeFragment\n  ...CodeActionColumn_DiscountCodeFragment\n}\n\nfragment DiscountCodeTable_QueryFragment_XKRaI on Query {\n  node(id: $id) {\n    __typename\n    ... on DiscountAction {\n      discountCodes(after: $after, first: $first) {\n        edges {\n          node {\n            ...DiscountCodeTable_DiscountCodeFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          startCursor\n          endCursor\n          hasPreviousPage\n          hasNextPage\n        }\n      }\n      ...DiscountCodeCodesTableHeader_DiscountActionFragment\n    }\n    id\n  }\n}\n\nfragment DownloadCodesCsvButton_DiscountActionFragment on DiscountAction {\n  discountCodesCSV\n}\n"
  }
};
})();

(node as any).hash = "2ef19dfbf56d89331a5220a58dd4e798";

export default node;
