import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderHistoryStatusColumn_OrderHistoryFragment$key } from "../../../../../__generated__/OrderHistoryStatusColumn_OrderHistoryFragment.graphql";
import { OrderStatusType } from "../../../../translations/OrderStatusType";

const ORDER_HISTORY_FRAGMENT = graphql`
	fragment OrderHistoryStatusColumn_OrderHistoryFragment on OrderHistory {
		status
	}
`;

interface OwnProps {
	orderHistoryFragmentRef: OrderHistoryStatusColumn_OrderHistoryFragment$key;
}

export const OrderHistoryStatusColumn = ({ orderHistoryFragmentRef }: OwnProps) => {
	const orderHistory = useFragment<OrderHistoryStatusColumn_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	return <div>{OrderStatusType[orderHistory.status]}</div>;
};
