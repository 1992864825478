import {graphql} from "babel-plugin-relay/macro";
import {fetchQuery, useRelayEnvironment} from "react-relay";
import {useEffect, useState} from "react";
import {TrainerInput_Query} from "../../../__generated__/TrainerInput_Query.graphql";
import {MultiSelect} from "primereact/multiselect";

const QUERY = graphql`
    query TrainerInput_Query($first: Int, $after: String){
        Admin {
            User {
                Trainers(first: $first, after: $after) {
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            email
                            name
                        }
                    }
                }
            }
        }
    }
`


interface OwnProps {
    selectedTrainers?: string[] | null
    onSelect: (trainerIds?: string[]) => void
    placeholder?: string
}

export interface TrainerProps {
    id: string,
    email: string,
    name: string
}

export const TrainerInput = ({selectedTrainers, onSelect, placeholder}: OwnProps) => {
    const environment = useRelayEnvironment();

    const [trainers, setTrainers] = useState<TrainerProps[]>([])

    const queryTrainer = () => fetchQuery<TrainerInput_Query>(environment, QUERY, {})
        .toPromise().then(result => {
            setTrainers(() => result!.Admin.User.Trainers.edges!.map(e => ({
                id: e!.node!.id,
                email: e!.node.email,
                name: e!.node!.name
            })))
        })

    useEffect(() => {
        queryTrainer().then()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const trainerOptionTemplate = (option: any) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    return (
        <div className="m-1">
            <MultiSelect value={trainers?.filter(l => selectedTrainers?.includes(l.id))}
                         options={trainers}
                         onChange={e => onSelect(e.value.map((l: any) => l.id))}
                         optionLabel="name"
                         filter
                         showClear
                         onFilter={e => {
                             queryTrainer().then()
                         }}
                         filterBy="name"
                         placeholder={placeholder || "Ausbilder auswählen"}
                         itemTemplate={trainerOptionTemplate}/>
        </div>
    )

}
