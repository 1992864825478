import axios from "axios";

export const useAuthedAxios = (apiBase: string, state: {token: string, currentAccountId: string}) => {
    const axiosInstance = axios.create({
        baseURL: apiBase
    });

    const token = state.token
    const accountId = state.currentAccountId

    if (token && accountId) {
        axiosInstance.defaults.headers.post["X-Auth-Token"] = token;
        axiosInstance.defaults.headers.post["X-Auth-Account-Id"] = accountId;
        axiosInstance.defaults.headers.get["X-Auth-Token"] = token;
        axiosInstance.defaults.headers.get["X-Auth-Account-Id"] = accountId;
        axiosInstance.defaults.headers.delete["X-Auth-Token"] = token;
        axiosInstance.defaults.headers.delete["X-Auth-Account-Id"] = accountId;
        axiosInstance.defaults.headers.head["X-Auth-Token"] = token;
        axiosInstance.defaults.headers.head["X-Auth-Account-Id"] = accountId;
    }

    return axiosInstance;
};
