/**
 * @generated SignedSource<<52c48006e396718350b6091867d049b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeModuleOrderInput = {
  clientMutationId?: string | null;
  courseId: string;
  moduleRefs: ReadonlyArray<string>;
};
export type EditCourseForm_ChangeModuleOrderMutation$variables = {
  input: ChangeModuleOrderInput;
};
export type EditCourseForm_ChangeModuleOrderMutation$data = {
  readonly Admin: {
    readonly Course: {
      readonly changeModuleOrder: {
        readonly course: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"CourseScreen_CourseFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditCourseForm_ChangeModuleOrderMutation = {
  response: EditCourseForm_ChangeModuleOrderMutation$data;
  variables: EditCourseForm_ChangeModuleOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCourseForm_ChangeModuleOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeModuleOrderPayload",
                "kind": "LinkedField",
                "name": "changeModuleOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoursesEdge",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Course",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseScreen_CourseFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditCourseForm_ChangeModuleOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeModuleOrderPayload",
                "kind": "LinkedField",
                "name": "changeModuleOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CoursesEdge",
                    "kind": "LinkedField",
                    "name": "course",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Course",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secondName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "icon",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locationRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalInformation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRebookedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRegulatedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Price",
                            "kind": "LinkedField",
                            "name": "price",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grossPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "netPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxRatePercentage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Module",
                            "kind": "LinkedField",
                            "name": "modules",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "internalTitle",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endDate",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "trainers",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ac6bae07c4dace31c8162245dd3429ba",
    "id": null,
    "metadata": {},
    "name": "EditCourseForm_ChangeModuleOrderMutation",
    "operationKind": "mutation",
    "text": "mutation EditCourseForm_ChangeModuleOrderMutation(\n  $input: ChangeModuleOrderInput!\n) {\n  Admin {\n    Course {\n      changeModuleOrder(input: $input) {\n        course {\n          node {\n            ...CourseScreen_CourseFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment AddModuleButton_CourseFragment on Course {\n  id\n}\n\nfragment CourseHeader_CourseFragment on Course {\n  id\n  secondName\n}\n\nfragment CourseScreen_CourseFragment on Course {\n  id\n  name\n  secondName\n  description\n  icon {\n    id\n  }\n  createdAt\n  locationRef\n  internalInformation\n  amountOfRebookedParticipant\n  amountOfRegulatedParticipant\n  price {\n    grossPrice\n    netPrice\n    taxRatePercentage\n  }\n  modules {\n    id\n    internalTitle\n    startDate\n    endDate\n    trainers {\n      name\n      id\n    }\n  }\n  ...EditCourseForm_CourseFragment\n  ...CourseHeader_CourseFragment\n}\n\nfragment EditCourseForm_CourseFragment on Course {\n  id\n  ...AddModuleButton_CourseFragment\n  ...RemoveModuleButton_CourseFragment\n}\n\nfragment RemoveModuleButton_CourseFragment on Course {\n  id\n}\n"
  }
};
})();

(node as any).hash = "d95da366de0fd184d84ca3ff31fc3d5c";

export default node;
