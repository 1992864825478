import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import {
	PaymentDataForm_OrderFragment$key,
	PaymentMethodType,
} from "../../../../__generated__/PaymentDataForm_OrderFragment.graphql";
import { PaymentMethodDisplay } from "../../ui/PaymentMethodDisplay";
import { Divider } from "primereact/divider";

const ORDER_FRAGMENT = graphql`
	fragment PaymentDataForm_OrderFragment on Order {
		allowedPaymentMethods
		selectedPaymentMethod {
			paymentMethodId
			selectedPaymentMethodType
		}
	}
`;

interface OwnProps {
	orderFragmentRef: PaymentDataForm_OrderFragment$key;
}

export const PaymentDataForm = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<PaymentDataForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);
	return (
		<div className="flex flex-wrap flex-row">
			<div>
				<h3>Erlaubte Zahlmethoden</h3>
				{order.allowedPaymentMethods.map(
					(paymentMethod: PaymentMethodType, index: number) => (
						<PaymentMethodDisplay key={index} paymentMethod={paymentMethod} />
					),
				)}
			</div>
			<Divider layout="vertical" />
			<div>
				{order.selectedPaymentMethod?.paymentMethodId && (
					<>
						<h3>Gewählte Zahlmethode</h3>
						<PaymentMethodDisplay
							paymentMethod={order.selectedPaymentMethod?.paymentMethodId}
						/>
					</>
				)}
			</div>
		</div>
	);
};
