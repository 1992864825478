import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {LocationsScreen} from "../screens/location/LocationsScreen";
import {EditLocationScreen} from "../screens/location/EditLocationScreen";

export const EDIT_LOCATION_PATH = "/locations/:locationId/edit";

export const LocationRoutes: SecureRouteDefinition[] = [
    {
        path: "/locations",
        element: <LocationsScreen/>,
        requiredPermissions: ["Sales_Sales"]
    },
    {
        path: EDIT_LOCATION_PATH,
        element: <EditLocationScreen/>,
        requiredPermissions: ["Sales_Sales"]
    }
]
