import graphql from "babel-plugin-relay/macro";
import {usePaginationFragment} from "react-relay";
import React from "react";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {TrainersTable_Refetch} from "../../../__generated__/TrainersTable_Refetch.graphql";
import {TrainersTable_Trainers$key} from "../../../__generated__/TrainersTable_Trainers.graphql";
import {TrainerProps} from "../forms/TrainerInput";

const TRAINERS_FRAGMENT = graphql`
    fragment TrainersTable_Trainers on Query @refetchable(queryName: "TrainersTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
    ){
        Admin {
            User {
                Trainers(first: $first, after: $after) @connection(key: "TrainersTable_Trainers") {
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            email
                            name
                        }
                    }
                }
            }
        }

    }
`;

interface OwnProps {
    trainersFragment: TrainersTable_Trainers$key
}

export const TrainersTable = ({trainersFragment}: OwnProps) => {
    const {
        data: {Admin: {User: {Trainers: {edges: trainers}}}},
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext
    } = usePaginationFragment<TrainersTable_Refetch, TrainersTable_Trainers$key>(TRAINERS_FRAGMENT, trainersFragment)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const items: TrainerProps[] = trainers?.map(e => ({
        id: e!.node!.id,
        email: e!.node.email,
        name: e!.node!.name
    })) || [];

    return <>
        <DataTable className="p-mb-2" value={items}>
            <Column header="E-Mail" body={(row: TrainerProps) => row.email}/>
            <Column header="Name" body={(row: TrainerProps) => row.name}/>
        </DataTable>

        <div className="d-flex justify-content-center align-items-center mt-4">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>

}
