/**
 * @generated SignedSource<<7db5998a2c337f14c7f1beee98591014>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TheKeyScreen_Query$variables = {
  accountId: string;
  first?: number | null;
};
export type TheKeyScreen_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"UsersInAccountAdminTable_GetUsersInAccountListFragment">;
};
export type TheKeyScreen_Query = {
  response: TheKeyScreen_Query$data;
  variables: TheKeyScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "accountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TheKeyScreen_Query",
    "selections": [
      {
        "args": (v2/*: any*/),
        "kind": "FragmentSpread",
        "name": "UsersInAccountAdminTable_GetUsersInAccountListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TheKeyScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ManagementAdminSchema",
            "kind": "LinkedField",
            "name": "Management",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "UserInAccountConnection",
                "kind": "LinkedField",
                "name": "GetUsersInAccount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserInAccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserInAccount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "email",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Group",
                            "kind": "LinkedField",
                            "name": "groups",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v2/*: any*/),
                "filters": [
                  "accountId"
                ],
                "handle": "connection",
                "key": "UsersInAccountAdminTable_GetUsersInAccount",
                "kind": "LinkedHandle",
                "name": "GetUsersInAccount"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a296bd90338e028ffadee62414ebaa2b",
    "id": null,
    "metadata": {},
    "name": "TheKeyScreen_Query",
    "operationKind": "query",
    "text": "query TheKeyScreen_Query(\n  $first: Int\n  $accountId: ID!\n) {\n  ...UsersInAccountAdminTable_GetUsersInAccountListFragment_16yfkd\n}\n\nfragment ChangeUserGroupsAdminButton_UserInAccountFragment on UserInAccount {\n  ...ChangeUserGroupsAdminModal_UserInAccountFragment\n}\n\nfragment ChangeUserGroupsAdminModal_UserInAccountFragment on UserInAccount {\n  id\n  user {\n    id\n  }\n  groups {\n    id\n  }\n}\n\nfragment UsersInAccountAdminTable_GetUsersInAccountListFragment_16yfkd on Query {\n  Admin {\n    Management {\n      GetUsersInAccount(accountId: $accountId, first: $first) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            user {\n              id\n              email\n              name\n            }\n            groups {\n              id\n              name\n            }\n            ...ChangeUserGroupsAdminButton_UserInAccountFragment\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "506744979e38b29a4d68e5566288a788";

export default node;
