import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {AddTrainingDayButton_ModuleFragment$key} from "../../../__generated__/AddTrainingDayButton_ModuleFragment.graphql";
import {AddTrainingDayButton_AddTrainingDayMutation} from "../../../__generated__/AddTrainingDayButton_AddTrainingDayMutation.graphql";

const FRAGMENT = graphql`
    fragment AddTrainingDayButton_ModuleFragment on Module {
        id
    }
`

const CREATE_MUTATION = graphql`
    mutation AddTrainingDayButton_AddTrainingDayMutation($input: CreateTrainingDayInput!) {
        Admin {
             Course {
                createTrainingDay(input: $input){
                    module {
                        node {
                            ...ModuleScreen_ModuleFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseId: string
    moduleFragmentRef: AddTrainingDayButton_ModuleFragment$key | null
}

export const AddTrainingDayButton = ({courseId, moduleFragmentRef}: OwnProps) => {
    const [addTrainingDay, addTrainingDayInFlight] = useMutation<AddTrainingDayButton_AddTrainingDayMutation>(CREATE_MUTATION)
    const module = useFragment<AddTrainingDayButton_ModuleFragment$key>(FRAGMENT, moduleFragmentRef)

    return module ?
        <Button
            icon="pi pi-plus"
            className="align-self-end mb-2"
            tooltip="Ausbildungstag erzeugen"
            loading={addTrainingDayInFlight}
            type={"button"}
            onClick={() => {
                addTrainingDay({
                    variables: {
                        input: {
                            courseId: courseId,
                            moduleId: module.id
                        }
                    }
                })
            }}
        /> :
        null
}
