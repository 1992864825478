import React, { Suspense, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useFragment, useMutation } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { DiscountTypeInput } from "../../../../billing-admin/components/relay/form-fields/DiscountTypeInput";
import { InputNumber } from "primereact/inputnumber";
import { Toast } from "primereact/toast";
import moment from "moment/moment";
import { Calendar } from "primereact/calendar";
import { UsageLimitationInput } from "../../../../billing-admin/components/relay/form-fields/UsageLimitationInput";
import { DiscountActionForm_DiscountActionFragment$key } from "../../../../__generated__/DiscountActionForm_DiscountActionFragment.graphql";
import {
	DiscountActionForm_EditActionMutation,
	UpdateUsageLimited,
} from "../../../../__generated__/DiscountActionForm_EditActionMutation.graphql";
import { DiscountTypeKindInput } from "../../../../__generated__/DiscountActionsScreen_Query.graphql";
import { ValidatedField } from "../../../../billing-admin/components/relay/form-fields/ValidatedField";
import { DefaultTextFieldComponent } from "../../../../billing-admin/components/relay/form-fields/DefaultTextInput";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment DiscountActionForm_DiscountActionFragment on DiscountAction {
		id
		title
		calcType {
			... on DiscountActionEuroCalcType {
				euro
			}
		}
		validUntil {
			kind
			... on DiscountActionValidUntilDateTime {
				dateTime
			}
		}
		discountType {
			kind
		}
		usageLimitation {
			kind
			... on DiscountActionUsageLimited {
				kind
				maxAmountOfUsages {
					... on DiscountActionAmountLimited {
						max
					}
				}
				maxAmountOfUsagesPerAccount {
					... on DiscountActionAmountLimited {
						max
					}
				}
				onlyForAccountIds
				onlyForProductIds
			}
		}
	}
`;

const EDIT_ACTION_MUTATION = graphql`
	mutation DiscountActionForm_EditActionMutation($input: EditEuroDiscountActionInput!) {
		Admin {
			Billing {
				editEuroDiscountAction(input: $input) {
					discountAction {
						...DiscountActionForm_DiscountActionFragment
					}
				}
			}
		}
	}
`;

interface DiscountAction {
	id: string;
	title: string;
	discountType: string;
	euro: number;
	validUntil?: string;
	usageLimitation?: UpdateUsageLimited;
}

interface OwnProps {
	discountActionFragmentRef: DiscountActionForm_DiscountActionFragment$key;
}

export const DiscountActionForm = ({ discountActionFragmentRef }: OwnProps) => {
	const toast = useRef<Toast>(null);

	const discountAction = useFragment<DiscountActionForm_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	const [editActionRaw, isLoading] =
		useMutation<DiscountActionForm_EditActionMutation>(EDIT_ACTION_MUTATION);

	const formik = useFormik<DiscountAction>({
		initialValues: {
			id: discountAction.id,
			title: discountAction.title,
			discountType: discountAction.discountType.kind,
			euro: discountAction.calcType.euro || 0,
			validUntil: discountAction.validUntil.dateTime,
			usageLimitation:
				discountAction.usageLimitation.kind === "limited"
					? {
							maxAmountOfUsages:
								discountAction.usageLimitation.maxAmountOfUsages?.max,
							maxAmountOfUsagesPerAccount:
								discountAction.usageLimitation.maxAmountOfUsagesPerAccount?.max,
							onlyForProductIds:
								discountAction.usageLimitation.onlyForProductIds?.map((l) => l) ||
								[],
							onlyForAccountIds:
								discountAction.usageLimitation.onlyForAccountIds?.map((l) => l) ||
								[],
					  }
					: undefined,
		},
		validationSchema: Yup.object().shape({}),
		onSubmit: (values, { setSubmitting }) => {
			editActionRaw({
				variables: {
					input: {
						discountActionId: discountAction.id,
						newTitle: values.title,
						newDiscountType: values.discountType as DiscountTypeKindInput,
						newEuro: values.euro,
						newValidUntil: values.validUntil,
						newUsageLimitation: values.usageLimitation,
					},
				},
				onCompleted: () => {
					toast.current?.show({
						severity: "success",
						life: 3000,
						summary: "Discount-Aktion",
						detail: "Erfolgreich gespeichert",
					});
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<Card>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				{!isLoading ? (
					<form onSubmit={formik.handleSubmit} className="p-fluid">
						<div className="flex flex-column">
							<Button
								disabled={false}
								type="submit"
								icon="pi pi-save"
								label="Speichern"
								className="ml-auto w-8rem"
							/>
							<ValidatedField<DiscountAction, string>
								name={"title"}
								label="Titel"
								required={true}
								component={DefaultTextFieldComponent}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountAction, string>
								name={"discountType"}
								label="Discount-Typ"
								component={({ fieldValue, updateField }) => (
									<DiscountTypeInput
										fieldValue={fieldValue}
										updateField={updateField}
									/>
								)}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountAction, number>
								name={"euro"}
								label="Wert in €"
								component={({ fieldValue, updateField }) => (
									<InputNumber
											value={fieldValue}
										onChange={(e) => updateField(e.value || undefined)}
									/>
								)}
								formikConfig={formik}
							/>
							<ValidatedField<DiscountAction, string>
								name={"validUntil"}
								label="Gültig bis"
								component={({ fieldName, fieldValue, updateField }) => (
									<Calendar
										name={fieldName}
										dateFormat={"dd.mm.yy"}
										showTime
										showButtonBar
										value={
											fieldValue
												? moment(fieldValue.replace("[UTC]", "")).toDate()
												: undefined
										}
										onChange={(e) =>
											updateField(
												e.value
													? moment(e.value as Date).toISOString(true)
													: undefined,
											)
										}
									/>
								)}
								formikConfig={formik}
							/>
							<Card>
								<ValidatedField<DiscountAction, UpdateUsageLimited>
									name={"usageLimitation"}
									label="Beschränkungen"
									component={({ fieldValue, updateField }) => (
										<UsageLimitationInput
											fieldValue={fieldValue}
											update={updateField}
										/>
									)}
									formikConfig={formik}
								/>
							</Card>
						</div>
					</form>
				) : (
					<ProgressSpinner />
				)}
			</Suspense>
		</Card>
	);
};
