/**
 * @generated SignedSource<<abbfbe4513db631c8d64c39c86912540>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublishedCourseTable_Refetch$variables = {
  after?: string | null;
  filterByCourseLocation?: string | null;
  filterByModuleLocation?: string | null;
  filterByNameOrSecondName?: string | null;
  filterByRebookedFull?: boolean | null;
  filterByRegularFull?: boolean | null;
  filterByStartDate?: string | null;
  filterByTrainers?: ReadonlyArray<string> | null;
  first?: number | null;
};
export type PublishedCourseTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PublishedCourseTable_PublishedCoursesFragment">;
};
export type PublishedCourseTable_Refetch = {
  response: PublishedCourseTable_Refetch$data;
  variables: PublishedCourseTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByCourseLocation"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByModuleLocation"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByNameOrSecondName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByRebookedFull"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByRegularFull"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByStartDate"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByTrainers"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filterByCourseLocation",
    "variableName": "filterByCourseLocation"
  },
  {
    "kind": "Variable",
    "name": "filterByModuleLocation",
    "variableName": "filterByModuleLocation"
  },
  {
    "kind": "Variable",
    "name": "filterByNameOrSecondName",
    "variableName": "filterByNameOrSecondName"
  },
  {
    "kind": "Variable",
    "name": "filterByRebookedFull",
    "variableName": "filterByRebookedFull"
  },
  {
    "kind": "Variable",
    "name": "filterByRegularFull",
    "variableName": "filterByRegularFull"
  },
  {
    "kind": "Variable",
    "name": "filterByStartDate",
    "variableName": "filterByStartDate"
  },
  {
    "kind": "Variable",
    "name": "filterByTrainers",
    "variableName": "filterByTrainers"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishedCourseTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PublishedCourseTable_PublishedCoursesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PublishedCourseTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "PublishedCoursesConnection",
                "kind": "LinkedField",
                "name": "publishedCourses",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PublishedCoursesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PublishedCourse",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "secondName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "productRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "startDate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRegulatedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRebookedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "filterByNameOrSecondName",
                  "filterByRegularFull",
                  "filterByRebookedFull",
                  "filterByCourseLocation",
                  "filterByModuleLocation",
                  "filterByTrainers",
                  "filterByStartDate"
                ],
                "handle": "connection",
                "key": "PublishedCourseTable_publishedCourses",
                "kind": "LinkedHandle",
                "name": "publishedCourses"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8ddb5d00bb55e1e18c36c978b9e7e83",
    "id": null,
    "metadata": {},
    "name": "PublishedCourseTable_Refetch",
    "operationKind": "query",
    "text": "query PublishedCourseTable_Refetch(\n  $after: String\n  $filterByCourseLocation: ID\n  $filterByModuleLocation: ID\n  $filterByNameOrSecondName: String\n  $filterByRebookedFull: Boolean\n  $filterByRegularFull: Boolean\n  $filterByStartDate: ZonedDateTIme\n  $filterByTrainers: [ID!]\n  $first: Int\n) {\n  ...PublishedCourseTable_PublishedCoursesFragment_4x58v\n}\n\nfragment PublishedCourseTable_PublishedCoursesFragment_4x58v on Query {\n  Admin {\n    Course {\n      publishedCourses(first: $first, after: $after, filterByNameOrSecondName: $filterByNameOrSecondName, filterByRegularFull: $filterByRegularFull, filterByRebookedFull: $filterByRebookedFull, filterByCourseLocation: $filterByCourseLocation, filterByModuleLocation: $filterByModuleLocation, filterByTrainers: $filterByTrainers, filterByStartDate: $filterByStartDate) {\n        edges {\n          node {\n            id\n            name\n            secondName\n            productRef\n            startDate\n            amountOfRegulatedParticipant\n            amountOfRebookedParticipant\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7258a21140e24643ca4aafff1412da78";

export default node;
