import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {MultiSelect} from "primereact/multiselect";
import {FieldConfig} from "../../ui/ValidatedField";
import {GroupsSelectField_Query} from "../../../__generated__/GroupsSelectField_Query.graphql";

const QUERY = graphql`
    query GroupsSelectField_Query {
        Management {
            Groups(first: 50) {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
        }
    }
`


export const GroupsSelectField = ({
                                      fieldValue,
                                      fieldName,
                                      updateField
                                  }: FieldConfig<string[]>,) => {
    const {Management: {Groups: {edges: groups}}} = useLazyLoadQuery<GroupsSelectField_Query>(QUERY, {})


    return <MultiSelect
        name={fieldName}
        value={fieldValue}
        options={groups?.map(g => g!.node!).map(p => {
            return ({
                label: p.name,
                name: p.name,
                value: p.id
            });
        })}
        onChange={e => updateField(e.value)}
        filter={true}
        filterBy={"name"}
    />
}
