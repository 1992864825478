/**
 * @generated SignedSource<<439ff629c7991f4ac25e68f88567e2d1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "Email_Templates" | "Files_Delete" | "Management_Management" | "Sales_Sales" | "System_Owner" | "System_Root" | "Trainer_Trainer" | "%future added value";
export type PermissionsField_Query$variables = {};
export type PermissionsField_Query$data = {
  readonly Management: {
    readonly AvailablePermissions: ReadonlyArray<Permission>;
  };
};
export type PermissionsField_Query = {
  response: PermissionsField_Query$data;
  variables: PermissionsField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ManagementQueries",
    "kind": "LinkedField",
    "name": "Management",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "AvailablePermissions",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PermissionsField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PermissionsField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "db8e9491050891d63529fe93fb8f7f37",
    "id": null,
    "metadata": {},
    "name": "PermissionsField_Query",
    "operationKind": "query",
    "text": "query PermissionsField_Query {\n  Management {\n    AvailablePermissions\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff542df62b45565b88145d11c5e4ea51";

export default node;
