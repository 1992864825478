import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { InvoiceSentUpdatePart_InvoiceSentUpdateFragment$key } from "../../../../../../__generated__/InvoiceSentUpdatePart_InvoiceSentUpdateFragment.graphql";

const INVOICE_SENT_UPDATE_FRAGMENT = graphql`
	fragment InvoiceSentUpdatePart_InvoiceSentUpdateFragment on InvoiceSentUpdate {
		kind
		invoiceId
		invoiceNumber
	}
`;

interface OwnProps {
	invoiceSentUpdateFragmentRef: InvoiceSentUpdatePart_InvoiceSentUpdateFragment$key;
}

export const InvoiceSentUpdatePart = ({ invoiceSentUpdateFragmentRef }: OwnProps) => {
	const invoiceSent = useFragment<InvoiceSentUpdatePart_InvoiceSentUpdateFragment$key>(
		INVOICE_SENT_UPDATE_FRAGMENT,
		invoiceSentUpdateFragmentRef,
	);

	return (
		<Fieldset legend={HistoryEventType[invoiceSent.kind]}>
			<div className="grid">
				<div className="col-3">Rechnungs ID:</div>
				<div className="col">{invoiceSent.invoiceId}</div>
			</div>
			<div className="grid">
				<div className="col-3">Rechnungsnummer:</div>
				<div className="col">{invoiceSent.invoiceNumber}</div>
			</div>
		</Fieldset>
	);
};
