/**
 * @generated SignedSource<<b5413f778751c0ae7387039bbd103472>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTrainingDayInput = {
  additionalInformation?: string | null;
  clientMutationId?: string | null;
  date: string;
  id: string;
  internalInformation?: string | null;
  internalTitle: string;
  moduleId: string;
  requiredAmountOfPreviousTrainingDays: number;
};
export type TrainingDayScreen_UpdateMutation$variables = {
  input: EditTrainingDayInput;
};
export type TrainingDayScreen_UpdateMutation$data = {
  readonly Admin: {
    readonly Course: {
      readonly editTrainingDay: {
        readonly trainingDay: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_TrainingDayFragment" | "TrainingDayScreen_TrainingDayFragment">;
          };
        };
      } | null;
    };
  };
};
export type TrainingDayScreen_UpdateMutation = {
  response: TrainingDayScreen_UpdateMutation$data;
  variables: TrainingDayScreen_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrainingDayScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrainingDayPayload",
                "kind": "LinkedField",
                "name": "editTrainingDay",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrainingDaysEdge",
                    "kind": "LinkedField",
                    "name": "trainingDay",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrainingDay",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseHeader_TrainingDayFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TrainingDayScreen_TrainingDayFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrainingDayScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrainingDayPayload",
                "kind": "LinkedField",
                "name": "editTrainingDay",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrainingDaysEdge",
                    "kind": "LinkedField",
                    "name": "trainingDay",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrainingDay",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalInformation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "date",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiredAmountOfPreviousTrainingDays",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionalInformation",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7aa7d1bb931c39b1fb5f8f25ff63d21",
    "id": null,
    "metadata": {},
    "name": "TrainingDayScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation TrainingDayScreen_UpdateMutation(\n  $input: EditTrainingDayInput!\n) {\n  Admin {\n    Course {\n      editTrainingDay(input: $input) {\n        trainingDay {\n          node {\n            id\n            ...CourseHeader_TrainingDayFragment\n            ...TrainingDayScreen_TrainingDayFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CourseHeader_TrainingDayFragment on TrainingDay {\n  id\n  internalTitle\n}\n\nfragment TrainingDayScreen_TrainingDayFragment on TrainingDay {\n  id\n  internalTitle\n  internalInformation\n  date\n  requiredAmountOfPreviousTrainingDays\n  additionalInformation\n  ...CourseHeader_TrainingDayFragment\n}\n"
  }
};
})();

(node as any).hash = "268d0e6e608a48c627b77bcbb5e80d82";

export default node;
