import {DataTable, DataTableSelectionChangeParams} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useMutation, usePaginationFragment} from "react-relay";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {FileUpload} from "primereact/fileupload";
import {useFileUpload} from "../../../hooks/useFileUpload";
import styled from "styled-components";
import {FilesTable_FilesListFragment$key} from "../../../__generated__/FilesTable_FilesListFragment.graphql";
import {FilesTable_Refetch} from "../../../__generated__/FilesTable_Refetch.graphql";
import {FilesTable_DeleteMutation} from "../../../__generated__/FilesTable_DeleteMutation.graphql";

const FILES_FRAGMENT = graphql`
    fragment FilesTable_FilesListFragment on Query @refetchable(queryName: "FilesTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByFileTypes: {type: "[String!]"}
    ){
        Admin {
            Files {
                Files(first: $first, after: $after, fileType: $filterByFileTypes) @connection(key: "FilesTable_Files") {
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            name
                            fileType
                            uploadDateTime
                            thumbnail
                            url
                        }
                    }
                }
            }
        }
    }
`

const DELETE_MUTATION = graphql`
    mutation FilesTable_DeleteMutation($input: DeleteFileInput!, $connections: [ID!]!) {
        Admin {
            Files{
                deleteFile(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

interface SelectionSettings {
    selection: any
    onSelectionChange: (selection: DataTableSelectionChangeParams) => void;
}

interface OwnProps {
    filesFragmentRef: FilesTable_FilesListFragment$key

    selectionSettings?: SelectionSettings
}

export const FilesTable = ({filesFragmentRef, selectionSettings}: OwnProps) => {
    const {
        data: {
            Admin: {Files: {Files: {edges: files}}}
        },
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext,
        refetch
    } = usePaginationFragment<FilesTable_Refetch, FilesTable_FilesListFragment$key>(FILES_FRAGMENT, filesFragmentRef)

    const [deleteBrand, isDeleting] = useMutation<FilesTable_DeleteMutation>(DELETE_MUTATION);

    const {showDialog, dialogComponent} = useDialogLogic();

    const {onBeforeSend} = useFileUpload()


    return <>
        {dialogComponent}
        <FileUpload className="mb-3" name="file"
                    url={`${process.env.REACT_APP_API_BASE}/api/upload`}
                    onUpload={() => {
                        refetch({}, {fetchPolicy: "network-only"})
                    }}

                    onBeforeSend={onBeforeSend}/>

        <DataTable
            className="mb-3"
            value={files?.map(b => b!.node!) as any[]}
            onSelectionChange={selectionSettings ? selectionSettings.onSelectionChange : undefined}
            selection={selectionSettings ? selectionSettings.selection : undefined}
            selectionMode={selectionSettings ? "single" : undefined}
        >
            <Column selectionMode="single" style={{width: '3em'}}/>
            <Column header="Datei" body={row => {
                return <a href={row.url} rel="noreferrer nofollow" target={"_blank"}>{
                    row.thumbnail ? <img height={75} src={row.thumbnail} alt={row.name}/> :
                        <EmptyImageContainer className="flex justify-content-center align-items-center">Keine
                            Vorschau</EmptyImageContainer>}
                </a>;
            }}/>
            <Column header="Name" field="name"/>
            <Column header="Typ" field="fileType"/>
            <Column header="Erstellt am" body={row => {
                return <DateTimeDisplay value={row.uploadDateTime}/>
            }}/>
            <Column header="Aktionen" style={{width: "20%"}} body={item => <>
                <Button
                    disabled={isDeleting}
                    type="button"
                    onClick={() => {
                        showDialog({
                            title: "Datei löschen",
                            content: "Möchten Sie diese Datei wirklich löschen? Das kann nicht rückgängig gemacht werden.",
                            dialogCallback: result => {
                                if (result === "Accept") {
                                    deleteBrand({
                                        variables: {
                                            input: {
                                                ids: [item.id]
                                            },
                                            connections: [
                                                ConnectionHandler.getConnectionID("client:root:Admin:Files", "FilesTable_Files")
                                            ]
                                        }
                                    })
                                }
                            }
                        })
                    }} icon={"pi pi-trash"}/>
            </>}/>
        </DataTable>

        <div className="flex justify-content-center align-items-center">
            <Button
                type="button"
                disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                className="mr-3 p-button-secondary">Zurück</Button>
            <Button
                type="button"
                className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>
}

const EmptyImageContainer = styled.div`
  height: 75px;
  width: 150px;
`
