import React, { Suspense } from "react";
import { ToggleButton } from "primereact/togglebutton";
import { InputNumber } from "primereact/inputnumber";
import { ProgressSpinner } from "primereact/progressspinner";
import graphql from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay";
import { AccountsSelectWithId } from "./AccountsSelectWithId";
import { ProductsSelect } from "./ProductsSelect";
import { UpdateUsageLimited } from "../../../../__generated__/DiscountActionForm_EditActionMutation.graphql";
import { UsageLimitationInput_Query } from "../../../../__generated__/UsageLimitationInput_Query.graphql";

interface OwnProps {
	fieldValue?: UpdateUsageLimited;
	update: (discountTypeKind?: UpdateUsageLimited) => void;
}

const QUERY = graphql`
	query UsageLimitationInput_Query($accountIds: [ID!]!, $productIds: [ID!]!) {
		...AccountsSelectWithId_QueryFragment @arguments(ids: $accountIds)
		...ProductsSelect_QueryFragment @arguments(ids: $productIds)
	}
`;

export const UsageLimitationInput = ({ fieldValue, update }: OwnProps) => {
	const query = useLazyLoadQuery<UsageLimitationInput_Query>(QUERY, {
		accountIds: fieldValue?.onlyForAccountIds || [],
		productIds: fieldValue?.onlyForProductIds || [],
	});

	return (
		<div>
			<ToggleButton
				onLabel="Ja"
				offLabel="Nein"
				className="w-8rem"
				checked={fieldValue !== undefined}
				onChange={(e) =>
					e.value
						? update({
								maxAmountOfUsages: undefined,
								maxAmountOfUsagesPerAccount: undefined,
								onlyForProductIds: [],
								onlyForAccountIds: [],
						  })
						: update(undefined)
				}
			/>
			{fieldValue && (
				<div className="mt-6">
					<span className="p-float-label">
						<InputNumber
							max={1000}
							min={0}
							value={fieldValue?.maxAmountOfUsages || undefined}
							onValueChange={(e) =>
								update({
									...fieldValue,
									maxAmountOfUsages: e.value,
								})
							}
						/>
						<label htmlFor="maxAmountOfUsages">
							maximale Anzahl der Benutzungen (max: 1000)
						</label>
					</span>
					<span className="mt-4 p-float-label">
						<InputNumber
							max={1000}
							min={0}
							value={fieldValue?.maxAmountOfUsagesPerAccount || undefined}
							onValueChange={(e) =>
								update({
									...fieldValue,
									maxAmountOfUsagesPerAccount: e.value,
								})
							}
						/>
						<label htmlFor="maxAmountOfUsagesPerAccount">
							maximale Anzahl der Benutzungen pro Nutzer (max: 1000)
						</label>
					</span>
					<span className="mt-4 p-float-label">
						<Suspense fallback={<ProgressSpinner />}>
							<AccountsSelectWithId
								fieldValue={fieldValue.onlyForAccountIds.map((l) => l) || []}
								onChange={(accountIds) =>
									update({
										...fieldValue,
										onlyForAccountIds: accountIds,
									})
								}
								queryFragmentRef={query}
							/>
						</Suspense>
						<label htmlFor="onlyForAccountIds">Nur für Accounts</label>
					</span>
					<span className="mt-4 p-float-label">
						<Suspense fallback={<ProgressSpinner />}>
							<ProductsSelect
								fieldValue={fieldValue.onlyForProductIds.map((l) => l) || []}
								onChange={(productIds) =>
									update({
										...fieldValue,
										onlyForProductIds: productIds,
									})
								}
								queryFragmentRef={query}
							/>
						</Suspense>
						<label htmlFor="onlyForProductIds">Nur für Lehrgänge</label>
					</span>
				</div>
			)}
		</div>
	);
};
