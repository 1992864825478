/**
 * @generated SignedSource<<bac9c8fc17e8792f4777b331fd356209>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditCourseTemplateForm_CourseTemplateFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddModuleTemplateButton_CourseTemplateFragment" | "RemoveModuleTemplateButton_CourseTemplateFragment">;
  readonly " $fragmentType": "EditCourseTemplateForm_CourseTemplateFragment";
};
export type EditCourseTemplateForm_CourseTemplateFragment$key = {
  readonly " $data"?: EditCourseTemplateForm_CourseTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditCourseTemplateForm_CourseTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditCourseTemplateForm_CourseTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddModuleTemplateButton_CourseTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveModuleTemplateButton_CourseTemplateFragment"
    }
  ],
  "type": "CourseTemplate",
  "abstractKey": null
};

(node as any).hash = "bb330782bc15b95bd64201d9e864cc09";

export default node;
