/**
 * @generated SignedSource<<746193d83203ffbf222f621fb2caa8f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EventType = "CanceledByAdmin" | "CanceledByUser" | "RebookedByUser" | "RegisteredByAdmin" | "RegisteredByUser" | "RegisteredOnWaitingListByAdmin" | "RegisteredOnWaitingListByUser" | "RemovedFromAdmin" | "RemovedFromWaitingListByAdmin" | "RemovedFromWaitingListByUser" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type HistoryItem_EntryFragment$data = {
  readonly destinationCourseState: {
    readonly publishedCourse: {
      readonly modulesAdmin: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      }>;
      readonly name: string;
      readonly secondName: string;
    };
  } | null;
  readonly destinationModuleId: string | null;
  readonly eventType: EventType;
  readonly fromUser: {
    readonly email: string;
  } | null;
  readonly participantsUser: {
    readonly email: string;
  } | null;
  readonly sourceCourseState: {
    readonly publishedCourse: {
      readonly modulesAdmin: ReadonlyArray<{
        readonly id: string;
        readonly title: string;
      }>;
      readonly name: string;
      readonly secondName: string;
    };
  } | null;
  readonly sourceModuleId: string | null;
  readonly " $fragmentType": "HistoryItem_EntryFragment";
};
export type HistoryItem_EntryFragment$key = {
  readonly " $data"?: HistoryItem_EntryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoryItem_EntryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "email",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "PublishedCourse",
    "kind": "LinkedField",
    "name": "publishedCourse",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "secondName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "PublishedModuleAdmin",
        "kind": "LinkedField",
        "name": "modulesAdmin",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistoryItem_EntryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "fromUser",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "participantsUser",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseState",
      "kind": "LinkedField",
      "name": "sourceCourseState",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CourseState",
      "kind": "LinkedField",
      "name": "destinationCourseState",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceModuleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "destinationModuleId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eventType",
      "storageKey": null
    }
  ],
  "type": "Entry",
  "abstractKey": null
};
})();

(node as any).hash = "bbddabf2bbafc2f0dfdedbe27f54e707";

export default node;
