export const HistoryEventI18n: { [key: string]: string } = {
    "RegisteredByUser": "Von Teilnehmer registriert/gebucht",
    "RebookedByUser": "Von Teilnehmer umgebucht",
    "RegisteredOnWaitingListByUser": "Von Teilnehmer auf Warteliste gesetzt",
    "RemovedFromWaitingListByUser": "Von Teilnehmer auf Warteliste entfernt",
    "CanceledByAdmin": "Storniert vom Admin",
    "CanceledByUser": "Abgemeldet vom Nutzer",
    "RegisteredByAdmin": "von Admin registriert",
    "RemovedFromAdmin": "von Admin entfernt",
    "RegisteredOnWaitingListByAdmin": "von Admin auf Warteliste gesetzt",
    "RemovedFromWaitingListByAdmin": "von Admin auf Warteliste entfernt",
    "": "Unbekannt"
}

