import {useFormik} from "formik";
import {Button} from "primereact/button";
import React from "react";
import * as Yup from "yup";
import {
    DefaultEditorComponent,
    DefaultTextFieldComponent
} from "../../ui/DefaultTextInput";
import {ValidatedField} from "../fields/ValidatedField";
import {EMAIL_VARIABLES} from "../../../i18n/email.i18n";

export interface FormState {
    subject?: string
    preview?: string
    body?: string
}

interface OwnProps {
    loading?: boolean
    initialValues?: FormState
    variables: readonly string[],
    onSubmit: (values: FormState) => void
}

export const EditEmailTemplateForm = ({
                                          variables,
                                          loading,
                                          initialValues,
                                          onSubmit
                                      }: OwnProps) => {
    const formik = useFormik<FormState>({
        initialValues: {
            subject: initialValues?.subject ?? "Betreff",
            preview: initialValues?.preview ?? "Vorschautext",
            body: initialValues?.body ?? "Nachricht"
        },
        validationSchema: Yup.object().shape({
            subject: Yup.string().required("Betreff wird benötigt"),
            body: Yup.string().required("Nachricht Code wird benötigt"),
        }),
        onSubmit: (values, {setSubmitting}) => {
            onSubmit({
                ...values,
            })
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedField<FormState, string>
            name={"subject"}
            label={"Betreff"}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />
        <ValidatedField<FormState, string>
            name={"preview"}
            label={"Vorschautext"}
            helpText={"Dieser Text wird in manchen Mailprogrammen angezeigt"}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />

        <ValidatedField<FormState, string>
            name={"body"}
            label={"Nachricht"}
            component={DefaultEditorComponent}
            formikConfig={formik}
        />

        <h3>Verfügbare Variablen</h3>
        <p>Diese Variablen können in allen obigen Feldern eingefügt werden und werden durch die entsprechenden Texte
            ersetzt.</p>
        <ul>
            {variables.map(variable => {
                return <li key={variable}><strong>%%{variable}%%</strong> - {EMAIL_VARIABLES[variable]}</li>
            })}
        </ul>


        <Button
            disabled={loading}
            type="submit"
            label="Speichern"
            className="p-mt-2"/>
    </form>
}
