/**
 * @generated SignedSource<<13fe58d8b78c0689059bf2abb9270d17>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModuleTemplateScreen_ModuleTemplateFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly internalTitle: string;
  readonly isExam: boolean;
  readonly lastUpdate: string;
  readonly shortDescription: string;
  readonly shortTitle: string;
  readonly title: string;
  readonly trainingDays: ReadonlyArray<{
    readonly id: string;
    readonly internalTitle: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"EditModuleTemplateForm_ModuleTemplateFragment" | "TemplateHeader_ModuleTemplateFragment">;
  readonly " $fragmentType": "ModuleTemplateScreen_ModuleTemplateFragment";
};
export type ModuleTemplateScreen_ModuleTemplateFragment$key = {
  readonly " $data"?: ModuleTemplateScreen_ModuleTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModuleTemplateScreen_ModuleTemplateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleTemplateScreen_ModuleTemplateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrainingDayTemplate",
      "kind": "LinkedField",
      "name": "trainingDays",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditModuleTemplateForm_ModuleTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateHeader_ModuleTemplateFragment"
    }
  ],
  "type": "ModuleTemplate",
  "abstractKey": null
};
})();

(node as any).hash = "a5754f59615618bd7eeb8f52db27793d";

export default node;
