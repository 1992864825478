import React, {useState} from 'react'
import graphql from "babel-plugin-relay/macro";
import {Button} from "primereact/button";
import {AddParticipantToCourseStateForm} from "../forms/AddParticipantToCourseStateForm";
import {useFragment} from "react-relay";
import {Dialog} from "primereact/dialog";
import {AddParticipantToModuleForm} from "../forms/AddParticipantToModuleForm";

export type ButtonType = "course-state" | "module" | "training-day"

interface OwnProps {
    buttonType: ButtonType

    moduleId?: string
    trainingDayId?: string

    courseStateFragmentRef: any

    className?: string
}

interface State {
    open: boolean
}

const COURSE_STATE_FRAGMENT = graphql`
    fragment AddParticipantButton_CourseStateFragment on CourseState {
        ... AddParticipantToCourseStateForm_CourseStateFragment
        ... AddParticipantToModuleForm_CourseStateFragment
    }
`

export const AddParticipantButton = ({buttonType, moduleId, trainingDayId, courseStateFragmentRef, className}: OwnProps) => {
    const courseState = useFragment(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const [state, setState] = useState<State>({open: false})

    let dialogContent;

    switch (buttonType) {
        case "course-state":
            dialogContent = <AddParticipantToCourseStateForm className={className} courseStateFragmentRef={courseState} />
            break;
        case "module":
            dialogContent = <AddParticipantToModuleForm className={className} courseStateFragmentRef={courseState} moduleId={moduleId!} />
            break;
        default:
            dialogContent = <h3>Hier ist ein Fehler aufgetreten</h3>
    }

    return <div>
        <Button icon="pi pi-plus"
                        type="button"
        onClick={() => setState({open: true})}/>
        <Dialog onHide={() => setState({open: false})} visible={state?.open}>
            {dialogContent}
        </Dialog>
    </div>
}
