import React, {useContext} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {UsersSearchContext} from "./container/UsersSearchContainer";

export const UsersFilter = () => {
    const {filters, setFilters, clearFilters} = useContext(UsersSearchContext)


    return <div className="flex mb-3 flex-wrap">
        <div className="m-1">
            <InputText
                placeholder={"Name"}
                value={filters.filterByName || ""}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByName: e.target.value
                })}
            />
        </div>
        <div className="m-1">
            <InputText
                placeholder={"E-Mail"}
                value={filters.filterByEmail || ""}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByEmail: e.target.value
                })}
            />
        </div>
        <div className="flex align-items-center m-1">
            <Button className="" tooltip={"Zurücksetzen"} icon="pi pi-times" onClick={() => clearFilters()}/>
        </div>
    </div>
}

