/**
 * @generated SignedSource<<08de53705d7af1b0249a7526592deb97>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderStatusColumn_OrderFragment$data = {
  readonly status: OrderStatus;
  readonly " $fragmentType": "OrderStatusColumn_OrderFragment";
};
export type OrderStatusColumn_OrderFragment$key = {
  readonly " $data"?: OrderStatusColumn_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderStatusColumn_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderStatusColumn_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "4a3f4fef5780667df27b2499b05f10e4";

export default node;
