/**
 * @generated SignedSource<<2a818f58ea0fa5caa6b3e4401997c627>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditTrainingDayTemplateInput = {
  additionalInformation?: string | null;
  clientMutationId?: string | null;
  id: string;
  internalInformation?: string | null;
  internalTitle: string;
  requiredAmountOfPreviousTrainingDays: number;
};
export type TrainingDayTemplateScreen_UpdatingMutation$variables = {
  input: EditTrainingDayTemplateInput;
};
export type TrainingDayTemplateScreen_UpdatingMutation$data = {
  readonly Admin: {
    readonly Template: {
      readonly editTrainingDayTemplate: {
        readonly trainingDayTemplate: {
          readonly node: {
            readonly id: string;
            readonly " $fragmentSpreads": FragmentRefs<"TemplateHeader_TrainingDayTemplateFragment" | "TrainingDayTemplateScreen_TrainingDayTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type TrainingDayTemplateScreen_UpdatingMutation = {
  response: TrainingDayTemplateScreen_UpdatingMutation$data;
  variables: TrainingDayTemplateScreen_UpdatingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TrainingDayTemplateScreen_UpdatingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrainingDayTemplatePayload",
                "kind": "LinkedField",
                "name": "editTrainingDayTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrainingDayTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "trainingDayTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrainingDayTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TemplateHeader_TrainingDayTemplateFragment"
                          },
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "TrainingDayTemplateScreen_TrainingDayTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TrainingDayTemplateScreen_UpdatingMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditTrainingDayTemplatePayload",
                "kind": "LinkedField",
                "name": "editTrainingDayTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TrainingDayTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "trainingDayTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrainingDayTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalTitle",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "internalInformation",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "requiredAmountOfPreviousTrainingDays",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionalInformation",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ef7e3616db7d9ac1e272501abdf393fe",
    "id": null,
    "metadata": {},
    "name": "TrainingDayTemplateScreen_UpdatingMutation",
    "operationKind": "mutation",
    "text": "mutation TrainingDayTemplateScreen_UpdatingMutation(\n  $input: EditTrainingDayTemplateInput!\n) {\n  Admin {\n    Template {\n      editTrainingDayTemplate(input: $input) {\n        trainingDayTemplate {\n          node {\n            id\n            ...TemplateHeader_TrainingDayTemplateFragment\n            ...TrainingDayTemplateScreen_TrainingDayTemplateFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment TemplateHeader_TrainingDayTemplateFragment on TrainingDayTemplate {\n  id\n  internalTitle\n}\n\nfragment TrainingDayTemplateScreen_TrainingDayTemplateFragment on TrainingDayTemplate {\n  id\n  internalTitle\n  internalInformation\n  requiredAmountOfPreviousTrainingDays\n  additionalInformation\n  ...TemplateHeader_TrainingDayTemplateFragment\n}\n"
  }
};
})();

(node as any).hash = "77c922205fee1981d186b8c5eedf97ac";

export default node;
