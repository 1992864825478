/**
 * @generated SignedSource<<e0ed62fe1d2e3f1df0fa46c4d8e32a7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderPaymentMethodColumn_OrderFragment$data = {
  readonly selectedPaymentMethod: {
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly " $fragmentType": "OrderPaymentMethodColumn_OrderFragment";
};
export type OrderPaymentMethodColumn_OrderFragment$key = {
  readonly " $data"?: OrderPaymentMethodColumn_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPaymentMethodColumn_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPaymentMethodColumn_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "cf3a2e5aeb9c28e8b05e2b10c4beb269";

export default node;
