/**
 * @generated SignedSource<<060a1c485ffbb2da426812519b431f52>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeEmailForm_UserFragment$data = {
  readonly email: string;
  readonly id: string;
  readonly " $fragmentType": "ChangeEmailForm_UserFragment";
};
export type ChangeEmailForm_UserFragment$key = {
  readonly " $data"?: ChangeEmailForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeEmailForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeEmailForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "002343948ceb26d0ced9ed935cc4e25b";

export default node;
