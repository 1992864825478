import {Button} from "primereact/button";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {toast} from "react-toastify";
import {DeleteUserButton_DeleteMutation} from "../../../__generated__/DeleteUserButton_DeleteMutation.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {selectPermissionsInAccount} from "../../../slices/AuthSlice";
import {useTypedSelector} from "../../../Store";

const DELETE_MUTATION = graphql`
    mutation DeleteUserButton_DeleteMutation($userId: ID!) {
        Admin {
            Auth {
                deleteUser(input: {userId: $userId}) {
                    deletedIds
                }
            }
        }
    }
`

interface OwnProps {
    userId: string
    className?: string
}

export const DeleteUserButton = ({userId, className}: OwnProps) => {
    const [startImport, isInFlight] = useMutation<DeleteUserButton_DeleteMutation>(DELETE_MUTATION)
    const permissions = useTypedSelector(selectPermissionsInAccount)

    const {dialogComponent, showDialog} = useDialogLogic();

    if(!permissions.includes("System_Owner")) return <></>

    return <>
        {dialogComponent}
        <Button
            icon={"pi pi-trash"}
            label={"Benutzer löschen"}
            className={"p-button-danger " + (className ? className : "")}
            disabled={isInFlight}
            onClick={() => {
                showDialog({
                    title: "Benutzer anonymisieren?",
                    content: "Diesen Benutzer anonymisieren - das kann nicht rückgängig gemacht werden.",
                    affirmativeText: "Benutzer anonymisieren",
                    negativeText: "Nein",
                    dialogCallback: result => {
                        if (result === "Accept") {
                            startImport({
                                variables: {userId: userId},
                                onCompleted: () => {
                                    toast.success("Benutzer wurde anonymisiert.")
                                }
                            })
                        }
                    }
                })

            }}>

        </Button>
    </>
}
