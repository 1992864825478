import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {usePaginationFragment} from "react-relay";
import {UsersTable_Refetch} from "../../../__generated__/UsersTable_Refetch.graphql";
import {UsersTable_UsersFragment$key} from "../../../__generated__/UsersTable_UsersFragment.graphql";
import {useNavigate} from "react-router-dom";
import {useContext} from "react";
import {useDebounceFilters} from "../../../hooks/useDebounceFilter";
import {UsersFilters, UsersSearchContext} from "../filter/container/UsersSearchContainer";
import {UsersFilter} from "../filter/UsersFilter";
import {CreateUserButton} from "../buttons/CreateUserButton";

const USERS_FRAGMENT = graphql`
    fragment UsersTable_UsersFragment on Query @refetchable(queryName: "UsersTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByName: {type: "String"},
        filterByEmail: {type: "String"},
    ){
        Admin {
            User {
                Users(first: $first, after: $after, filterByName: $filterByName, filterByEmail: $filterByEmail) @connection(key: "UsersTable_Users") {
                    __id
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            email
                            name
                            extension {
                                ... on UserExtensionImpl {
                                    isBlocked
                                }
                            }
                            isDeleted
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    usersFragment: UsersTable_UsersFragment$key
}

export const UsersTable = ({usersFragment}: OwnProps) => {
    const navigate = useNavigate();
    const {filters} = useContext(UsersSearchContext)
    const {
        data: {Admin: {User: {Users}}},
        hasPrevious,
        hasNext,
        loadPrevious,
        refetch,
        loadNext,
    } = usePaginationFragment<UsersTable_Refetch, UsersTable_UsersFragment$key>(USERS_FRAGMENT, usersFragment)

    useDebounceFilters<UsersFilters>(filters, refetch)

    return <>
        <div className="flex justify-content-end mb-4">
            <CreateUserButton connectionId={Users.__id}/>
        </div>
        <DataTable header={<UsersFilter/>} className="mb-3" value={Users?.edges?.map(b => b!.node!) as any[]}>
            <Column header="Name" body={row => {
                const isBlocked = row?.extension?.isBlocked
                const name = row.name

                return <div className={isBlocked ? "line-through" : ""}> {name}</div>
            }}/>
            <Column header="E-Mail" body={row => {
                const isBlocked = row?.extension?.isBlocked
                const email = row.email

                return <div className={isBlocked ? "line-through" : ""}> {email}</div>
            }}/>
            <Column header="Aktionen" body={row => {
                return <Button
                    className="mr-2"
                    tooltip="Weitere Informationen"
                    onClick={() => {
                        navigate(`/users/${row.id}/0/edit`)
                    }} icon={"pi pi-info-circle"}/>
            }}/>
        </DataTable>

        <div className="flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>
}
