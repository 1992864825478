export const PERMISSION_TRANSLATIONS: { [key: string]: string } = {
    "Owner": "Inhaber",
    "Trainer_Trainer": "Ausbilder",
    "Sales_Sales": "Berater",
    "Files_Delete": "Datein löschen",
    "Management_Management": "Account managen",
}

export const PERMISSION_NAME_TRANSLATION: { [key: string]: string } = {
    "Owner": "Inhaber",
    "User": "Nutzer"
}
