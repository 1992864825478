import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {Permission} from "../__generated__/CurrentUserSetter_Query.graphql";
import {ReduxState} from "../Store";

export interface LoginData {
    userId: string
    token: string
    groupAssociations: {
        account: {
            id: string
        }
    }[]
}

export interface User {
    id: string
}

export interface CurrentUserData {
    user: User
    permissionsInAccount: Permission[]
}

export interface AuthState {
    isLoggedIn: boolean,
    loginData?: LoginData
    currentUser?: CurrentUserData
}

let parsedLoginData: LoginData | undefined = undefined
try {
    const storedData = localStorage.getItem("tkt-login-data")

    parsedLoginData = storedData ? JSON.parse(storedData) : undefined
} catch {
}

const INITIAL_STATE: AuthState = {
    isLoggedIn: !!parsedLoginData,
    loginData: parsedLoginData
}

const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<{ loginData: LoginData }>) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.isLoggedIn = true
            state.loginData = action.payload.loginData
            localStorage.setItem("tkt-login-data", JSON.stringify(action.payload.loginData))
        },
        logout: (state) => {
            state.isLoggedIn = false
            state.loginData = undefined
            localStorage.removeItem("tkt-login-data")
        },
        setUserData: (state, action: PayloadAction<CurrentUserData>) => {
            state.currentUser = action.payload
        }
    },
})

export const {setLoggedIn, logout, setUserData} = authSlice.actions
export const AuthSliceReducer = authSlice.reducer

const selectAuthSlice = (state: ReduxState) => state.auth

export const selectCurrentUser = createSelector(selectAuthSlice, state =>
    state.currentUser
)

export const selectIsLoggedIn = createSelector(selectAuthSlice, state =>
    state.isLoggedIn
)

export const selectUserAccount = createSelector(selectAuthSlice, state =>
    state.loginData?.groupAssociations.map(l => l.account.id)
)

export const selectPermissionsInAccount = createSelector(selectAuthSlice, state =>
    state.currentUser?.permissionsInAccount || []
)
