/**
 * @generated SignedSource<<b654f54015a631f8c1e166881ab5e85f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ParticipantsScreen_CourseStateFragment$data = {
  readonly id: string;
  readonly publishedCourse: {
    readonly name: string;
    readonly secondName: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AddParticipantButton_CourseStateFragment" | "EditParticipantsForm_CourseStateFragment">;
  readonly " $fragmentType": "ParticipantsScreen_CourseStateFragment";
};
export type ParticipantsScreen_CourseStateFragment$key = {
  readonly " $data"?: ParticipantsScreen_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ParticipantsScreen_CourseStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ParticipantsScreen_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishedCourse",
      "kind": "LinkedField",
      "name": "publishedCourse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditParticipantsForm_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddParticipantButton_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};

(node as any).hash = "49b047b345b95ea4ae8eace0185d4b2d";

export default node;
