import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

interface OwnProps {
	discountActionId: string;
	codeId: string;
}

export const EditCodeButton = ({ discountActionId, codeId }: OwnProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Aktion editieren"
			onClick={(e) => navigate(`/discount-actions/${discountActionId}/${codeId}/edit`)}
		/>
	);
};
