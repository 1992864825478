import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {RenderConfig} from "../relay/fields/ValidatedField";
import {InputTextarea} from "primereact/inputtextarea";
import {Editor} from "primereact/editor";
import React from "react";
import styled from "styled-components";
import {FieldConfig} from "./ValidatedField";


export function DefaultTextFieldComponent({
                                              fieldName,
                                              fieldValue,
                                              updateField,
                                              isValid,
                                              disabled
                                          }: FieldConfig<string>) {
    return <InputText id={fieldName} name={fieldName} value={fieldValue}
                      onChange={e => updateField(e.target.value)}
                      disabled={disabled}
                      className={classNames({"p-invalid": !isValid})}/>
}

export function DefaultTextareaComponent({
                                             fieldName,
                                             fieldValue,
                                             updateField,
                                             isValid,
                                             disabled
                                         }: RenderConfig<string>) {
    return <StyledTextarea id={fieldName} name={fieldName} value={fieldValue}
                           onChange={e => updateField(e.target.value)}
                           disabled={disabled}
                           className={classNames({"p-invalid": !isValid})}/>
}

export function DefaultEditorComponent({
                                           fieldName,
                                           fieldValue,
                                           updateField,
                                           isValid
                                       }: RenderConfig<string>) {
    return <Editor id={fieldName} value={fieldValue}
                   style={{height: '320px'}}
                   onTextChange={(e) => updateField(e.htmlValue || "")}
                   className={classNames({"p-invalid": !isValid})}/>
}


const StyledTextarea = styled(InputTextarea)`
  min-height: 200px;
`
