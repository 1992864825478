import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { OrderCreationUpdatePart_OrderCreationUpdateFragment$key } from "../../../../../../__generated__/OrderCreationUpdatePart_OrderCreationUpdateFragment.graphql";

const ORDER_CREATION_UPDATE_FRAGMENT = graphql`
	fragment OrderCreationUpdatePart_OrderCreationUpdateFragment on OrderCreationUpdate {
		kind
	}
`;

interface OwnProps {
	orderCreationUpdateFragmentRef: OrderCreationUpdatePart_OrderCreationUpdateFragment$key;
}

export const OrderCreationUpdatePart = ({ orderCreationUpdateFragmentRef }: OwnProps) => {
	const orderCreationUpdate =
		useFragment<OrderCreationUpdatePart_OrderCreationUpdateFragment$key>(
			ORDER_CREATION_UPDATE_FRAGMENT,
			orderCreationUpdateFragmentRef,
		);

	return <Fieldset legend={HistoryEventType[orderCreationUpdate.kind]}></Fieldset>;
};
