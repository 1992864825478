import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import React from "react";
import {OrdersScreen} from "../screens/billing/OrdersScreen";
import { EditOrderScreen } from "../screens/billing/EditOrderScreen";
import { DiscountActionsScreen } from "../screens/billing/DiscountActionsScreen";
import {DiscountActionScreen} from "../screens/billing/DiscountActionScreen";
import {DiscountCodeScreen} from "../screens/billing/DiscountCodeScreen";

export const ORDERS_PATH = "/orders";
export const EDIT_ORDERS_PATH = "/orders/:orderId/edit";
export const DISCOUNT_ACTIONS_PATH = "/discount-actions";
export const EDIT_DISCOUNT_ACTION_PATH = "/discount-actions/:discountActionId/edit";
export const EDIT_DISCOUNT_CODE_PATH = "/discount-actions/:discountActionId/:codeId/edit";


export const BillingRoutes: SecureRouteDefinition[] = [
    {
        path: ORDERS_PATH,
        element: <OrdersScreen />,
        requiredPermissions: [],
    },
    {
        path: EDIT_ORDERS_PATH,
        element: <EditOrderScreen />,
        requiredPermissions: ["System_Root"],
    },
    {
        path: DISCOUNT_ACTIONS_PATH,
        element: <DiscountActionsScreen />,
        requiredPermissions: [],
    },
    {
        path: EDIT_DISCOUNT_ACTION_PATH,
        element: <DiscountActionScreen />,
        requiredPermissions: ["System_Root"],
    },
    {
        path: EDIT_DISCOUNT_CODE_PATH,
        element: <DiscountCodeScreen />,
        requiredPermissions: ["System_Root"],
    },
]
