import moment from "moment-timezone";

interface OwnProps {
	value?: string | null;
	hideTimezone?: boolean;
}

export const formatDateTime = (value: string) => {
	return moment(value.replace("[UTC]", "")).tz(moment.tz.guess()).format("DD.MM.YYYY HH:mm");
};

export const DateTimeDisplay = ({ value, hideTimezone }: OwnProps) => {
	if (!value) {
		return null;
	}

	const formattedDateTime = formatDateTime(value);

	if (hideTimezone) {
		return <span>{formattedDateTime}</span>;
	}

	return (
		<div>
			<div>{formattedDateTime}</div>
			{!hideTimezone && <small>{moment.tz.guess()}</small>}
		</div>
	);
};
