/**
 * @generated SignedSource<<a6a8e2005090a35a4370dddac2bad59d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditEuroDiscountActionInput = {
  clientMutationId?: string | null;
  discountActionId: string;
  newDiscountType: string;
  newEuro: number;
  newTitle: string;
  newUsageLimitation?: UpdateUsageLimited | null;
  newValidUntil?: string | null;
};
export type UpdateUsageLimited = {
  maxAmountOfUsages?: number | null;
  maxAmountOfUsagesPerAccount?: number | null;
  onlyForAccountIds: ReadonlyArray<string>;
  onlyForProductIds: ReadonlyArray<string>;
};
export type DiscountActionForm_EditActionMutation$variables = {
  input: EditEuroDiscountActionInput;
};
export type DiscountActionForm_EditActionMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly editEuroDiscountAction: {
        readonly discountAction: {
          readonly " $fragmentSpreads": FragmentRefs<"DiscountActionForm_DiscountActionFragment">;
        };
      } | null;
    };
  };
};
export type DiscountActionForm_EditActionMutation = {
  response: DiscountActionForm_EditActionMutation$data;
  variables: DiscountActionForm_EditActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = [
  (v2/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "max",
        "storageKey": null
      }
    ],
    "type": "DiscountActionAmountLimited",
    "abstractKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DiscountActionForm_EditActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditEuroDiscountActionPayload",
                "kind": "LinkedField",
                "name": "editEuroDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountAction",
                    "kind": "LinkedField",
                    "name": "discountAction",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "DiscountActionForm_DiscountActionFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DiscountActionForm_EditActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditEuroDiscountActionPayload",
                "kind": "LinkedField",
                "name": "editEuroDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountAction",
                    "kind": "LinkedField",
                    "name": "discountAction",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "calcType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "euro",
                                "storageKey": null
                              }
                            ],
                            "type": "DiscountActionEuroCalcType",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "validUntil",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dateTime",
                                "storageKey": null
                              }
                            ],
                            "type": "DiscountActionValidUntilDateTime",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "discountType",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "usageLimitation",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "maxAmountOfUsages",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "maxAmountOfUsagesPerAccount",
                                "plural": false,
                                "selections": (v4/*: any*/),
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "onlyForAccountIds",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "onlyForProductIds",
                                "storageKey": null
                              }
                            ],
                            "type": "DiscountActionUsageLimited",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bcc7d35c0592d59320f45d5d0fd8836d",
    "id": null,
    "metadata": {},
    "name": "DiscountActionForm_EditActionMutation",
    "operationKind": "mutation",
    "text": "mutation DiscountActionForm_EditActionMutation(\n  $input: EditEuroDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      editEuroDiscountAction(input: $input) {\n        discountAction {\n          ...DiscountActionForm_DiscountActionFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DiscountActionForm_DiscountActionFragment on DiscountAction {\n  id\n  title\n  calcType {\n    __typename\n    ... on DiscountActionEuroCalcType {\n      euro\n    }\n  }\n  validUntil {\n    __typename\n    kind\n    ... on DiscountActionValidUntilDateTime {\n      dateTime\n    }\n  }\n  discountType {\n    __typename\n    kind\n  }\n  usageLimitation {\n    __typename\n    kind\n    ... on DiscountActionUsageLimited {\n      kind\n      maxAmountOfUsages {\n        __typename\n        ... on DiscountActionAmountLimited {\n          max\n        }\n      }\n      maxAmountOfUsagesPerAccount {\n        __typename\n        ... on DiscountActionAmountLimited {\n          max\n        }\n      }\n      onlyForAccountIds\n      onlyForProductIds\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d5bf172b6e70c3d9728b6a5eb0fde77d";

export default node;
