import {RenderConfig} from "../relay/fields/ValidatedField";
import {classNames} from "primereact/utils";
import React from "react";
import {Checkbox} from "primereact/checkbox";

export function DefaultSwitchComponent({
                                           fieldName,
                                           fieldValue,
                                           updateField,
                                           isValid
                                       }: RenderConfig<boolean>) {
    return <div className="flex flex-row">
        <Checkbox id={fieldName}
                  name={fieldName}
                  checked={fieldValue}
                  onChange={(e) => updateField(e.checked)}
                  className={classNames({"p-invalid": !isValid})}/>
    </div>
}
