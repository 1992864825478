import {graphql} from "babel-plugin-relay/macro";
import {Dropdown} from "primereact/dropdown";
import {fetchQuery, useRelayEnvironment} from "react-relay";
import {useEffect, useState} from "react";
import {LocationInput_Query} from "../../../__generated__/LocationInput_Query.graphql";

const QUERY = graphql`
    query LocationInput_Query($first: Int, $after: String){
        Admin {
            Location {
                Locations(first: $first, after: $after) {
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            name
                            hotel
                        }
                    }
                }
            }
        }
    }
`


interface OwnProps {
    selectedLocation?: string | null
    onSelect: (locationId?: string) => void
    placeholder?: string
}

interface LocationProps {
    id: string
    name: string
    hotel?: string | null
}

export const LocationInput = ({selectedLocation, onSelect, placeholder}: OwnProps) => {
    const environment = useRelayEnvironment();

    const [locations, setLocation] = useState<LocationProps[]>([])

    useEffect(() => {
        fetchQuery<LocationInput_Query>(environment, QUERY, {})
            .toPromise().then(result => {
            setLocation(() => result!.Admin.Location.Locations.edges!.map(e => e!.node!))
        })
        // eslint-disable-next-line
    }, [])

    const selectedLocationTemplate = (option: { name: string, hotel: string | null }, props: { placeholder: string }) => {
        if (option) {
            return (
                <div>
                    <div>{option.name} {option.hotel ? "-" + option.hotel : ""}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }


    const locationOptionTemplate = (option: any) => {
        return (
            <div className="">
                <div>{option.name} {option.hotel ? "-" + option.hotel : ""}</div>
            </div>
        );
    }

    return (
        <div className="m-1">
            <Dropdown value={locations.find(l => l.id === selectedLocation)}
                      options={locations}
                      onChange={e => onSelect(e.value?.id || null)}
                      optionLabel="name"
                      className={""}
                      filter
                      showClear
                      onFilter={e => {
                          fetchQuery<LocationInput_Query>(environment, QUERY, {})
                              .toPromise().then(result => {
                              setLocation(() => result!.Admin.Location.Locations.edges!.map(e => e!.node!))
                          })
                      }}
                      filterBy="name"
                      placeholder={placeholder || "Ort auswählen"}
                      valueTemplate={selectedLocationTemplate}
                      itemTemplate={locationOptionTemplate}/>
        </div>
    )
}
