import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useMutation, usePaginationFragment} from "react-relay";
import {useNavigate} from "react-router-dom";
import {CourseTemplateTable_Refetch} from "../../../__generated__/CourseTemplateTable_Refetch.graphql";
import {CourseTemplateTable_CourseTemplateFragment$key} from "../../../__generated__/CourseTemplateTable_CourseTemplateFragment.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {CourseTemplateTable_DeleteCourseMutation} from "../../../__generated__/CourseTemplateTable_DeleteCourseMutation.graphql";
import {toast} from "react-toastify";
import {CourseTemplateTable_CreateMutation} from "../../../__generated__/CourseTemplateTable_CreateMutation.graphql";
import {CourseTemplateTable_CreateCourseMutation} from "../../../__generated__/CourseTemplateTable_CreateCourseMutation.graphql";

const COURSE_TEMPLATE_FRAGMENT = graphql`
    fragment CourseTemplateTable_CourseTemplateFragment on Query @refetchable(queryName: "CourseTemplateTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
    ){
        Admin {
            Template {
                CourseTemplates(first: $first, after: $after) @connection(key: "CourseTemplateTable_CourseTemplates"){
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            name
                            description
                        }
                    }
                }
            }
        }
    }
`


const CREATE_MUTATION = graphql`
    mutation CourseTemplateTable_CreateMutation($input: CreateCourseTemplateInput!, $connections: [ID!]!) {
        Admin {
            Template {
                createCourseTemplate(input: $input) {
                    data @appendEdge(connections: $connections){
                        node {
                            id
                            name
                            description
                        }
                    }
                }
            }
        }
    }
`

const DELETE_MUTATION = graphql`
    mutation CourseTemplateTable_DeleteCourseMutation($input: DeleteCourseTemplateInput!, $connections: [ID!]!){
        Admin {
            Template {
                deleteCourseTemplate(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

const CREATE_COURSE_MUTATION = graphql`
    mutation CourseTemplateTable_CreateCourseMutation($input: CreateCourseInput!){
        Admin {
            Course {
                createCourse(input: $input) {
                    data {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`


interface OwnProps {
    courseTemplateFragment: CourseTemplateTable_CourseTemplateFragment$key
}

export const CourseTemplateTable = ({courseTemplateFragment}: OwnProps) => {
    const navigate = useNavigate();
    const {
        data: {Admin: {Template: {CourseTemplates: {edges: courseTemplates}}}},
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext,
    } = usePaginationFragment<CourseTemplateTable_Refetch, CourseTemplateTable_CourseTemplateFragment$key>(COURSE_TEMPLATE_FRAGMENT, courseTemplateFragment)

    const [createCourseTemplate, isCreating] = useMutation<CourseTemplateTable_CreateMutation>(CREATE_MUTATION)
    const [deleteCourseTemplate, isDeleting] = useMutation<CourseTemplateTable_DeleteCourseMutation>(DELETE_MUTATION)
    const [createCourse, isCreatingCourse] = useMutation<CourseTemplateTable_CreateCourseMutation>(CREATE_COURSE_MUTATION)

    const {showDialog, dialogComponent} = useDialogLogic();

    return <>
        {dialogComponent}
        <div className="flex justify-content-end mb-3">
            <Button
                tooltip="Lehrgangsvorlage erzeugen"
                disabled={isCreating}
                onClick={() => {
                    createCourseTemplate({
                        variables: {
                            input: {
                                data: {}
                            },
                            connections: [
                                ConnectionHandler.getConnectionID("client:root:Admin:Template", "CourseTemplateTable_CourseTemplates")
                            ]
                        },
                        onCompleted: (el) => {
                            toast.success("Kurs-Template erfolgreich angelegt")
                        },
                        onError: error => {
                            console.error(error.message)
                        }
                    })
                }
                }>Neue Vorlage</Button>
        </div>
        <DataTable className="mb-3" value={courseTemplates?.map(b => b!.node!) as any[]}>
            <Column header="Name" body={row => {
                return <div className={row.isMarkedForDeletion ? "line-through" : ""}> {row.name}</div>
            }}/>
            <Column header="Beschreibung" body={row => {
                return <div className={row.isMarkedForDeletion ? "line-through" : ""}> {row.description}</div>
            }}/>
            <Column header="Aktionen" style={{width: "200px"}} body={row => {
                return <div>
                    <Button
                        tooltip="Bearbeiten"
                        className="mr-2"
                        onClick={() => {
                            navigate(`/course-templates/${row.id}/edit`)
                        }} icon={"pi pi-pencil"}/>
                    <Button
                        className="mr-2"
                        tooltip="Lehrgang aus Template erzeugen"
                        disabled={isCreatingCourse}
                        onClick={() => {
                            showDialog({
                                title: "Lehrgang erzeugen",
                                content: "Möchten Sie einen neuen Lehrgang anlegen?",
                                dialogCallback: result => {
                                    if (result === "Accept") {
                                        createCourse({
                                            variables: {
                                                input: {
                                                    data: {
                                                        courseTemplateId: row.id
                                                    }
                                                }
                                            },
                                            onCompleted: (response) => {
                                                const courseId = response.Admin.Course.createCourse?.data.node.id
                                                navigate(`/courses/${courseId}/edit`)
                                                toast.success("Es wurde erfolgreich ein Kurs angelegt")
                                            }
                                        })
                                    }
                                }
                            })
                        }} icon={"pi pi-sign-in"}/>
                    <Button
                        disabled={isDeleting}
                        tooltip="Löschen"
                        onClick={() => {
                            showDialog({
                                title: "Lehrgangsvorlage löschen",
                                content: "Möchten Sie diese Lehrgangsvorlage wirklich löschen? Das kann nicht rückgängig gemacht werden.",
                                dialogCallback: result => {
                                    if (result === "Accept") {
                                        deleteCourseTemplate({
                                            variables: {
                                                input: {
                                                    ids: [row.id]
                                                },
                                                connections: [
                                                    ConnectionHandler.getConnectionID("client:root:Admin:Template", "CourseTemplateTable_CourseTemplates")
                                                ]
                                            }
                                        })
                                    }
                                }
                            })
                        }} icon={"pi pi-trash"}/>
                </div>
            }}/>
        </DataTable>

        <div className="flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>
}
