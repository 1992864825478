import {EmailTemplatesTable} from "../../components/relay/tables/EmailTemplatesTable";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {
    EmailTemplatesScreen_EmailTemplatesQuery
} from "../../__generated__/EmailTemplatesScreen_EmailTemplatesQuery.graphql";

const QUERY = graphql`
    query EmailTemplatesScreen_EmailTemplatesQuery {
        ...EmailTemplatesTable_EmailTemplatesListFragment
    }
`;

export const EmailTemplatesScreen = () => {
    const data = useLazyLoadQuery<EmailTemplatesScreen_EmailTemplatesQuery>(QUERY, {}, {fetchPolicy: "network-only"})

    return <>
        <h1>E-Mail-Vorlagen</h1>
        <EmailTemplatesTable EmailTemplatesFragmentRef={data}/>
    </>
}
