/**
 * @generated SignedSource<<0a5e5674b5d1097057a9452e322fdaba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderPaymentProviderColumn_OrderFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PaymentProviderLinks_OrderFragment">;
  readonly " $fragmentType": "OrderPaymentProviderColumn_OrderFragment";
};
export type OrderPaymentProviderColumn_OrderFragment$key = {
  readonly " $data"?: OrderPaymentProviderColumn_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPaymentProviderColumn_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPaymentProviderColumn_OrderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PaymentProviderLinks_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "6f0ab2fe64636402bace7318f95dbc7f";

export default node;
