import React from 'react'
import {CourseTemplateTable} from "../../components/relay/tables/CourseTemplateTable";
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CourseTemplatesScreen_Query} from "../../__generated__/CourseTemplatesScreen_Query.graphql";

export const CourseTemplatesScreen = () => {
    const data = useLazyLoadQuery<CourseTemplatesScreen_Query>(graphql`
        query CourseTemplatesScreen_Query {
            ...CourseTemplateTable_CourseTemplateFragment
        }
    `, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1>Lehrgangsvorlagen</h1>
        <CourseTemplateTable courseTemplateFragment={data}/>
    </div>
}
