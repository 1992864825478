import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {EditCourseForm, FormState} from '../../components/relay/forms/EditCourseForm';
import {toast} from "react-toastify";
import {CourseHeader} from "../../components/relay/header/CourseHeader";
import {CourseScreen_Query} from '../../__generated__/CourseScreen_Query.graphql';
import {CourseScreen_CourseFragment$key} from "../../__generated__/CourseScreen_CourseFragment.graphql";
import {CourseScreen_UpdateMutation} from "../../__generated__/CourseScreen_UpdateMutation.graphql";
import {EDIT_COURSE_PATH} from "../../routes/CourseRoutes";

const QUERY = graphql`
    query CourseScreen_Query($id: ID!) {
        node(id: $id) {
            ... on Course {
                ...CourseScreen_CourseFragment
            }
        }
    }
`

const COURSE_FRAGMENT = graphql`
    fragment CourseScreen_CourseFragment on Course {
        id
        name
        secondName
        description
        icon {
            id
        }
        createdAt
        locationRef
        internalInformation
        amountOfRebookedParticipant
        amountOfRegulatedParticipant
        price {
            grossPrice
            netPrice
            taxRatePercentage
        }
        modules {
            id
            internalTitle
            startDate
            endDate
            trainers {
                name
            }
        }

        ...EditCourseForm_CourseFragment
        ...CourseHeader_CourseFragment
    }
`;


const UPDATE_MUTATION = graphql`
    mutation CourseScreen_UpdateMutation($input: EditCourseInput!) {
        Admin {
            Course {
                editCourse(input: $input) {
                    course {
                        node {
                            id
                            ...CourseHeader_CourseFragment
                            ...CourseScreen_CourseFragment
                        }
                    }
                }
            }
        }
    }
`;


export const CourseScreen = () => {
    const {params: {courseId}} = useMatch(EDIT_COURSE_PATH)!


    const query = useLazyLoadQuery<CourseScreen_Query>(QUERY, {
        id: courseId!
    }, {fetchPolicy: "network-only"})

    const course = useFragment<CourseScreen_CourseFragment$key>(COURSE_FRAGMENT, query.node || null)
    const [updateCourse, isUpdating] = useMutation<CourseScreen_UpdateMutation>(UPDATE_MUTATION)

    return <div>
        <CourseHeader courseFragment={course}/>
        <h1>Lehrgang {course?.secondName} bearbeiten</h1>
        <EditCourseForm
            initialValues={{
                id: course?.id || "",
                name: course?.name || "",
                secondName: course?.secondName || "",
                amountOfRegulatedParticipant: course?.amountOfRegulatedParticipant || 0,
                amountOfRebookedParticipant: course?.amountOfRebookedParticipant || 0,
                locationRef: course?.locationRef,
                createdAt: course?.createdAt,
                description: course?.description,
                internalInformation: course?.internalInformation,
                price: course?.price || {
                    grossPrice: 0,
                    netPrice: 0,
                    taxRatePercentage: 19
                },
                iconRef: course?.icon?.id,
                modules: course?.modules.map((module) => ({
                    id: module?.id,
                    internalTitle: module?.internalTitle,
                    startDate: module?.startDate || "",
                    endDate: module?.endDate || "",
                    trainer: module?.trainers.map(l => l.name).join(", ") || ""
                })) || []
            }}
            loading={isUpdating}
            onSubmit={(values: FormState) => {
                if (course) {
                    updateCourse({
                        variables: {
                            input: {
                                id: course.id,
                                iconRef: values.iconRef,
                                name: values.name,
                                secondName: values.secondName,
                                locationRef: values.locationRef!,
                                amountOfRegulatedParticipant: values.amountOfRegulatedParticipant,
                                amountOfRebookedParticipant: values.amountOfRebookedParticipant,
                                price: values.price,
                                description: values.description,
                                internalInformation: values.internalInformation
                            }
                        },
                        onCompleted: () => {
                            toast.success("Kurs erfolgreich gespeichert")
                        },
                        onError: error => {
                            console.error(error.message)
                        },
                        updater: (store, data) => {
                            if (data.Admin.Course.editCourse?.course.node.id) store.get(data.Admin.Course.editCourse?.course.node.id)?.invalidateRecord()
                        }
                    })
                }
            }}
            courseFragmentRef={course}/>
    </div>

}
