import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {RemoveModuleTemplateButton_RemoveModuleMutation} from "../../../__generated__/RemoveModuleTemplateButton_RemoveModuleMutation.graphql";
import {RemoveModuleTemplateButton_CourseTemplateFragment$key} from "../../../__generated__/RemoveModuleTemplateButton_CourseTemplateFragment.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";

const FRAGMENT = graphql`
    fragment RemoveModuleTemplateButton_CourseTemplateFragment on CourseTemplate {
        id
    }
`


const DELETE_MUTATION = graphql`
    mutation RemoveModuleTemplateButton_RemoveModuleMutation($input: DeleteModuleTemplateInput!) {
        Admin {
            Template {
                deleteModuleTemplate(input: $input){
                    courseTemplate {
                        node {
                            ...CourseTemplateScreen_CourseTemplateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseTemplateFragmentRef: RemoveModuleTemplateButton_CourseTemplateFragment$key | null
    moduleId: string
}

export const RemoveModuleTemplateButton = ({courseTemplateFragmentRef, moduleId}: OwnProps) => {
    const [removeTrainingDayTemplate, removeTrainingDayTemplateInFlight] = useMutation<RemoveModuleTemplateButton_RemoveModuleMutation>(DELETE_MUTATION)
    const moduleTemplate = useFragment<RemoveModuleTemplateButton_CourseTemplateFragment$key>(FRAGMENT, courseTemplateFragmentRef)

    const {showDialog, dialogComponent} = useDialogLogic();

    return moduleTemplate ?
        <>{dialogComponent}
        <Button
            tooltip="Löschen"
            className="ml-2"
            icon="pi pi-trash"
            loading={removeTrainingDayTemplateInFlight}
            type={"button"}
            onClick={() => {
                showDialog({
                    title: "Modulvorlage entfernen?",
                    content: "Möchten Sie diese Modulvorlage wirklich löschen?",
                    dialogCallback: (result) => {
                        if (result === "Accept") {
                            removeTrainingDayTemplate({
                                variables: {
                                    input: {
                                        id: moduleTemplate.id,
                                        moduleRef: moduleId
                                    }
                                }
                            })
                        }
                    }
                })
            }}
        />
        </> :
        null
}
