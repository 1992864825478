/**
 * @generated SignedSource<<445b62a52149f07769ea9ccbb7cb8f13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddModuleTemplateButton_CourseTemplateFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AddModuleTemplateButton_CourseTemplateFragment";
};
export type AddModuleTemplateButton_CourseTemplateFragment$key = {
  readonly " $data"?: AddModuleTemplateButton_CourseTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddModuleTemplateButton_CourseTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddModuleTemplateButton_CourseTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CourseTemplate",
  "abstractKey": null
};

(node as any).hash = "ad3a53fca0c000135789ae74603167d4";

export default node;
