import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {RemoveModuleButton_RemoveModuleMutation} from "../../../__generated__/RemoveModuleButton_RemoveModuleMutation.graphql";
import {RemoveModuleButton_CourseFragment$key} from "../../../__generated__/RemoveModuleButton_CourseFragment.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";

const COURSE_FRAGMENT = graphql`
    fragment RemoveModuleButton_CourseFragment on Course {
        id
    }
`


const REMOVE_MODULE_MUTATION = graphql`
    mutation RemoveModuleButton_RemoveModuleMutation($input: DeleteModuleInput!) {
        Admin {
            Course {
                deleteModule(input: $input){
                    course {
                        node {
                            ...CourseScreen_CourseFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseFragmentRef: RemoveModuleButton_CourseFragment$key | null
    moduleId: string
}

export const RemoveModuleButton = ({courseFragmentRef, moduleId}: OwnProps) => {
    const [removeModule, isRemovingModule] = useMutation<RemoveModuleButton_RemoveModuleMutation>(REMOVE_MODULE_MUTATION)
    const module = useFragment<RemoveModuleButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    const {showDialog, dialogComponent} = useDialogLogic();

    return module ?
        <>{dialogComponent}
            <Button
                className="ml-2"
                icon="pi pi-trash"
                loading={isRemovingModule}
                tooltip="Löschen"
                type={"button"}
                onClick={() => {
                    showDialog({
                        title: "Module entfernen?",
                        content: "Möchten Sie dieses Modul wirklich löschen?",
                        dialogCallback: (result) => {
                            if (result === "Accept") {
                                removeModule({
                                    variables: {
                                        input: {
                                            id: module.id,
                                            moduleRef: moduleId
                                        }
                                    }
                                })
                            }
                        }
                    })
                }}
            />
        </> :
        null
}
