import {Column} from "primereact/column";
import graphql from "babel-plugin-relay/macro";
import {usePaginationFragment} from "react-relay";
import {TkDataTable} from "../../ui/TkDataTable";
import {EditGroupButton} from "../buttons/EditGroupButton";
import {CreateGroupButton} from "../buttons/CreateGroupButton";
import {DeleteGroupButton} from "../buttons/DeleteGroupButton";
import {GroupsTable_Refetch} from "../../../__generated__/GroupsTable_Refetch.graphql";
import {GroupsTable_GroupListFragment$key} from "../../../__generated__/GroupsTable_GroupListFragment.graphql";
import {PERMISSION_NAME_TRANSLATION, PERMISSION_TRANSLATIONS} from "../../../i18n/permissions.i18n";

const LIST_FRAGMENT = graphql`
    fragment GroupsTable_GroupListFragment on Query @refetchable(queryName: "GroupsTable_Refetch") @argumentDefinitions(
        first: {type: "Int", defaultValue: 20},
        after: {type: "String"},
    ){
        Management {
            Groups (first: $first, after: $after) @connection(key: "GroupsTable_Groups") {
                __id
                edges {
                    node {
                        id
                        name
                        permissions
                        ...EditGroupButton_GroupFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    groupListFragmentRef: GroupsTable_GroupListFragment$key
}

export const GroupsTable = ({groupListFragmentRef}: OwnProps) => {


    const {
        data: {
            Management: {Groups: {__id, edges: groups}}
        },
    } = usePaginationFragment<GroupsTable_Refetch, GroupsTable_GroupListFragment$key>(LIST_FRAGMENT, groupListFragmentRef)


    return <>
        <div className="flex justify-content-end">
            <CreateGroupButton connectionId={__id}/>
        </div>
        <TkDataTable
            emptyMessage={<div className="flex justify-content-center align-items-center">
                <div className="mr-2">There are no groups in your account yet.</div>
            </div>}
            className="mb-3"
            value={groups?.map(b => b!.node!) as any[]}
        >
            <Column header="Name" body={row => {
                if (row.name === "Owner" || row.name === "User") return PERMISSION_NAME_TRANSLATION[row.name]
                else return row.name
            }}/>
            <Column header="Permissions" body={row => {
                const x = atob(row.id)
                if (x === "Group:owner") {
                    return "Nutzer in dieser Gruppe, haben alle Rechte."
                } else if (x === "Group:user") {
                    return "Nutzer in dieser Gruppe haben keine besonderen Rechte."
                } else {
                    return row.permissions.map((p: any) => PERMISSION_TRANSLATIONS[p] || p).join(", ")
                }

            }}/>
            <Column
                header="Actions"
                body={row => {
                    const x = atob(row.id)

                    if (x === "Group:owner" || x === "Group:user") {
                        return <div>
                            Built-in Gruppen können nicht verändert werden
                        </div>
                    } else {
                        return <div className="flex">
                            <EditGroupButton className="mr-2" groupFragmentRef={row}/>
                            <DeleteGroupButton connectionId={__id} groupId={row.id}/>
                        </div>
                    }
                }}
            />
        </TkDataTable>

    </>
}


