/**
 * @generated SignedSource<<be5969b2d490dd788cd90946ccb36285>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeUserActivationInput = {
  clientMutationId?: string | null;
  isActivated: boolean;
  userId: string;
};
export type ChangeActivatedForm_ChangeEmailMutation$variables = {
  input: ChangeUserActivationInput;
};
export type ChangeActivatedForm_ChangeEmailMutation$data = {
  readonly Admin: {
    readonly User: {
      readonly changeUserActivation: {
        readonly user: {
          readonly " $fragmentSpreads": FragmentRefs<"SingleUserForm_UserFragment">;
        };
      } | null;
    };
  };
};
export type ChangeActivatedForm_ChangeEmailMutation = {
  response: ChangeActivatedForm_ChangeEmailMutation$data;
  variables: ChangeActivatedForm_ChangeEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ChangeActivatedForm_ChangeEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeUserActivationPayload",
                "kind": "LinkedField",
                "name": "changeUserActivation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SingleUserForm_UserFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ChangeActivatedForm_ChangeEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAdminMutationSchema",
            "kind": "LinkedField",
            "name": "User",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeUserActivationPayload",
                "kind": "LinkedField",
                "name": "changeUserActivation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "activated",
                        "storageKey": null
                      },
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "extension",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "isBlocked",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "salutation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "adsOptIn",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "note",
                                "storageKey": null
                              }
                            ],
                            "type": "UserExtensionImpl",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserGroupAssociation",
                        "kind": "LinkedField",
                        "name": "groupAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Group",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "permissions",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e3ace09f5836d3e1e57e30b577c3e57d",
    "id": null,
    "metadata": {},
    "name": "ChangeActivatedForm_ChangeEmailMutation",
    "operationKind": "mutation",
    "text": "mutation ChangeActivatedForm_ChangeEmailMutation(\n  $input: ChangeUserActivationInput!\n) {\n  Admin {\n    User {\n      changeUserActivation(input: $input) {\n        user {\n          ...SingleUserForm_UserFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment ChangeActivatedForm_UserFragment on User {\n  id\n  email\n  activated\n}\n\nfragment ChangeEmailForm_UserFragment on User {\n  id\n  email\n}\n\nfragment ChangePasswordForm_UserFragment on User {\n  id\n  email\n}\n\nfragment ChangeUserExtensionForm_UserFragment on User {\n  id\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      isBlocked\n      salutation\n      adsOptIn\n      note\n    }\n  }\n}\n\nfragment SingleUserForm_UserFragment on User {\n  id\n  email\n  activated\n  name\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      isBlocked\n    }\n  }\n  groupAssociations {\n    group {\n      id\n      name\n      permissions\n    }\n  }\n  ...ChangeEmailForm_UserFragment\n  ...ChangeActivatedForm_UserFragment\n  ...ChangePasswordForm_UserFragment\n  ...ChangeUserExtensionForm_UserFragment\n}\n"
  }
};
})();

(node as any).hash = "f2436c86a55cae8c910ca6a64092e974";

export default node;
