/**
 * @generated SignedSource<<ffa8061dd170d01035fc811b0fa1f8de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateLocationInput = {
  clientMutationId?: string | null;
  data: LocationInput;
  id: string;
};
export type LocationInput = {
  clientMutationId?: string | null;
  hotel?: string | null;
  name: string;
};
export type EditLocationScreen_UpdateLocationMutation$variables = {
  input: UpdateLocationInput;
};
export type EditLocationScreen_UpdateLocationMutation$data = {
  readonly Admin: {
    readonly Location: {
      readonly updateLocation: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditLocationScreen_LocationFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditLocationScreen_UpdateLocationMutation = {
  response: EditLocationScreen_UpdateLocationMutation$data;
  variables: EditLocationScreen_UpdateLocationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLocationScreen_UpdateLocationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateLocationPayload",
                "kind": "LinkedField",
                "name": "updateLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditLocationScreen_LocationFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditLocationScreen_UpdateLocationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateLocationPayload",
                "kind": "LinkedField",
                "name": "updateLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hotel",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "70ec49d95a4005575d66eb4f28b09d13",
    "id": null,
    "metadata": {},
    "name": "EditLocationScreen_UpdateLocationMutation",
    "operationKind": "mutation",
    "text": "mutation EditLocationScreen_UpdateLocationMutation(\n  $input: UpdateLocationInput!\n) {\n  Admin {\n    Location {\n      updateLocation(input: $input) {\n        data {\n          node {\n            ...EditLocationScreen_LocationFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditLocationScreen_LocationFragment on Location {\n  id\n  name\n  hotel\n}\n"
  }
};
})();

(node as any).hash = "698adce940121c016e516bb3626544ee";

export default node;
