import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DateTimeDisplay } from "../../../../../billing-admin-impl/components/ui/DateTimeDisplay";
import { CodeCreatedAtColumn_DiscountCodeFragment$key } from "../../../../../__generated__/CodeCreatedAtColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment CodeCreatedAtColumn_DiscountCodeFragment on DiscountCode {
		createdAt
	}
`;

interface OwnProps {
	discountCodeFragmentRef: CodeCreatedAtColumn_DiscountCodeFragment$key;
}

export const CodeCreatedAtColumn = ({ discountCodeFragmentRef }: OwnProps) => {
	const discountCode = useFragment<CodeCreatedAtColumn_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	return <DateTimeDisplay value={discountCode.createdAt} />;
};
