import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import {LocationsTable} from "../../components/relay/tables/LocationsTable";
import {LocationsScreen_LocationsQuery} from "../../__generated__/LocationsScreen_LocationsQuery.graphql";

const QUERY = graphql`
    query LocationsScreen_LocationsQuery {
        ...LocationsTable_Locations
    }
`

export const LocationsScreen = () => {
    const navigate = useNavigate();
    const locations = useLazyLoadQuery<LocationsScreen_LocationsQuery>(QUERY, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1 className="mb-3">Standorte</h1>

        <div className="flex justify-content-end mb-3">
            <Button onClick={_ => navigate("/locations/new/edit")}>
                Neu
            </Button>
        </div>
        <LocationsTable locationsFragment={locations}/>
    </div>

}
