/**
 * @generated SignedSource<<418f4e228542608da63f750179c6e533>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CourseStatesTable_UserHistoryFragment$data = {
  readonly node: {
    readonly courseState: {
      readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_CourseStateFragment">;
    };
    readonly historyEntries: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"HistoryItem_EntryFragment">;
    }>;
    readonly order: {
      readonly cart: {
        readonly selection: {
          readonly createdByUser: {
            readonly name: string;
          } | null;
        };
      } | null;
      readonly creditNoteData: {
        readonly creditNoteDataBase64: string;
        readonly creditNoteNumber: string;
      } | null;
      readonly history: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly createdAt: string;
            readonly status: OrderStatus;
          };
        } | null> | null;
      };
      readonly invoiceData: {
        readonly invoiceDataBase64: string;
        readonly invoiceNumber: string;
      } | null;
    } | null;
  };
  readonly " $fragmentType": "CourseStatesTable_UserHistoryFragment";
};
export type CourseStatesTable_UserHistoryFragment$key = {
  readonly " $data"?: CourseStatesTable_UserHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_UserHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseStatesTable_UserHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserHistory",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Entry",
          "kind": "LinkedField",
          "name": "historyEntries",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HistoryItem_EntryFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseState",
          "kind": "LinkedField",
          "name": "courseState",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CourseStatesTable_CourseStateFragment"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Order",
          "kind": "LinkedField",
          "name": "order",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Cart",
              "kind": "LinkedField",
              "name": "cart",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Selection",
                  "kind": "LinkedField",
                  "name": "selection",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "createdByUser",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderHistoriesConnection",
              "kind": "LinkedField",
              "name": "history",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderHistoriesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderHistory",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "createdAt",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "status",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InvoiceData",
              "kind": "LinkedField",
              "name": "invoiceData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiceDataBase64",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "invoiceNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CreditNoteData",
              "kind": "LinkedField",
              "name": "creditNoteData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditNoteDataBase64",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "creditNoteNumber",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserHistoryEdge",
  "abstractKey": null
};

(node as any).hash = "d4ea423cb3c46310bb679bb09bc8df5e";

export default node;
