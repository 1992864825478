import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {UsersTable} from "../../components/relay/tables/UsersTable";
import {UsersScreen_Query} from "../../__generated__/UsersScreen_Query.graphql";
import {UsersSearchContainer} from "../../components/relay/filter/container/UsersSearchContainer";

export const UsersScreen = () => {
    const data = useLazyLoadQuery<UsersScreen_Query>(graphql`
        query UsersScreen_Query {
            ...UsersTable_UsersFragment
        }
    `, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1>Benutzer</h1>
        <UsersSearchContainer>
            <UsersTable usersFragment={data}/>
        </UsersSearchContainer>
    </div>
}
