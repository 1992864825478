import React from 'react';
import graphql from 'babel-plugin-relay/macro';
import {useFragment, useMutation} from "react-relay";
import {useFormik} from "formik";
import {
    ChangeUserExtensionForm_UserFragment$key
} from "../../../__generated__/ChangeUserExtensionForm_UserFragment.graphql";
import {
    ChangeUserExtensionForm_ChangeUserExtensionMutation
} from "../../../__generated__/ChangeUserExtensionForm_ChangeUserExtensionMutation.graphql";
import {classNames} from "primereact/utils";
import {ValidatedField} from "../fields/ValidatedField";
import {Dropdown} from "primereact/dropdown";
import {toast} from "react-toastify";
import {InputSwitch} from "primereact/inputswitch";
import {InputTextarea} from "primereact/inputtextarea";
import {Button} from "primereact/button";

const USER_FRAGMENT = graphql`
    fragment ChangeUserExtensionForm_UserFragment on User {
        id
        extension {
            ... on UserExtensionImpl {
                isBlocked
                salutation
                adsOptIn
                note
            }
        }
    }
`

const CHANGE_USER_EXTENSION_MUTATION = graphql`
    mutation ChangeUserExtensionForm_ChangeUserExtensionMutation ($input: ChangeUserExtensionInput!) {
        Admin {
            User {
                changeUserExtension(input: $input) {
                    user {
                        ...SingleUserForm_UserFragment
                    }
                }
            }
        }
    }
`


interface State {
    isBlocked: boolean
    salutation: string
    note: string | null
}

interface OwnProps {
    userFragmentRef: ChangeUserExtensionForm_UserFragment$key
}

export const ChangeUserExtensionForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<ChangeUserExtensionForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [changeUserExtension, isChangingUserExtension] = useMutation<ChangeUserExtensionForm_ChangeUserExtensionMutation>(CHANGE_USER_EXTENSION_MUTATION)

    const formik = useFormik<State>({
        initialValues: {
            note: user.extension.note!,
            salutation: user.extension.salutation!,
            isBlocked: user.extension.isBlocked!
        },
        onSubmit: (values: State, {setSubmitting}) => {
            changeUserExtension({
                variables: {
                    input: {
                        userId: user.id,
                        note: values.note,
                        salutation: values.salutation,
                        isBlocked: values.isBlocked
                    }
                },
                onCompleted: () => {
                    toast.success("Änderungen wurden gespeichert")
                },
                onError: (error: Error) => {
                    toast.error("Es gab Probleme beim Speichern | " + error.message)
                }

            })
            setSubmitting(false)
        }

    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedField<State, string>
            name={"salutation"}
            label={"Anrede"}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <div>
                    <Dropdown
                        name={fieldName}
                        value={fieldValue}
                        onChange={e => updateField(e.target.value)}
                        options={[
                            {label: "Divers", value: "Divers"},
                            {label: "Frau", value: "Mr"},
                            {label: "Herr", value: "Mrs"},
                            {label: "Unbestimmt", value: "Unknown"},
                        ]}
                        optionValue={"value"}
                        optionLabel={"label"}
                        className={classNames({'p-invalid': !isValid})}
                    />
                </div>

            }}/>
        <ValidatedField<State, string>
            name={"note"}
            label={"Notiz"}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <div>
                    <InputTextarea
                        name={fieldName}
                        value={fieldValue}
                        onChange={e => updateField(e.target.value)}
                        className={classNames({'p-invalid': !isValid})}
                    />
                </div>

            }}/>
        <ValidatedField<State, boolean>
            name={"isBlocked"}
            label={"Ist Gesperrt"}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <div>
                    <InputSwitch
                        name={fieldName}
                        checked={fieldValue}
                        onChange={e => updateField(e.value)}
                        className={classNames({'p-invalid': !isValid})}
                    />
                </div>

            }}/>
        <div className="col-10 md:col-1">
            <Button
                type="button"
                loading={isChangingUserExtension}
                disabled={isChangingUserExtension}
                onClick={() => formik.submitForm()}
                className="mr-2" icon={"pi pi-save"}/>
        </div>
    </form>

}
