import React, { useState } from "react";
export interface DiscountUsageFilters {
	filterByUsedAtFrom?: string;
	filterByUsedToFrom?: string;
}

interface Search {
	filters: DiscountUsageFilters;
	setFilters: (filters: DiscountUsageFilters) => void;
	clearFilters: () => void;
}

export const DiscountUsageSearchContext = React.createContext<Search>({
	setFilters: () => {},
	clearFilters: () => {},
	filters: {},
});

interface OwnProps {
	children: any;
}

export const DiscountUsageSearchContainer = ({ children }: OwnProps) => {
	const [state, setState] = useState<DiscountUsageFilters>({});

	return (
		<DiscountUsageSearchContext.Provider
			value={{
				filters: state,
				setFilters: (filters) => {
					setState(() => filters);
				},
				clearFilters: () => {
					setState({});
				},
			}}
		>
			{children}
		</DiscountUsageSearchContext.Provider>
	);
};
