import React, {Suspense} from 'react';
import graphql from "babel-plugin-relay/macro";
import {UsersInAccountAdminTable} from "../../components/relay/tables/UsersInAccountAdminTable";
import {useLazyLoadQuery} from "react-relay";
import {TheKeyScreen_Query} from "../../__generated__/TheKeyScreen_Query.graphql";

const QUERY = graphql`
    query TheKeyScreen_Query($first: Int, $accountId: ID!) {
        ...UsersInAccountAdminTable_GetUsersInAccountListFragment @arguments(first: $first, accountId: $accountId)
    }
`

export const TheKeyScreen = () => {
    const theKeyAccountId = btoa("Account:Account:thekey")

    const query = useLazyLoadQuery<TheKeyScreen_Query>(QUERY, {
        first: 20,
        accountId: theKeyAccountId
    }, {fetchPolicy: "network-only"})

    return (
        <Suspense fallback={<div>Lade...</div>}>
            <UsersInAccountAdminTable accountId={theKeyAccountId} usersInAccountFragmentRef={query}/>
        </Suspense>
    )
}
