/**
 * @generated SignedSource<<4fad44efb40b8462bb983ebd59b3c2ca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseHeader_CourseFragment$data = {
  readonly id: string;
  readonly secondName: string;
  readonly " $fragmentType": "CourseHeader_CourseFragment";
};
export type CourseHeader_CourseFragment$key = {
  readonly " $data"?: CourseHeader_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_CourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseHeader_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondName",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};

(node as any).hash = "1d0da361f436599b16a83d1b74e9529f";

export default node;
