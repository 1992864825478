/**
 * @generated SignedSource<<e2c9d97e3a265f727714f8a11a9ba477>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeUserGroupsAdminButton_UserInAccountFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ChangeUserGroupsAdminModal_UserInAccountFragment">;
  readonly " $fragmentType": "ChangeUserGroupsAdminButton_UserInAccountFragment";
};
export type ChangeUserGroupsAdminButton_UserInAccountFragment$key = {
  readonly " $data"?: ChangeUserGroupsAdminButton_UserInAccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeUserGroupsAdminButton_UserInAccountFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeUserGroupsAdminButton_UserInAccountFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeUserGroupsAdminModal_UserInAccountFragment"
    }
  ],
  "type": "UserInAccount",
  "abstractKey": null
};

(node as any).hash = "ac67879b94793cee3f3213f8e4e7d99f";

export default node;
