export const AttendanceStatusI18n: { [key: string]: string } = {
    "TookPart" : "Teilgenommen",
    "Absent": "Abwesend",
    "Unknown": "Offen",
    "NotPresent" : "nicht Teilgenommen",
    "CanceledByUser": "Abgemeldet" ,
    "WaitingList": "Warteliste",
    "CourseCanceledByAdmin": "Storniert",
    "CanceledTooLate": "Zu spät abgemeldet"
}
