import {RenderConfig} from "../relay/fields/ValidatedField";
import {InputNumber} from "primereact/inputnumber";
import {classNames} from "primereact/utils";
import React from "react";

export function DefaultNumberComponent({
                                           fieldName,
                                           fieldValue,
                                           updateField,
                                           isValid
                                       }: RenderConfig<number>) {
    return <InputNumber id={fieldName}
                        name={fieldName}value={fieldValue}
                        onValueChange={(e) => updateField(e.value || undefined)}
                        mode="decimal"
                        showButtons min={0} max={100}
                        className={classNames({"p-invalid": !isValid})}/>
}
