import {useFormik} from "formik";
import {Button} from "primereact/button";
import React from "react";
import * as Yup from "yup";
import {DefaultEditorComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {ValidatedField} from "../fields/ValidatedField";

interface FormState {
    question?: string,
    answer?: string,
}

interface OwnProps {
    loading?: boolean
    initialValues?: FormState
    onSubmit: (values: FormState) => void
}

export const EditFaqForm = ({loading, initialValues, onSubmit}: OwnProps) => {
    const formik = useFormik<FormState>({
        initialValues: {
            question: initialValues?.question ?? "Frage",
            answer: initialValues?.answer ?? "Antwort"
        },
        validationSchema: Yup.object().shape({
            question: Yup.string().required("Frage wird benötigt"),
            answer: Yup.string().required("Antwort wird benötigt"),
        }),
        onSubmit: (values, {setSubmitting}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedField<FormState, string>
            name={"question"}
            label={"Frage"}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />
        <ValidatedField<FormState, string>
            name={"answer"}
            label={"Antwort"}
            component={DefaultEditorComponent}
            formikConfig={formik}
        />
        <Button
            disabled={loading}
            type="submit"
            label="Speichern"
            className="p-mt-2"/>
    </form>
}
