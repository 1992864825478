import {useFormik} from "formik";
import * as Yup from "yup";
import {ValidatedField} from "./ValidatedField";
import {DefaultTextFieldComponent} from "./DefaultTextInput";
import {Password} from "primereact/password";
import {classNames} from "primereact/utils";
import React, {useImperativeHandle} from "react";
import {FormikProps} from "formik/dist/types";
import {Dropdown} from "primereact/dropdown";

export interface RegistrationFormState {
    salutation: string
    name: string
    email: string
    email2: string

    password: string
    password2: string

}

interface OwnProps {
    onSuccess: (formState: RegistrationFormState, onSuccess?: () => void) => void
    buttonLabel?: string
}

export const RegistrationForm = React.forwardRef<FormikProps<RegistrationFormState>, OwnProps>(({
                                                                                                    onSuccess,
                                                                                                    buttonLabel
                                                                                                }: OwnProps, ref) => {
    const formik = useFormik<RegistrationFormState>({
        initialValues: {
            salutation: 'Unknown',
            name: '',
            email: '',
            email2: '',
            password: '',
            password2: '',

        },
        validationSchema: Yup.object().shape({
            salutation: Yup
                .string()
                .required("Anrede wird benötigt."),
            name: Yup
                .string()
                .required("Name wird benötigt."),
            email: Yup
                .string()
                .email("Please enter a valid e-mail address.")
                .required("E-Mail is a required field."),

            email2: Yup.string()
                .email("Please enter a valid e-mail address.")
                .oneOf([Yup.ref("email"), undefined], "E-Mails have to match."),

            password: Yup
                .string()
                .min(8, "A password needs at least 8 characters.")
                .required("Password is a required field."),
            password2: Yup.string()
                .oneOf([Yup.ref("password"), undefined], "Passwords have to match."),
        }),
        onSubmit: (data, {setSubmitting}) => {
            onSuccess(data, () => {
                setSubmitting(false)
            })
        }
    });

    useImperativeHandle(ref, () => ({
        ...formik
    }))

    return <form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
        <ValidatedField<RegistrationFormState, string>
            name={"salutation"}
            label={"Anrede"}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <div>
                    <Dropdown
                        name={fieldName}
                        value={fieldValue}
                        onChange={e => updateField(e.target.value)}
                        options={[
                            {label: "Divers", value: "Divers"},
                            {label: "Frau", value: "Mr"},
                            {label: "Herr", value: "Mrs"},
                            {label: "Unbestimmt", value: "Unknown"},
                        ]}
                        optionValue={"value"}
                        optionLabel={"label"}
                        className={classNames({'p-invalid': !isValid})}
                    />
                </div>

            }}
        />
        <ValidatedField<RegistrationFormState, string>
            className="mb-4"
            name={"name"}
            label={"Name"}
            required={true}
            formikConfig={formik}
            component={DefaultTextFieldComponent}/>

        <ValidatedField<RegistrationFormState, string>
            className="mb-4"
            name={"email"}
            label={"E-Mail"}
            required={true}
            formikConfig={formik}
            component={DefaultTextFieldComponent}/>


        <ValidatedField<RegistrationFormState, string>
            className="mb-4"
            name={"email2"}
            label={"E-Mail wiederholen"}
            required={true}
            formikConfig={formik}
            component={DefaultTextFieldComponent}/>

        <ValidatedField<RegistrationFormState, string>
            className="mb-4"
            name={"password"}
            label={"Passwort (kann später geändert werden)"}
            required={true}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <Password id={fieldName} name={fieldName} value={fieldValue}
                                 onChange={e => updateField(e.target.value)}
                                 toggleMask
                                 feedback={false}
                                 className={classNames({'p-invalid': !isValid})}
                />

            }}/>

        <ValidatedField<RegistrationFormState, string>
            className="mb-6"
            name={"password2"}
            label={"Passwort wiederholen"}
            required={true}
            formikConfig={formik}
            component={({fieldValue, updateField, fieldName, isValid}) => {
                return <Password id={fieldName} name={fieldName} value={fieldValue}
                                 onChange={e => updateField(e.target.value)}
                                 toggleMask
                                 feedback={false}
                                 className={classNames({'p-invalid': !isValid})}
                />

            }}/>

    </form>
})
