import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {FilesScreen} from "../screens/files/FilesScreen";

export const FilesModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/files",
        element: <FilesScreen/>,
        requiredPermissions: ["System_Owner"]
    },
];
