/**
 * @generated SignedSource<<ebc2ed9b260085634a7b88c59e0d3726>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "Email_Templates" | "Files_Delete" | "Management_Management" | "Sales_Sales" | "System_Owner" | "System_Root" | "Trainer_Trainer" | "%future added value";
export type CurrentUserSetter_Query$variables = {};
export type CurrentUserSetter_Query$data = {
  readonly Viewer: {
    readonly Auth: {
      readonly currentUser: {
        readonly permissionsInAccount: ReadonlyArray<Permission>;
        readonly user: {
          readonly id: string;
        };
      } | null;
    };
  };
};
export type CurrentUserSetter_Query = {
  response: CurrentUserSetter_Query$data;
  variables: CurrentUserSetter_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AuthViewerSchema",
        "kind": "LinkedField",
        "name": "Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "permissionsInAccount",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CurrentUserSetter_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CurrentUserSetter_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "acee453027f6ffe439605829313358c4",
    "id": null,
    "metadata": {},
    "name": "CurrentUserSetter_Query",
    "operationKind": "query",
    "text": "query CurrentUserSetter_Query {\n  Viewer {\n    Auth {\n      currentUser {\n        user {\n          id\n        }\n        permissionsInAccount\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "624b0d2889b04455da901ce844a14433";

export default node;
