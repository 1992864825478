import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {Card} from "primereact/card";
import {DeleteUserButton} from "../buttons/DeleteUserButton";
import {ChangeEmailForm} from "./ChangeEmailForm";
import {ChangeActivatedForm} from "./ChangeActivatedForm";
import React from "react";
import {ChangePasswordForm} from "./ChangePasswordForm";
import {SingleUserForm_UserFragment$key} from "../../../__generated__/SingleUserForm_UserFragment.graphql";
import { ChangeUserExtensionForm } from "./ChangeUserExtensionForm";

const USER_FRAGMENT = graphql`
    fragment SingleUserForm_UserFragment on User {
        id
        email
        activated
        name
        extension {
            ... on UserExtensionImpl {
                isBlocked
            }
        }
        groupAssociations {
            group {
                id
                name
                permissions
            }
        }

        ...ChangeEmailForm_UserFragment
        ...ChangeActivatedForm_UserFragment
        ...ChangePasswordForm_UserFragment
        ...ChangeUserExtensionForm_UserFragment
    }
`


interface OwnProps {
    userFragmentRef: SingleUserForm_UserFragment$key | null
}

export const SingleUserForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<SingleUserForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)

    const isRoot = user?.groupAssociations.find(l => l.group?.permissions.includes("System_Owner"))?.group?.permissions.includes("System_Owner") || false
    const isBlocked = user?.extension.isBlocked || false

    return user ? <div>
        <h1 className="mb-3">
            Benutzer {user?.name}
            {isBlocked && <span> (Gesperrt)</span>}
        </h1>

        {
            isRoot &&
            <div className="mb-2 flex justify-content-end">
                <DeleteUserButton className="mr-2" userId={user?.id!}/>
            </div>
        }

        <Card className="mb-4"
              header={
                  <div className="p-2">
                      <h3 className="flex align-items-center m-0">Daten</h3>
                  </div>
              }
        >
            <div className="p-fluid">
                <div className="field grid">
                    <label className="col-fixed font-bold" style={{width: 150}}>
                        Name
                    </label>
                    <div className="col">
                        {user?.name}
                    </div>
                </div>
                <ChangeEmailForm userFragmentRef={user!}/>
                <ChangePasswordForm userFragmentRef={user!}/>
                <ChangeActivatedForm userFragmentRef={user!}/>
            </div>
        </Card>
        <Card className="mb-4"
              header={
                  <div className="p-2">
                      <h3 className="flex align-items-center m-0">Zusätzliche Informationen</h3>
                      <ChangeUserExtensionForm userFragmentRef={user}/>
                  </div>
              }
        >
        </Card>
    </div> : null;
}
