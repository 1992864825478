/**
 * @generated SignedSource<<e775a8228643c35a668f1d2f0d429c3c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModuleTemplateScreen_Query$variables = {
  id: string;
};
export type ModuleTemplateScreen_Query$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"ModuleTemplateScreen_ModuleTemplateFragment">;
  } | null;
};
export type ModuleTemplateScreen_Query = {
  response: ModuleTemplateScreen_Query$data;
  variables: ModuleTemplateScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ModuleTemplateScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ModuleTemplateScreen_ModuleTemplateFragment"
              }
            ],
            "type": "ModuleTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ModuleTemplateScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortTitle",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortDescription",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isExam",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "lastUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TrainingDayTemplate",
                "kind": "LinkedField",
                "name": "trainingDays",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ModuleTemplatePath",
                "kind": "LinkedField",
                "name": "moduleTemplatePath",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "courseTemplateRef",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "ModuleTemplate",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "607221150987bbf1421e78df711befe3",
    "id": null,
    "metadata": {},
    "name": "ModuleTemplateScreen_Query",
    "operationKind": "query",
    "text": "query ModuleTemplateScreen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on ModuleTemplate {\n      ...ModuleTemplateScreen_ModuleTemplateFragment\n    }\n    id\n  }\n}\n\nfragment AddTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {\n  id\n}\n\nfragment EditModuleTemplateForm_ModuleTemplateFragment on ModuleTemplate {\n  id\n  moduleTemplatePath {\n    courseTemplateRef\n    id\n  }\n  ...AddTrainingDayTemplateButton_ModuleTemplateFragment\n  ...RemoveTrainingDayTemplateButton_ModuleTemplateFragment\n}\n\nfragment ModuleTemplateScreen_ModuleTemplateFragment on ModuleTemplate {\n  id\n  title\n  shortTitle\n  internalTitle\n  shortDescription\n  createdAt\n  isExam\n  lastUpdate\n  trainingDays {\n    id\n    internalTitle\n  }\n  ...EditModuleTemplateForm_ModuleTemplateFragment\n  ...TemplateHeader_ModuleTemplateFragment\n}\n\nfragment RemoveTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {\n  id\n}\n\nfragment TemplateHeader_ModuleTemplateFragment on ModuleTemplate {\n  id\n  shortTitle\n}\n"
  }
};
})();

(node as any).hash = "5cfff7e55b51c3fabb9c8adcb82e4d65";

export default node;
