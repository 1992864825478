import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import React, { useRef } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { CreateDiscountActionButton_CreateMutation } from "../../../../__generated__/CreateDiscountActionButton_CreateMutation.graphql";

const CREATE_DISCOUNT_ACTION_MUTATION = graphql`
	mutation CreateDiscountActionButton_CreateMutation(
		$input: CreateEuroDiscountActionInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createEuroDiscountAction(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...DiscountActionsTable_DiscountActionFragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	className: string;
	connectionId: string;
}

export const CreateDiscountActionButton = ({ connectionId, className }: OwnProps) => {
	const [createDiscountAction, isLoading] =
		useMutation<CreateDiscountActionButton_CreateMutation>(CREATE_DISCOUNT_ACTION_MUTATION);

	const overlayPanel = useRef<OverlayPanel>(null);
	const nameOfAction = useRef<any>(null);
	const toast = useRef<any>(null);

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="Neu"
				loading={isLoading}
				disabled={isLoading}
				className={className}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputText id="nameOfAction" ref={nameOfAction} />
					<label htmlFor="nameOfAction">Name der Aktion</label>
				</span>
				<Button
					label="Aktion erstellen"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						createDiscountAction({
							variables: {
								input: {
									title: nameOfAction.current.value || "Neu",
								},
								connections: [connectionId],
							},
							onCompleted: () => {
								toast.current?.show({
									severity: "success",
									life: 3000,
									summary: "Aktion erstellt",
									detail: "Discountaktion wurde erfolgreich erstellt",
								});
							},
						});
					}}
				/>
			</OverlayPanel>
		</>
	);
};
