import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { ProgressSpinner } from "primereact/progressspinner";
import { graphql } from "babel-plugin-relay/macro";
import { EditOrderScreen_OrderQuery } from "../../__generated__/EditOrderScreen_OrderQuery.graphql";
import { useMatch } from "react-router-dom";
import { EDIT_ORDERS_PATH } from "../../routes/BillingRoutes";
import { BillingDetailsForm } from "../../billing-admin/components/relay/forms/BillingDetailsForm";
import { Accordion, AccordionTab } from "primereact/accordion";
import { PaymentDataForm } from "../../billing-admin/components/relay/forms/PaymentDataForm";
import { InvoiceDataForm } from "../../billing-admin/components/relay/forms/InvoiceDataForm";
import { Divider } from "primereact/divider";
import { CreditNoteDataForm } from "../../billing-admin/components/relay/forms/CreditNoteDataForm";
import { Card } from "primereact/card";
import { OrderHistoryTable } from "../../billing-admin/components/relay/tables/OrderHistoryTable";

const QUERY = graphql`
	query EditOrderScreen_OrderQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Order {
				id
				billingDetails {
					...BillingDetailsForm_BillingDetailsFragment
				}
				...PaymentDataForm_OrderFragment
				...InvoiceDataForm_OrderFragment
				...CreditNoteDataForm_OrderFragment
			}
		}
		...OrderHistoryTable_OrderHistoriesFragment @arguments(first: 10, after: null)
	}
`;
export const EditOrderScreen = () => {
	const {
		params: { orderId },
	} = useMatch(EDIT_ORDERS_PATH)!;

	const query = useLazyLoadQuery<EditOrderScreen_OrderQuery>(QUERY, {
		id: orderId!,
		skip: !orderId,
	});

	return query.node ? (
		<Suspense fallback={<ProgressSpinner />}>
			<div className="p-4">
				<Card>
					<h2 className="mb-3 text-center">[Bestellung] {query.node?.id}</h2>
					<Accordion activeIndex={0}>
						<AccordionTab header="Kundeninformationen">
							<BillingDetailsForm
								billingDetailsFragmentRef={query.node?.billingDetails}
							/>
						</AccordionTab>
						<AccordionTab header="Zahlungsdaten">
							<PaymentDataForm orderFragmentRef={query.node} />
						</AccordionTab>
						<AccordionTab header="Rechnungsdaten">
							<InvoiceDataForm orderFragmentRef={query.node} />
							<Divider layout={"horizontal"} />
							<CreditNoteDataForm orderFragmentRef={query.node} />
						</AccordionTab>
						<AccordionTab header="Verlauf">
							<OrderHistoryTable ordersFragmentRef={query} />
						</AccordionTab>
					</Accordion>
				</Card>
			</div>
		</Suspense>
	) : (
		<h4>Fehler beim Laden der Bestellung</h4>
	);
};
