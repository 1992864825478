import React from 'react'
import {OrderList} from "primereact/orderlist";
import {Button} from "primereact/button";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {ValidatedField} from "../fields/ValidatedField";
import {DefaultTextareaComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {ProgressSpinner} from "primereact/progressspinner";
import {AddTrainingDayTemplateButton} from "../buttons/AddTrainingDayTemplateButton";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {EditModuleTemplateForm_ModuleTemplateFragment$key} from "../../../__generated__/EditModuleTemplateForm_ModuleTemplateFragment.graphql";
import {RemoveTrainingDayTemplateButton} from "../buttons/RemoveTrainingDayTemplateButton";
import * as Yup from "yup";
import {DefaultSwitchComponent} from "../../ui/DefaultBooleanInput";
import {EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation} from "../../../__generated__/EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation.graphql";
import {toast} from "react-toastify";
import {Divider} from "primereact/divider";

const FRAGMENT = graphql`
    fragment EditModuleTemplateForm_ModuleTemplateFragment on ModuleTemplate {
        id
        moduleTemplatePath {
            courseTemplateRef
        }
        ...AddTrainingDayTemplateButton_ModuleTemplateFragment
        ...RemoveTrainingDayTemplateButton_ModuleTemplateFragment
    }
`

const CHANGE_TRAINING_DAY_TEMPLATE_ORDER_MUTATION = graphql`
    mutation EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation($input: ChangeTrainingDayTemplateOrderInput!){
        Admin {
            Template {
                changeTrainingDayTemplateOrder(input: $input) {
                    moduleTemplate {
                        node {
                            ...ModuleTemplateScreen_ModuleTemplateFragment
                        }
                    }

                }
            }
        }
    }
`

export interface FormState {
    id: string | null
    internalTitle: string
    createdAt?: string
    isExam: boolean
    title: string
    shortTitle: string
    trainingDays: TrainingDayDisplay[]
    shortDescription: string
}

export interface TrainingDayDisplay {
    id: string,
    internalTitle: string
}

interface OwnProps {
    loading?: boolean
    initialValues: FormState
    onSubmit: (values: FormState) => void

    moduleTemplateFragmentRef: EditModuleTemplateForm_ModuleTemplateFragment$key | null
}

export const EditModuleTemplateForm = ({loading, initialValues, onSubmit, moduleTemplateFragmentRef}: OwnProps) => {
    const moduleTemplate = useFragment<EditModuleTemplateForm_ModuleTemplateFragment$key>(FRAGMENT, moduleTemplateFragmentRef)

    const [changeOrder] = useMutation<EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation>(CHANGE_TRAINING_DAY_TEMPLATE_ORDER_MUTATION)

    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            internalTitle: Yup.string().required("Interner Titel wird benötigt"),
            title: Yup.string().required("Titel wird benötigt"),
            shortTitle: Yup.string().required("Kurztitel wird benötigt"),
            shortDescription: Yup.string().required("Kurze Beschreibung wird benötigt"),
        }),
        initialValues: {
            id: initialValues.id,
            internalTitle: initialValues.internalTitle,
            title: initialValues.title,
            shortTitle: initialValues.shortTitle,
            isExam: initialValues.isExam,
            shortDescription: initialValues.shortDescription,
            createdAt: initialValues.createdAt,
            trainingDays: initialValues.trainingDays.map(trainingDay => ({
                id: trainingDay.id,
                internalTitle: trainingDay.internalTitle
            }))
        },
        onSubmit: (values: FormState) => onSubmit(values)
    })


    if (loading) return <ProgressSpinner/>

    return <div className="position-relative">
        <FormContainer onSubmit={() => formik.handleSubmit()} className={"p-fluid"}>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="internalTitle"
                    label="Interner Titel (Nur im Admin sichtbar)"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="title"
                    label="Titel"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="shortTitle"
                    label="Kurztitel"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group flex align-items-center ">
                <ValidatedField<FormState, boolean>
                    name="isExam"
                    label="Prüfungsmodul"
                    component={DefaultSwitchComponent}
                    formikConfig={formik}/>
            </div>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="shortDescription"
                    label="Beschreibung"
                    required={true}
                    component={DefaultTextareaComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <Button type={"button"} onClick={() => formik.handleSubmit()} style={{marginTop: "50px"}}>Modulvorlage
                Speichern</Button>
            <WholeGridSpaceContainer>
                <Divider layout={"horizontal"} children={<b>Ausbildungstagvorlagen</b>}/>
                <ValidatedField<FormState, TrainingDayDisplay[]>
                    name="trainingDays"
                    required={true}
                    component={({fieldValue, updateField}) => {
                        return (
                            <div className="flex flex-column">
                                <AddTrainingDayTemplateButton moduleTemplateFragmentRef={moduleTemplate}/>
                                <OrderList
                                    dragdrop={false}
                                    onChange={e => {
                                        if (moduleTemplate && e.value.length > 0 && !e.value.some((l: any) => l === undefined)) {
                                            changeOrder({
                                                variables: {
                                                    input: {
                                                        moduleTemplateId: moduleTemplate.id,
                                                        trainingDayRefs: e.value.map((tdr: TrainingDayDisplay) => tdr.id)
                                                    }
                                                },
                                                onCompleted: () => {
                                                    toast.success("Reihenfolge erfolgreich gespeichert")
                                                }
                                            })
                                        }
                                    }}
                                    itemTemplate={(item) => {
                                        return <div className="flex align-items-center" onDoubleClick={() => {
                                            navigate(`/course-templates/${moduleTemplate?.moduleTemplatePath.courseTemplateRef}/${moduleTemplate?.id}/${item.id}/edit`)
                                        }}>
                                            {item.internalTitle}
                                            <Button
                                                tooltip="Bearbeiten"
                                                className="ml-auto"
                                                icon="pi pi-pencil"
                                                type={"button"}
                                                onClick={() => {
                                                    navigate(`/course-templates/${moduleTemplate?.moduleTemplatePath.courseTemplateRef}/${moduleTemplate?.id}/${item.id}/edit`)
                                                }}
                                            />
                                            <RemoveTrainingDayTemplateButton trainingDayId={item.id}
                                                                             moduleTemplateFragmentRef={moduleTemplate}/>
                                        </div>
                                    }}
                                    value={fieldValue}/>
                            </div>
                        )
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
        </FormContainer>
    </div>
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const WholeGridSpaceContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 1rem 0;
`
