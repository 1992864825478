import React from 'react'
import {OrderList} from "primereact/orderlist";
import {Button} from "primereact/button";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {ValidatedField} from "../fields/ValidatedField";
import {DefaultTextareaComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {ProgressSpinner} from "primereact/progressspinner";
import {AddTrainingDayButton} from "../buttons/AddTrainingDayButton";
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {EditModuleForm_ModuleFragment$key} from "../../../__generated__/EditModuleForm_ModuleFragment.graphql";
import {RemoveTrainingDayButton} from "../buttons/RemoveTrainingDayButton";
import * as Yup from "yup";
import {DefaultSwitchComponent} from "../../ui/DefaultBooleanInput";
import {
    EditModuleForm_ChangeTrainingDayOrderMutation
} from "../../../__generated__/EditModuleForm_ChangeTrainingDayOrderMutation.graphql";
import {toast} from "react-toastify";
import {LocationInput} from "./LocationInput";
import {TrainerInput} from "./TrainerInput";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";

const FRAGMENT = graphql`
    fragment EditModuleForm_ModuleFragment on Module {
        id
        modulePath {
            courseRef
        }
        ...AddTrainingDayButton_ModuleFragment
        ...RemoveTrainingDayButton_ModuleFragment
    }
`

const CHANGE_TRAINING_DAY_ORDER_MUTATION = graphql`
    mutation EditModuleForm_ChangeTrainingDayOrderMutation($input: ChangeTrainingDayOrderInput!){
        Admin {
            Course {
                changeTrainingDayOrder(input: $input) {
                    module {
                        node {
                            ...ModuleScreen_ModuleFragment
                        }
                    }
                }
            }
        }
    }
`

export interface FormState {
    id: string | null
    internalTitle: string
    createdAt?: string
    isExam: boolean
    locationId: string
    trainerIds: string[]
    title: string
    shortTitle: string
    trainingDays: TrainingDayDisplay[]
    shortDescription: string
}

export interface TrainingDayDisplay {
    id: string,
    internalTitle: string
    date: string
}

interface OwnProps {
    courseId: string
    loading?: boolean
    initialValues: FormState
    onSubmit: (values: FormState) => void

    moduleFragmentRef: EditModuleForm_ModuleFragment$key | null
}

export const EditModuleForm = ({courseId, loading, initialValues, onSubmit, moduleFragmentRef}: OwnProps) => {
    const module = useFragment<EditModuleForm_ModuleFragment$key>(FRAGMENT, moduleFragmentRef)

    const [changeOrder] = useMutation<EditModuleForm_ChangeTrainingDayOrderMutation>(CHANGE_TRAINING_DAY_ORDER_MUTATION)

    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            internalTitle: Yup.string().required("Interner Titel wird benötigt"),
            title: Yup.string().required("Titel wird benötigt"),
            shortTitle: Yup.string().required("Kurztitel wird benötigt"),
            shortDescription: Yup.string().required("Kurze Beschreibung wird benötigt"),
            locationId: Yup.string().required("es wird ein Ort benötigt"),
            trainerIds: Yup.array().min(1).required("es wird mindestens ein Ausbilder benötigt"),
        }),
        initialValues: {
            id: initialValues.id,
            internalTitle: initialValues.internalTitle,
            title: initialValues.title,
            shortTitle: initialValues.shortTitle,
            isExam: initialValues.isExam,
            locationId: initialValues.locationId,
            trainerIds: initialValues.trainerIds,
            shortDescription: initialValues.shortDescription,
            createdAt: initialValues.createdAt,
            trainingDays: initialValues.trainingDays.map(trainingDay => ({
                id: trainingDay.id,
                internalTitle: trainingDay.internalTitle,
                date: trainingDay.date,
            }))
        },
        onSubmit: (values: FormState, {setSubmitting}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })


    if (loading) return <ProgressSpinner/>

    return <div className="position-relative">
        <FormContainer onSubmit={() => formik.handleSubmit()} className={"p-fluid"}>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="internalTitle"
                    label="Interner Titel (Nur im Admin sichtbar)"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="title"
                    label="Titel"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="shortTitle"
                    label="Kurztitel"
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="locationId"
                    label="Ort"
                    required
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <LocationInput onSelect={updateField} selectedLocation={fieldValue}/>

                    }}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string[]>
                    name="trainerIds"
                    label="Ausbilder"
                    required
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <TrainerInput onSelect={(val) => updateField(val || [])} selectedTrainers={fieldValue}/>

                    }}
                    formikConfig={formik}/>
            </div>
            <div className="form-group flex align-items-center ">
                <ValidatedField<FormState, boolean>
                    name="isExam"
                    label="Prüfungsmodul"
                    component={DefaultSwitchComponent}
                    formikConfig={formik}/>
            </div>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="shortDescription"
                    label="Beschreibung"
                    required={true}
                    component={DefaultTextareaComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <Button
                type={"button"}
                disabled={formik.isSubmitting}
                onClick={() => formik.handleSubmit()}>
                Modul Speichern
            </Button>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, TrainingDayDisplay[]>
                    name="trainingDays"
                    label="Ausbildungstage"
                    required={true}
                    component={({fieldValue, updateField}) => {
                        return (
                            <div className="flex flex-column">
                                <AddTrainingDayButton courseId={courseId} moduleFragmentRef={module}/>
                                <OrderList
                                    dragdrop={false}
                                    onChange={e => {
                                        if (module && e.value.length > 0 && !e.value.some((l: any) => l === undefined)) {
                                            changeOrder({
                                                variables: {
                                                    input: {
                                                        moduleId: module.id,
                                                        trainingDayRefs: e.value.map((td: TrainingDayDisplay) => td.id)
                                                    }
                                                },
                                                onCompleted: () => {
                                                    toast.success("Reihenfolge erfolgreich gespeichert")
                                                }
                                            })
                                        }
                                    }}
                                    itemTemplate={(item) => {
                                        return <div className="flex align-items-center" onDoubleClick={() => {
                                            navigate(`/courses/${module?.modulePath.courseRef}/${module?.id}/${item.id}/edit`)
                                        }}>
                                            <div className="grid w-full">
                                                <div
                                                    className="col md:col lg:col flex align-items-center">{item.internalTitle}</div>
                                                <div className="col-2 md:col-6 lg:col-5 flex align-items-center">
                                                    <span className="mr-2">Am:</span>
                                                    <DateTimeDisplay value={item.date}
                                                                     hideTimezone
                                                                     showTime={false}/>
                                                </div>
                                                <Button
                                                    className="ml-auto"
                                                    icon="pi pi-pencil"
                                                    tooltip="Bearbeiten"
                                                    type={"button"}
                                                    onClick={() => {
                                                        navigate(`/courses/${module?.modulePath.courseRef}/${module?.id}/${item.id}/edit`)
                                                    }}
                                                />
                                                <RemoveTrainingDayButton trainingDayId={item.id}
                                                                         moduleFragmentRef={module}/>
                                            </div>
                                        </div>
                                    }}
                                    value={fieldValue}/>
                            </div>
                        )
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
        </FormContainer>
    </div>
}

const FormContainer = styled.form`
  display: grid;
  grid--columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid--columns: 1fr;
  }
`

const WholeGridSpaceContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 1rem 0;
`
