/**
 * @generated SignedSource<<1ec751363ddac294cf39e58b1e9bc589>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateModuleTemplateInput = {
  clientMutationId?: string | null;
  id: string;
};
export type AddModuleTemplateButton_AddModuleMutation$variables = {
  input: CreateModuleTemplateInput;
};
export type AddModuleTemplateButton_AddModuleMutation$data = {
  readonly Admin: {
    readonly Template: {
      readonly createModuleTemplate: {
        readonly courseTemplate: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"CourseTemplateScreen_CourseTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type AddModuleTemplateButton_AddModuleMutation = {
  response: AddModuleTemplateButton_AddModuleMutation$data;
  variables: AddModuleTemplateButton_AddModuleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddModuleTemplateButton_AddModuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateModuleTemplatePayload",
                "kind": "LinkedField",
                "name": "createModuleTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "courseTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseTemplateScreen_CourseTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddModuleTemplateButton_AddModuleMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateModuleTemplatePayload",
                "kind": "LinkedField",
                "name": "createModuleTemplate",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "courseTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "iconRef",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRebookedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amountOfRegulatedParticipant",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Price",
                            "kind": "LinkedField",
                            "name": "price",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "grossPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "netPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "taxRatePercentage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModuleTemplate",
                            "kind": "LinkedField",
                            "name": "modules",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "internalTitle",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ffeecce2735f9fd367b272043d7640a7",
    "id": null,
    "metadata": {},
    "name": "AddModuleTemplateButton_AddModuleMutation",
    "operationKind": "mutation",
    "text": "mutation AddModuleTemplateButton_AddModuleMutation(\n  $input: CreateModuleTemplateInput!\n) {\n  Admin {\n    Template {\n      createModuleTemplate(input: $input) {\n        courseTemplate {\n          node {\n            ...CourseTemplateScreen_CourseTemplateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment AddModuleTemplateButton_CourseTemplateFragment on CourseTemplate {\n  id\n}\n\nfragment CourseTemplateScreen_CourseTemplateFragment on CourseTemplate {\n  id\n  name\n  description\n  iconRef\n  createdAt\n  amountOfRebookedParticipant\n  amountOfRegulatedParticipant\n  price {\n    grossPrice\n    netPrice\n    taxRatePercentage\n  }\n  modules {\n    id\n    internalTitle\n  }\n  ...EditCourseTemplateForm_CourseTemplateFragment\n  ...TemplateHeader_CourseTemplateFragment\n}\n\nfragment EditCourseTemplateForm_CourseTemplateFragment on CourseTemplate {\n  id\n  ...AddModuleTemplateButton_CourseTemplateFragment\n  ...RemoveModuleTemplateButton_CourseTemplateFragment\n}\n\nfragment RemoveModuleTemplateButton_CourseTemplateFragment on CourseTemplate {\n  id\n}\n\nfragment TemplateHeader_CourseTemplateFragment on CourseTemplate {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "7a66c4b3e1ff077345e37e230fc69cb0";

export default node;
