import React from "react"
import graphql from "babel-plugin-relay/macro"
import {useFragment} from "react-relay";
import {
    OrderPaymentMethodColumn_OrderFragment$key
} from "../../../../../__generated__/OrderPaymentMethodColumn_OrderFragment.graphql";
import {PaymentMethodType} from "../../../../translations/PaymentMethodType";

const ORDER_FRAGMENT = graphql`
    fragment OrderPaymentMethodColumn_OrderFragment on Order {
        selectedPaymentMethod {
            selectedPaymentMethodType
        }
    }
`

interface OwnProps {
    orderFragmentRef: OrderPaymentMethodColumn_OrderFragment$key
}

export const OrderPaymentMethodColumn = ({orderFragmentRef}: OwnProps) => {
    const order = useFragment<OrderPaymentMethodColumn_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef)

    if(!order.selectedPaymentMethod?.selectedPaymentMethodType) return <div>N/A</div>

    return <div>{PaymentMethodType[order.selectedPaymentMethod?.selectedPaymentMethodType]}</div>
}
