import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { CodeCodeColumn_DiscountCodeFragment$key } from "../../../../../__generated__/CodeCodeColumn_DiscountCodeFragment.graphql";

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment CodeCodeColumn_DiscountCodeFragment on DiscountCode {
		code
	}
`;

interface OwnProps {
	discountCodeFragmentRef: CodeCodeColumn_DiscountCodeFragment$key;
}

export const CodeCodeColumn = ({ discountCodeFragmentRef }: OwnProps) => {
	const discountCode = useFragment<CodeCodeColumn_DiscountCodeFragment$key>(
		DISCOUNT_CODE_FRAGMENT,
		discountCodeFragmentRef,
	);

	return <div>{discountCode.code}</div>;
};
