/**
 * @generated SignedSource<<72c6c7cb7f1aa094be76fa45a189c7c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeUserExtensionForm_UserFragment$data = {
  readonly extension: {
    readonly adsOptIn?: boolean;
    readonly isBlocked?: boolean;
    readonly note?: string | null;
    readonly salutation?: string;
  };
  readonly id: string;
  readonly " $fragmentType": "ChangeUserExtensionForm_UserFragment";
};
export type ChangeUserExtensionForm_UserFragment$key = {
  readonly " $data"?: ChangeUserExtensionForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeUserExtensionForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeUserExtensionForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBlocked",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "salutation",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "adsOptIn",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "note",
              "storageKey": null
            }
          ],
          "type": "UserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "d424ccffc9d3076019ddbed93baa27aa";

export default node;
