/**
 * @generated SignedSource<<ed901f277754222254e9472b67a9cc19>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetNoteForParticipantInput = {
  clientMutationId?: string | null;
  note?: string | null;
  trainingDayId: string;
  userId: string;
};
export type CourseStatesTable_SetNoteMutation$variables = {
  input: SetNoteForParticipantInput;
};
export type CourseStatesTable_SetNoteMutation$data = {
  readonly Admin: {
    readonly States: {
      readonly setNoteForParticipant: {
        readonly courseState: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_CourseStateFragment">;
          };
        };
      } | null;
    };
  };
};
export type CourseStatesTable_SetNoteMutation = {
  response: CourseStatesTable_SetNoteMutation$data;
  variables: CourseStatesTable_SetNoteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseStatesTable_SetNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetNoteForParticipantPayload",
                "kind": "LinkedField",
                "name": "setNoteForParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseStatesTable_CourseStateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseStatesTable_SetNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetNoteForParticipantPayload",
                "kind": "LinkedField",
                "name": "setNoteForParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModuleState",
                            "kind": "LinkedField",
                            "name": "moduleStates",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "moduleId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TrainingDayState",
                                "kind": "LinkedField",
                                "name": "trainingDayStates",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "trainingDayId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AttendanceTypeAdmin",
                                    "kind": "LinkedField",
                                    "name": "attendanceListAdmin",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceStatus",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "foodCreditState",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "note",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublishedCourse",
                            "kind": "LinkedField",
                            "name": "publishedCourse",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "secondName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PublishedModuleAdmin",
                                "kind": "LinkedField",
                                "name": "modulesAdmin",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PublishedTrainingDay",
                                    "kind": "LinkedField",
                                    "name": "trainingDays",
                                    "plural": true,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "date",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4ba8e414d9e62350483482771139d786",
    "id": null,
    "metadata": {},
    "name": "CourseStatesTable_SetNoteMutation",
    "operationKind": "mutation",
    "text": "mutation CourseStatesTable_SetNoteMutation(\n  $input: SetNoteForParticipantInput!\n) {\n  Admin {\n    States {\n      setNoteForParticipant(input: $input) {\n        courseState {\n          node {\n            ...CourseStatesTable_CourseStateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CourseStatesTable_CourseStateFragment on CourseState {\n  id\n  moduleStates {\n    moduleId\n    trainingDayStates {\n      trainingDayId\n      attendanceListAdmin {\n        attendanceStatus\n        attendanceType\n        foodCreditState\n        note\n        id\n      }\n      id\n    }\n    id\n  }\n  publishedCourse {\n    name\n    secondName\n    modulesAdmin {\n      id\n      title\n      startDate\n      endDate\n      trainingDays {\n        id\n        date\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "1c0af7dc1e14bd4b87d4e4301b9287db";

export default node;
