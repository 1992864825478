import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {GroupScreen} from "../screens/group/GroupScreen";

export const GroupRoutes: SecureRouteDefinition[] = [
    {
        path: "/groups",
        element: <GroupScreen/>,
        requiredPermissions: ["System_Owner", "System_Root"]
    }
]
