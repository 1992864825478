import {graphql} from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {useFormik} from "formik";
import React from "react";
import * as Yup from "yup";
import {TkDialog} from "../../ui/TkDialog";
import {ValidatedField} from "../../ui/ValidatedField";
import {EditGroupModal_CreateMutation} from "../../../__generated__/EditGroupModal_CreateMutation.graphql";
import {EditGroupModal_GroupFragment$key, Permission} from "../../../__generated__/EditGroupModal_GroupFragment.graphql";
import {EditGroupModal_EditMutation} from "../../../__generated__/EditGroupModal_EditMutation.graphql";
import {DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {PermissionsField} from "../fields/PermissionsField";
import {TkButtonLink} from "../../ui/TkButtonLink";


const GROUP_FRAGMENT = graphql`
    fragment EditGroupModal_GroupFragment on Group {
        id
        name
        permissions
    }
`


const CREATE_MUTATION = graphql`
    mutation EditGroupModal_CreateMutation($input: CreateGroupInput!, $connections: [ID!]!) {
        Management {
            createGroup(input: $input) {
                edge @appendEdge(connections: $connections){
                    node {
                        id
                        ...EditGroupModal_GroupFragment
                    }
                }

            }
        }
    }
`

const EDIT_MUTATION = graphql`
    mutation EditGroupModal_EditMutation($input: EditGroupInput!, $connections: [ID!]!) {
        Management {
            editGroup(input: $input) {
                edge @appendEdge(connections: $connections){
                    node {
                        id
                        ...EditGroupModal_GroupFragment
                    }
                }

            }
        }
    }
`


interface OwnProps {
    groupFragmentRef?: EditGroupModal_GroupFragment$key | null
    onCompleted?: () => void
    connectionId?: string

    isVisible: boolean
    onHide: () => void
}


interface FormState {
    name?: string
    permissions?: Permission[]
}

export const EditGroupModal = ({
                                   groupFragmentRef,
                                   connectionId,
                                   onCompleted,
                                   isVisible,
                                   onHide
                               }: OwnProps) => {
    const group = useFragment<EditGroupModal_GroupFragment$key>(GROUP_FRAGMENT, groupFragmentRef || null)

    const [create] = useMutation<EditGroupModal_CreateMutation>(CREATE_MUTATION)
    const [edit] = useMutation<EditGroupModal_EditMutation>(EDIT_MUTATION)

    const formik = useFormik<FormState>({
        initialValues: {
            name: group?.name || "",
            permissions: group?.permissions ? [...group.permissions] : [],
        },
        validationSchema: Yup.object().shape({
            name: Yup
                .string()
                .required("Name is a required field."),
            permissions: Yup
                .array()
                .required("Permissions are required")
        }),
        onSubmit: (values, {setSubmitting}) => {
            if (group) {
                edit({
                    variables: {
                        input: {
                            id: group.id,
                            name: values.name!,
                            permissions: values.permissions!
                        },
                        connections: connectionId ? [connectionId] : []
                    },
                    onCompleted: () => {
                        setSubmitting(false)
                        onCompleted && onCompleted()
                    },
                    onError: e => {
                        console.error(e)
                        setSubmitting(false)
                    }
                })
            } else {
                create({
                    variables: {
                        input: {
                            name: values.name!,
                            permissions: values.permissions!
                        },
                        connections: connectionId ? [connectionId] : []
                    },
                    onCompleted: () => {
                        setSubmitting(false)
                        onCompleted && onCompleted()
                    },
                    onError: e => {
                        console.error(e)
                        setSubmitting(false)
                    }
                })
            }

        }
    })

    return <TkDialog
        header={<h1>{group ? "Gruppe Editieren" : "Gruppe erstellen"}</h1>} visible={isVisible}
        onHide={() => onHide()}
        footer={<div className="flex">
            <TkButtonLink disabled={formik.isSubmitting}
                          type="button"
                          onClick={() => onHide()} label={"Zurück"}
                          className="m-auto w-auto"/>
            <TkButtonLink disabled={formik.isSubmitting}
                          onClick={() => formik.handleSubmit()}
                          label={group ? "Gruppe Editieren" : "Gruppe erstellen"}
                          className="m-auto w-auto"
            />
        </div>}
    >
        <form onSubmit={formik.handleSubmit} className="p-fluid mb-5">
            <ValidatedField<FormState, string>
                className="mb-4"
                name={"name"}
                label={"Name"}
                required={true}
                formikConfig={formik}
                component={DefaultTextFieldComponent}/>
            <ValidatedField<FormState, Permission[]>
                className="mb-4"
                name={"permissions"}
                required={true}
                formikConfig={formik}
                component={PermissionsField}/>
        </form>
    </TkDialog>


}
