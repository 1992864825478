import React from 'react'
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {EditModuleForm, FormState} from "../../components/relay/forms/EditModuleForm";
import {ModuleScreen_Query} from "../../__generated__/ModuleScreen_Query.graphql";
import {ModuleScreen_ModuleFragment$key} from "../../__generated__/ModuleScreen_ModuleFragment.graphql";
import {toast} from "react-toastify";
import {ModuleScreen_UpdatingMutation} from "../../__generated__/ModuleScreen_UpdatingMutation.graphql";
import {CourseHeader} from "../../components/relay/header/CourseHeader";
import {EDIT_MODULE_PATH} from "../../routes/CourseRoutes";

const QUERY = graphql`
    query ModuleScreen_Query($id: ID!) {
        node(id: $id) {
            ... on Module {
                ...ModuleScreen_ModuleFragment
            }
        }
    }
`

const MODULE_FRAGMENT = graphql`
    fragment ModuleScreen_ModuleFragment on Module {
        id
        title
        shortTitle
        internalTitle
        shortDescription
        createdAt
        isExam
        trainerIds
        locationId
        lastUpdate
        trainingDays{
            id
            internalTitle
            date
        }

        ...EditModuleForm_ModuleFragment
        ...CourseHeader_ModuleFragment
    }
`;

const UPDATE_MUTATION = graphql`
    mutation ModuleScreen_UpdatingMutation($input: EditModuleInput!) {
        Admin {
            Course {
                editModule(input: $input) {
                    module{
                        node {
                            id
                            ...CourseHeader_ModuleFragment
                            ...ModuleScreen_ModuleFragment
                        }
                    }
                }
            }
        }
    }
`;

export const ModuleScreen = () => {
    const {params: {courseId, moduleId}} = useMatch(EDIT_MODULE_PATH)!
    const query = useLazyLoadQuery<ModuleScreen_Query>(QUERY, {
        id: moduleId!,
    }, {fetchPolicy: "network-only"})

    const module = useFragment<ModuleScreen_ModuleFragment$key>(MODULE_FRAGMENT, query.node || null)

    const [updateModule, isUpdatingModule] = useMutation<ModuleScreen_UpdatingMutation>(UPDATE_MUTATION)

    return <div>
        <CourseHeader courseId={courseId} moduleFragment={module}/>
        <h1>{module?.internalTitle} bearbeiten</h1>
        <EditModuleForm
            courseId={courseId!}
            initialValues={{
                id: module?.id || "",
                internalTitle: module?.internalTitle || "",
                title: module?.title || "",
                shortTitle: module?.shortTitle || "",
                shortDescription: module?.shortDescription || "",
                trainerIds: module?.trainerIds.map(l => l) || [],
                locationId: module?.locationId || "",
                createdAt: module?.createdAt,
                isExam: module?.isExam || false,
                trainingDays: module?.trainingDays.map(trainingDay => ({
                    id: trainingDay?.id,
                    internalTitle: trainingDay?.internalTitle,
                    date: trainingDay?.date
                })) || []
            }}
            loading={isUpdatingModule}
            onSubmit={
                (values: FormState) => {
                    if (module) {
                        updateModule({
                            variables: {
                                input: {
                                    id: module.id,
                                    internalTitle: values.internalTitle,
                                    title: values.title,
                                    shortTitle: values.shortTitle,
                                    locationId: values.locationId,
                                    trainerIds: values.trainerIds,
                                    isExam: values.isExam,
                                    shortDescription: values.shortDescription
                                }
                            },
                            onCompleted: (response) => {
                                toast.success("Modul erfolgreich gespeichert")
                            },
                            onError: error => {
                                toast.error(error.message)
                            }
                        })
                    }
                }
            }
            moduleFragmentRef={module}/>
    </div>
}
