import React, {useState} from "react";
import {TkButton} from "../../ui/TkButton";
import {CreateUserModal} from "../modals/CreateUserModal";

interface OwnProps {
    connectionId: string
}

export const CreateUserButton = ({connectionId}: OwnProps) => {
    const [isVisible, setVisible] = useState<boolean>(false)

    return <div>
        <TkButton
            onClick={() => setVisible(true)}
            label={"Teilehmer hinzufügen"}/>
        <CreateUserModal
            connectionId={connectionId}
            isVisible={isVisible}
            onHide={() => setVisible(false)}
            onCompleted={() => {
                setVisible(false)
            }}/>
    </div>
}
