/**
 * @generated SignedSource<<ad09ec01e32fcdf7c4d7caa637827d1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryIdColumn_OrderHistoryFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "OrderHistoryIdColumn_OrderHistoryFragment";
};
export type OrderHistoryIdColumn_OrderHistoryFragment$key = {
  readonly " $data"?: OrderHistoryIdColumn_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryIdColumn_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderHistoryIdColumn_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "OrderHistory",
  "abstractKey": null
};

(node as any).hash = "76e2089d3d9395d2940a9c0435e3dfdb";

export default node;
