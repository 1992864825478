import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {FaqsScreen} from "../screens/faq/FaqsScreen";
import {EditFaqScreen} from "../screens/faq/EditFaqScreen";

export const EDIT_FAQ_PATH = "/faqs/:faqId/edit";

export const FaqRoutes: SecureRouteDefinition[] = [
    {
        path: "/faqs",
        element: <FaqsScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_FAQ_PATH,
        element: <EditFaqScreen/>,
        requiredPermissions: ["System_Owner"]
    }
]
