import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useMatch } from "react-router";
import { useLazyLoadQuery } from "react-relay";
import { BreadCrumb } from "primereact/breadcrumb";
import { EDIT_DISCOUNT_CODE_PATH } from "../../routes/BillingRoutes";
import { DiscountCodeScreen_Query } from "../../__generated__/DiscountCodeScreen_Query.graphql";
import { DiscountUsageSearchContainer } from "../../billing-admin/components/relay/filters/DiscountUsageSearchContext";
import { DiscountCodeForm } from "../../billing-admin/components/relay/forms/DiscountCodeForm";
import { DiscountCodeUsageTable } from "../../billing-admin/components/relay/tables/DiscountCodeUsageTable";
export interface Account {
	id: string;
	name: string;
}

const QUERY = graphql`
	query DiscountCodeScreen_Query(
		$id: ID!
		$filterByUsedAtFrom: ZonedDateTIme
		$filterByUsedToFrom: ZonedDateTIme
	) {
		node(id: $id) {
			... on DiscountCode {
				code
				...DiscountCodeForm_DiscountCodeFragment
			}
		}
		...DiscountCodeUsageTable_DiscountCodeFragment
			@arguments(
				first: 10
				after: null
				id: $id
				filterByUsedAtFrom: $filterByUsedAtFrom
				filterByUsedToFrom: $filterByUsedToFrom
			)
	}
`;

export const DiscountCodeScreen = () => {
	const route = useMatch(EDIT_DISCOUNT_CODE_PATH);
	const discountActionId = route?.params.discountActionId!;
	const codeId = route?.params.codeId!;

	const query = useLazyLoadQuery<DiscountCodeScreen_Query>(QUERY, {
		id: codeId,
	});

	const items = [
		{ label: "Alle Aktionen", url: `${window.location.origin}/discount-actions` },
		{
			label: "Aktion",
			url: `${window.location.origin}/discount-actions/${discountActionId}/edit`,
		},
		{
			label: query.node?.code,
			url: `${window.location.origin}/discount-actions/${discountActionId}/${codeId}edit`,
		},
	];

	return (
		query.node && (
			<DiscountUsageSearchContainer>
				<BreadCrumb className="mb-5" model={items} />
				<DiscountCodeForm discountCodeFragmentRef={query.node} />
				<DiscountCodeUsageTable query={query} />
			</DiscountUsageSearchContainer>
		)
	);
};
