/**
 * @generated SignedSource<<210b0e0e2e08b16ece876dc25ae5434c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreditNoteDataForm_OrderFragment$data = {
  readonly creditNoteData: {
    readonly creditNoteDataBase64: string;
    readonly creditNoteId: string;
    readonly creditNoteNumber: string;
  } | null;
  readonly " $fragmentType": "CreditNoteDataForm_OrderFragment";
};
export type CreditNoteDataForm_OrderFragment$key = {
  readonly " $data"?: CreditNoteDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreditNoteDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreditNoteDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CreditNoteData",
      "kind": "LinkedField",
      "name": "creditNoteData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creditNoteId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creditNoteNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "creditNoteDataBase64",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "e05b60ce08b78c538df9e6506de550d3";

export default node;
