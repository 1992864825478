/**
 * @generated SignedSource<<627661b22800de9519bf8d975bbe12b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PublishedCourseTable_PublishedCoursesFragment$data = {
  readonly Admin: {
    readonly Course: {
      readonly publishedCourses: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly amountOfRebookedParticipant: number;
            readonly amountOfRegulatedParticipant: number;
            readonly id: string;
            readonly name: string;
            readonly productRef: string;
            readonly secondName: string;
            readonly startDate: string | null;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "PublishedCourseTable_PublishedCoursesFragment";
};
export type PublishedCourseTable_PublishedCoursesFragment$key = {
  readonly " $data"?: PublishedCourseTable_PublishedCoursesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PublishedCourseTable_PublishedCoursesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Course",
  "publishedCourses"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByCourseLocation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByModuleLocation"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByNameOrSecondName"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByRebookedFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByRegularFull"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByStartDate"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "filterByTrainers"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./PublishedCourseTable_Refetch.graphql')
    }
  },
  "name": "PublishedCourseTable_PublishedCoursesFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CourseAdminSchema",
          "kind": "LinkedField",
          "name": "Course",
          "plural": false,
          "selections": [
            {
              "alias": "publishedCourses",
              "args": [
                {
                  "kind": "Variable",
                  "name": "filterByCourseLocation",
                  "variableName": "filterByCourseLocation"
                },
                {
                  "kind": "Variable",
                  "name": "filterByModuleLocation",
                  "variableName": "filterByModuleLocation"
                },
                {
                  "kind": "Variable",
                  "name": "filterByNameOrSecondName",
                  "variableName": "filterByNameOrSecondName"
                },
                {
                  "kind": "Variable",
                  "name": "filterByRebookedFull",
                  "variableName": "filterByRebookedFull"
                },
                {
                  "kind": "Variable",
                  "name": "filterByRegularFull",
                  "variableName": "filterByRegularFull"
                },
                {
                  "kind": "Variable",
                  "name": "filterByStartDate",
                  "variableName": "filterByStartDate"
                },
                {
                  "kind": "Variable",
                  "name": "filterByTrainers",
                  "variableName": "filterByTrainers"
                }
              ],
              "concreteType": "PublishedCoursesConnection",
              "kind": "LinkedField",
              "name": "__PublishedCourseTable_publishedCourses_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PublishedCoursesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "PublishedCourse",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "id",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "secondName",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "productRef",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "startDate",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "amountOfRegulatedParticipant",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "amountOfRebookedParticipant",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7258a21140e24643ca4aafff1412da78";

export default node;
