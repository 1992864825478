import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { ProgressSpinner } from "primereact/progressspinner";
import { graphql } from "babel-plugin-relay/macro";
import { OrdersTable } from "../../billing-admin/components/relay/tables/OrdersTable";
import { OrdersScreen_Query } from "../../__generated__/OrdersScreen_Query.graphql";

const QUERY = graphql`
	query OrdersScreen_Query(
		$orderId: ID
		$userId: ID
		$name: String
		$numItemsPerPage: Int!
	) {
		...OrdersTable_Orders
			@arguments(
				orderId: $orderId
				userId: $userId
				name: $name
				first: $numItemsPerPage
				after: null
			)
	}
`;

export const OrdersScreen = () => {
	const ordersPerPage = 20;

	const query = useLazyLoadQuery<OrdersScreen_Query>(QUERY, {
		orderId: undefined,
		userId: undefined,
		name: undefined,
		numItemsPerPage: ordersPerPage,
	});

	return (
		<Suspense fallback={<ProgressSpinner />}>
			<h2 className="mb-3 text-center">Bestellungen</h2>
			<OrdersTable ordersFragmentRef={query} ordersPerPage={ordersPerPage} />
		</Suspense>
	);
};
