/**
 * @generated SignedSource<<1e910d9726582a5eedbf7cd001a3114d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddTrainingDayTemplateButton_ModuleTemplateFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AddTrainingDayTemplateButton_ModuleTemplateFragment";
};
export type AddTrainingDayTemplateButton_ModuleTemplateFragment$key = {
  readonly " $data"?: AddTrainingDayTemplateButton_ModuleTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddTrainingDayTemplateButton_ModuleTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddTrainingDayTemplateButton_ModuleTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ModuleTemplate",
  "abstractKey": null
};

(node as any).hash = "4c2ac894f75fbc7869cba1fbced4e941";

export default node;
