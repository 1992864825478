import React from "react";
import {useFormik} from "formik";
import {InputText} from "primereact/inputtext";
import {classNames} from "primereact/utils";
import {Password} from "primereact/password";
import {Button} from "primereact/button";
import {Card} from "primereact/card";
import {ValidatedField} from "../../components/relay/fields/ValidatedField";
import * as Yup from "yup";
import styled from "styled-components";
import graphql from "babel-plugin-relay/macro";
import {LoginScreen_LoginMutation} from "../../__generated__/LoginScreen_LoginMutation.graphql";
import {useTypedDispatch} from "../../Store";
import {useMutation} from "react-relay";
import {LoginData, setLoggedIn} from "../../slices/AuthSlice";
import {toast} from "react-toastify";

const LOGIN_MUTATION = graphql`
    mutation LoginScreen_LoginMutation($input: LoginInput!){
        Auth{
            login(input: $input) {
                userId
                token
                groupAssociations {
                    account {
                        id
                    }
                }
            }
        }
    }
`;

interface FormState {
    email: string
    password: string
}

export const LoginScreen = () => {
    const [login, isLoggingIn] = useMutation<LoginScreen_LoginMutation>(LOGIN_MUTATION)
    const dispatch = useTypedDispatch()

    const formik = useFormik<FormState>({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: Yup.object().shape({
            email: Yup
                .string()
                .email("Bitte geben Sie eine gültige E-Mail ein")
                .required("Das Feld E-Mail wird benötigt."),
            password: Yup
                .string()
                .min(8, "Ein Passwort muss mindestens 8 Stellen lang sein.")
                .required("Das Feld Passwort wird benötigt."),
        }),
        onSubmit: (data) => {
            login({
                variables: {
                    input: {
                        email: data.email,
                        password: data.password
                    }
                },
                onCompleted: response => {
                    const hasTheKeyAccount = response.Auth.login?.groupAssociations.map(k => k.account?.id).includes(btoa("Account:Account:thekey")) || false
                    console.log(hasTheKeyAccount)
                    console.log(response.Auth.login?.groupAssociations)
                    if (response.Auth.login && hasTheKeyAccount) {
                        dispatch(setLoggedIn({loginData: response.Auth.login as LoginData}))
                        window.location.reload()
                    } else {
                        toast.error("Leider bist du nicht berechtigt auf diese Seite zuzugreifen.")
                    }
                }
            })
        }
    });

    return <div className="flex justify-content-center align-items-center p-sidebar-full">
        <Card>
            <h1 className="text-center">Login</h1>
            <FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
                <ValidatedField<FormState, string>
                    name={"email"}
                    label={"E-Mail"}
                    iconClass={"pi-envelope"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          className={classNames({'p-invalid': !isValid})}/>

                    }}/>
                <ValidatedField<FormState, string>
                    name={"password"}
                    label={"Passwort"}
                    formikConfig={formik}
                    component={({fieldValue, updateField, fieldName, isValid}) => {
                        return <Password id={fieldName} name={fieldName} value={fieldValue}
                                         onChange={e => updateField(e.target.value)}
                                         toggleMask
                                         feedback={false}
                                         className={classNames({'p-invalid': !isValid})}
                        />

                    }}/>
                <Button disabled={isLoggingIn} type="submit" label="Einloggen" className="p-mt-2"/>
            </FormContainer>
        </Card>
    </div>
}

const FormContainer = styled.form`
  min-width: 400px;
`
