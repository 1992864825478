import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {AddModuleButton_AddModuleMutation} from "../../../__generated__/AddModuleButton_AddModuleMutation.graphql";
import {AddModuleButton_CourseFragment$key} from "../../../__generated__/AddModuleButton_CourseFragment.graphql";

const COURSE_FRAGMENT = graphql`
    fragment AddModuleButton_CourseFragment on Course {
        id
    }
`

const ADD_MODULE_MUTATION = graphql`
    mutation AddModuleButton_AddModuleMutation($input: CreateModuleInput!) {
        Admin {
            Course {
                createModule(input: $input){
                    course {
                        node {
                            ...CourseScreen_CourseFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseFragmentRef: AddModuleButton_CourseFragment$key | null
}

export const AddModuleButton = ({courseFragmentRef}: OwnProps) => {
    const [addModule, isAddingModule] = useMutation<AddModuleButton_AddModuleMutation>(ADD_MODULE_MUTATION)
    const course = useFragment<AddModuleButton_CourseFragment$key>(COURSE_FRAGMENT, courseFragmentRef)

    return course ?
        <Button
            icon="pi pi-plus"
            className="align-self-end mb-2"
            tooltip="Modul erzeugen"
            loading={isAddingModule}
            type={"button"}
            onClick={() => {
                addModule({
                    variables: {
                        input: {
                            id: course.id
                        }
                    }
                })
            }}
        /> :
        null
}
