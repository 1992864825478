/**
 * @generated SignedSource<<e198ece4a641d7932d716c2203336aa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditModuleForm_ModuleFragment$data = {
  readonly id: string;
  readonly modulePath: {
    readonly courseRef: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AddTrainingDayButton_ModuleFragment" | "RemoveTrainingDayButton_ModuleFragment">;
  readonly " $fragmentType": "EditModuleForm_ModuleFragment";
};
export type EditModuleForm_ModuleFragment$key = {
  readonly " $data"?: EditModuleForm_ModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditModuleForm_ModuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditModuleForm_ModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModulePath",
      "kind": "LinkedField",
      "name": "modulePath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddTrainingDayButton_ModuleFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveTrainingDayButton_ModuleFragment"
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "025ac4c7c85f4c559fc9e179a7d1a7bf";

export default node;
