import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { BillingDetailsForm_BillingDetailsFragment$key } from "../../../../__generated__/BillingDetailsForm_BillingDetailsFragment.graphql";
import { PrivateBillingDetailsForm } from "./PrivateBillingDetailsForm";
import { BusinessBillingDetailsForm } from "./BusinessBillingDetailsForm";

const BILLING_DETAILS_FRAGMENT = graphql`
	fragment BillingDetailsForm_BillingDetailsFragment on BillingDetails {
		customerType
		... on PrivateBillingDetails {
			...PrivateBillingDetailsForm_BillingDetailsFragment
		}
		... on BusinessBillingDetails {
			...BusinessBillingDetailsForm_BillingDetailsFragment
		}
	}
`;

interface OwnProps {
	billingDetailsFragmentRef: any;
}

export const BillingDetailsForm = ({ billingDetailsFragmentRef }: OwnProps) => {
	const billingDetails = useFragment<BillingDetailsForm_BillingDetailsFragment$key>(
		BILLING_DETAILS_FRAGMENT,
		billingDetailsFragmentRef,
	);

	switch (billingDetails.customerType) {
		case "Private":
			return <PrivateBillingDetailsForm privateBillingDetailsFragmentRef={billingDetails} />;
		case "Business":
			return (
				<BusinessBillingDetailsForm businessBillingDetailsFragmentRef={billingDetails} />
			);
		default:
			return <div>Keine Daten vorhanden</div>;
	}
};
