import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import { ParticipantsScreen } from "../screens/participants/ParticipantsScreen";
import {CoursesScreen} from "../screens/course/CoursesScreen";
import {CourseScreen} from "../screens/course/CourseScreen";
import {ModuleScreen} from "../screens/course/ModuleScreen";
import {TrainingDayScreen} from "../screens/course/TrainingDayScreen";

export const EDIT_COURSE_PATH = "/courses/:courseId/edit"
export const EDIT_MODULE_PATH = "/courses/:courseId/:moduleId/edit"
export const EDIT_TRAINING_DAY_PATH = "/courses/:courseId/:moduleId/:trainingDayId/edit"
export const PUBLISHED_COURSE_OVERVIEW = "/courses/:publishedCourseId/overview"

export const CourseRoutes: SecureRouteDefinition[] = [
    {
        path: "/courses",
        element: <CoursesScreen/>,
        requiredPermissions: []
    },
    {
        path: EDIT_COURSE_PATH,
        element: <CourseScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_MODULE_PATH,
        element: <ModuleScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_TRAINING_DAY_PATH,
        element: <TrainingDayScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: PUBLISHED_COURSE_OVERVIEW,
        element: <ParticipantsScreen/>,
        requiredPermissions: []
    }
]
