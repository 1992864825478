/**
 * @generated SignedSource<<19b568f9172b7cb12e760ae2a0ed8962>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveModuleTemplateButton_CourseTemplateFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoveModuleTemplateButton_CourseTemplateFragment";
};
export type RemoveModuleTemplateButton_CourseTemplateFragment$key = {
  readonly " $data"?: RemoveModuleTemplateButton_CourseTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveModuleTemplateButton_CourseTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveModuleTemplateButton_CourseTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CourseTemplate",
  "abstractKey": null
};

(node as any).hash = "517a29efec49e2f0f77557b6ae08e8b2";

export default node;
