/**
 * @generated SignedSource<<65d87d574c7ff552ba0010a6e28e0c1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveTrainingDayButton_ModuleFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoveTrainingDayButton_ModuleFragment";
};
export type RemoveTrainingDayButton_ModuleFragment$key = {
  readonly " $data"?: RemoveTrainingDayButton_ModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveTrainingDayButton_ModuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveTrainingDayButton_ModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "4d2b9b3f4cf9d772d40a4b29879ed7e8";

export default node;
