/**
 * @generated SignedSource<<121b4bf10e60b93cf02bffbac37486e4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveTrainingDayTemplateButton_ModuleTemplateFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoveTrainingDayTemplateButton_ModuleTemplateFragment";
};
export type RemoveTrainingDayTemplateButton_ModuleTemplateFragment$key = {
  readonly " $data"?: RemoveTrainingDayTemplateButton_ModuleTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveTrainingDayTemplateButton_ModuleTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveTrainingDayTemplateButton_ModuleTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "ModuleTemplate",
  "abstractKey": null
};

(node as any).hash = "e151d73f6d39f3acdd0b84e6c99bc4de";

export default node;
