import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useMutation, usePaginationFragment} from "react-relay";
import React, {useContext} from "react";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {useNavigate} from "react-router-dom";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {FaqsTable_Refetch} from "../../../__generated__/FaqsTable_Refetch.graphql";
import {FaqsTable_Faqs$key} from "../../../__generated__/FaqsTable_Faqs.graphql";
import {useDebounceFilters} from "../../../hooks/useDebounceFilter";
import {FaqSearchContext, FaqsFilters} from "../filter/container/FaqSearchContainer";

const FAQS_FRAGMENT = graphql`
    fragment FaqsTable_Faqs on Query @refetchable(queryName: "FaqsTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByQuestion: {type: "String"}
    ){
        Admin {
            Faq {
                Faqs(first: $first, after: $after, filterByQuestion: $filterByQuestion) @connection(key: "FaqsTable_Faqs") {
                    pageInfo {
                        startCursor
                        hasPreviousPage
                        hasNextPage
                        endCursor
                    }
                    edges {
                        node {
                            id
                            question
                        }
                    }
                }
            }
        }

    }
`;

const DELETE_MUTATION = graphql`
    mutation FaqsTable_DeleteMutation($input: DeleteFaqInput!, $connections: [ID!]!) {
        Admin {
            Faq {
                deleteFaq(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`;

interface OwnProps {
    faqsFragmentRef: FaqsTable_Faqs$key
}

export const FaqsTable = ({faqsFragmentRef}: OwnProps) => {
    const navigate = useNavigate();

    const {filters} = useContext(FaqSearchContext)

    const {
        data: {Admin: {Faq: {Faqs: {edges: faqs}}}},
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext,
        refetch
    } = usePaginationFragment<FaqsTable_Refetch, FaqsTable_Faqs$key>(FAQS_FRAGMENT, faqsFragmentRef)

    useDebounceFilters<FaqsFilters>(filters, refetch)

    const [deleteFaq, isDeleting] = useMutation<any>(DELETE_MUTATION)

    const {showDialog, dialogComponent} = useDialogLogic();

    const items = faqs?.map((x: any) => x!.node!) || [];
    return <>
        {dialogComponent}
        <DataTable className="p-mb-2" value={items}>
            <Column header="Frage" body={row => row.question}/>
            <Column header="Aktionen" style={{width: "20%"}} body={item => <>
                <Button
                    className="mr-2"
                    tooltip="Bearbeiten"
                    onClick={() => {
                        navigate(`/faqs/${item.id}/edit`)
                    }} icon={"pi pi-pencil"}/>
                <Button
                    disabled={isDeleting}
                    tooltip="Löschen"
                    onClick={() => {
                        showDialog({
                            title: "Frage löschen",
                            content: "Möchtest du diese Frage wirklich löschen?\nDies kann nicht rückgängig gemacht werden",
                            dialogCallback: result => {
                                if (result === "Accept") {
                                    deleteFaq({
                                        variables: {
                                            input: {
                                                ids: [item.id]
                                            },
                                            connections: [
                                                ConnectionHandler.getConnectionID("client:root:Admin:Faq", "FaqsTable_Faqs")
                                            ]
                                        },
                                        updater: (store, data) => {
                                            store.get(item.id)?.invalidateRecord()
                                        }
                                    })
                                }
                            }
                        })
                    }} icon={"pi pi-trash"}/>
            </>}/>
        </DataTable>

        <div className="d-flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>

}
