/**
 * @generated SignedSource<<a0549f32c5314adc2c6c51d7d2306897>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GroupsSelectField_Query$variables = {};
export type GroupsSelectField_Query$data = {
  readonly Management: {
    readonly Groups: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly name: string;
        };
      } | null> | null;
    };
  };
};
export type GroupsSelectField_Query = {
  response: GroupsSelectField_Query$data;
  variables: GroupsSelectField_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ManagementQueries",
    "kind": "LinkedField",
    "name": "Management",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "first",
            "value": 50
          }
        ],
        "concreteType": "GroupsConnection",
        "kind": "LinkedField",
        "name": "Groups",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GroupsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Group",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "Groups(first:50)"
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GroupsSelectField_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GroupsSelectField_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "07be23e585af903fde4146884a211fd7",
    "id": null,
    "metadata": {},
    "name": "GroupsSelectField_Query",
    "operationKind": "query",
    "text": "query GroupsSelectField_Query {\n  Management {\n    Groups(first: 50) {\n      edges {\n        node {\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2d7d550275b1a257beee3e20326c5138";

export default node;
