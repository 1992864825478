import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderHistoryIdColumn_OrderHistoryFragment$key } from "../../../../../__generated__/OrderHistoryIdColumn_OrderHistoryFragment.graphql";

const ORDER_HISTORY_FRAGMENT = graphql`
	fragment OrderHistoryIdColumn_OrderHistoryFragment on OrderHistory {
		id
	}
`;

interface OwnProps {
	orderHistoryFragmentRef: OrderHistoryIdColumn_OrderHistoryFragment$key;
}

export const OrderHistoryIdColumn = ({ orderHistoryFragmentRef }: OwnProps) => {
	const orderHistory = useFragment<OrderHistoryIdColumn_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	return (
		<div
			className="cursor-pointer"
			onClick={() => navigator.clipboard.writeText(orderHistory.id)}
		>
			<b>
				ID<i className="pi pi-copy"></i>
			</b>
		</div>
	);
};
