import React from 'react';
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {
    PublishedCourseButton_PublishCourseMutation
} from "../../../__generated__/PublishedCourseButton_PublishCourseMutation.graphql";
import {ProgressBar} from "primereact/progressbar";
import {useDialogLogic} from "../../../hooks/useDialogLogic";

const PUBLISH_COURSE_MUTATION = graphql`
    mutation PublishedCourseButton_PublishCourseMutation($input: CreatePublishCourseInput!, $connections: [ID!]!) {
        Admin {
            Course {
                createPublishCourse(input: $input) {
                    data @appendEdge(connections: $connections){
                        node {
                            id
                            name
                            secondName
                            startDate
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseId: string
    publishedCoursesConnectionId: string
}

export const PublishedCourseButton = ({courseId, publishedCoursesConnectionId}: OwnProps) => {
    const [publishCourse, isPublishing] = useMutation<PublishedCourseButton_PublishCourseMutation>(PUBLISH_COURSE_MUTATION)

    const {showDialog, dialogComponent} = useDialogLogic();

    const publishedCourse = (shouldSendNotification: boolean) => {
        publishCourse({
            variables: {
                input: {
                    data: {
                        courseId: courseId,
                        shouldSendNotification: shouldSendNotification
                    }
                },
                connections: [publishedCoursesConnectionId]
            }
        })
    }

    return (
        <>
            {dialogComponent}
            <Button
                className="mr-2"
                tooltip="Veröffentlichen"
                disabled={isPublishing}
                onClick={() => {
                    showDialog({
                        title: "Lehrgang Veröffentlichen",
                        content: "Möchtest du diesen Lehrgang wirklich veröffentlichen?",
                        affirmativeText: "Veröffentlichen",
                        affirmativeWithConditionText: "Veröffentlichen und Nutzer benachrichtigen",
                        dialogCallback: result => {
                            if (result === "Accept") publishedCourse(false)
                            if (result === "AcceptWithCondition") publishedCourse(true)
                        }
                    })
                }} icon={"pi pi-cloud-upload\n"}/>
            {isPublishing ?
                <div className="mt-5 mb-5">
                    <ProgressBar mode="indeterminate"/>
                </div> : null
            }</>
    )
}
