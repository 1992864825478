import React from "react";
import { Dropdown } from "primereact/dropdown";
import { DiscountTypeKind } from "../../../../__generated__/ActionTypeColumn_DiscountActionFragment.graphql";

interface OwnProps {
	fieldValue?: string;
	updateField: (discountType: string) => void;
}

export const DiscountTypeInput = ({ fieldValue, updateField }: OwnProps) => {
	const options: { label: string; value: DiscountTypeKind }[] = [
		{ label: "System", value: "system" },
		{ label: "User", value: "user" },
	];

	return (
		<Dropdown
			value={options.find((k) => k.value === fieldValue)?.value}
			options={options}
			placeholder="Discount-Typ"
			onChange={(e) => updateField(e.value)}
		/>
	);
};
