import React from 'react'
import {Button} from "primereact/button";
import styled from "styled-components";
import {useFormik} from "formik";
import {ProgressSpinner} from "primereact/progressspinner";
import {ValidatedField} from "../fields/ValidatedField";
import {DefaultEditorComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import * as Yup from "yup";
import {DefaultNumberComponent} from "../../ui/DefaultNumberInput";
import {DefaultDateComponent} from "../../ui/DefaultDateInput";

export interface FormState {
    id: string | null
    internalTitle: string
    internalInformation?: string | null
    additionalInformation?: string | null
    date: Date,
    requiredAmountOfPreviousTrainingDays: number
}

interface OwnProps {
    loading?: boolean
    initialValues?: FormState
    onSubmit: (values: FormState) => void
}

export const EditTrainingDayForm = ({initialValues, loading, onSubmit}: OwnProps) => {
    const formik = useFormik<FormState>({
        validationSchema: Yup.object().shape({
            internalTitle: Yup.string().required("Interner Titel wird benötigt"),
            date: Yup.date().required("Es wird ein Datum benötigt")
        }),
        initialValues: {
            id: initialValues?.id || "",
            internalTitle: initialValues?.internalTitle || "",
            date: initialValues?.date || new Date(),
            internalInformation: initialValues?.internalInformation,
            requiredAmountOfPreviousTrainingDays: initialValues?.requiredAmountOfPreviousTrainingDays || 0,
            additionalInformation: initialValues?.additionalInformation
        },
        onSubmit: (values, {setSubmitting, setErrors}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })

    if (loading) return <ProgressSpinner/>

    return <div className="position-relative">
        <FormContainer onSubmit={() => formik.handleSubmit()} className={"p-fluid"}>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="internalTitle"
                    label="Interner Titel (Nur im Admin sichtbar)"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, number>
                    name="requiredAmountOfPreviousTrainingDays"
                    label="Anzahl zuvor abgeschlossener Ausbildungstage"
                    component={DefaultNumberComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="date"
                    label="Datum"
                    required
                    component={DefaultDateComponent}
                    formikConfig={formik}/>
            </div>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="internalInformation"
                    label="Interne Informationen"
                    component={DefaultEditorComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="additionalInformation"
                    label="Zusätzliche Informationen"
                    component={DefaultEditorComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <Button
                type={"button"}
                onClick={() => formik.handleSubmit()}>
                Ausbildungstag Speichern
            </Button>
        </FormContainer>
    </div>
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const WholeGridSpaceContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 1rem 0;
`
