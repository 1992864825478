import {RenderConfig} from "../relay/fields/ValidatedField";
import React from "react";
import {Calendar} from "primereact/calendar";
import {convertToDate, convertToString} from "../../utils/DateTimeUtils";
import moment from "moment-timezone/moment-timezone-utils";

export function DefaultDateComponent({
                                         fieldName,
                                         fieldValue,
                                         updateField,
                                         disabled
                                     }: RenderConfig<string>) {
    return <Calendar name={fieldName}
                     dateFormat={"dd.mm.yy"}
                     disabled={disabled}
                     value={fieldValue ? convertToDate(fieldValue) : undefined}
                     readOnlyInput={false}
                     onChange={(e) => updateField(e.value ? convertToString(moment(e.value as Date).add(1, "hour").toDate()) : undefined)}/>
}
