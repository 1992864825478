import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {FilesTable} from "../../components/relay/tables/FilesTable";
import {FilesScreen_FilesQuery} from "../../__generated__/FilesScreen_FilesQuery.graphql";

const QUERY = graphql`
    query FilesScreen_FilesQuery {
        ...FilesTable_FilesListFragment
    }
`;

export const FilesScreen = () => {
    const data = useLazyLoadQuery<FilesScreen_FilesQuery>(QUERY, {}, {fetchPolicy: "network-only"})

    return <>
        <h1>Dateien</h1>

        <FilesTable filesFragmentRef={data}/>
    </>
}
