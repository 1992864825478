import React, {Suspense} from 'react';
import graphql from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {GroupsTable} from "../../components/relay/tables/GroupsTable";
import {Loader} from "../../components/ui/Loader";
import {GroupScreen_Query} from "../../__generated__/GroupScreen_Query.graphql";

const QUERY = graphql`
    query GroupScreen_Query($first: Int) {
        ...GroupsTable_GroupListFragment @arguments(first: $first)
    }
`

export const GroupScreen = () => {
    const query = useLazyLoadQuery<GroupScreen_Query>(QUERY, {first: 20})

    return (
        <Suspense fallback={<Loader/>}>
            <GroupsTable groupListFragmentRef={query}/>
        </Suspense>
    )
}
