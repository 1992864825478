import {FieldConfig} from "../../ui/ValidatedField";
import React from "react";
import {graphql} from "babel-plugin-relay/macro";
import {useLazyLoadQuery} from "react-relay";
import {Permission, PermissionsField_Query} from "../../../__generated__/PermissionsField_Query.graphql";
import {Checkbox} from "primereact/checkbox";
import {PERMISSION_TRANSLATIONS} from "../../../i18n/permissions.i18n";

const QUERY = graphql`
    query PermissionsField_Query {
        Management {            
            AvailablePermissions
        }
    }
`

export const PermissionsField = ({fieldValue, updateField}: FieldConfig<Permission[]>) => {
    const {Management: {AvailablePermissions}} = useLazyLoadQuery<PermissionsField_Query>(QUERY, {}, {fetchPolicy: "network-only"})

    const grouped = [...AvailablePermissions].groupBy(x => x.split("_")[0])

    return <div>
        <h3 className=" m-0">Permissions</h3>
        <div className="grid">
            {grouped.map(group => {
                return <div key={group.key} className="col-4">
                    <h4>{group.key}</h4>

                    <div>
                        {group.value.map(v => <div className="mb-2" key={v}>
                            <Checkbox
                                inputId={v}
                                className="mr-2"
                                onChange={e => {
                                    const fieldValueWithoutValue = fieldValue?.filter(p => v !== p) || []
                                    if (e.checked) {
                                        updateField([...fieldValueWithoutValue, v])
                                    } else {
                                        updateField(fieldValueWithoutValue)
                                    }
                                }}
                                checked={fieldValue?.includes(v)}></Checkbox>
                            <label htmlFor="cb3" className="p-checkbox-label">{PERMISSION_TRANSLATIONS[v] || v}</label>
                        </div>)}
                    </div>
                </div>
            })}
        </div>
    </div>;
}

