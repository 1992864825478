/**
 * @generated SignedSource<<250f558258e780179a1fec41d31fdb80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SingleUserScreen_Query$variables = {
  id: string;
};
export type SingleUserScreen_Query$data = {
  readonly node: {
    readonly groupAssociations?: ReadonlyArray<{
      readonly account: {
        readonly id: string;
      } | null;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"SingleUserForm_UserFragment" | "UserHistoryScreen_UserFragment">;
  } | null;
};
export type SingleUserScreen_Query = {
  response: SingleUserScreen_Query$data;
  variables: SingleUserScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Account",
  "kind": "LinkedField",
  "name": "account",
  "plural": false,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SingleUserScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SingleUserForm_UserFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserHistoryScreen_UserFragment"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserGroupAssociation",
                "kind": "LinkedField",
                "name": "groupAssociations",
                "plural": true,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SingleUserScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "activated",
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "extension",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "isBlocked",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "salutation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "adsOptIn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "note",
                        "storageKey": null
                      }
                    ],
                    "type": "UserExtensionImpl",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserGroupAssociation",
                "kind": "LinkedField",
                "name": "groupAssociations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Group",
                    "kind": "LinkedField",
                    "name": "group",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c7e9436e459549691beab9e0caf7cfcf",
    "id": null,
    "metadata": {},
    "name": "SingleUserScreen_Query",
    "operationKind": "query",
    "text": "query SingleUserScreen_Query(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SingleUserForm_UserFragment\n    ...UserHistoryScreen_UserFragment\n    ... on User {\n      groupAssociations {\n        account {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment ChangeActivatedForm_UserFragment on User {\n  id\n  email\n  activated\n}\n\nfragment ChangeEmailForm_UserFragment on User {\n  id\n  email\n}\n\nfragment ChangePasswordForm_UserFragment on User {\n  id\n  email\n}\n\nfragment ChangeUserExtensionForm_UserFragment on User {\n  id\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      isBlocked\n      salutation\n      adsOptIn\n      note\n    }\n  }\n}\n\nfragment CourseStatesTable_UserFragment on User {\n  id\n}\n\nfragment SingleUserForm_UserFragment on User {\n  id\n  email\n  activated\n  name\n  extension {\n    __typename\n    ... on UserExtensionImpl {\n      isBlocked\n    }\n  }\n  groupAssociations {\n    group {\n      id\n      name\n      permissions\n    }\n  }\n  ...ChangeEmailForm_UserFragment\n  ...ChangeActivatedForm_UserFragment\n  ...ChangePasswordForm_UserFragment\n  ...ChangeUserExtensionForm_UserFragment\n}\n\nfragment UserHistoryScreen_UserFragment on User {\n  id\n  name\n  ...CourseStatesTable_UserFragment\n}\n"
  }
};
})();

(node as any).hash = "7df33185a00e6b2f985760390e2fb540";

export default node;
