/**
 * @generated SignedSource<<98cde487440b4c58497bb28284da9a70>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseTemplateScreen_Query$variables = {
  id: string;
  skip: boolean;
};
export type CourseTemplateScreen_Query$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"CourseTemplateScreen_CourseTemplateFragment">;
  } | null;
};
export type CourseTemplateScreen_Query = {
  response: CourseTemplateScreen_Query$data;
  variables: CourseTemplateScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseTemplateScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CourseTemplateScreen_CourseTemplateFragment"
                  }
                ],
                "type": "CourseTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseTemplateScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "iconRef",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountOfRebookedParticipant",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amountOfRegulatedParticipant",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Price",
                    "kind": "LinkedField",
                    "name": "price",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "grossPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "netPrice",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "taxRatePercentage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleTemplate",
                    "kind": "LinkedField",
                    "name": "modules",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "internalTitle",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "CourseTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "b32f3790e536da55563f3509a483ee19",
    "id": null,
    "metadata": {},
    "name": "CourseTemplateScreen_Query",
    "operationKind": "query",
    "text": "query CourseTemplateScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on CourseTemplate {\n      ...CourseTemplateScreen_CourseTemplateFragment\n    }\n    id\n  }\n}\n\nfragment AddModuleTemplateButton_CourseTemplateFragment on CourseTemplate {\n  id\n}\n\nfragment CourseTemplateScreen_CourseTemplateFragment on CourseTemplate {\n  id\n  name\n  description\n  iconRef\n  createdAt\n  amountOfRebookedParticipant\n  amountOfRegulatedParticipant\n  price {\n    grossPrice\n    netPrice\n    taxRatePercentage\n  }\n  modules {\n    id\n    internalTitle\n  }\n  ...EditCourseTemplateForm_CourseTemplateFragment\n  ...TemplateHeader_CourseTemplateFragment\n}\n\nfragment EditCourseTemplateForm_CourseTemplateFragment on CourseTemplate {\n  id\n  ...AddModuleTemplateButton_CourseTemplateFragment\n  ...RemoveModuleTemplateButton_CourseTemplateFragment\n}\n\nfragment RemoveModuleTemplateButton_CourseTemplateFragment on CourseTemplate {\n  id\n}\n\nfragment TemplateHeader_CourseTemplateFragment on CourseTemplate {\n  id\n  name\n}\n"
  }
};
})();

(node as any).hash = "c7d4ae6cb94615508cc7d54162d899a8";

export default node;
