import React from "react";
import {useUrlFilterState} from "../../../../hooks/useUrlFilterState";

export interface FaqsFilters {
    filterByQuestion?: string
}


interface Search {
    filters: FaqsFilters
    setFilters: (filters: FaqsFilters) => void
    clearFilters: () => void
}

export const FaqSearchContext = React.createContext<Search>({
    setFilters: () => {
    },
    clearFilters: () => {
    },
    filters: {}
})

interface OwnProps {
    children: any
}

export const FaqSearchContainer = ({children}: OwnProps) => {
    const {state, setState} = useUrlFilterState<FaqsFilters>()

    return <FaqSearchContext.Provider value={{
        filters: state,
        setFilters: filters => {
            setState(() => filters)
        },
        clearFilters: () => {
            setState({})
        }
    }}>
        {children}
    </FaqSearchContext.Provider>
}
