import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment$key } from "../../../../../../__generated__/CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment.graphql";

const CREDIT_NOTE_SENT_UPDATE_FRAGMENT = graphql`
	fragment CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment on CreditNoteSentUpdate {
		kind
		creditNoteId
		creditNoteNumber
	}
`;

interface OwnProps {
	creditNoteSentUpdateFragmentRef: CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment$key;
}

export const CreditNoteSentUpdatePart = ({ creditNoteSentUpdateFragmentRef }: OwnProps) => {
	const creditNoteSent = useFragment<CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment$key>(
		CREDIT_NOTE_SENT_UPDATE_FRAGMENT,
		creditNoteSentUpdateFragmentRef,
	);

	return (
		<Fieldset legend={HistoryEventType[creditNoteSent.kind]}>
			<div className="grid">
				<div className="col-3">Gutschrift ID:</div>
				<div className="col">{creditNoteSent.creditNoteId}</div>
			</div>
			<div className="grid">
				<div className="col-3">Gutschriftsnummer:</div>
				<div className="col">{creditNoteSent.creditNoteNumber}</div>
			</div>
		</Fieldset>
	);
};
