/**
 * @generated SignedSource<<34a457c4efc4aca6baea819ca1a0fcad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryCreatedAtColumn_OrderHistoryFragment$data = {
  readonly createdAt: string;
  readonly " $fragmentType": "OrderHistoryCreatedAtColumn_OrderHistoryFragment";
};
export type OrderHistoryCreatedAtColumn_OrderHistoryFragment$key = {
  readonly " $data"?: OrderHistoryCreatedAtColumn_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryCreatedAtColumn_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderHistoryCreatedAtColumn_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    }
  ],
  "type": "OrderHistory",
  "abstractKey": null
};

(node as any).hash = "e5ae597cbe23542cdb63321a0942448a";

export default node;
