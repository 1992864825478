import React from "react";
import CardImage from "../../assets/payment-method/card.png";
import GiropayImage from "../../assets/payment-method/giropay.png";
import PaypalImage from "../../assets/payment-method/paypal.png";
import SofortImage from "../../assets/payment-method/sofort.png";
import SepaImage from "../../assets/payment-method/sepa.png";
import InvoiceImage from "../../assets/payment-method/invoice.png";
import styled from "styled-components";
import { PaymentMethod } from "../../../billing-admin-impl/translations/PaymentMethod";

interface OwnProps {
	paymentMethod: string;
}

export const PaymentMethodDisplay = ({ paymentMethod }: OwnProps) => {
	let Image;
	switch (paymentMethod) {
		case "Card":
			Image = CardImage;
			break;
		case "Giropay":
			Image = GiropayImage;
			break;
		case "Paypal":
			Image = PaypalImage;
			break;
		case "Sofort":
			Image = SofortImage;
			break;
		case "MonthlyTk":
		case "MonthlyPartner":
		case "Sepa":
			Image = SepaImage;
			break;
		case "InvoiceTk":
		case "InvoicePartner":
			Image = InvoiceImage;
			break;
		case "IapGoogle":
		case "IapApple":
			Image = "IAP";
			break;
	}

	return (
		<div className="flex align-items-center m-1">
			{Image === "IAP" ? (
				<PaymentMethodIcon className="pi pi-shopping-cart" />
			) : (
				<PaymentMethodImage src={Image} alt={Image} />
			)}

			<div>{PaymentMethod[paymentMethod]}</div>
		</div>
	);
};

const PaymentMethodIcon = styled.i`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 25px;
	margin-right: 10px;
`;

const PaymentMethodImage = styled.img`
	width: 45px;
	height: 25px;
	margin-right: 10px;
`;
