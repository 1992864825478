import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderHistoryEventColumn_OrderHistoryFragment$key } from "../../../../../__generated__/OrderHistoryEventColumn_OrderHistoryFragment.graphql";
import { OrderCreationUpdatePart } from "./parts/OrderCreationUpdatePart";
import { CartUpdatePart } from "./parts/CartUpdatePart";
import { BillingDetailsUpdatePart } from "./parts/BillingDetailsUpdatePart";
import { PaymentMethodUpdatePart } from "./parts/PaymentMethodUpdatePart";
import { PaymentInProcessUpdatePart } from "./parts/PaymentInProcessUpdatePart";
import { PurchaseUpdatePart } from "./parts/PurchaseUpdatePart";
import { InvoiceSentUpdatePart } from "./parts/InvoiceSentUpdatePart";
import { CreditNoteSentUpdatePart } from "./parts/CreditNoteSentUpdatePart";

const ORDER_HISTORY_FRAGMENT = graphql`
	fragment OrderHistoryEventColumn_OrderHistoryFragment on OrderHistory {
		event {
			kind
			... on OrderCreationUpdate {
				...OrderCreationUpdatePart_OrderCreationUpdateFragment
			}
			... on CartUpdate {
				...CartUpdatePart_CartUpdateFragment
			}
			... on BillingDetailsUpdate {
				...BillingDetailsUpdatePart_BillingDetailsUpdateFragment
			}
			... on PaymentMethodUpdate {
				...PaymentMethodUpdatePart_PaymentMethodUpdateFragment
			}
			... on PaymentInProcessUpdate {
				...PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment
			}
			... on PurchaseUpdate {
				...PurchaseUpdatePart_PurchaseUpdateFragment
			}
			... on InvoiceSentUpdate {
				...InvoiceSentUpdatePart_InvoiceSentUpdateFragment
			}
			... on CreditNoteSentUpdate {
				...CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment
			}
		}
	}
`;

interface OwnProps {
	orderHistoryFragmentRef: OrderHistoryEventColumn_OrderHistoryFragment$key;
}

export const OrderHistoryEventColumn = ({ orderHistoryFragmentRef }: OwnProps) => {
	const orderHistory = useFragment<OrderHistoryEventColumn_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	let eventComponent: any;
	switch (orderHistory.event.kind) {
		case "OrderCreationUpdate":
			eventComponent = (
				<OrderCreationUpdatePart orderCreationUpdateFragmentRef={orderHistory.event} />
			);
			break;
		case "CartUpdate":
			eventComponent = <CartUpdatePart cartUpdateFragmentRef={orderHistory.event} />;
			break;
		case "BillingDetailsUpdate":
			eventComponent = (
				<BillingDetailsUpdatePart billingDetailsUpdateFragmentRef={orderHistory.event} />
			);
			break;
		case "PaymentMethodUpdate":
			eventComponent = (
				<PaymentMethodUpdatePart paymentMethodUpdateFragmentRef={orderHistory.event} />
			);
			break;
		case "PaymentInProcessUpdate":
			eventComponent = (
				<PaymentInProcessUpdatePart
					paymentInProcessUpdateFragmentRef={orderHistory.event}
				/>
			);
			break;
		case "PurchaseUpdate":
			eventComponent = <PurchaseUpdatePart purchaseUpdateFragmentRef={orderHistory.event} />;
			break;
		case "InvoiceSentUpdate":
			eventComponent = (
				<InvoiceSentUpdatePart invoiceSentUpdateFragmentRef={orderHistory.event} />
			);
			break;
		case "CreditNoteSentUpdate":
			eventComponent = (
				<CreditNoteSentUpdatePart creditNoteSentUpdateFragmentRef={orderHistory.event} />
			);
			break;
		default:
			eventComponent = <div>Ereignis nicht implementiert</div>;
	}

	return <div>{eventComponent}</div>;
};
