import React from "react";
import graphql from "babel-plugin-relay/macro";
import { OrderIdColumn_OrderFragment$key } from "../../../../../__generated__/OrderIdColumn_OrderFragment.graphql";
import { useFragment } from "react-relay";

const ORDER_FRAGMENT = graphql`
	fragment OrderIdColumn_OrderFragment on Order {
		id
	}
`;

interface OwnProps {
	orderFragmentRef: OrderIdColumn_OrderFragment$key;
}

export const OrderIdColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderIdColumn_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	return (
		<div className="cursor-pointer" onClick={() => navigator.clipboard.writeText(order.id)}>
			<b>
				ID<i className="pi pi-copy"></i>
			</b>
		</div>
	);
};
