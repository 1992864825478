import React from 'react';
import graphql from 'babel-plugin-relay/macro'
import {Button} from "primereact/button";
import {useMutation} from "react-relay";
import {
    CancelCourseForUserButton_CancelCourseForUserMutation
} from "../../../__generated__/CancelCourseForUserButton_CancelCourseForUserMutation.graphql";
import {toast} from "react-toastify";
import {DialogResult, useDialogLogic} from "../../../hooks/useDialogLogic";

const CANCEL_COURSE_FOR_USER_MUTATION = graphql`
    mutation CancelCourseForUserButton_CancelCourseForUserMutation($input: CancelUserForCourseInput!) {
        Admin {
            States {
                cancelUserForCourse(input: $input) {
                    clientMutationId
                }
            }
        }
    }
`

interface OwnProps {
    userId: string,
    courseStateId: string
    className?: string
}

export const CancelCourseForUserButton = ({courseStateId, userId, className}: OwnProps) => {
    const [cancelUserFromCourse, isCancelling] = useMutation<CancelCourseForUserButton_CancelCourseForUserMutation>(CANCEL_COURSE_FOR_USER_MUTATION)
    const {showDialog, dialogComponent} = useDialogLogic()

    return (
        <>
            {dialogComponent}
            <Button tooltip="Stornieren"
                    icon="pi pi-ban"
                    loading={isCancelling}
                    disabled={isCancelling}
                    className={["p-button-warning", className].join(" ")}
                    onClick={() => {
                        showDialog({
                            content: "Möchtest du diesen Nutzer wirklich für diesen Lehrgang stornieren? ACHTUNG: dieser Voragng kann nicht rückgängig gemacht werden! ",
                            title: "Nutzer für den ausgewählten Lehrgang Stornieren?",
                            affirmativeText: "Stornieren",
                            affirmativeWithConditionText: "Stornieren und Gutschrift erstellen",
                            dialogCallback: (result: DialogResult) => {
                                if (result === "Accept") {
                                    cancelUserFromCourse({
                                        variables: {
                                            input: {
                                                userId: userId,
                                                courseStateId: courseStateId,
                                                shouldCreateCreditNote: false
                                            }
                                        },
                                        onCompleted: () => {
                                            toast.success("Nutzer erfolgreich für den Lehrgang Storniert")
                                        },
                                        onError: () => {
                                            toast.success("Leider ist etwas falsch gelaufen. Versuche es später nochmal")
                                        }
                                    })
                                } else if(result === "AcceptWithCondition") {
                                    cancelUserFromCourse({
                                        variables: {
                                            input: {
                                                userId: userId,
                                                courseStateId: courseStateId,
                                                shouldCreateCreditNote: true
                                            }
                                        },
                                        onCompleted: () => {
                                            toast.success("Nutzer wurde erfolgreich informiert und eine Gutschrift wurde erstellt")
                                        },
                                        onError: () => {
                                            toast.success("Leider ist etwas falsch gelaufen. Versuche es später nochmal")
                                        }
                                    })
                                }
                            }
                        })

                    }}/>
        </>
    )
}
