/**
 * @generated SignedSource<<0aa05c189417a750116d7dbb1e7c522c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrdersTable_OrderFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrderActionColumn_OrderFragment" | "OrderCreatedAtColumn_OrderFragment" | "OrderIdColumn_OrderFragment" | "OrderPaymentMethodColumn_OrderFragment" | "OrderPaymentProviderColumn_OrderFragment" | "OrderPriceColumn_OrderFragment" | "OrderStatusColumn_OrderFragment">;
  readonly " $fragmentType": "OrdersTable_OrderFragment";
};
export type OrdersTable_OrderFragment$key = {
  readonly " $data"?: OrdersTable_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrdersTable_OrderFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "OrdersTable_OrderFragment"
};

(node as any).hash = "34fe4d9083a3f5038f340d6b3b411a40";

export default node;
