/**
 * @generated SignedSource<<de1bec397154ba6bff91227e4c8398c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseHeader_TrainingDayFragment$data = {
  readonly id: string;
  readonly internalTitle: string;
  readonly " $fragmentType": "CourseHeader_TrainingDayFragment";
};
export type CourseHeader_TrainingDayFragment$key = {
  readonly " $data"?: CourseHeader_TrainingDayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_TrainingDayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseHeader_TrainingDayFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    }
  ],
  "type": "TrainingDay",
  "abstractKey": null
};

(node as any).hash = "715c09a0a9a9f855868f7df0b3d0cbd6";

export default node;
