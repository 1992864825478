import React, {ReactNode} from "react";
import {CurrentUserSetter} from "../relay/CurrentUserSetter";
import {useTypedSelector} from "../../Store";
import {selectIsLoggedIn} from "../../slices/AuthSlice";

interface OwnProps {
    loginFallback: ReactNode
    children: any
}


export const AuthenticationFallback = ({children, loginFallback}: OwnProps) => {
    const isLoggedIn = useTypedSelector(selectIsLoggedIn)

    return <>
        {isLoggedIn ? <CurrentUserSetter>
            {children}
        </CurrentUserSetter> : loginFallback}
    </>
}
