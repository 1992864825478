/**
 * @generated SignedSource<<b18cdedae4746a2755852d835fd08359>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillingDetailsUpdatePart_BillingDetailsUpdateFragment$data = {
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "BillingDetailsUpdatePart_BillingDetailsUpdateFragment";
};
export type BillingDetailsUpdatePart_BillingDetailsUpdateFragment$key = {
  readonly " $data"?: BillingDetailsUpdatePart_BillingDetailsUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsUpdatePart_BillingDetailsUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsUpdatePart_BillingDetailsUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "BillingDetailsUpdate",
  "abstractKey": null
};

(node as any).hash = "e076dd44f16ec4da5186901463309f40";

export default node;
