import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { graphql } from "babel-plugin-relay/macro";
import { readInlineData, usePaginationFragment } from "react-relay";
import { OrdersTableFilters } from "../filters/OrdersTableFilters";
import { OrdersTable_Orders$key } from "../../../../__generated__/OrdersTable_Orders.graphql";
import { OrdersTable_Refetch } from "../../../../__generated__/OrdersTable_Refetch.graphql";
import { OrderIdColumn } from "../columns/order/OrderIdColumn";
import { Pagination } from "../../ui/Pagination";
import { OrderCreatedAtColumn } from "../columns/order/OrderCreatedAtColumn";
import { OrderStatusColumn } from "../columns/order/OrderStatusColumn";
import { OrderPriceColumn } from "../columns/order/OrderPriceColumn";
import { OrderPaymentProviderColumn } from "../columns/order/OrderPaymentProviderColumn";
import { OrderActionColumn } from "../columns/order/OrderActionColumn";
import {OrderPaymentMethodColumn} from "../columns/order/OrderPaymentMethodColumn";

const ORDERS_FRAGMENT = graphql`
	fragment OrdersTable_Orders on Query
	@refetchable(queryName: "OrdersTable_Refetch")
	@argumentDefinitions(
		orderId: { type: "ID" }
		userId: { type: "ID" }
		name: { type: "String" }
		first: { type: "Int!" }
		after: { type: "String" }
	) {
		Admin {
			Billing {
				Orders(
					first: $first
					after: $after
					name: $name
					orderId: $orderId
					userId: $userId
				) @connection(key: "OrdersTable_Orders") {
					edges {
						node {
							...OrdersTable_OrderFragment
						}
					}
				}
			}
		}
	}
`;

export interface OwnProps {
	ordersFragmentRef: OrdersTable_Orders$key;
	ordersPerPage: number;
}

const ORDER_FRAGMENT = graphql`
	fragment OrdersTable_OrderFragment on Order @inline {
		...OrderIdColumn_OrderFragment
		...OrderCreatedAtColumn_OrderFragment
		...OrderStatusColumn_OrderFragment
		...OrderPriceColumn_OrderFragment
		...OrderPaymentProviderColumn_OrderFragment
		...OrderActionColumn_OrderFragment
		...OrderPaymentMethodColumn_OrderFragment
	}
`;

export const OrdersTable = ({ ordersFragmentRef, ordersPerPage }: OwnProps) => {
	const {
		data: orders,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<OrdersTable_Refetch, OrdersTable_Orders$key>(
		ORDERS_FRAGMENT,
		ordersFragmentRef,
	);

	const ordersConnection = orders.Admin.Billing.Orders.edges || [];

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Bestellungen"}
				className="p-mb-2"
				header={
					<OrdersTableFilters
						refetch={(orderId?: string, userId?: string, name?: string) =>
							refetch({
								orderId: orderId,
								userId: userId,
								name: name,
								first: ordersPerPage,
							})
						}
					/>
				}
				value={
					ordersConnection
						.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(ORDER_FRAGMENT, item)) || []
				}
			>
				<Column header={"ID"} body={(item) => <OrderIdColumn orderFragmentRef={item} />} />
				<Column
					header={"Erstellungsdatum"}
					body={(item) => <OrderCreatedAtColumn orderFragmentRef={item} />}
				/>
				<Column
					header={"Status"}
					body={(item) => <OrderStatusColumn orderFragmentRef={item} />}
				/>
				<Column
					header={"Preis"}
					body={(item) => <OrderPriceColumn orderFragmentRef={item} />}
				/>
				<Column
					header={"Zahlungsdienstleister"}
					body={(item) => <OrderPaymentProviderColumn orderFragmentRef={item} />}
				/>
				<Column
					header={"Zahlweise"}
					body={(item) => <OrderPaymentMethodColumn orderFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					style={{ width: "20%" }}
					body={(item) => <OrderActionColumn orderFragmentRef={item} />}
				/>
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(ordersPerPage)} />
		</>
	);
};
