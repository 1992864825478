/**
 * @generated SignedSource<<bb1f46a5ebfbd4611eff72db337795ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AttendanceStatus = "Absent" | "CanceledByUser" | "CanceledTooLate" | "CourseCanceledByAdmin" | "NotPresent" | "TookPart" | "Unknown" | "WaitingList" | "%future added value";
export type AttendanceType = "CourseCancel" | "Rebooked" | "Regular" | "WaitingList" | "%future added value";
export type FoodCreditState = "CateringInvoice" | "Expired" | "Open" | "Redeemed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CourseStatesTable_CourseStateFragment$data = {
  readonly id: string;
  readonly moduleStates: ReadonlyArray<{
    readonly moduleId: string;
    readonly trainingDayStates: ReadonlyArray<{
      readonly attendanceListAdmin: ReadonlyArray<{
        readonly attendanceStatus: AttendanceStatus;
        readonly attendanceType: AttendanceType;
        readonly foodCreditState: FoodCreditState;
        readonly note: string | null;
      }>;
      readonly trainingDayId: string;
    }>;
  }>;
  readonly publishedCourse: {
    readonly modulesAdmin: ReadonlyArray<{
      readonly endDate: string;
      readonly id: string;
      readonly startDate: string;
      readonly title: string;
      readonly trainingDays: ReadonlyArray<{
        readonly date: string;
        readonly id: string;
      }>;
    }>;
    readonly name: string;
    readonly secondName: string;
  };
  readonly " $fragmentType": "CourseStatesTable_CourseStateFragment";
};
export type CourseStatesTable_CourseStateFragment$key = {
  readonly " $data"?: CourseStatesTable_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_CourseStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseStatesTable_CourseStateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleState",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TrainingDayState",
          "kind": "LinkedField",
          "name": "trainingDayStates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trainingDayId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AttendanceTypeAdmin",
              "kind": "LinkedField",
              "name": "attendanceListAdmin",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attendanceStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attendanceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "foodCreditState",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "note",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishedCourse",
      "kind": "LinkedField",
      "name": "publishedCourse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PublishedModuleAdmin",
          "kind": "LinkedField",
          "name": "modulesAdmin",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PublishedTrainingDay",
              "kind": "LinkedField",
              "name": "trainingDays",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
})();

(node as any).hash = "fd2df7a41008c5081b9008c44de9bc88";

export default node;
