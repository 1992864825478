import React, {useEffect, useState} from 'react';
import graphql from "babel-plugin-relay/macro";
import {CourseHeader_CourseFragment$key} from "../../../__generated__/CourseHeader_CourseFragment.graphql";
import {CourseHeader_ModuleFragment$key} from "../../../__generated__/CourseHeader_ModuleFragment.graphql";
import {CourseHeader_TrainingDayFragment$key} from "../../../__generated__/CourseHeader_TrainingDayFragment.graphql";
import {useFragment, useLazyLoadQuery} from "react-relay";
import {MenuItem} from "primereact/menuitem";
import {CourseHeader_CourseQuery} from "../../../__generated__/CourseHeader_CourseQuery.graphql";
import { CourseHeader_ModuleQuery } from '../../../__generated__/CourseHeader_ModuleQuery.graphql';
import {BreadCrumb} from "primereact/breadcrumb";

const COURSE_QUERY = graphql`
    query CourseHeader_CourseQuery($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on Course {
                ...CourseHeader_CourseFragment
            }
        }
    }
`

const MODULE_QUERY = graphql`
    query CourseHeader_ModuleQuery($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on Module {
                ...CourseHeader_ModuleFragment
            }
        }
    }
`
const COURSE_FRAGMENT = graphql`
    fragment CourseHeader_CourseFragment on Course {
        id
        secondName
    }
`

const MODULE_FRAGMENT = graphql`
    fragment CourseHeader_ModuleFragment on Module {
        id
        shortTitle
    }
`

const TRAINING_DAY_FRAGMENT = graphql`
    fragment CourseHeader_TrainingDayFragment on TrainingDay {
        id
        internalTitle
    }
`

interface OwnProps {
    courseId?: string
    moduleId?: string
    courseFragment?: CourseHeader_CourseFragment$key | null
    moduleFragment?: CourseHeader_ModuleFragment$key | null
    trainingDayFragment?: CourseHeader_TrainingDayFragment$key | null

}

export const CourseHeader = ({courseId, moduleId, courseFragment, moduleFragment, trainingDayFragment}: OwnProps) => {
    const baseUrl = process.env.REACT_APP_ADMIN_URL
    const courseQuery = useLazyLoadQuery<CourseHeader_CourseQuery>(COURSE_QUERY, {
        id: courseId || "",
        skip: !courseId
    }, {fetchPolicy: "network-only"})

    const moduleQuery = useLazyLoadQuery<CourseHeader_ModuleQuery>(MODULE_QUERY, {
        id: moduleId || "",
        skip: !moduleId
    }, {fetchPolicy: "network-only"})

    const course = useFragment<CourseHeader_CourseFragment$key>(COURSE_FRAGMENT, courseFragment || courseQuery.node!)
    const module = useFragment<CourseHeader_ModuleFragment$key>(MODULE_FRAGMENT, moduleFragment || moduleQuery.node!)
    const trainingDay = useFragment<CourseHeader_TrainingDayFragment$key>(TRAINING_DAY_FRAGMENT, trainingDayFragment || null)

    const [items, setItems] = useState<MenuItem[]>([])

    const home: MenuItem = {icon: 'pi pi-home', url: baseUrl + '/courses'}

    useEffect(() => {
        if (course && module && trainingDay) {
            setItems([{
                label: `Lehrgang ${course.secondName}`,
                url: `${baseUrl}/courses/${course.id}/edit`
            }, {
                label: `Modul ${module.shortTitle}`,
                url: `${baseUrl}/courses/${course.id}/${module.id}/edit`
            }, {
                label: `Ausbildungstag ${trainingDay.internalTitle}`,
                url: `${baseUrl}/courses/${course.id}/${module.id}/${trainingDay.id}/edit`
            }])
        } else if (course && module) {
            setItems([{
                label: `Lehrgang ${course.secondName}`,
                url: `${baseUrl}/courses/${course.id}/edit`
            }, {
                label: `Modul ${module.shortTitle}`,
                url: `${baseUrl}/courses/${course.id}/${module.id}/edit`
            }])
        } else if (course) {
            setItems([{
                label: `Lehrgang ${course.secondName}`,
                url: `${baseUrl}/courses/${course.id}/edit`
            }])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course, module, trainingDay])

    return <BreadCrumb model={items} home={home}/>
}
