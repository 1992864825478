import React, {useContext} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {FaqSearchContext} from "./container/FaqSearchContainer";

export const FaqFilter = () => {
    const {filters, setFilters, clearFilters} = useContext(FaqSearchContext)

    return <div className="flex mb-3 flex-wrap">
        <div className="m-1">
            <InputText
                placeholder={"Frage"}
                value={filters.filterByQuestion || ""}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByQuestion: e.target.value
                })}
            />
        </div>
        <div className="flex align-items-center m-1">
            <Button className="" tooltip={"Zurücksetzen"} icon="pi pi-times" onClick={() => clearFilters()}/>
        </div>
    </div>
}

