import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {
    ParticipantsInformation_CourseStateFragment$key
} from "../../__generated__/ParticipantsInformation_CourseStateFragment.graphql";
import {useFragment} from "react-relay";
import {Chip} from "primereact/chip";
import styled from "styled-components";

const COURSE_STATE_FRAGMENT = graphql`
    fragment ParticipantsInformation_CourseStateFragment on CourseState {
        moduleStates {
            moduleId
            amountOfRebookedParticipants
            amountOfRegularParticipants
            amountOfWaitingListParticipants
        }

        publishedCourse {
            amountOfRegulatedParticipant
            amountOfRebookedParticipant
        }
    }
`


interface OwnProps {
    courseStateFragmentRef: ParticipantsInformation_CourseStateFragment$key
    moduleId: String
}

type ParticipantType = "Regular" | "Rebooked" | "WaitingList"

export const ParticipantsInformation = ({courseStateFragmentRef, moduleId}: OwnProps) => {
    const courseState = useFragment<ParticipantsInformation_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const isFull = (participantType: ParticipantType): boolean => {
        if (participantType === "Regular") {
            const regularParticipants = courseState.moduleStates.find(ms => ms.moduleId === moduleId)?.amountOfRegularParticipants || 0

            return regularParticipants >= courseState.publishedCourse.amountOfRegulatedParticipant
        }

        if (participantType === "Rebooked") {
            const rebookedParticipants = courseState.moduleStates.find(ms => ms.moduleId === moduleId)?.amountOfRebookedParticipants || 0

            return rebookedParticipants >= courseState.publishedCourse.amountOfRebookedParticipant
        }

        return participantType !== "WaitingList"
    }

    return (
        <div className="flex flex-row">
            <StyledChip className="pl-2 pr-2 mr-3"
                        isFull={isFull("Regular")}
                        label={`Reguläre Teilnehmer: ${courseState.moduleStates.find(ms => ms.moduleId === moduleId)?.amountOfRegularParticipants} / ${courseState.publishedCourse.amountOfRegulatedParticipant}`}/>
            <StyledChip className="pl-2 pr-2 mr-3"
                        isFull={isFull("Rebooked")}
                        label={`Umgebuchte Teilnehmer: ${courseState.moduleStates.find(ms => ms.moduleId === moduleId)?.amountOfRebookedParticipants} / ${courseState.publishedCourse.amountOfRebookedParticipant}`}/>
            <StyledChip className="pl-2 pr-2 mr-3"
                        isFull={isFull("WaitingList")}
                        label={`Warteliste: ${courseState.moduleStates.find(ms => ms.moduleId === moduleId)?.amountOfWaitingListParticipants}`}/>
        </div>
    )
}

interface StyledChipProps {
    isFull: boolean
}

const StyledChip = styled(Chip)<StyledChipProps>`
  color: ${props => props.isFull ? "red" : "black"}
`
