/**
 * @generated SignedSource<<4d0a056fa23ae95028e5181b6b28450c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreatePublishCourseInput = {
  clientMutationId?: string | null;
  data: PublishCourseInput;
};
export type PublishCourseInput = {
  clientMutationId?: string | null;
  courseId: string;
  shouldSendNotification: boolean;
};
export type PublishedCourseButton_PublishCourseMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePublishCourseInput;
};
export type PublishedCourseButton_PublishCourseMutation$data = {
  readonly Admin: {
    readonly Course: {
      readonly createPublishCourse: {
        readonly data: {
          readonly node: {
            readonly id: string;
            readonly name: string;
            readonly secondName: string;
            readonly startDate: string | null;
          };
        };
      } | null;
    };
  };
};
export type PublishedCourseButton_PublishCourseMutation = {
  response: PublishedCourseButton_PublishCourseMutation$data;
  variables: PublishedCourseButton_PublishCourseMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "PublishedCoursesEdge",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishedCourse",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secondName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PublishedCourseButton_PublishCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePublishCoursePayload",
                "kind": "LinkedField",
                "name": "createPublishCourse",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PublishedCourseButton_PublishCourseMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePublishCoursePayload",
                "kind": "LinkedField",
                "name": "createPublishCourse",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e64c11e9672aab35c99889b93f8d640",
    "id": null,
    "metadata": {},
    "name": "PublishedCourseButton_PublishCourseMutation",
    "operationKind": "mutation",
    "text": "mutation PublishedCourseButton_PublishCourseMutation(\n  $input: CreatePublishCourseInput!\n) {\n  Admin {\n    Course {\n      createPublishCourse(input: $input) {\n        data {\n          node {\n            id\n            name\n            secondName\n            startDate\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6f6d8f2b115b880b7190dbddbbc54af";

export default node;
