/**
 * @generated SignedSource<<9b6ebcdb3c4e955211b2e224670e5a82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderPriceColumn_OrderFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrderPriceDisplay_OrderFragment">;
  readonly " $fragmentType": "OrderPriceColumn_OrderFragment";
};
export type OrderPriceColumn_OrderFragment$key = {
  readonly " $data"?: OrderPriceColumn_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderPriceColumn_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderPriceColumn_OrderFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "OrderPriceDisplay_OrderFragment"
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "68ff86436d9282107e33f3e4068b8efc";

export default node;
