/**
 * @generated SignedSource<<add39785533cc222cf679dc9366ead81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FoodCreditStateInput = "CateringInvoice" | "Expired" | "Open" | "Redeemed" | "%future added value";
export type SetFoodCreditStateForParticipantInput = {
  clientMutationId?: string | null;
  foodCreditState: FoodCreditStateInput;
  trainingDayId: string;
  userId: string;
};
export type CourseStatesTable_SetFoodCreditStateMutation$variables = {
  input: SetFoodCreditStateForParticipantInput;
};
export type CourseStatesTable_SetFoodCreditStateMutation$data = {
  readonly Admin: {
    readonly States: {
      readonly setFoodCreditStateForParticipant: {
        readonly courseState: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"CourseStatesTable_CourseStateFragment">;
          };
        };
      } | null;
    };
  };
};
export type CourseStatesTable_SetFoodCreditStateMutation = {
  response: CourseStatesTable_SetFoodCreditStateMutation$data;
  variables: CourseStatesTable_SetFoodCreditStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CourseStatesTable_SetFoodCreditStateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetFoodCreditStateForParticipantPayload",
                "kind": "LinkedField",
                "name": "setFoodCreditStateForParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CourseStatesTable_CourseStateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CourseStatesTable_SetFoodCreditStateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetFoodCreditStateForParticipantPayload",
                "kind": "LinkedField",
                "name": "setFoodCreditStateForParticipant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModuleState",
                            "kind": "LinkedField",
                            "name": "moduleStates",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "moduleId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TrainingDayState",
                                "kind": "LinkedField",
                                "name": "trainingDayStates",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "trainingDayId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AttendanceTypeAdmin",
                                    "kind": "LinkedField",
                                    "name": "attendanceListAdmin",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceStatus",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "foodCreditState",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "note",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublishedCourse",
                            "kind": "LinkedField",
                            "name": "publishedCourse",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "secondName",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PublishedModuleAdmin",
                                "kind": "LinkedField",
                                "name": "modulesAdmin",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PublishedTrainingDay",
                                    "kind": "LinkedField",
                                    "name": "trainingDays",
                                    "plural": true,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "date",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82c02ee2b9e57f2c8078267744e6b979",
    "id": null,
    "metadata": {},
    "name": "CourseStatesTable_SetFoodCreditStateMutation",
    "operationKind": "mutation",
    "text": "mutation CourseStatesTable_SetFoodCreditStateMutation(\n  $input: SetFoodCreditStateForParticipantInput!\n) {\n  Admin {\n    States {\n      setFoodCreditStateForParticipant(input: $input) {\n        courseState {\n          node {\n            ...CourseStatesTable_CourseStateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment CourseStatesTable_CourseStateFragment on CourseState {\n  id\n  moduleStates {\n    moduleId\n    trainingDayStates {\n      trainingDayId\n      attendanceListAdmin {\n        attendanceStatus\n        attendanceType\n        foodCreditState\n        note\n        id\n      }\n      id\n    }\n    id\n  }\n  publishedCourse {\n    name\n    secondName\n    modulesAdmin {\n      id\n      title\n      startDate\n      endDate\n      trainingDays {\n        id\n        date\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "82953542634b34bcb0150fc2da5e4c4a";

export default node;
