import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {EmailTemplatesScreen} from "../screens/email/EmailTemplatesScreen";
import {EditEmailTemplateScreen} from "../screens/email/EditEmailTemplateScreen";

export const EDIT_TEMPLATE_PATH = "/email-templates/:emailTemplateId/edit";

export const EmailModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/email-templates",
        element: <EmailTemplatesScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_TEMPLATE_PATH,
        element: <EditEmailTemplateScreen/>,
        requiredPermissions: ["System_Owner"]
    }
];
