import {graphql} from "babel-plugin-relay/macro";
import {Dropdown} from "primereact/dropdown";
import {fetchQuery, useRelayEnvironment} from "react-relay";
import {useEffect, useState} from "react";
import {UserInput_Query} from "../../../__generated__/UserInput_Query.graphql";

const QUERY = graphql`
    query UserInput_Query($first: Int, $after: String, $filterByName: String){
        Admin {
            User {
                Users (first: $first, after: $after, filterByName: $filterByName) {
                    pageInfo {
                        endCursor
                        hasNextPage
                        hasPreviousPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            email
                        }
                    }
                }
            }
        }
    }
`


interface OwnProps {
    selectedUser?: string | null
    onSelect: (userId?: string) => void

    className?: string
}

interface UserInputProps {
    id: string,
    email: string
}

export const UserInput = ({selectedUser, onSelect, className}: OwnProps) => {
    const environment = useRelayEnvironment();

    const [users, setUsers] = useState<UserInputProps[]>([])

    useEffect(() => {
        fetchQuery<UserInput_Query>(environment, QUERY, {})
            .toPromise().then(result => {
            setUsers(() => result!.Admin.User.Users.edges!.map(e => transformToUserInput(e!.node!)))
        })
        // eslint-disable-next-line
    }, [])

    const transformToUserInput = (user: { id: string, email: string }): UserInputProps => (
        {
            id: user.id,
            email: user.email,
        }
    )

    const selectedAccountTemplate = (user: UserInputProps, props: { placeholder: string }) => {
        if (user) {
            return (
                <div>
                    <div>{user?.email}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }


    const accountOptionTemplate = (user: UserInputProps) => {
        return (
            <div>
                <div>{user?.email}</div>
            </div>
        );
    }

    return <Dropdown value={users.find(l => l.id === selectedUser)}
                     options={users}
                     optionLabel="name"
                     onChange={e => onSelect(e.value?.id || null)}
                     filter
                     showClear
                     onFilter={e => {
                         fetchQuery<UserInput_Query>(environment, QUERY, {
                             filterByName: e.filter?.length > 0 ? e.filter : undefined
                         }).toPromise().then(result => {
                             setUsers(() => result!.Admin.User.Users.edges!.map(e => transformToUserInput(e!.node!)))
                         })
                     }}
                     filterBy="name"
                     className={className}
                     placeholder="Teilnehmer auswählen"
                     valueTemplate={selectedAccountTemplate}
                     itemTemplate={accountOptionTemplate}/>

}
