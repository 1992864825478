import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$key } from "../../../../../../__generated__/PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment.graphql";

const PAYMENT_IN_PROCESS_UPDATE_FRAGMENT = graphql`
	fragment PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment on PaymentInProcessUpdate {
		kind
	}
`;

interface OwnProps {
	paymentInProcessUpdateFragmentRef: PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$key;
}

export const PaymentInProcessUpdatePart = ({ paymentInProcessUpdateFragmentRef }: OwnProps) => {
	const paymentInProcess =
		useFragment<PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$key>(
			PAYMENT_IN_PROCESS_UPDATE_FRAGMENT,
			paymentInProcessUpdateFragmentRef,
		);

	return <Fieldset legend={HistoryEventType[paymentInProcess.kind]}></Fieldset>;
};
