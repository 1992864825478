import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TextDisplayField } from "../../ui/TextDisplayField";
import { Button } from "primereact/button";
import { InvoiceDataForm_OrderFragment$key } from "../../../../__generated__/InvoiceDataForm_OrderFragment.graphql";

const ORDER_FRAGMENT = graphql`
	fragment InvoiceDataForm_OrderFragment on Order {
		invoiceData {
			invoiceNumber
			invoiceId
			invoiceDataBase64
		}
	}
`;

interface OwnProps {
	orderFragmentRef: InvoiceDataForm_OrderFragment$key;
}

export const InvoiceDataForm = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<InvoiceDataForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	const downloadInvoicePDF = () => {
		if (order.invoiceData?.invoiceDataBase64) {
			const link = document.createElement("a");
			link.href =
				"data:application/octet-stream;base64," + order.invoiceData?.invoiceDataBase64;
			link.download = order.invoiceData.invoiceNumber + ".pdf";
			link.click();
		}
	};

	return order.invoiceData ? (
		<div>
			<div className="formgrid grid">
				<TextDisplayField label="Rechnungs-ID" value={order.invoiceData.invoiceId} />
				<TextDisplayField label="Rechnungsnummer" value={order.invoiceData.invoiceNumber} />
			</div>
			{order.invoiceData.invoiceDataBase64 && (
				<Button onClick={downloadInvoicePDF} label="PDF Download" icon="pi pi-download" />
			)}
		</div>
	) : (
		<h4>Keine Rechnungsdaten verfügbar</h4>
	);
};
