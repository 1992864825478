export const EMAIL_TEMPLATE_KEYS: { [key: string]: string } = {
    "billing-invoice-email": "Rechnungs Email",
    "billing-order-fulfilled-email": "Buchung abgeschlossen",
    "billing-payment-processor-email": "Crefo Mitteilung",
    "billing-sepa-mandate-email": "Sepa Mandat",
    "forgot-password": "Passwort vergessen",
    "activate-account": "Account aktivieren",
    "invitation-to-account": "Account einladung",
    "course-state-registration-confirmation": "Modul neuregistrierung",
    "course-publish-user-notification": "Lehrgangsveröffentlichung",
    "module-appointment-reminder": "Modulstart Erinnerung ",
    "course-appointment-reminder": "Lehrgangsstart Erinnerung ",
    "course-state-module-registration-confirmation": "Modulumbuchung",
    "course-state-course-registration-confirmation": "Lehrgangsregistrierung",
    "billing-course-canceled-credit-note-crefo": "Rechnungskorrektur (Crefo)",
    "billing-course-canceled-credit-note": "Rechnungskorrektur",
    "course-canceled-user-information": "Stornierung",
    "waiting-list-notification": "Warteliste Information",
}

export const EMAIL_VARIABLES: { [key: string]: string } = {
    "authenticationToken": "Aktivierungs-Token (muss in den Link)",
    "frontendUrl": "URL des Nutzersystem-User-Frontends",
    "recipientName": "Name des Empfängers",
    "paymentMethod": "Zahlungsmethode",
    "mandateAcceptedDateTime": "Akzeptanzdatum des Sepa-Mandats",
    "mandateNumber":"Mandatsnummer",
    "mandateText":"Mandatstext",
    "customerAddress":"Adresse des Käufers",
    "dateOfBirth":"Geburtstag des Käufers",
    "crefoInvoiceNumber":"Rechnungsnummer",
    "customerEmail":"Email des Käufers",
    "invitingUserName":"Name des Nutzers der die Einladung verschickt hat",
    "accountName":"Accountname",
    "iban": "IBAN",
    "courseName": "Lehrgangsname",
    "courseStart": "Lehrgangsstart",
    "moduleName": "Modulname",
    "moduleStart": "Modulstart",
    "courseChanges": "Lehrgangsänderungen",
    "moduleEnd": "Modulende",
    "trainingDayInformation": "zusätzliche Informationen",
}

