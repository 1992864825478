/**
 * @generated SignedSource<<c92ec813631135c04f364ba78e13db79>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoiceProviderLink_InvoiceDataFragment$data = {
  readonly invoiceId: string;
  readonly invoiceNumber: string;
  readonly " $fragmentType": "InvoiceProviderLink_InvoiceDataFragment";
};
export type InvoiceProviderLink_InvoiceDataFragment$key = {
  readonly " $data"?: InvoiceProviderLink_InvoiceDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceProviderLink_InvoiceDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceProviderLink_InvoiceDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    }
  ],
  "type": "InvoiceData",
  "abstractKey": null
};

(node as any).hash = "87084ac7452483816e8edf9095b1c6e9";

export default node;
