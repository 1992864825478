/**
 * @generated SignedSource<<392eca141a7f19ae13b79a3435d10185>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PaymentMethodUpdatePart_PaymentMethodUpdateFragment$data = {
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "PaymentMethodUpdatePart_PaymentMethodUpdateFragment";
};
export type PaymentMethodUpdatePart_PaymentMethodUpdateFragment$key = {
  readonly " $data"?: PaymentMethodUpdatePart_PaymentMethodUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentMethodUpdatePart_PaymentMethodUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentMethodUpdatePart_PaymentMethodUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "PaymentMethodUpdate",
  "abstractKey": null
};

(node as any).hash = "79c99723393fe8c537c445bb688e327a";

export default node;
