import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { PaymentProviderLinks_OrderFragment$key } from "../../../__generated__/PaymentProviderLinks_OrderFragment.graphql";
import { PaymentMethod } from "../../translations/PaymentMethod";

const ORDER_FRAGMENT = graphql`
	fragment PaymentProviderLinks_OrderFragment on Order {
		paymentData {
			paymentMethod
			dataType
			... on StripeData {
				paymentIntentId
			}
			... on StripeSepaData {
				paymentIntentId
			}
		}
		status
	}
`;

interface OwnProps {
	orderFragmentRef: PaymentProviderLinks_OrderFragment$key;
}

const isProduction = process.env.APP_ENVIRONMENT === "prod";

export const PaymentProviderLinks = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<PaymentProviderLinks_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	if (order.status === "PaymentReceived" || order.status === "Purchased") {
		const paymentString = PaymentMethod[order.paymentData?.paymentMethod || ""];
		const paymentIntentId = order.paymentData?.paymentIntentId;

		switch (order.paymentData?.dataType) {
			case "Stripe":
			case "StripeSepa":
				if (isProduction && paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				}
				if (paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/test/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				} else return <>{paymentString}</>;
			default:
				return <>{paymentString}</>;
		}
	} else {
		return <>Unbezahlt</>;
	}
};
