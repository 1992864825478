/**
 * @generated SignedSource<<515f01096ce3c9b1d1826650ba5bac2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvoiceSentUpdatePart_InvoiceSentUpdateFragment$data = {
  readonly invoiceId: string;
  readonly invoiceNumber: string;
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "InvoiceSentUpdatePart_InvoiceSentUpdateFragment";
};
export type InvoiceSentUpdatePart_InvoiceSentUpdateFragment$key = {
  readonly " $data"?: InvoiceSentUpdatePart_InvoiceSentUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceSentUpdatePart_InvoiceSentUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceSentUpdatePart_InvoiceSentUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    }
  ],
  "type": "InvoiceSentUpdate",
  "abstractKey": null
};

(node as any).hash = "edf2db6d45247932b8ab68c731d2623f";

export default node;
