/**
 * @generated SignedSource<<c02f56a87722a1cbd0a5eee43fa13d9e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeTrainingDayOrderInput = {
  clientMutationId?: string | null;
  moduleId: string;
  trainingDayRefs: ReadonlyArray<string>;
};
export type EditModuleForm_ChangeTrainingDayOrderMutation$variables = {
  input: ChangeTrainingDayOrderInput;
};
export type EditModuleForm_ChangeTrainingDayOrderMutation$data = {
  readonly Admin: {
    readonly Course: {
      readonly changeTrainingDayOrder: {
        readonly module: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"ModuleScreen_ModuleFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditModuleForm_ChangeTrainingDayOrderMutation = {
  response: EditModuleForm_ChangeTrainingDayOrderMutation$data;
  variables: EditModuleForm_ChangeTrainingDayOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditModuleForm_ChangeTrainingDayOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeTrainingDayOrderPayload",
                "kind": "LinkedField",
                "name": "changeTrainingDayOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModulesEdge",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Module",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModuleScreen_ModuleFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditModuleForm_ChangeTrainingDayOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CourseAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Course",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeTrainingDayOrderPayload",
                "kind": "LinkedField",
                "name": "changeTrainingDayOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModulesEdge",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Module",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortTitle",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isExam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "trainerIds",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "locationId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastUpdate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TrainingDay",
                            "kind": "LinkedField",
                            "name": "trainingDays",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "date",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModulePath",
                            "kind": "LinkedField",
                            "name": "modulePath",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "courseRef",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44ae5864c799e91835cdafda2ed5f611",
    "id": null,
    "metadata": {},
    "name": "EditModuleForm_ChangeTrainingDayOrderMutation",
    "operationKind": "mutation",
    "text": "mutation EditModuleForm_ChangeTrainingDayOrderMutation(\n  $input: ChangeTrainingDayOrderInput!\n) {\n  Admin {\n    Course {\n      changeTrainingDayOrder(input: $input) {\n        module {\n          node {\n            ...ModuleScreen_ModuleFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment AddTrainingDayButton_ModuleFragment on Module {\n  id\n}\n\nfragment CourseHeader_ModuleFragment on Module {\n  id\n  shortTitle\n}\n\nfragment EditModuleForm_ModuleFragment on Module {\n  id\n  modulePath {\n    courseRef\n    id\n  }\n  ...AddTrainingDayButton_ModuleFragment\n  ...RemoveTrainingDayButton_ModuleFragment\n}\n\nfragment ModuleScreen_ModuleFragment on Module {\n  id\n  title\n  shortTitle\n  internalTitle\n  shortDescription\n  createdAt\n  isExam\n  trainerIds\n  locationId\n  lastUpdate\n  trainingDays {\n    id\n    internalTitle\n    date\n  }\n  ...EditModuleForm_ModuleFragment\n  ...CourseHeader_ModuleFragment\n}\n\nfragment RemoveTrainingDayButton_ModuleFragment on Module {\n  id\n}\n"
  }
};
})();

(node as any).hash = "5656dc0f2acbefe9ff8f4c46bae96c4d";

export default node;
