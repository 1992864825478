/**
 * @generated SignedSource<<c567a03f8a00a1ef19056ab9ff8fe6c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryEventColumn_OrderHistoryFragment$data = {
  readonly event: {
    readonly kind: HistoryEventType;
    readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsUpdatePart_BillingDetailsUpdateFragment" | "CartUpdatePart_CartUpdateFragment" | "CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment" | "InvoiceSentUpdatePart_InvoiceSentUpdateFragment" | "OrderCreationUpdatePart_OrderCreationUpdateFragment" | "PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment" | "PaymentMethodUpdatePart_PaymentMethodUpdateFragment" | "PurchaseUpdatePart_PurchaseUpdateFragment">;
  };
  readonly " $fragmentType": "OrderHistoryEventColumn_OrderHistoryFragment";
};
export type OrderHistoryEventColumn_OrderHistoryFragment$key = {
  readonly " $data"?: OrderHistoryEventColumn_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryEventColumn_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderHistoryEventColumn_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "OrderCreationUpdatePart_OrderCreationUpdateFragment"
            }
          ],
          "type": "OrderCreationUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CartUpdatePart_CartUpdateFragment"
            }
          ],
          "type": "CartUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BillingDetailsUpdatePart_BillingDetailsUpdateFragment"
            }
          ],
          "type": "BillingDetailsUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PaymentMethodUpdatePart_PaymentMethodUpdateFragment"
            }
          ],
          "type": "PaymentMethodUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment"
            }
          ],
          "type": "PaymentInProcessUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PurchaseUpdatePart_PurchaseUpdateFragment"
            }
          ],
          "type": "PurchaseUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "InvoiceSentUpdatePart_InvoiceSentUpdateFragment"
            }
          ],
          "type": "InvoiceSentUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CreditNoteSentUpdatePart_CreditNoteSentUpdateFragment"
            }
          ],
          "type": "CreditNoteSentUpdate",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderHistory",
  "abstractKey": null
};

(node as any).hash = "b98e14af85049feca98d58cd233fde1f";

export default node;
