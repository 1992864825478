/**
 * @generated SignedSource<<64dc794acf6e9d54e484c30b6f798f2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ModuleScreen_ModuleFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly internalTitle: string;
  readonly isExam: boolean;
  readonly lastUpdate: string;
  readonly locationId: string;
  readonly shortDescription: string;
  readonly shortTitle: string;
  readonly title: string;
  readonly trainerIds: ReadonlyArray<string>;
  readonly trainingDays: ReadonlyArray<{
    readonly date: string;
    readonly id: string;
    readonly internalTitle: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_ModuleFragment" | "EditModuleForm_ModuleFragment">;
  readonly " $fragmentType": "ModuleScreen_ModuleFragment";
};
export type ModuleScreen_ModuleFragment$key = {
  readonly " $data"?: ModuleScreen_ModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ModuleScreen_ModuleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ModuleScreen_ModuleFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isExam",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "trainerIds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locationId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrainingDay",
      "kind": "LinkedField",
      "name": "trainingDays",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditModuleForm_ModuleFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseHeader_ModuleFragment"
    }
  ],
  "type": "Module",
  "abstractKey": null
};
})();

(node as any).hash = "492db13bc80e6f79d5630ab3a864aeee";

export default node;
