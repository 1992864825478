import React from "react";
import {useUrlFilterState} from "../../../../hooks/useUrlFilterState";

export interface PublishedCoursesFilters {
    filterByNameOrSecondName?: string
    filterByCourseLocation?: string
    filterByModuleLocation?: string
    filterByTrainers?: string[]
    filterByStartDate?: string
    filterByRegularFull?: boolean
    filterByRebookedFull?: boolean
}


interface Search {
    filters: PublishedCoursesFilters
    setFilters: (filters: PublishedCoursesFilters) => void
    clearFilters: () => void
}

export const PublishedCourseSearchContext = React.createContext<Search>({
    setFilters: () => {
    },
    clearFilters: () => {
    },
    filters: {}
})

interface OwnProps {
    children: any
}

export const PublishedCourseSearchContainer = ({children}: OwnProps) => {
    const {state, setState} = useUrlFilterState<PublishedCoursesFilters>()

    return <PublishedCourseSearchContext.Provider value={{
        filters: state,
        setFilters: filters => {
            setState(() => filters)
        },
        clearFilters: () => {
            setState({})
        }
    }}>
        {children}
    </PublishedCourseSearchContext.Provider>
}
