import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {RemoveTrainingDayButton_ModuleFragment$key} from "../../../__generated__/RemoveTrainingDayButton_ModuleFragment.graphql";
import {RemoveTrainingDayButton_RemoveTrainingDayMutation} from "../../../__generated__/RemoveTrainingDayButton_RemoveTrainingDayMutation.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";

const MODULE_FRAGMENT = graphql`
    fragment RemoveTrainingDayButton_ModuleFragment on Module {
        id
    }
`

const REMOVE_TRAINING_DAY_MUTATION = graphql`
    mutation RemoveTrainingDayButton_RemoveTrainingDayMutation($input: DeleteTrainingDayInput!) {
        Admin {
            Course {
                deleteTrainingDay(input: $input){
                    module {
                        node {
                            ...ModuleScreen_ModuleFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    moduleFragmentRef: RemoveTrainingDayButton_ModuleFragment$key | null
    trainingDayId: string
}

export const RemoveTrainingDayButton = ({moduleFragmentRef, trainingDayId}: OwnProps) => {
    const [removeTrainingDay, removeTrainingDayInFlight] = useMutation<RemoveTrainingDayButton_RemoveTrainingDayMutation>(REMOVE_TRAINING_DAY_MUTATION)
    const module = useFragment<RemoveTrainingDayButton_ModuleFragment$key>(MODULE_FRAGMENT, moduleFragmentRef)

    const {showDialog, dialogComponent} = useDialogLogic();

    return module ?
        <>
            {dialogComponent}
            <Button
                className="ml-2"
                icon="pi pi-trash"
                loading={removeTrainingDayInFlight}
                type={"button"}
                tooltip="Löschen"
                onClick={() => {
                    showDialog({
                        title: "Ausbildungstag entfernen?",
                        content: "Möchten Sie diesen Ausbildungstag wirklich löschen?",
                        dialogCallback: (result) => {
                            if (result === "Accept") {
                                removeTrainingDay({
                                    variables: {
                                        input: {
                                            id: module.id,
                                            trainingDayRef: trainingDayId
                                        }
                                    }
                                })
                            }
                        }
                    })
                }}
            />
        </> :
        null
}
