/**
 * @generated SignedSource<<03630fe1053f5d661a135baae2d221ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseHeader_ModuleFragment$data = {
  readonly id: string;
  readonly shortTitle: string;
  readonly " $fragmentType": "CourseHeader_ModuleFragment";
};
export type CourseHeader_ModuleFragment$key = {
  readonly " $data"?: CourseHeader_ModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_ModuleFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseHeader_ModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortTitle",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "8ec216ef733cd06ff5f1b9914e096a26";

export default node;
