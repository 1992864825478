import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {EditCourseTemplateForm, FormState} from '../../components/relay/forms/EditCourseTemplateForm';
import {CourseTemplateScreen_Query} from "../../__generated__/CourseTemplateScreen_Query.graphql";
import {CourseTemplateScreen_CourseTemplateFragment$key} from "../../__generated__/CourseTemplateScreen_CourseTemplateFragment.graphql";
import {toast} from "react-toastify";
import {CourseTemplateScreen_UpdatingMutation} from "../../__generated__/CourseTemplateScreen_UpdatingMutation.graphql";
import {TemplateHeader} from "../../components/relay/header/TemplateHeader";
import {EDIT_COURSE_TEMPLATE_PATH} from "../../routes/CourseTemplateRoutes";

const QUERY = graphql`
    query CourseTemplateScreen_Query($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip) {
            ... on CourseTemplate {
                ...CourseTemplateScreen_CourseTemplateFragment
            }
        }
    }
`

const COURSE_TEMPLATE_FRAGMENT = graphql`
    fragment CourseTemplateScreen_CourseTemplateFragment on CourseTemplate {
        id
        name
        description
        iconRef
        createdAt
        amountOfRebookedParticipant
        amountOfRegulatedParticipant
        price {
            grossPrice
            netPrice
            taxRatePercentage
        }
        modules {
            id
            internalTitle
        }

        ...EditCourseTemplateForm_CourseTemplateFragment
        ...TemplateHeader_CourseTemplateFragment
    }
`;

const UPDATE_MUTATION = graphql`
    mutation CourseTemplateScreen_UpdatingMutation($input: EditCourseTemplateInput!) {
        Admin {
            Template {
                editCourseTemplate(input: $input) {
                    courseTemplate {
                        node {
                            id
                            ...TemplateHeader_CourseTemplateFragment
                            ...CourseTemplateScreen_CourseTemplateFragment
                        }
                    }
                }
            }
        }
    }
`;


export const CourseTemplateScreen = () => {
    const {params: {courseTemplateId}} = useMatch(EDIT_COURSE_TEMPLATE_PATH)!

    const query = useLazyLoadQuery<CourseTemplateScreen_Query>(QUERY, {
        id: courseTemplateId!,
        skip: !courseTemplateId || courseTemplateId === "new"
    }, {fetchPolicy: "network-only"})

    const courseTemplate = useFragment<CourseTemplateScreen_CourseTemplateFragment$key>(COURSE_TEMPLATE_FRAGMENT, query.node || null)
    const [updateCourseTemplate, isUpdating] = useMutation<CourseTemplateScreen_UpdatingMutation>(UPDATE_MUTATION)

    return <div>
        <TemplateHeader courseTemplateFragment={courseTemplate}/>
        <h2>Lehrgang {courseTemplate?.name} bearbeiten</h2>
        <EditCourseTemplateForm
            initialValues={{
                id: courseTemplate?.id || "",
                name: courseTemplate?.name || "",
                amountOfRegulatedParticipant: courseTemplate?.amountOfRegulatedParticipant || 0,
                amountOfRebookedParticipant: courseTemplate?.amountOfRebookedParticipant || 0,
                createdAt: courseTemplate?.createdAt,
                description: courseTemplate?.description,
                price: courseTemplate?.price || {
                    grossPrice: 0,
                    netPrice: 0,
                    taxRatePercentage: 19
                },
                iconRef: courseTemplate?.iconRef,
                modules: courseTemplate?.modules.map((module) => ({
                    id: module?.id,
                    internalTitle: module?.internalTitle
                })) || []
            }}
            loading={isUpdating}
            onSubmit={(values: FormState) => {
                if (courseTemplate) {
                    updateCourseTemplate({
                        variables: {
                            input: {
                                id: courseTemplate.id,
                                iconRef: values.iconRef,
                                name: values.name,
                                amountOfRegulatedParticipant: values.amountOfRegulatedParticipant,
                                amountOfRebookedParticipant: values.amountOfRebookedParticipant,
                                price: values.price,
                                description: values.description
                            }
                        },
                        onCompleted: () => {
                            toast.success("Kurs-Template erfolgreich gespeichert")
                        },
                        onError: error => {
                            console.error(error.message)
                        },
                        updater: (store, data) => {
                            if (data.Admin.Template.editCourseTemplate?.courseTemplate.node.id) store.get(data.Admin.Template.editCourseTemplate?.courseTemplate.node.id)?.invalidateRecord()
                        }
                    })
                }
            }}
            courseTemplateFragmentRef={courseTemplate}/>
    </div>

}
