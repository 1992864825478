/**
 * @generated SignedSource<<2aaae1b288ee6667ae4e2662a91c5868>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "Email_Templates" | "Files_Delete" | "Management_Management" | "Sales_Sales" | "System_Owner" | "System_Root" | "Trainer_Trainer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditGroupModal_GroupFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly permissions: ReadonlyArray<Permission>;
  readonly " $fragmentType": "EditGroupModal_GroupFragment";
};
export type EditGroupModal_GroupFragment$key = {
  readonly " $data"?: EditGroupModal_GroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditGroupModal_GroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditGroupModal_GroupFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "permissions",
      "storageKey": null
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "f7272747912cf9247eeb40486ac21bc2";

export default node;
