import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {ChangePasswordForm_UserFragment$key} from "../../../__generated__/ChangePasswordForm_UserFragment.graphql";
import {ChangePasswordForm_ChangeEmailMutation} from "../../../__generated__/ChangePasswordForm_ChangeEmailMutation.graphql";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {RenderConfig, ValidatedField} from "../fields/ValidatedField";
import * as Yup from "yup";
import {classNames} from "primereact/utils";
import {InputText} from "primereact/inputtext";
import {toast} from "react-toastify";

const USER_FRAGMENT = graphql`
    fragment ChangePasswordForm_UserFragment on User {
        id
        email
    }
`;

const UPDATE_META_VALUE_MUTATION = graphql`
    mutation ChangePasswordForm_ChangeEmailMutation($input: ChangeUserPasswordInput!) {
        Admin {
            User{
                changeUserPassword(input: $input) {
                    user {
                        ...SingleUserForm_UserFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    userFragmentRef: ChangePasswordForm_UserFragment$key
}

interface FormState {
    value: string
}

export const ChangePasswordForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<ChangePasswordForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [changePassword, isInFlight] = useMutation<ChangePasswordForm_ChangeEmailMutation>(UPDATE_META_VALUE_MUTATION)
    const formik = useFormik<FormState>({
        initialValues: {
            value: ""
        },
        validationSchema: Yup.object().shape({
            value: Yup
                .string()
                .min(8, "Das Passwort muss mindestens 8 Zeichen lang sein.")
                .required("Das Feld Passwort wird benötigt"),
        }),
        onSubmit: values => {
            changePassword({
                variables: {
                    input: {
                        userId: user.id,
                        Password: values.value
                    }
                },
                onCompleted: () => {
                    toast("Die Passwortänderung war erfolgreich", {type: "success"})
                    formik.resetForm()
                }
            })
        }
    })

    return <form onSubmit={formik.handleSubmit} className="field grid">
        <label className="col-fixed font-bold" style={{width: 150}}>
            Passwort
        </label>
        <div className="col">
            <ValidatedField<FormState, string>
                className="mb-0"
                name={"value"}
                formikConfig={formik}
                helpText={"Mindestens 8 Zeichen"}
                component={
                    ({fieldName, fieldValue, updateField, isValid, disabled}: RenderConfig<string>) => {
                        return <InputText id={fieldName} name={fieldName} value={fieldValue}
                                          onChange={e => updateField(e.target.value)}
                                          placeholder={"Neues Passwort..."}
                                          disabled={disabled}
                                          className={classNames({"p-invalid": !isValid})}/>
                    }}/>
        </div>
        <div className="flex align-items-center">
            <Button
                type="submit"
                disabled={isInFlight || formik.values.value.length < 8}
                className="mr-2" icon={"pi pi-save"}/>
        </div>

    </form>
}
