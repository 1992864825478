import React from "react";
import currencyFormatter from "currency-formatter";

interface OwnProps {
	value: number;
	className?: string;
}

export const CurrencyDisplay = ({ value, className }: OwnProps) => {
	const price = currencyFormatter.format(value, {
		code: "EUR",
	});
	return <div className={className}>{price}</div>;
};
