import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {TheKeyScreen} from "../screens/thekey/TheKeyScreen";

export const TheKeyRoutes: SecureRouteDefinition[] = [
    {
        path: "/thekey",
        element: <TheKeyScreen/>,
        requiredPermissions: ["Sales_Sales"]
    }
]
