/**
 * @generated SignedSource<<e4ca851bb06217e5b849ab695b508e54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplateHeader_ModuleTemplateQuery$variables = {
  id: string;
  skip: boolean;
};
export type TemplateHeader_ModuleTemplateQuery$data = {
  readonly node?: {
    readonly " $fragmentSpreads": FragmentRefs<"TemplateHeader_ModuleTemplateFragment">;
  } | null;
};
export type TemplateHeader_ModuleTemplateQuery = {
  response: TemplateHeader_ModuleTemplateQuery$data;
  variables: TemplateHeader_ModuleTemplateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateHeader_ModuleTemplateQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "TemplateHeader_ModuleTemplateFragment"
                  }
                ],
                "type": "ModuleTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplateHeader_ModuleTemplateQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shortTitle",
                    "storageKey": null
                  }
                ],
                "type": "ModuleTemplate",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "9f1c33956cff9daf302d9934fd936ba8",
    "id": null,
    "metadata": {},
    "name": "TemplateHeader_ModuleTemplateQuery",
    "operationKind": "query",
    "text": "query TemplateHeader_ModuleTemplateQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on ModuleTemplate {\n      ...TemplateHeader_ModuleTemplateFragment\n    }\n    id\n  }\n}\n\nfragment TemplateHeader_ModuleTemplateFragment on ModuleTemplate {\n  id\n  shortTitle\n}\n"
  }
};
})();

(node as any).hash = "74a45a1762561790f3869fb6627fca9f";

export default node;
