import React, {Suspense, useState} from 'react'
import {UnpublishedCourseTable} from "../../components/relay/tables/UnpublishedCourseTable";
import {TabMenu} from 'primereact/tabmenu';
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {CoursesScreen_Query} from "../../__generated__/CoursesScreen_Query.graphql";
import {PublishedCourseTable} from '../../components/relay/tables/PublishedCourseTable';
import {PublishedCourseSearchContainer} from "../../components/relay/filter/container/PublishedCourseSearchContainer";
import {useTypedSelector} from "../../Store";
import {selectPermissionsInAccount} from "../../slices/AuthSlice";
import {ForbiddenScreen} from "../forbidden/ForbiddenScreen";

const items = [
    {label: 'Unveröffentlicht', icon: 'pi pi-fw pi-circle'},
    {label: 'Veröffentlicht', icon: 'pi pi-fw pi-check-circle'},
];

export const CoursesScreen = () => {
    const [activeIndex, setActiveIndex] = useState(1)
    const [publishedCoursesConnectionId, setPublishedCoursesConnectionId] = useState<string | undefined>()
    const permissions = useTypedSelector(selectPermissionsInAccount)

    const courseData = useLazyLoadQuery<CoursesScreen_Query>(graphql`
        query CoursesScreen_Query {
            ...UnpublishedCourseTable_UnpublishedCourseFragment
            ...PublishedCourseTable_PublishedCoursesFragment
        }
    `, {fetchPolicy: "network-only"})

    let activeTab;

    switch (activeIndex) {
        case 0:
            activeTab = permissions.includes("System_Owner") ? <UnpublishedCourseTable unpublishedCourseFragment={courseData} publishedCoursesConnectionId={publishedCoursesConnectionId || ""}/> : <ForbiddenScreen />
            break;
        case 1:
            activeTab = (
                <PublishedCourseSearchContainer>
                    <Suspense fallback={<div>Lade...</div>}>
                        <PublishedCourseTable courseStateFragmentRef={courseData} setConnectionId={(connectionId: string) => setPublishedCoursesConnectionId(connectionId)}/>
                    </Suspense>
                </PublishedCourseSearchContainer>
            )
            break;
    }

    return <div>
        <h1>Lehrgänge</h1>

        <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}/>
        <div className="mt-5">
            {activeTab}
        </div>
    </div>
}
