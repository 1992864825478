import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";

interface OwnProps {
	id: string;
}

export const EditDiscountActionButton = ({ id }: OwnProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Bearbeiten"
			onClick={() => navigate(`/discount-actions/${id}/edit`)}
		/>
	);
};
