import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {UsersScreen} from "../screens/user/UsersScreen";
import {SingleUserScreen} from "../screens/user/SingleUserScreen";

export const EDIT_USER_PATH = "/users/:userId/:activeIndex/edit";
export const EDIT_COURSE_STATE_PATH = "/users/:userId/:activeIndex/:courseStateId/edit";

export const AuthModuleRoutes: SecureRouteDefinition[] = [
    {
        path: "/users",
        element: <UsersScreen/>,
        requiredPermissions: []
    },
    {
        path: EDIT_USER_PATH,
        element: <SingleUserScreen/>,
        requiredPermissions: []
    }
];
