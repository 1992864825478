import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {RemoveTrainingDayTemplateButton_ModuleTemplateFragment$key} from "../../../__generated__/RemoveTrainingDayTemplateButton_ModuleTemplateFragment.graphql";
import {RemoveTrainingDayTemplateButton_RemoveTrainingDayMutation} from "../../../__generated__/RemoveTrainingDayTemplateButton_RemoveTrainingDayMutation.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";

const FRAGMENT = graphql`
    fragment RemoveTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {
        id
    }
`

const DELETE_MUTATION = graphql`
    mutation RemoveTrainingDayTemplateButton_RemoveTrainingDayMutation($input: DeleteTrainingDayTemplateInput!) {
        Admin {
            Template {
                deleteTrainingDayTemplate(input: $input){
                    moduleTemplate {
                        node {
                            ...ModuleTemplateScreen_ModuleTemplateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    moduleTemplateFragmentRef: RemoveTrainingDayTemplateButton_ModuleTemplateFragment$key | null
    trainingDayId: string
}

export const RemoveTrainingDayTemplateButton = ({moduleTemplateFragmentRef, trainingDayId}: OwnProps) => {
    const [removeTrainingDayTemplate, removeTrainingDayTemplateInFlight] = useMutation<RemoveTrainingDayTemplateButton_RemoveTrainingDayMutation>(DELETE_MUTATION)
    const moduleTemplate = useFragment<RemoveTrainingDayTemplateButton_ModuleTemplateFragment$key>(FRAGMENT, moduleTemplateFragmentRef)

    const {showDialog, dialogComponent} = useDialogLogic();

    return moduleTemplate ?
        <>{dialogComponent}
            <Button
                className="ml-2"
                icon="pi pi-trash"
                tooltip="Löschen"
                loading={removeTrainingDayTemplateInFlight}
                type={"button"}
                onClick={() => {
                    showDialog({
                        title: "Ausbildungstagvorlage entfernen?",
                        content: "Möchten Sie diese Ausbildungstagvorlage wirklich löschen?",
                        dialogCallback: (result) => {
                            if (result === "Accept") {
                                removeTrainingDayTemplate({
                                    variables: {
                                        input: {
                                            id: moduleTemplate.id,
                                            trainingDayRef: trainingDayId
                                        }
                                    }
                                })
                            }
                        }
                    })

                }}
            />
        </> :
        null
}
