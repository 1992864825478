/**
 * @generated SignedSource<<45b0f971a093b8342236f7f1f29bdd35>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveModuleButton_CourseFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "RemoveModuleButton_CourseFragment";
};
export type RemoveModuleButton_CourseFragment$key = {
  readonly " $data"?: RemoveModuleButton_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveModuleButton_CourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveModuleButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};

(node as any).hash = "c10bd58cae90ea8d0b48c8d0886a8f14";

export default node;
