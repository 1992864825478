import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {ChangeEmailForm_UserFragment$key} from "../../../__generated__/ChangeEmailForm_UserFragment.graphql";
import {ChangeEmailForm_ChangeEmailMutation} from "../../../__generated__/ChangeEmailForm_ChangeEmailMutation.graphql";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {ValidatedField} from "../fields/ValidatedField";
import {DefaultTextFieldComponent} from "../../ui/DefaultTextInput";

const USER_FRAGMENT = graphql`
    fragment ChangeEmailForm_UserFragment on User {
        id
        email
    }
`;

const UPDATE_META_VALUE_MUTATION = graphql`
    mutation ChangeEmailForm_ChangeEmailMutation($input: ChangeUserEmailInput!) {
        Admin {
            User {
                changeUserEmail(input: $input) {
                    user {
                        ...SingleUserForm_UserFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    userFragmentRef: ChangeEmailForm_UserFragment$key
}

interface FormState {
    value: string
}

export const ChangeEmailForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<ChangeEmailForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [changeEmail, isInFlight] = useMutation<ChangeEmailForm_ChangeEmailMutation>(UPDATE_META_VALUE_MUTATION)
    const formik = useFormik<FormState>({
        initialValues: {
            value: user.email
        },
        onSubmit: values => {
            changeEmail({
                variables: {
                    input: {
                        userId: user.id,
                        email: values.value
                    }
                }
            })
        }
    })

    return <form className="field grid" onSubmit={formik.handleSubmit}>
        <label className="col-fixed font-bold" style={{width: 150}}>
            E-Mail
        </label>
        <div className="col">
            <ValidatedField
                className="mb-0"
                name={"value"}
                formikConfig={formik}
                component={DefaultTextFieldComponent}/>
        </div>
        <div className="flex align-items-center">
            <Button
                type="submit"
                disabled={isInFlight || user.email === formik.values.value}
                className="mr-2" icon={"pi pi-save"}/>
        </div>

    </form>
}
