import React from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import { ValidatedField } from "../form-fields/ValidatedField";
import { DefaultTextFieldComponent } from "../form-fields/DefaultTextInput";

interface FilterState {
	name?: string;
	orderId?: string;
	userId?: string;
}

interface OwnProps {
	refetch: (orderId?: string, userId?: string, name?: string) => void;
}

export const OrdersTableFilters = ({ refetch }: OwnProps) => {
	const initialValues = {
		name: undefined,
		orderId: undefined,
		userId: undefined,
	};

	const formik = useFormik<FilterState>({
		initialValues: initialValues,
		onSubmit: (values: FilterState, { setSubmitting }) => {
			refetch(values.orderId, values.userId, values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<FilterState, string>
					name="orderId"
					label="Bestellnummer"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<FilterState, string>
					name="userId"
					label="Kundennummer"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<FilterState, string>
					name="name"
					label="Vor-/Nachname"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem ml-auto"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem ml-2"
				/>
			</div>
		</form>
	);
};
