import React from "react";
import {useUrlFilterState} from "../../../../hooks/useUrlFilterState";

export interface UsersFilters {
    filterByName?: string
    filterByEmail?: string
}


interface Search {
    filters: UsersFilters
    setFilters: (filters: UsersFilters) => void
    clearFilters: () => void
}

export const UsersSearchContext = React.createContext<Search>({
    setFilters: () => {
    },
    clearFilters: () => {
    },
    filters: {}
})

interface OwnProps {
    children: any
}

export const UsersSearchContainer = ({children}: OwnProps) => {
    const {state, setState} = useUrlFilterState<UsersFilters>()

    return <UsersSearchContext.Provider value={{
        filters: state,
        setFilters: filters => {
            setState(() => filters)
        },
        clearFilters: () => {
            setState({})
        }
    }}>
        {children}
    </UsersSearchContext.Provider>
}
