import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {AddTrainingDayTemplateButton_AddTrainingDayMutation} from "../../../__generated__/AddTrainingDayTemplateButton_AddTrainingDayMutation.graphql";
import {AddTrainingDayTemplateButton_ModuleTemplateFragment$key} from "../../../__generated__/AddTrainingDayTemplateButton_ModuleTemplateFragment.graphql";

const FRAGMENT = graphql`
    fragment AddTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {
        id
    }
`

const CREATE_MUTATION = graphql`
    mutation AddTrainingDayTemplateButton_AddTrainingDayMutation($input: CreateTrainingDayTemplateInput!) {
        Admin {
            Template {
                createTrainingDayTemplate(input: $input){
                    moduleTemplate {
                        node {
                            ...ModuleTemplateScreen_ModuleTemplateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    moduleTemplateFragmentRef: AddTrainingDayTemplateButton_ModuleTemplateFragment$key | null
}

export const AddTrainingDayTemplateButton = ({moduleTemplateFragmentRef}: OwnProps) => {
    const [addTrainingDayTemplate, addTrainingDayTemplateInFlight] = useMutation<AddTrainingDayTemplateButton_AddTrainingDayMutation>(CREATE_MUTATION)
    const moduleTemplate = useFragment<AddTrainingDayTemplateButton_ModuleTemplateFragment$key>(FRAGMENT, moduleTemplateFragmentRef)

    return moduleTemplate ?
        <Button
            icon="pi pi-plus"
            tooltip="Ausbildungstag erzeugen"
            className="align-self-end mb-2"
            loading={addTrainingDayTemplateInFlight}
            type={"button"}
            onClick={() => {
                addTrainingDayTemplate({
                    variables: {
                        input: {
                            id: moduleTemplate.id
                        }
                    }
                })
            }}
        /> :
        null
}
