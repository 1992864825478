import {TkButton} from "../../ui/TkButton";
import {useState} from "react";
import {EditGroupModal} from "../modals/EditGroupModal";

interface OwnProps {
    connectionId: string
}

export const CreateGroupButton = ({connectionId}: OwnProps) => {
    const [isVisible, setVisible] = useState<boolean>(false)

    return <div>
        <TkButton
            onClick={() => setVisible(true)}
            label={"Gruppe erstellen"}/>

        <EditGroupModal
            connectionId={connectionId}
            isVisible={isVisible}
            onHide={() => setVisible(false)}
            onCompleted={() => {
                setVisible(false)
            }}/>
    </div>

}
