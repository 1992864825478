import {TkButton} from "../../ui/TkButton";
import {useState} from "react";
import {CreateUserInAccountModal} from "../modals/CreateUserInAccountModal";

interface OwnProps {
    accountId: string
    connectionId: string
}

export const CreateUserInAccountButton = ({accountId, connectionId}: OwnProps) => {
    const [isVisible, setVisible] = useState<boolean>(false)

    return <div>
        <TkButton
            onClick={() => setVisible(true)}
            label={"Nutzer zum Account hinzufügen"}/>

        <CreateUserInAccountModal
            accountId={accountId}
            connectionId={connectionId}
            isVisible={isVisible}
            onHide={() => setVisible(false)}
            onCompleted={() => {
                setVisible(false)
            }}/>
    </div>

}
