import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {HistoryItem} from "./HistoryItem";
import {History_HistoryFragment$key} from "../../__generated__/History_HistoryFragment.graphql";

const HISTORY_FRAGMENT = graphql`
    fragment History_HistoryFragment on History {
        entries {
            ... HistoryItem_EntryFragment
        }
    }
`

interface OwnProps {
    historyFragmentRef: History_HistoryFragment$key | null
}

export const History = ({historyFragmentRef}: OwnProps) => {
    const history = useFragment<History_HistoryFragment$key>(HISTORY_FRAGMENT, historyFragmentRef)

    if(!history) return <h2>Leider gibt es noch keine Historie</h2>

    return (
        <div>
            {history.entries.map(history =>
                <HistoryItem entryFragmentRef={history}/>
            )}
        </div>
    )
}
