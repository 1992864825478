import graphql from "babel-plugin-relay/macro";
import {usePaginationFragment} from "react-relay";
import {Column} from "primereact/column";
import {TkButton} from "../../ui/TkButton";
import {TkDataTable} from "../../ui/TkDataTable";
import {filterGroups} from "../modals/ChangeUserGroupsModal";
import {ChangeUserGroupsAdminButton} from "../buttons/ChangeUserGroupsAdminButton";
import {RemoveUserFromAccountAdminButton} from "../buttons/RemoveUserFromAccountAdminButton";
import {CreateUserInAccountButton} from "../buttons/CreateUserInAccountButton";
import {UsersInAccountAdminTable_Refetch} from "../../../__generated__/UsersInAccountAdminTable_Refetch.graphql";
import {PERMISSION_NAME_TRANSLATION} from "../../../i18n/permissions.i18n";
import {
    UsersInAccountAdminTable_GetUsersInAccountListFragment$key
} from "../../../__generated__/UsersInAccountAdminTable_GetUsersInAccountListFragment.graphql";
import {useTypedSelector} from "../../../Store";
import {selectPermissionsInAccount} from "../../../slices/AuthSlice";


const SCENARIOS_FRAGMENT = graphql`
    fragment UsersInAccountAdminTable_GetUsersInAccountListFragment on Query @refetchable(queryName: "UsersInAccountAdminTable_Refetch") @argumentDefinitions(
        accountId: {type: "ID!"},
        first: {type: "Int", defaultValue: 20},
        after: {type: "String"},
    ){
        Admin {
            Management {
                GetUsersInAccount(accountId: $accountId, first: $first, after: $after)@connection(key: "UsersInAccountAdminTable_GetUsersInAccount") {
                    __id
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            user {
                                id
                                email
                                name
                            }
                            groups {
                                id
                                name
                            }
                            ...ChangeUserGroupsAdminButton_UserInAccountFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    usersInAccountFragmentRef: UsersInAccountAdminTable_GetUsersInAccountListFragment$key
    accountId: string;
}

export const UsersInAccountAdminTable = ({usersInAccountFragmentRef, accountId}: OwnProps) => {
    const permissions = useTypedSelector(selectPermissionsInAccount)
    const {
        data: {
            Admin: {Management: {GetUsersInAccount: {__id, edges}}}
        },
        hasNext,
        loadNext,
    } = usePaginationFragment<UsersInAccountAdminTable_Refetch, UsersInAccountAdminTable_GetUsersInAccountListFragment$key>(SCENARIOS_FRAGMENT, usersInAccountFragmentRef)


    const UsersInAccount = edges?.map(b => b!.node!);
    return <div>
        <div className="flex justify-content-end mb-4">
            {permissions.includes("System_Owner") &&
                <CreateUserInAccountButton connectionId={__id} accountId={accountId}/>}
        </div>
        <TkDataTable
            header={<h2>TheKey</h2>}
            selectionMode={"single"}
            dataKey={"id"}
            emptyMessage={"No users found"}
            className="mb-3"
            value={UsersInAccount}
        >
            <Column header="Name" body={row => {
                return row.user.name
            }}/>
            <Column header="Email" body={row => {
                return row.user.email
            }}/>
            <Column header="Gruppe" body={row => {
                return <div className="flex align-items-center">
                    <div>{filterGroups(row.groups).map((g: any) => {
                        if(g.name === "Owner" || g.name === "User") return PERMISSION_NAME_TRANSLATION[g.name]
                        else return g.name
                    }).join(", ")}</div>
                    {permissions.includes("System_Owner") && <ChangeUserGroupsAdminButton accountId={accountId} userInAccountFragment={row}/>}
                </div>
            }}/>
            {permissions.includes("System_Owner") && <Column
                header="Aktionen"
                body={row => {
                    return <div className="flex">
                        <RemoveUserFromAccountAdminButton accountId={accountId} userId={row.user.id}
                                                          connectionId={__id}/>
                    </div>
                }}
            />}
        </TkDataTable>

        {hasNext && <div className="flex justify-content-center align-items-center">
            <TkButton
                type="button"
                className="p-button-secondary" onClick={() => loadNext(20)}>Load more</TkButton>
        </div>}
    </div>

}
