import {useMatch, useNavigate} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {EditFaqForm} from "../../components/relay/forms/EditFaqForm";
import {EDIT_FAQ_PATH} from "../../routes/FaqRoutes";
import {EditFaqScreen_Query} from "../../__generated__/EditFaqScreen_Query.graphql";
import {EditFaqScreen_FaqFragment$key} from "../../__generated__/EditFaqScreen_FaqFragment.graphql";
import {EditFaqScreen_CreateFaqMutation} from "../../__generated__/EditFaqScreen_CreateFaqMutation.graphql";
import {EditFaqScreen_UpdateFaqMutation} from "../../__generated__/EditFaqScreen_UpdateFaqMutation.graphql";

const QUERY = graphql`
    query EditFaqScreen_Query($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on Faq {
                ...EditFaqScreen_FaqFragment
            }
        }
    }
`

const FAQ_FRAGMENT = graphql`
    fragment EditFaqScreen_FaqFragment on Faq {
        id
        question
        answer
    }
`

const CREATE_FAQ_MUTATION = graphql`
    mutation EditFaqScreen_CreateFaqMutation($input: CreateFaqInput!, $connections: [ID!]!) {
        Admin {
            Faq {
                createFaq(input: $input) {
                    data @appendEdge(connections: $connections){
                        node {
                            ...EditFaqScreen_FaqFragment
                        }
                    }
                }
            }
        }
    }
`

const UPDATE_FAQ_MUTATION = graphql`
    mutation EditFaqScreen_UpdateFaqMutation($input: UpdateFaqInput!) {
        Admin {
            Faq {
                updateFaq(input: $input) {
                    data{
                        node {
                            ...EditFaqScreen_FaqFragment
                        }
                    }
                }
            }
        }
    }
`


export const EditFaqScreen = () => {
    const navigate = useNavigate()
    const {params: {faqId}} = useMatch(EDIT_FAQ_PATH)!

    const query = useLazyLoadQuery<EditFaqScreen_Query>(QUERY, {
        id: faqId!,
        skip: !faqId || faqId === "new"
    })

    const faq = useFragment<EditFaqScreen_FaqFragment$key>(FAQ_FRAGMENT, query.node || null)
    const [createFaq, isCreatingFaq] = useMutation<EditFaqScreen_CreateFaqMutation>(CREATE_FAQ_MUTATION)
    const [updateFaq, isUpdatingFaq] = useMutation<EditFaqScreen_UpdateFaqMutation>(UPDATE_FAQ_MUTATION)

    return <>
        <h1 className="mb-3">Bearbeite Frage</h1>

        <EditFaqForm
            initialValues={{
                question: faq?.question,
                answer: faq?.answer
            }}
            loading={isCreatingFaq || isUpdatingFaq}
            onSubmit={values => {
                const data = {
                    question: values.question!,
                    answer: values.answer!
                }

                if (faq) {
                    updateFaq({
                        variables: {
                            input: {
                                id: faq.id,
                                data: data
                            }
                        },
                        onCompleted: _ => navigate("/faqs")
                    })
                } else {
                    createFaq({
                        variables: {
                            input: {
                                data: data
                            },
                            connections: [
                                ConnectionHandler.getConnectionID("client:root:Admin:Faq", "FaqsTable_Faqs")
                            ]
                        },
                        onCompleted: _ => navigate("/faqs")
                    })
                }
            }}/>
    </>
}
