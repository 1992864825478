/**
 * @generated SignedSource<<fa240309b8c4896d841e43852b3ef8f3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditModuleTemplateForm_ModuleTemplateFragment$data = {
  readonly id: string;
  readonly moduleTemplatePath: {
    readonly courseTemplateRef: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AddTrainingDayTemplateButton_ModuleTemplateFragment" | "RemoveTrainingDayTemplateButton_ModuleTemplateFragment">;
  readonly " $fragmentType": "EditModuleTemplateForm_ModuleTemplateFragment";
};
export type EditModuleTemplateForm_ModuleTemplateFragment$key = {
  readonly " $data"?: EditModuleTemplateForm_ModuleTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditModuleTemplateForm_ModuleTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditModuleTemplateForm_ModuleTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleTemplatePath",
      "kind": "LinkedField",
      "name": "moduleTemplatePath",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "courseTemplateRef",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddTrainingDayTemplateButton_ModuleTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveTrainingDayTemplateButton_ModuleTemplateFragment"
    }
  ],
  "type": "ModuleTemplate",
  "abstractKey": null
};

(node as any).hash = "124f25742cbe3b35762bd7923c50e8dc";

export default node;
