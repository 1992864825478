/**
 * @generated SignedSource<<b70d464e37d36a4360e88e94fffbe3e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateGroupInput = {
  clientMutationId?: string | null;
  name: string;
  permissions: ReadonlyArray<string>;
};
export type EditGroupModal_CreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateGroupInput;
};
export type EditGroupModal_CreateMutation$data = {
  readonly Management: {
    readonly createGroup: {
      readonly edge: {
        readonly node: {
          readonly id: string;
          readonly " $fragmentSpreads": FragmentRefs<"EditGroupModal_GroupFragment">;
        };
      };
    } | null;
  };
};
export type EditGroupModal_CreateMutation = {
  response: EditGroupModal_CreateMutation$data;
  variables: EditGroupModal_CreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditGroupModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementMutations",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateGroupPayload",
            "kind": "LinkedField",
            "name": "createGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Group",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditGroupModal_GroupFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditGroupModal_CreateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ManagementMutations",
        "kind": "LinkedField",
        "name": "Management",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "CreateGroupPayload",
            "kind": "LinkedField",
            "name": "createGroup",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GroupsEdge",
                "kind": "LinkedField",
                "name": "edge",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Group",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "appendEdge",
                "key": "",
                "kind": "LinkedHandle",
                "name": "edge",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "722e741d73191c130aa3f01bf4456e28",
    "id": null,
    "metadata": {},
    "name": "EditGroupModal_CreateMutation",
    "operationKind": "mutation",
    "text": "mutation EditGroupModal_CreateMutation(\n  $input: CreateGroupInput!\n) {\n  Management {\n    createGroup(input: $input) {\n      edge {\n        node {\n          id\n          ...EditGroupModal_GroupFragment\n        }\n      }\n    }\n  }\n}\n\nfragment EditGroupModal_GroupFragment on Group {\n  id\n  name\n  permissions\n}\n"
  }
};
})();

(node as any).hash = "d63ee88b039a9133cc2143a1e021ad2d";

export default node;
