/**
 * @generated SignedSource<<5f5758964018608ea636319821b79aa2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeTrainingDayTemplateOrderInput = {
  clientMutationId?: string | null;
  moduleTemplateId: string;
  trainingDayRefs: ReadonlyArray<string>;
};
export type EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation$variables = {
  input: ChangeTrainingDayTemplateOrderInput;
};
export type EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation$data = {
  readonly Admin: {
    readonly Template: {
      readonly changeTrainingDayTemplateOrder: {
        readonly moduleTemplate: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"ModuleTemplateScreen_ModuleTemplateFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation = {
  response: EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation$data;
  variables: EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "internalTitle",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeTrainingDayTemplateOrderPayload",
                "kind": "LinkedField",
                "name": "changeTrainingDayTemplateOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "moduleTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModuleTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ModuleTemplateScreen_ModuleTemplateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TemplateAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Template",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ChangeTrainingDayTemplateOrderPayload",
                "kind": "LinkedField",
                "name": "changeTrainingDayTemplateOrder",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleTemplatesEdge",
                    "kind": "LinkedField",
                    "name": "moduleTemplate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ModuleTemplate",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortTitle",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isExam",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastUpdate",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "TrainingDayTemplate",
                            "kind": "LinkedField",
                            "name": "trainingDays",
                            "plural": true,
                            "selections": [
                              (v2/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModuleTemplatePath",
                            "kind": "LinkedField",
                            "name": "moduleTemplatePath",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "courseTemplateRef",
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1fe47b65d4607773f53d81ce82a08425",
    "id": null,
    "metadata": {},
    "name": "EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation",
    "operationKind": "mutation",
    "text": "mutation EditModuleTemplateForm_ChangeTrainingDayTemplateOrderMutation(\n  $input: ChangeTrainingDayTemplateOrderInput!\n) {\n  Admin {\n    Template {\n      changeTrainingDayTemplateOrder(input: $input) {\n        moduleTemplate {\n          node {\n            ...ModuleTemplateScreen_ModuleTemplateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment AddTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {\n  id\n}\n\nfragment EditModuleTemplateForm_ModuleTemplateFragment on ModuleTemplate {\n  id\n  moduleTemplatePath {\n    courseTemplateRef\n    id\n  }\n  ...AddTrainingDayTemplateButton_ModuleTemplateFragment\n  ...RemoveTrainingDayTemplateButton_ModuleTemplateFragment\n}\n\nfragment ModuleTemplateScreen_ModuleTemplateFragment on ModuleTemplate {\n  id\n  title\n  shortTitle\n  internalTitle\n  shortDescription\n  createdAt\n  isExam\n  lastUpdate\n  trainingDays {\n    id\n    internalTitle\n  }\n  ...EditModuleTemplateForm_ModuleTemplateFragment\n  ...TemplateHeader_ModuleTemplateFragment\n}\n\nfragment RemoveTrainingDayTemplateButton_ModuleTemplateFragment on ModuleTemplate {\n  id\n}\n\nfragment TemplateHeader_ModuleTemplateFragment on ModuleTemplate {\n  id\n  shortTitle\n}\n"
  }
};
})();

(node as any).hash = "0bee636be0d9687eaf611499fd479914";

export default node;
