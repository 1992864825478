/**
 * @generated SignedSource<<2748f38edebbfe8c6f19add58dfd288f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "Business" | "Private" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BillingDetailsForm_BillingDetailsFragment$data = {
  readonly customerType: CustomerType;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBillingDetailsForm_BillingDetailsFragment" | "PrivateBillingDetailsForm_BillingDetailsFragment">;
  readonly " $fragmentType": "BillingDetailsForm_BillingDetailsFragment";
};
export type BillingDetailsForm_BillingDetailsFragment$key = {
  readonly " $data"?: BillingDetailsForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BillingDetailsForm_BillingDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BillingDetailsForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "PrivateBillingDetailsForm_BillingDetailsFragment"
        }
      ],
      "type": "PrivateBillingDetails",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BusinessBillingDetailsForm_BillingDetailsFragment"
        }
      ],
      "type": "BusinessBillingDetails",
      "abstractKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": "__isBillingDetails"
};

(node as any).hash = "eb40c51124ede3ea057a45c8967ea0c7";

export default node;
