import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { BusinessBillingDetailsForm_BillingDetailsFragment$key } from "../../../../__generated__/BusinessBillingDetailsForm_BillingDetailsFragment.graphql";
import { TextDisplayField } from "../../ui/TextDisplayField";
import { CompanyType } from "../../../translations/CompanyType";

const BUSINESS_BILLING_DETAILS_FRAGMENT = graphql`
	fragment BusinessBillingDetailsForm_BillingDetailsFragment on BusinessBillingDetails {
		firstName
		lastName
		street
		houseNumber
		postalCode
		city
		companyDetails
		country
		salutation
		invoiceEmail
		company
		companyType
	}
`;

interface OwnProps {
	businessBillingDetailsFragmentRef: BusinessBillingDetailsForm_BillingDetailsFragment$key;
}

export const BusinessBillingDetailsForm = ({ businessBillingDetailsFragmentRef }: OwnProps) => {
	const businessBillingDetails =
		useFragment<BusinessBillingDetailsForm_BillingDetailsFragment$key>(
			BUSINESS_BILLING_DETAILS_FRAGMENT,
			businessBillingDetailsFragmentRef,
		);
	return (
		<div className="p-4">
			<div className="formgrid grid">
				<TextDisplayField label="Vorname" value={businessBillingDetails.firstName} />
				<TextDisplayField label="Nachname" value={businessBillingDetails.lastName} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Straße" value={businessBillingDetails.street} />
				<TextDisplayField label="Hausnummer" value={businessBillingDetails.houseNumber} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Stadt/Ort" value={businessBillingDetails.city} />
				<TextDisplayField label="Postleitzahl" value={businessBillingDetails.postalCode} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Land" value={businessBillingDetails.country} />
				<TextDisplayField label="Anrede" value={businessBillingDetails.salutation} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Rechnungs E-Mail"
					value={businessBillingDetails.invoiceEmail}
				/>
				<TextDisplayField
					label="Rechtsform"
					value={CompanyType[businessBillingDetails.companyType]}
				/>
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Unternehmensname" value={businessBillingDetails.company} />
				<TextDisplayField
					label="Unternehmensdetails"
					value={businessBillingDetails.companyDetails || undefined}
				/>
			</div>
		</div>
	);
};
