import React from 'react'
import {useFragment, useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {UserHistoryScreen_UserFragment$key} from "../../__generated__/UserHistoryScreen_UserFragment.graphql";
import {AttendanceType, UserHistoryScreen_Query} from "../../__generated__/UserHistoryScreen_Query.graphql";
import {CourseStatesTable} from "../../components/relay/tables/CourseStatesTable";

const QUERY = graphql`
    query UserHistoryScreen_Query($first: Int, $after: String ,$userId: ID!){
        Admin {
            States {
                UserHistory(first: $first, after: $after,userId: $userId) @connection(key: "UserHistoryScreen_UserHistory") {
                    edges {
                        ...CourseStatesTable_UserHistoryFragment
                        node {
                            courseState {
                                moduleStates {
                                    trainingDayStates {
                                        attendanceListAdmin {
                                            attendanceType
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const USER_FRAGMENT = graphql`
    fragment UserHistoryScreen_UserFragment on User {
        id
        name
        ... CourseStatesTable_UserFragment
    }
`

interface OwnProps {
    userFragmentRef: UserHistoryScreen_UserFragment$key
}

export const UserHistoryScreen = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<UserHistoryScreen_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const userHistories = useLazyLoadQuery<UserHistoryScreen_Query>(QUERY, {
        userId: user.id
    }, {fetchPolicy: "network-only"})

    const filterCourseStateByAttendanceType = (
        attendanceType: AttendanceType,
        courseState: { readonly moduleStates: readonly { readonly trainingDayStates: readonly { readonly attendanceListAdmin: readonly { readonly attendanceType: AttendanceType }[] }[] }[] }) => {
        return courseState.moduleStates.flatMap(moduleState => moduleState.trainingDayStates.flatMap(l => l.attendanceListAdmin.flatMap(k => k.attendanceType))).every(k => k === attendanceType)
    }

    const regularCourseStates = userHistories.Admin.States.UserHistory.edges?.filter(edge =>
        edge &&
        edge.node.courseState.moduleStates.flatMap(moduleState =>
            moduleState.trainingDayStates.flatMap(l =>
                l.attendanceListAdmin.flatMap(k => k.attendanceType)
            )
        ).includes("Regular")
    )
    const rebookedCourseStates = userHistories.Admin.States.UserHistory.edges?.filter(edge => edge && filterCourseStateByAttendanceType("Rebooked", edge.node.courseState))
    const waitingListCourseStates = userHistories.Admin.States.UserHistory.edges?.filter(edge => edge && filterCourseStateByAttendanceType("WaitingList", edge.node.courseState))
    const canceledCourseStates = userHistories.Admin.States.UserHistory.edges?.filter(edge => edge && filterCourseStateByAttendanceType("CourseCancel", edge.node.courseState))

    return <div>
        <h2>Kursstatus für {user.name}</h2>
        {regularCourseStates && regularCourseStates.length > 0 && <>
            <h3>Regulär</h3>
            {regularCourseStates?.map(state => <CourseStatesTable userFragmentRef={user}
                                                                  userHistoryFragmentRef={state!}/>)}
        </>}

        {rebookedCourseStates && rebookedCourseStates.length > 0 && <>
            <h3>Umbuchend</h3>
            {rebookedCourseStates.map(state => <CourseStatesTable userFragmentRef={user}
                                                                  userHistoryFragmentRef={state!}/>)}
        </>}

        {waitingListCourseStates && waitingListCourseStates.length > 0 && <>
            <h3>Warteliste</h3>
            {waitingListCourseStates.map(state => <CourseStatesTable userFragmentRef={user}
                                                                     userHistoryFragmentRef={state!}/>)}
        </>
        }

        {canceledCourseStates && canceledCourseStates.length > 0 && <>
            <h3>Storniert</h3>
            {canceledCourseStates?.map(state => <CourseStatesTable userFragmentRef={user}
                                                                   userHistoryFragmentRef={state!}/>)}
        </>}
    </div>
}
