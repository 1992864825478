import {Dropdown} from "primereact/dropdown";
import {FoodCreditState} from "../../__generated__/EditParticipantsForm_CourseStateFragment.graphql";
import {FoodCreditStateI18n} from "../../i18n/foodCreditState.i18n,";
import {Button} from "primereact/button";
import React, {useState} from "react";

interface OwnProps {
    selectedStatus: FoodCreditState
    onSave: (foodCreditState: FoodCreditState) => void
    disabled?: boolean
    placeholder?: string
    className?: string
}

export const FoodCreditStateInputWithSave = ({
                                                 selectedStatus,
                                                 onSave,
                                                 placeholder,
                                                 className,
                                                 disabled
                                             }: OwnProps) => {
    const [selectedFoodCreditState, setSelectedFoodCreditState] = useState<FoodCreditState>(selectedStatus)

    const options: { label: string, value: FoodCreditState }[] = [
        {label: FoodCreditStateI18n["Open"], value: "Open"},
        {label: FoodCreditStateI18n["Expired"], value: "Expired"},
        {label: FoodCreditStateI18n["Redeemed"], value: "Redeemed"},
        {label: FoodCreditStateI18n["CateringInvoice"], value: "CateringInvoice"},
    ]

    return (
        <div className="flex">
            <div className="col w-full">
                <Dropdown
                    options={options}
                    className=" w-full"
                    placeholder={placeholder}
                    value={selectedFoodCreditState}
                    onChange={e => setSelectedFoodCreditState(e.value)}/>
            </div>
            <div className="col-10 md:col-1">
                <Button
                    type="button"
                    loading={disabled}
                    disabled={disabled}
                    onClick={() => onSave(selectedFoodCreditState)}
                    className="mr-2" icon={"pi pi-save"}/>
            </div>
        </div>
    )

}
