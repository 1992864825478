/**
 * @generated SignedSource<<fb8da6ed3391523aa88ee2271ad1f075>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditCourseForm_CourseFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddModuleButton_CourseFragment" | "RemoveModuleButton_CourseFragment">;
  readonly " $fragmentType": "EditCourseForm_CourseFragment";
};
export type EditCourseForm_CourseFragment$key = {
  readonly " $data"?: EditCourseForm_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditCourseForm_CourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditCourseForm_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddModuleButton_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveModuleButton_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};

(node as any).hash = "23a17a2c7124af83346efebd205658d5";

export default node;
