import React from "react";
import { useFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { OrderPriceDisplay_OrderFragment$key } from "../../../__generated__/OrderPriceDisplay_OrderFragment.graphql";
import { InvoiceProviderLink } from "../../../billing-admin-impl/components/relay/InvoiceProviderLink";
import { CurrencyDisplay } from "../../../billing-admin-impl/components/ui/CurrencyDisplay";

const ORDER_FRAGMENT = graphql`
	fragment OrderPriceDisplay_OrderFragment on Order {
		status
		cart {
			totals {
				includingAllDiscounts {
					netPrice
				}
			}
		}
		selectedPaymentMethod {
			... on MonthlySelectedPaymentMethod {
				chosenOption {
					monthlyRate {
						netPrice
					}
				}
			}
		}
		invoiceData {
			...InvoiceProviderLink_InvoiceDataFragment
		}
	}
`;

export interface OwnProps {
	orderFragmentRef: OrderPriceDisplay_OrderFragment$key;
}

export const OrderPriceDisplay = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderPriceDisplay_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	if (order.status === "Purchased" || order.status === "PaymentReceived") {
		const Price = order.selectedPaymentMethod?.chosenOption ? (
			<>
				Monatlich:{" "}
				<CurrencyDisplay
					value={order.selectedPaymentMethod.chosenOption.monthlyRate.netPrice}
				/>
			</>
		) : (
			<CurrencyDisplay value={order.cart?.totals.includingAllDiscounts?.netPrice!} />
		);

		if (order.invoiceData) {
			return (
				<div className="flex">
					{Price}&nbsp;(
					<InvoiceProviderLink invoiceDataFragmentRef={order.invoiceData} />)
				</div>
			);
		} else {
			return Price;
		}
	} else {
		return <div>unbezahlt</div>;
	}
};
