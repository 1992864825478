/**
 * @generated SignedSource<<4d4347eec6d33133e1c9aecdef3de7eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type Permission = "Email_Templates" | "Files_Delete" | "Management_Management" | "Sales_Sales" | "System_Owner" | "System_Root" | "Trainer_Trainer" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SingleUserForm_UserFragment$data = {
  readonly activated: boolean;
  readonly email: string;
  readonly extension: {
    readonly isBlocked?: boolean;
  };
  readonly groupAssociations: ReadonlyArray<{
    readonly group: {
      readonly id: string;
      readonly name: string;
      readonly permissions: ReadonlyArray<Permission>;
    } | null;
  }>;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeActivatedForm_UserFragment" | "ChangeEmailForm_UserFragment" | "ChangePasswordForm_UserFragment" | "ChangeUserExtensionForm_UserFragment">;
  readonly " $fragmentType": "SingleUserForm_UserFragment";
};
export type SingleUserForm_UserFragment$key = {
  readonly " $data"?: SingleUserForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SingleUserForm_UserFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SingleUserForm_UserFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "extension",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "isBlocked",
              "storageKey": null
            }
          ],
          "type": "UserExtensionImpl",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserGroupAssociation",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Group",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "permissions",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeEmailForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeActivatedForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangePasswordForm_UserFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChangeUserExtensionForm_UserFragment"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();

(node as any).hash = "ba2630bf08239bf9bf26925f7b71fbfc";

export default node;
