import {useMatch, useNavigate} from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import {EditLocationScreen_LocationFragment$key} from "../../__generated__/EditLocationScreen_LocationFragment.graphql";
import {EditLocationScreen_CreateLocationMutation} from "../../__generated__/EditLocationScreen_CreateLocationMutation.graphql";
import {EditLocationScreen_UpdateLocationMutation} from "../../__generated__/EditLocationScreen_UpdateLocationMutation.graphql";
import {EDIT_LOCATION_PATH} from "../../routes/LocationRoutes";
import {EditLocationScreen_Query} from "../../__generated__/EditLocationScreen_Query.graphql";
import {EditLocationForm} from "../../components/relay/forms/EditLocationForm";

const QUERY = graphql`
    query EditLocationScreen_Query($id: ID!, $skip: Boolean!) {
        node(id: $id) @skip(if: $skip){
            ... on Location {
                ...EditLocationScreen_LocationFragment
            }
        }
    }
`

const LOCATION_FRAGMENT = graphql`
    fragment EditLocationScreen_LocationFragment on Location {
        id
        name
        hotel
    }
`

const CREATE_LOCATION_MUTATION = graphql`
    mutation EditLocationScreen_CreateLocationMutation($input: CreateLocationInput!, $connections: [ID!]!) {
        Admin {
            Location {
                createLocation(input: $input) {
                    data @appendEdge(connections: $connections){
                        node {
                            ...EditLocationScreen_LocationFragment
                        }
                    }
                }
            }
        }
    }
`

const UPDATE_LOCATION_MUTATION = graphql`
    mutation EditLocationScreen_UpdateLocationMutation($input: UpdateLocationInput!) {
        Admin {
            Location {
                updateLocation(input: $input) {
                    data{
                        node {
                            ...EditLocationScreen_LocationFragment
                        }
                    }
                }
            }
        }
    }
`

export const EditLocationScreen = () => {
    const navigate = useNavigate()
    const {params: {locationId}} = useMatch(EDIT_LOCATION_PATH)!

    const query = useLazyLoadQuery<EditLocationScreen_Query>(QUERY, {
        id: locationId!,
        skip: !locationId || locationId === "new"
    })

    const location = useFragment<EditLocationScreen_LocationFragment$key>(LOCATION_FRAGMENT, query.node || null)
    const [createLocation, isCreatingTag] = useMutation<EditLocationScreen_CreateLocationMutation>(CREATE_LOCATION_MUTATION)
    const [updateLocation, isUpdatingTag] = useMutation<EditLocationScreen_UpdateLocationMutation>(UPDATE_LOCATION_MUTATION)

    return <>
        <h1 className="mb-3">Bearbeite Standort</h1>

        <EditLocationForm
            initialValues={{
                name: location?.name,
                hotel: location?.hotel
            }}
            loading={isCreatingTag || isUpdatingTag}
            onSubmit={values => {
                const data = {
                    name: values.name!,
                    hotel: values.hotel!
                }
                if (location) {
                    updateLocation({
                        variables: {
                            input: {
                                id: location.id,
                                data: data
                            }
                        },
                        onCompleted: _ => navigate("/locations")
                    })
                } else {
                    createLocation({
                        variables: {
                            input: {
                                data: data
                            },
                            connections: [
                                ConnectionHandler.getConnectionID("client:root:Admin:Location", "LocationsTable_Locations")
                            ]
                        },
                        onCompleted: _ => navigate("/locations")
                    })
                }
            }}/>
    </>
}
