import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderHistoryCreatedAtColumn_OrderHistoryFragment$key } from "../../../../../__generated__/OrderHistoryCreatedAtColumn_OrderHistoryFragment.graphql";
import { DateTimeDisplay } from "../../../../../billing-admin-impl/components/ui/DateTimeDisplay";

const ORDER_HISTORY_FRAGMENT = graphql`
	fragment OrderHistoryCreatedAtColumn_OrderHistoryFragment on OrderHistory {
		createdAt
	}
`;

interface OwnProps {
	orderHistoryFragmentRef: OrderHistoryCreatedAtColumn_OrderHistoryFragment$key;
}

export const OrderHistoryCreatedAtColumn = ({ orderHistoryFragmentRef }: OwnProps) => {
	const orderHistory = useFragment<OrderHistoryCreatedAtColumn_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	return <DateTimeDisplay value={orderHistory.createdAt} hideTimezone />;
};
