/**
 * @generated SignedSource<<d0d34b4647a4befcbad97ed59bd66d44>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseTemplateScreen_CourseTemplateFragment$data = {
  readonly amountOfRebookedParticipant: number;
  readonly amountOfRegulatedParticipant: number;
  readonly createdAt: string;
  readonly description: string | null;
  readonly iconRef: string | null;
  readonly id: string;
  readonly modules: ReadonlyArray<{
    readonly id: string;
    readonly internalTitle: string;
  }>;
  readonly name: string;
  readonly price: {
    readonly grossPrice: number;
    readonly netPrice: number;
    readonly taxRatePercentage: number;
  };
  readonly " $fragmentSpreads": FragmentRefs<"EditCourseTemplateForm_CourseTemplateFragment" | "TemplateHeader_CourseTemplateFragment">;
  readonly " $fragmentType": "CourseTemplateScreen_CourseTemplateFragment";
};
export type CourseTemplateScreen_CourseTemplateFragment$key = {
  readonly " $data"?: CourseTemplateScreen_CourseTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseTemplateScreen_CourseTemplateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseTemplateScreen_CourseTemplateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "iconRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountOfRebookedParticipant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountOfRegulatedParticipant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxRatePercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleTemplate",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditCourseTemplateForm_CourseTemplateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateHeader_CourseTemplateFragment"
    }
  ],
  "type": "CourseTemplate",
  "abstractKey": null
};
})();

(node as any).hash = "ba75cc4c17ac1126f5c95925cc17017a";

export default node;
