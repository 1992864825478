import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {CourseTemplateScreen} from "../screens/courseTemplate/CourseTemplateScreen";
import {ModuleTemplateScreen} from "../screens/courseTemplate/ModuleTemplateScreen";
import {TrainingDayTemplateScreen} from "../screens/courseTemplate/TrainingDayTemplateScreen";
import {CourseTemplatesScreen} from "../screens/courseTemplate/CourseTemplatesScreen";

export const EDIT_COURSE_TEMPLATE_PATH = "/course-templates/:courseTemplateId/edit"
export const EDIT_MODULE_TEMPLATE_PATH = "/course-templates/:courseTemplateId/:moduleTemplateId/edit"
export const EDIT_TRAINING_DAY_TEMPLATE_PATH = "/course-templates/:courseTemplateId/:moduleTemplateId/:trainingDayTemplateId/edit"

export const CourseTemplateRoutes: SecureRouteDefinition[] = [
    {
        path: "/course-templates",
        element: <CourseTemplatesScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_COURSE_TEMPLATE_PATH,
        element: <CourseTemplateScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_MODULE_TEMPLATE_PATH,
        element: <ModuleTemplateScreen/>,
        requiredPermissions: ["System_Owner"]
    },
    {
        path: EDIT_TRAINING_DAY_TEMPLATE_PATH,
        element: <TrainingDayTemplateScreen/>,
        requiredPermissions: ["System_Owner"]
    },
]
