import moment from "moment-timezone";

interface OwnProps {
    value: string
    hideTimezone?: boolean
    showTime?: boolean
}

export const DateTimeDisplay = ({value, hideTimezone, showTime}: OwnProps) => {
    return <div>
        {value ? (
            <>
                <div>{moment(value.replace("[UTC]", "")).tz(moment.tz.guess()).format(showTime ? "DD.MM.YYYY HH:mm": "DD.MM.YYYY")}</div>
                {!hideTimezone && <small>{moment.tz.guess()}</small>}
            </>
        ) : null
        }
    </div>
}
