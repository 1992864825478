import React from 'react'
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {EditTrainingDayForm, FormState} from "../../components/relay/forms/EditTrainingDayForm";
import {CourseHeader} from "../../components/relay/header/CourseHeader";
import {TrainingDayScreen_TrainingDayFragment$key} from "../../__generated__/TrainingDayScreen_TrainingDayFragment.graphql";
import {TrainingDayScreen_UpdateMutation} from "../../__generated__/TrainingDayScreen_UpdateMutation.graphql";
import {TrainingDayScreen_Query} from "../../__generated__/TrainingDayScreen_Query.graphql";
import {toast} from "react-toastify";
import {EDIT_TRAINING_DAY_PATH} from "../../routes/CourseRoutes";
import {convertToDate, convertToString} from "../../utils/DateTimeUtils";

const QUERY = graphql`
    query TrainingDayScreen_Query($id: ID!) {
        node(id: $id) {
            ... on TrainingDay {
                ...TrainingDayScreen_TrainingDayFragment
            }
        }
    }
`

const TRAINING_DAY_FRAGMENT = graphql`
    fragment TrainingDayScreen_TrainingDayFragment on TrainingDay {
        id
        internalTitle
        internalInformation
        date
        requiredAmountOfPreviousTrainingDays
        additionalInformation
        ...CourseHeader_TrainingDayFragment
    }
`;

const UPDATE_MUTATION = graphql`
    mutation TrainingDayScreen_UpdateMutation($input: EditTrainingDayInput!) {
        Admin {
            Course {
                editTrainingDay(input: $input){
                    trainingDay {
                        node {
                            id
                            ...CourseHeader_TrainingDayFragment
                            ...TrainingDayScreen_TrainingDayFragment
                        }
                    }
                }
            }
        }
    }
`;

export const TrainingDayScreen = () => {
    const {params: {courseId, moduleId, trainingDayId}} = useMatch(EDIT_TRAINING_DAY_PATH)!

    const query = useLazyLoadQuery<TrainingDayScreen_Query>(QUERY, {
        id: trainingDayId!,
    }, {fetchPolicy: "network-only"})

    const trainingDay = useFragment<TrainingDayScreen_TrainingDayFragment$key>(TRAINING_DAY_FRAGMENT, query.node || null)

    const [updateModule, isUpdatingModule] = useMutation<TrainingDayScreen_UpdateMutation>(UPDATE_MUTATION)

    return <div>
        <CourseHeader courseId={courseId} moduleId={moduleId} trainingDayFragment={trainingDay}/>
        <h1>{trainingDay?.internalTitle} bearbeiten</h1>
        <EditTrainingDayForm
            initialValues={{
                id: trainingDay?.id || null,
                internalTitle: trainingDay?.internalTitle || "",
                date: trainingDay?.date ?  convertToDate(trainingDay?.date) : new Date(),
                internalInformation: trainingDay?.internalInformation,
                additionalInformation: trainingDay?.additionalInformation,
                requiredAmountOfPreviousTrainingDays: trainingDay?.requiredAmountOfPreviousTrainingDays || 0
            }}
            loading={isUpdatingModule}
            onSubmit={(values: FormState) => {
                if (trainingDay && moduleId) {
                    updateModule(
                        {
                            variables: {
                                input: {
                                    id: trainingDay.id,
                                    moduleId: moduleId,
                                    internalTitle: values.internalTitle,
                                    requiredAmountOfPreviousTrainingDays: values.requiredAmountOfPreviousTrainingDays,
                                    date: convertToString(values.date),
                                    internalInformation: !values.internalInformation || values.internalInformation.length === 0 ? null : values.internalInformation,
                                    additionalInformation: !values.additionalInformation || values.additionalInformation.length === 0 ? null : values.additionalInformation
                                }
                            },
                            onCompleted: () => {
                                toast.success("Ausbildungstag erfolgreich gespeichert")
                            },
                            onError: error => {
                                toast.error(error.message)
                            }
                        }
                    )
                }
            }}
        />
    </div>

}
