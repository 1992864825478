import React, {useState} from 'react';
import {AttendanceStatus} from "../../__generated__/CourseStatesTable_CourseStateFragment.graphql";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {AttendanceStatusI18n} from "../../i18n/attendanceStatus.i18n,";

interface OwnProps {
    attendanceStatus: AttendanceStatus

    onSave: (value: AttendanceStatus) => void

    disabled?: boolean
}

export const AttendanceStatusInputWithSave = ({attendanceStatus, onSave, disabled}: OwnProps) => {
    const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState<AttendanceStatus>(attendanceStatus)

    const items: { label: string, value: AttendanceStatus }[] = [
        {label: AttendanceStatusI18n["Unknown"], value: 'Unknown'},
        {label: AttendanceStatusI18n["WaitingList"], value: 'WaitingList'},
        {label: AttendanceStatusI18n["CourseCanceledByAdmin"], value: 'CourseCanceledByAdmin'},
        {label: AttendanceStatusI18n["CanceledByUser"], value: 'CanceledByUser'},
        {label: AttendanceStatusI18n["NotPresent"], value: 'NotPresent'},
        {label: AttendanceStatusI18n["Absent"], value: 'Absent'},
        {label: AttendanceStatusI18n["TookPart"], value: 'TookPart'},
        {label: AttendanceStatusI18n["CanceledTooLate"], value: 'CanceledTooLate'},
    ];


    return <div className="flex">
        <div className="col">
            <Dropdown
                id={selectedAttendanceStatus}
                name={selectedAttendanceStatus}
                value={selectedAttendanceStatus}
                options={items}
                className=" w-full"
                onChange={(value) => setSelectedAttendanceStatus(value.value)}/>
        </div>
        <div className="col-10 md:col-1">
            <Button
                type="button"
                loading={disabled}
                disabled={disabled}
                onClick={() => onSave(selectedAttendanceStatus)}
                className="mr-2" icon={"pi pi-save"}/>
        </div>
    </div>
}
