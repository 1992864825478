import React, {useState} from 'react';
import {Button} from "primereact/button";
import {InputTextarea} from "primereact/inputtextarea";

interface OwnProps {
    value: string;
    onSave: (input: string | undefined) => void;

    disabled?: boolean;
}

export const StringInputWithSave = ({value, onSave, disabled}: OwnProps) => {
    const [text, setText] = useState<string | undefined>(value)

    return (
        <div className="flex">
            <div className="">
                <InputTextarea
                    id={text}
                    name={text}
                    value={text}
                    cols={30}
                    autoResize
                    onChange={e => setText(e.target.value)}
                    disabled={disabled}/>
            </div>
            <div className="flex align-items-center col">
                <Button
                    type="button"
                    loading={disabled}
                    disabled={disabled}
                    onClick={() => onSave(text)}
                    className="mr-2" icon={"pi pi-save"}/>
            </div>
        </div>
    )
}
