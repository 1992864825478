/**
 * @generated SignedSource<<86daf7db8186ef13a03cd423177b1aa1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderCreationUpdatePart_OrderCreationUpdateFragment$data = {
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "OrderCreationUpdatePart_OrderCreationUpdateFragment";
};
export type OrderCreationUpdatePart_OrderCreationUpdateFragment$key = {
  readonly " $data"?: OrderCreationUpdatePart_OrderCreationUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderCreationUpdatePart_OrderCreationUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderCreationUpdatePart_OrderCreationUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "OrderCreationUpdate",
  "abstractKey": null
};

(node as any).hash = "4ed71c260779e72ecf3f0bc3e21425f4";

export default node;
