import React from "react";
import graphql from "babel-plugin-relay/macro";
import { InvoiceProviderLink_InvoiceDataFragment$key } from "../../../__generated__/InvoiceProviderLink_InvoiceDataFragment.graphql";
import { useFragment } from "react-relay";

const INVOICE_DATA_FRAGMENT = graphql`
	fragment InvoiceProviderLink_InvoiceDataFragment on InvoiceData {
		invoiceId
		invoiceNumber
	}
`;

interface OwnProps {
	invoiceDataFragmentRef: InvoiceProviderLink_InvoiceDataFragment$key;
}

export const InvoiceProviderLink = ({ invoiceDataFragmentRef }: OwnProps) => {
	const invoiceData = useFragment<InvoiceProviderLink_InvoiceDataFragment$key>(
		INVOICE_DATA_FRAGMENT,
		invoiceDataFragmentRef,
	);

	return (
		<a
			target="_blank"
			rel="noopener noreferrer"
			href={`https://app.lexoffice.de/permalink/invoices/view/${invoiceData.invoiceId}`}
		>
			{invoiceData.invoiceNumber}
		</a>
	);
};
