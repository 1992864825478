/**
 * @generated SignedSource<<90c3838826cc3364c82cb59ca85ba6ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DiscountActionCalcTypeKind = "euro" | "percentage" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ActionCalcTypeColumn_DiscountActionFragment$data = {
  readonly calcType: {
    readonly euro?: number;
    readonly kind?: DiscountActionCalcTypeKind;
    readonly percentage?: number;
  };
  readonly " $fragmentType": "ActionCalcTypeColumn_DiscountActionFragment";
};
export type ActionCalcTypeColumn_DiscountActionFragment$key = {
  readonly " $data"?: ActionCalcTypeColumn_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActionCalcTypeColumn_DiscountActionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActionCalcTypeColumn_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "calcType",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "DiscountActionPercentageCalcType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "euro",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "DiscountActionEuroCalcType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
})();

(node as any).hash = "bbe687e499d2673883075b498557880e";

export default node;
