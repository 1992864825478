/**
 * @generated SignedSource<<817af1039f0acb9c4d7790c58055ec5b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateUserStatesForTrainingDayInput = {
  clientMutationId?: string | null;
  courseStateId: string;
  publishedModuleId: string;
  publishedTrainingDayId: string;
  userIds: ReadonlyArray<string>;
};
export type EditParticipantsForm_UpdateUserStateMutation$variables = {
  input: UpdateUserStatesForTrainingDayInput;
};
export type EditParticipantsForm_UpdateUserStateMutation$data = {
  readonly Admin: {
    readonly States: {
      readonly updateUserStatesForTrainingDay: {
        readonly courseState: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditParticipantsForm_CourseStateFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditParticipantsForm_UpdateUserStateMutation = {
  response: EditParticipantsForm_UpdateUserStateMutation$data;
  variables: EditParticipantsForm_UpdateUserStateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditParticipantsForm_UpdateUserStateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateUserStatesForTrainingDayPayload",
                "kind": "LinkedField",
                "name": "updateUserStatesForTrainingDay",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditParticipantsForm_CourseStateFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditParticipantsForm_UpdateUserStateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "StatesAdminMutationSchema",
            "kind": "LinkedField",
            "name": "States",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateUserStatesForTrainingDayPayload",
                "kind": "LinkedField",
                "name": "updateUserStatesForTrainingDay",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CourseStatesEdge",
                    "kind": "LinkedField",
                    "name": "courseState",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CourseState",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PublishedCourse",
                            "kind": "LinkedField",
                            "name": "publishedCourse",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "internalInformation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "PublishedModuleAdmin",
                                "kind": "LinkedField",
                                "name": "modulesAdmin",
                                "plural": true,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "startDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endDate",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PublishedTrainingDay",
                                    "kind": "LinkedField",
                                    "name": "trainingDays",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "internalTitle",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "date",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountOfRegulatedParticipant",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountOfRebookedParticipant",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ModuleState",
                            "kind": "LinkedField",
                            "name": "moduleStates",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "moduleId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "TrainingDayState",
                                "kind": "LinkedField",
                                "name": "trainingDayStates",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "trainingDayId",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AttendanceTypeAdmin",
                                    "kind": "LinkedField",
                                    "name": "attendanceListAdminNormal",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "user",
                                        "plural": false,
                                        "selections": [
                                          (v2/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "email",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "extension",
                                            "plural": false,
                                            "selections": [
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "__typename",
                                                "storageKey": null
                                              },
                                              {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "isBlocked",
                                                    "storageKey": null
                                                  }
                                                ],
                                                "type": "UserExtensionImpl",
                                                "abstractKey": null
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceType",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "attendanceStatus",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "foodCreditState",
                                        "storageKey": null
                                      },
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountOfRebookedParticipants",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountOfRegularParticipants",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "amountOfWaitingListParticipants",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a948ad509117785ff989b1ba36196a96",
    "id": null,
    "metadata": {},
    "name": "EditParticipantsForm_UpdateUserStateMutation",
    "operationKind": "mutation",
    "text": "mutation EditParticipantsForm_UpdateUserStateMutation(\n  $input: UpdateUserStatesForTrainingDayInput!\n) {\n  Admin {\n    States {\n      updateUserStatesForTrainingDay(input: $input) {\n        courseState {\n          node {\n            ...EditParticipantsForm_CourseStateFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment AddParticipantButton_CourseStateFragment on CourseState {\n  ...AddParticipantToCourseStateForm_CourseStateFragment\n  ...AddParticipantToModuleForm_CourseStateFragment\n}\n\nfragment AddParticipantToCourseStateForm_CourseStateFragment on CourseState {\n  id\n}\n\nfragment AddParticipantToModuleForm_CourseStateFragment on CourseState {\n  id\n}\n\nfragment EditParticipantsForm_CourseStateFragment on CourseState {\n  id\n  publishedCourse {\n    internalInformation\n    modulesAdmin {\n      id\n      title\n      startDate\n      endDate\n      trainingDays {\n        internalTitle\n        id\n        date\n      }\n    }\n    id\n  }\n  moduleStates {\n    moduleId\n    trainingDayStates {\n      trainingDayId\n      attendanceListAdminNormal {\n        user {\n          id\n          email\n          name\n          extension {\n            __typename\n            ... on UserExtensionImpl {\n              isBlocked\n            }\n          }\n        }\n        attendanceType\n        attendanceStatus\n        foodCreditState\n        id\n      }\n      id\n    }\n    id\n  }\n  ...AddParticipantButton_CourseStateFragment\n  ...ParticipantsInformation_CourseStateFragment\n}\n\nfragment ParticipantsInformation_CourseStateFragment on CourseState {\n  moduleStates {\n    moduleId\n    amountOfRebookedParticipants\n    amountOfRegularParticipants\n    amountOfWaitingListParticipants\n    id\n  }\n  publishedCourse {\n    amountOfRegulatedParticipant\n    amountOfRebookedParticipant\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "eb944119775d26f8748099126ecde40f";

export default node;
