import React, { FocusEventHandler } from "react";
import {InputNumber} from "primereact/inputnumber";

interface OwnProps {
    netValue: number
    grossValue: number
    taxRate: number
    onBlur?: FocusEventHandler<any>;
    updatePrice: (netPrice: number, grossPrice: number) => void;
}

export const TaxPriceInput = ({netValue, grossValue, taxRate, onBlur, updatePrice}: OwnProps) => {
    return <div className="flex flex-row w-full">
        <label className="flex flex-column w-full pr-2">
            <div>Netto</div>
            <InputNumber
                value={netValue}
                mode="currency"
                currency="EUR"
                locale={"de-DE"}
                onChange={event => {
                    const priceValue = event.value;
                    if(priceValue) updatePrice(priceValue, toGrossPrice(priceValue, taxRate))
                }}/>
        </label>
        <label className="flex flex-column w-full">
            <div>Brutto</div>
            <InputNumber
                value={grossValue}
                locale={"de-DE"}
                mode="currency"
                currency="EUR"
                onChange={event => {
                    const priceValue = event.value
                    if(priceValue) updatePrice(toNetPrice(priceValue, taxRate), priceValue)
                }}/>
        </label>
    </div>
}

const toNetPrice = (grossPrice: number, taxRate: number): number => {
    const taxMultiplier = (100 + taxRate) / 100.0;
    return roundWithPrecision(grossPrice / taxMultiplier, 2)
}

const toGrossPrice = (netPrice: number, taxRate: number): number => {
    const taxMultiplier = (100 + taxRate) / 100.0;
    return roundWithPrecision(netPrice * taxMultiplier, 2)
}

function roundWithPrecision(value: number, decimalPlaces: number): number {
    const power = Math.pow(10, decimalPlaces)
    return Math.ceil(value * power) / power;
}
