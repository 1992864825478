/**
 * @generated SignedSource<<20746bdb0c136a500fcbd4815cbc7fe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditFaqScreen_FaqFragment$data = {
  readonly answer: string;
  readonly id: string;
  readonly question: string;
  readonly " $fragmentType": "EditFaqScreen_FaqFragment";
};
export type EditFaqScreen_FaqFragment$key = {
  readonly " $data"?: EditFaqScreen_FaqFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditFaqScreen_FaqFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditFaqScreen_FaqFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "question",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answer",
      "storageKey": null
    }
  ],
  "type": "Faq",
  "abstractKey": null
};

(node as any).hash = "4d7d80e6c71b9d4251f04a3de515a06a";

export default node;
