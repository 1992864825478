/**
 * @generated SignedSource<<c51951c4bfdf5d8bab6b77e7e033a636>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddParticipantToCourseStateForm_CourseStateFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AddParticipantToCourseStateForm_CourseStateFragment";
};
export type AddParticipantToCourseStateForm_CourseStateFragment$key = {
  readonly " $data"?: AddParticipantToCourseStateForm_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddParticipantToCourseStateForm_CourseStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddParticipantToCourseStateForm_CourseStateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};

(node as any).hash = "422154fc391009d7d264a45f5c615363";

export default node;
