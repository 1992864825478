import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {useMutation, usePaginationFragment} from "react-relay";
import {useNavigate} from "react-router-dom";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {PublishedCourseTable_Refetch} from "../../../__generated__/PublishedCourseTable_Refetch.graphql";
import {
    PublishedCourseTable_DeletePublishedCourseMutation
} from "../../../__generated__/PublishedCourseTable_DeletePublishedCourseMutation.graphql";
import React, {useContext, useEffect, useState} from "react";
import {toast} from "react-toastify";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";
import {generateCartLink} from "../../../utils/course-template-utils";
import {PublishedCourseSearchContext, PublishedCoursesFilters} from "../filter/container/PublishedCourseSearchContainer";
import {useDebounceFilters} from "../../../hooks/useDebounceFilter";
import {CreateCSVButton} from "../buttons/CreateCSVButton";
import {useTypedSelector} from "../../../Store";
import {selectCurrentUser} from "../../../slices/AuthSlice";
import {
    PublishedCourseTable_PublishedCoursesFragment$key
} from "../../../__generated__/PublishedCourseTable_PublishedCoursesFragment.graphql";
import {PublishedCourseFilter} from "../filter/PublishedCourseFilter";
import {ProgressBar} from "primereact/progressbar";


const PUBLISHED_COURSES_FRAGMENT = graphql`
    fragment PublishedCourseTable_PublishedCoursesFragment on Query @refetchable(queryName: "PublishedCourseTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
        filterByNameOrSecondName: {type: "String"},
        filterByCourseLocation: {type: "ID"},
        filterByModuleLocation: {type: "ID"},
        filterByTrainers: {type: "[ID!]"},
        filterByStartDate: {type: "ZonedDateTIme"},
        filterByRebookedFull: {type: "Boolean"},
        filterByRegularFull: {type: "Boolean"}
    ){
        Admin {
            Course {
                publishedCourses(
                    first: $first,
                    after: $after,
                    filterByNameOrSecondName: $filterByNameOrSecondName,
                    filterByRegularFull: $filterByRegularFull,
                    filterByRebookedFull: $filterByRebookedFull,
                    filterByCourseLocation: $filterByCourseLocation,
                    filterByModuleLocation: $filterByModuleLocation,
                    filterByTrainers: $filterByTrainers,
                    filterByStartDate: $filterByStartDate)@connection(key: "PublishedCourseTable_publishedCourses")
                {
                    __id
                    edges {
                        node {
                            id
                            name
                            secondName
                            productRef
                            startDate
                            amountOfRegulatedParticipant
                            amountOfRebookedParticipant
                        }
                    }
                }
            }
        }
    }
`

const DELETE_MUTATION = graphql`
    mutation PublishedCourseTable_DeletePublishedCourseMutation($input: DeletePublishedCourseInput!, $connections: [ID!]!){
        Admin {
            Course {
                deletePublishedCourse(input: $input){
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

interface OwnProps {
    courseStateFragmentRef: PublishedCourseTable_PublishedCoursesFragment$key
    setConnectionId: (connectionId: string) => void
}

interface PublishedCourse {
    id: string,
    name: string,
    startDate: string | null,
    secondName: string,
    productRef: string,
    rebookedFull: boolean
    regulatedFull: boolean,
    amountOfRegulatedParticipant: number,
    amountOfRebookedParticipant: number,
}

export const PublishedCourseTable = ({courseStateFragmentRef, setConnectionId}: OwnProps) => {
    const navigate = useNavigate()
    const {filters} = useContext(PublishedCourseSearchContext)
    const {showDialog, dialogComponent} = useDialogLogic();
    const [selectedCourseIds, setSelectedCourseIds] = useState<PublishedCourse[]>()
    const currentUser = useTypedSelector(selectCurrentUser)

    const {
        data,
        refetch
    } = usePaginationFragment<PublishedCourseTable_Refetch, PublishedCourseTable_PublishedCoursesFragment$key>(PUBLISHED_COURSES_FRAGMENT, courseStateFragmentRef)

    const [deletePublishedCourse, isDeleting] = useMutation<PublishedCourseTable_DeletePublishedCourseMutation>(DELETE_MUTATION)

    useDebounceFilters<PublishedCoursesFilters>(filters, refetch)

    const publishedCourses = data.Admin.Course.publishedCourses.edges?.filter(l => !!l).map(l => l?.node)
    const connectionId = data.Admin.Course.publishedCourses.__id

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => setConnectionId(connectionId), [connectionId])

    return <>
        {dialogComponent}
        {selectedCourseIds && selectedCourseIds.length > 0 ?
            <CreateCSVButton publishedCourseIds={selectedCourseIds?.map((row: PublishedCourse) => row.id) || []}/> :
            null}
        <DataTable className="mb-3"
                   selection={selectedCourseIds}
                   header={<PublishedCourseFilter/>}
                   onSelectionChange={e => setSelectedCourseIds(e.value)}
                   value={publishedCourses as any[]}>
            <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
            <Column header="Name" body={(row: PublishedCourse) =>
                <div> {row.name}</div>}/>
            <Column header="Zweitname" body={(row: PublishedCourse) =>
                <div> {row.secondName}</div>}/>
            <Column header="Startdatum" body={(row: PublishedCourse) => row.startDate ?
                <DateTimeDisplay value={row.startDate} hideTimezone={true}/> : null}/>
            <Column header="Aktionen" style={{width: "200px"}} body={row => {
                return <div>
                    <Button
                        className="mr-2"
                        tooltip="Link in die Zwischenablage kopieren"
                        onClick={() => {
                            if (currentUser && row.productRef) {
                                navigator
                                    .clipboard
                                    .writeText(process.env.REACT_APP_WEBAPP_URL + generateCartLink(currentUser.user.id, btoa("product:" + row.productRef)))
                                    .then(_ => toast.success("Link in die Zwischenablage kopiert"))
                            } else {
                                toast.error("Es gab einen Fehler beim kopieren des Links. Sollte nach erneutem einloggen immer noch der Fehler bestehen, wende dich an die ITler deines Vertrauens")
                            }
                        }} icon={"pi pi-shopping-cart"}/>
                    <Button
                        className="mr-2"
                        tooltip="Details"
                        onClick={() => {
                            navigate(`/courses/${row.id}/overview`);
                        }} icon={"pi pi-search"}/>
                    <Button
                        disabled={isDeleting}
                        tooltip="Löschen"
                        onClick={() => {
                            showDialog({
                                title: "Lehrgang löschen",
                                content: "Möchten Sie diesen Veröffentlichten Lehrgang wirklich löschen? Das kann nicht rückgängig gemacht werden.",
                                dialogCallback: result => {
                                    if (result === "Accept") {
                                        deletePublishedCourse({
                                            variables: {
                                                input: {
                                                    ids: [row.id]
                                                },
                                                connections: [connectionId]
                                            }
                                        })
                                    }
                                }
                            })
                        }} icon={"pi pi-trash"}/>
                </div>
            }}/>
        </DataTable>
        {isDeleting ?
            <div className="mt-5 mb-5">
                <ProgressBar mode="indeterminate" />
            </div> : null
        }
    </>
}
