import React from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment} from "react-relay";
import {Card} from "primereact/card";
import {Splitter, SplitterPanel} from "primereact/splitter";
import {HistoryItem_EntryFragment$key} from "../../__generated__/HistoryItem_EntryFragment.graphql";
import styled from "styled-components";
import {HistoryEventI18n} from "../../i18n/historyEvent.i18n";

const ENTRY_FRAGMENT = graphql`
    fragment HistoryItem_EntryFragment on Entry {
        fromUser {
            email
        }
        participantsUser {
            email
        }
        sourceCourseState {
            publishedCourse {
                name
                secondName
                modulesAdmin {
                    id
                    title
                }
            }
        }
        destinationCourseState {
            publishedCourse {
                name
                secondName
                modulesAdmin {
                    id
                    title
                }
            }
        }
        sourceModuleId
        destinationModuleId
        eventType
    }
`

interface OwnProps {
    entryFragmentRef: HistoryItem_EntryFragment$key
}

export const HistoryItem = ({entryFragmentRef}: OwnProps) => {
    const history = useFragment<HistoryItem_EntryFragment$key>(ENTRY_FRAGMENT, entryFragmentRef)

    const findSourceModule = (moduleId: string) => history.sourceCourseState?.publishedCourse.modulesAdmin.find(ma => ma.id === moduleId)?.title
    const findDestinationModule = (moduleId: string) => history.destinationCourseState?.publishedCourse.modulesAdmin.find(ma => ma.id === moduleId)?.title

    return (
        <Card className="w-full mb-4">
            <Grid>
                <div>von Benutzer:</div>
                <div>{history.fromUser?.email}</div>
                <div>Teilnehmer:</div>
                <div>{history.participantsUser?.email}</div>
                <div>Typ:</div>
                <div>{HistoryEventI18n[history.eventType]}</div>
            </Grid>
            <Splitter>
                <SplitterPanel className="p-2">
                    <h3>Von</h3>
                    <Grid>
                        <div>Kurs:</div>
                        <div>{history.sourceCourseState?.publishedCourse.name} - {history.sourceCourseState?.publishedCourse.secondName}</div>
                        {history.sourceModuleId ? (
                            <>
                                <div>Modul:</div>
                                <div>{findSourceModule(history.sourceModuleId)}</div>
                            </>
                        ) : null}
                    </Grid>
                </SplitterPanel>
                <SplitterPanel className="p-2 flex flex-column">
                    <h3>Nach</h3>
                    <Grid>
                        <div>Kurs:</div>
                        <div>{history.destinationCourseState?.publishedCourse.name} - {history.destinationCourseState?.publishedCourse.secondName}</div>
                        {history.destinationModuleId ? (
                            <>
                                <div>Modul:</div>
                                <div>{findDestinationModule(history.destinationModuleId)}</div>
                            </>
                        ) : null}
                    </Grid>
                </SplitterPanel>
            </Splitter>
        </Card>
    )
}

const Grid = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: 7rem 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
`
