import { useLazyLoadQuery } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import {TrainersTable} from "../../components/relay/tables/TrainersTable";
import {TrainersScreen_TrainerQuery} from "../../__generated__/TrainersScreen_TrainerQuery.graphql";

const QUERY = graphql`
    query TrainersScreen_TrainerQuery {
        ...TrainersTable_Trainers
    }
`

export const TrainersScreen = () => {
    const trainers = useLazyLoadQuery<TrainersScreen_TrainerQuery>(QUERY, {}, {fetchPolicy: "network-only"})

    return <div>
        <h1 className="mb-3">Ausbilder</h1>
        <TrainersTable trainersFragment={trainers}/>
    </div>

}
