/**
 * @generated SignedSource<<d4e705169c8b80947a32cf656e98666a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateFaqInput = {
  clientMutationId?: string | null;
  data: FaqInput;
};
export type FaqInput = {
  answer: string;
  clientMutationId?: string | null;
  question: string;
};
export type EditFaqScreen_CreateFaqMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateFaqInput;
};
export type EditFaqScreen_CreateFaqMutation$data = {
  readonly Admin: {
    readonly Faq: {
      readonly createFaq: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditFaqScreen_FaqFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditFaqScreen_CreateFaqMutation = {
  response: EditFaqScreen_CreateFaqMutation$data;
  variables: EditFaqScreen_CreateFaqMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditFaqScreen_CreateFaqMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FaqAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Faq",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateFaqPayload",
                "kind": "LinkedField",
                "name": "createFaq",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FaqsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Faq",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditFaqScreen_FaqFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditFaqScreen_CreateFaqMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FaqAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Faq",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateFaqPayload",
                "kind": "LinkedField",
                "name": "createFaq",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FaqsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Faq",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "question",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "answer",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "10b96e68ff08b5cc126fd15d44795c92",
    "id": null,
    "metadata": {},
    "name": "EditFaqScreen_CreateFaqMutation",
    "operationKind": "mutation",
    "text": "mutation EditFaqScreen_CreateFaqMutation(\n  $input: CreateFaqInput!\n) {\n  Admin {\n    Faq {\n      createFaq(input: $input) {\n        data {\n          node {\n            ...EditFaqScreen_FaqFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditFaqScreen_FaqFragment on Faq {\n  id\n  question\n  answer\n}\n"
  }
};
})();

(node as any).hash = "492698d7bc3557e73769a9ae7157df3e";

export default node;
