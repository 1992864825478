import {AxiosInstance} from "axios";

export const generateCartLink = (userId: string, productId: string, discountCode?: string) => {
    return "/checkout/orders/from-cart/" + btoa(JSON.stringify({
        createdByUser: userId,
        selectedProducts: [{
            productId: productId,
            amount: 1
        }],
        selectedDiscountCodes: discountCode ? [discountCode] : []
    }))
}


export const csvDownloader = (axiosInstance: AxiosInstance, url: string, fileName: string, payload: object, onFinish?: () => void) => {
    axiosInstance.post(url, payload)
        .then((value) => {
            const url = window.URL.createObjectURL(new Blob([value.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            if (link.parentNode) {
                link.parentNode.removeChild(link);
            }

            onFinish && onFinish()
        })
}
