import {Dropdown} from "primereact/dropdown";
import { AttendanceTypeInput } from "../../__generated__/AddParticipantToCourseStateForm_AddUserToCourseStateMutation.graphql";

interface OwnProps {
    selectedStatus?: AttendanceTypeInput | null
    onSelect: (attendanceStatus: AttendanceTypeInput) => void
    placeholder?: string
    className?: string
}
export const AttendanceTypeInputDropdown = ({selectedStatus, onSelect, placeholder, className}: OwnProps) => {
    const options: { label: string, value: AttendanceTypeInput }[] = [
        {label: "Regulär", value: "Regular"},
        {label: "Umbuchend", value: "Rebooked"},
        {label: "Warteliste", value: "WaitingList"},
    ]

    return (
        <div className="m-1">
            <Dropdown className={className}
                      options={options}
                      placeholder={placeholder}
                      value={selectedStatus}
                      onChange={e => onSelect(e.value)}/>
        </div>
    )

}
