/**
 * @generated SignedSource<<7439f09e1adf5430c5f9d81ca70a0f9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "Ug" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type BusinessBillingDetailsForm_BillingDetailsFragment$data = {
  readonly city: string;
  readonly company: string;
  readonly companyDetails: string | null;
  readonly companyType: CompanyType;
  readonly country: string;
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: string;
  readonly street: string;
  readonly " $fragmentType": "BusinessBillingDetailsForm_BillingDetailsFragment";
};
export type BusinessBillingDetailsForm_BillingDetailsFragment$key = {
  readonly " $data"?: BusinessBillingDetailsForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BusinessBillingDetailsForm_BillingDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BusinessBillingDetailsForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyType",
      "storageKey": null
    }
  ],
  "type": "BusinessBillingDetails",
  "abstractKey": null
};

(node as any).hash = "56e4e7d80f0efcc5323d9996a50027c6";

export default node;
