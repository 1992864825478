import React from 'react'
import {useFormik} from "formik";
import styled from 'styled-components'
import {ValidatedField} from "../fields/ValidatedField";
import {Button} from "primereact/button";
import {ProgressSpinner} from "primereact/progressspinner";
import {Divider} from 'primereact/divider';
import {DefaultEditorComponent, DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {OrderList} from "primereact/orderlist";
import {useNavigate} from "react-router-dom";
import {graphql} from 'babel-plugin-relay/macro'
import {RemoveModuleTemplateButton} from "../buttons/RemoveModuleTemplateButton";
import {useFragment, useMutation} from "react-relay";
import {EditCourseTemplateForm_CourseTemplateFragment$key} from "../../../__generated__/EditCourseTemplateForm_CourseTemplateFragment.graphql";
import {AddModuleTemplateButton} from "../buttons/AddModuleTemplateButton";
import {FileSelectionField} from "../fields/FileSelectionField";
import * as Yup from "yup";
import {TaxPriceInput} from "../../ui/TaxPriceInput";
import {DefaultNumberComponent} from "../../ui/DefaultNumberInput";
import {EditCourseTemplateForm_ChangeModuleTemplateOrderMutation} from "../../../__generated__/EditCourseTemplateForm_ChangeModuleTemplateOrderMutation.graphql";
import {toast} from "react-toastify";

const FRAGMENT = graphql`
    fragment EditCourseTemplateForm_CourseTemplateFragment on CourseTemplate {
        id
        ...AddModuleTemplateButton_CourseTemplateFragment
        ...RemoveModuleTemplateButton_CourseTemplateFragment
    }
`

const CHANGE_MODULE_TEMPLATE_ORDER_MUTATION = graphql`
    mutation EditCourseTemplateForm_ChangeModuleTemplateOrderMutation($input: ChangeModuleTemplateOrderInput!){
        Admin {
            Template {
                changeModuleTemplateOrder(input: $input) {
                    courseTemplate {
                        node {
                            ...CourseTemplateScreen_CourseTemplateFragment
                        }
                    }
                }
            }
        }
    }
`

export interface FormState {
    id: string
    name: string
    createdAt?: string
    description?: string | null
    amountOfRegulatedParticipant: number,
    amountOfRebookedParticipant: number
    price: Price
    iconRef?: string | null
    modules: ModulesDisplay[]
}

export interface ModulesDisplay {
    id: string,
    internalTitle: string
}

export interface Price {
    netPrice: number
    grossPrice: number
    taxRatePercentage: number
}

interface OwnProps {
    loading?: boolean
    initialValues: FormState
    onSubmit: (values: FormState) => void

    courseTemplateFragmentRef: EditCourseTemplateForm_CourseTemplateFragment$key | null
}

export const EditCourseTemplateForm = ({loading, initialValues, onSubmit, courseTemplateFragmentRef}: OwnProps) => {
    const courseTemplate = useFragment<EditCourseTemplateForm_CourseTemplateFragment$key>(FRAGMENT, courseTemplateFragmentRef)
    const [changeOrder] = useMutation<EditCourseTemplateForm_ChangeModuleTemplateOrderMutation>(CHANGE_MODULE_TEMPLATE_ORDER_MUTATION)

    const navigate = useNavigate();

    const formik = useFormik<FormState>({
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name wird benötigt")
        }),
        initialValues: {
            id: initialValues.id,
            name: initialValues.name,
            amountOfRegulatedParticipant: initialValues.amountOfRegulatedParticipant,
            amountOfRebookedParticipant: initialValues.amountOfRebookedParticipant,
            createdAt: initialValues.createdAt,
            description: initialValues.description,
            price: initialValues.price,
            iconRef: initialValues.iconRef,
            modules: initialValues.modules.map(l => ({
                id: l.id,
                internalTitle: l.internalTitle
            }))
        },
        onSubmit: (values: FormState) => onSubmit(values)
    })

    if (loading) return <ProgressSpinner/>

    return <div className="position-relative">
        <FormContainer onSubmit={() => formik.handleSubmit()} className={"p-fluid"}>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="name"
                    label="Name"
                    required={true}
                    component={DefaultTextFieldComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, string>
                    name="iconRef"
                    label="Icon"
                    component={({fieldValue, updateField, fieldName}) => {
                        return <FileSelectionField
                            name={fieldName}
                            selectedFileId={fieldValue}
                            setSelectedFileId={updateField}
                            filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
                        />
                    }}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, number>
                    name="amountOfRegulatedParticipant"
                    label="Verfügbare Plätze (Geregelt)"
                    component={DefaultNumberComponent}
                    formikConfig={formik}/>
            </div>
            <div className="form-group">
                <ValidatedField<FormState, number>
                    name="amountOfRebookedParticipant"
                    label="Verfügbare Plätze (Umbuchend)"
                    component={DefaultNumberComponent}
                    formikConfig={formik}/>
            </div>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, Price>
                    name="price"
                    label="Preis"
                    required={true}
                    component={({fieldValue, updateField, fieldName}) => {
                        return <TaxPriceInput
                            taxRate={fieldValue?.taxRatePercentage || 0}
                            netValue={fieldValue?.netPrice || 0}
                            grossValue={fieldValue?.grossPrice || 0}
                            onBlur={formik.handleBlur}
                            updatePrice={(netPrice, grossPrice) => updateField(
                                {
                                    netPrice: netPrice,
                                    grossPrice: grossPrice,
                                    taxRatePercentage: 0
                                }
                            )}/>
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <WholeGridSpaceContainer>
                <ValidatedField<FormState, string>
                    name="description"
                    label="Beschreibung"
                    component={DefaultEditorComponent}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
            <Button type={"button"} onClick={() => formik.handleSubmit()}>Lehrgangsvorlage Speichern</Button>
            <WholeGridSpaceContainer>
                <Divider layout={"horizontal"} children={<b>Module</b>}/>
                <ValidatedField<FormState, ModulesDisplay[]>
                    name="modules"
                    required={true}
                    component={({fieldValue, updateField}) => {
                        return (
                            <div className="flex flex-column">
                                <AddModuleTemplateButton courseTemplateFragmentRef={courseTemplate}/>
                                <OrderList
                                    dragdrop={false}
                                    onChange={e => {
                                        if (courseTemplate && e.value.length > 0 && !e.value.some((l: any) => l === undefined)) {
                                            changeOrder({
                                                variables: {
                                                    input: {
                                                        courseTemplateId: courseTemplate?.id,
                                                        moduleRefs: e.value.map((l: ModulesDisplay) => l.id)
                                                    }
                                                },
                                                onCompleted: () => {
                                                    toast.success("Reihenfolge erfolgreich gespeichert")
                                                }
                                            })
                                        }
                                    }}
                                    itemTemplate={(item) => {
                                        return <div className="flex align-items-center " onDoubleClick={() => {
                                            navigate(`/course-templates/${courseTemplate?.id}/${item.id}/edit`)
                                        }}>
                                            {item.internalTitle}
                                            <Button
                                                tooltip="Bearbeiten"
                                                className="ml-auto"
                                                type={"button"}
                                                icon="pi pi-pencil"
                                                onClick={() => {
                                                    navigate(`/course-templates/${courseTemplate?.id}/${item.id}/edit`)
                                                }}
                                            />
                                            <RemoveModuleTemplateButton courseTemplateFragmentRef={courseTemplate}
                                                                        moduleId={item.id}/>
                                        </div>
                                    }}
                                    value={fieldValue}/>
                            </div>
                        )
                    }}
                    formikConfig={formik}/>
            </WholeGridSpaceContainer>
        </FormContainer>
    </div>
}

const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`

const WholeGridSpaceContainer = styled.div`
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 1rem 0;
`
