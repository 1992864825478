import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { PurchaseUpdatePart_PurchaseUpdateFragment$key } from "../../../../../../__generated__/PurchaseUpdatePart_PurchaseUpdateFragment.graphql";

const PURCHASE_UPDATE_FRAGMENT = graphql`
	fragment PurchaseUpdatePart_PurchaseUpdateFragment on PurchaseUpdate {
		kind
	}
`;

interface OwnProps {
	purchaseUpdateFragmentRef: PurchaseUpdatePart_PurchaseUpdateFragment$key;
}

export const PurchaseUpdatePart = ({ purchaseUpdateFragmentRef }: OwnProps) => {
	const purchase = useFragment<PurchaseUpdatePart_PurchaseUpdateFragment$key>(
		PURCHASE_UPDATE_FRAGMENT,
		purchaseUpdateFragmentRef,
	);

	return <Fieldset legend={HistoryEventType[purchase.kind]}></Fieldset>;
};
