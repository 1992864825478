import React from 'react'
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {EditModuleTemplateForm, FormState} from "../../components/relay/forms/EditModuleTemplateForm";
import {ModuleTemplateScreen_Query} from "../../__generated__/ModuleTemplateScreen_Query.graphql";
import {ModuleTemplateScreen_ModuleTemplateFragment$key} from "../../__generated__/ModuleTemplateScreen_ModuleTemplateFragment.graphql";
import {toast} from "react-toastify";
import {ModuleTemplateScreen_UpdatingMutation} from "../../__generated__/ModuleTemplateScreen_UpdatingMutation.graphql";
import {TemplateHeader} from "../../components/relay/header/TemplateHeader";
import {EDIT_MODULE_TEMPLATE_PATH} from "../../routes/CourseTemplateRoutes";

const QUERY = graphql`
    query ModuleTemplateScreen_Query($id: ID!) {
        node(id: $id) {
            ... on ModuleTemplate {
                ...ModuleTemplateScreen_ModuleTemplateFragment
            }
        }
    }
`

const MODULE_TEMPLATE_FRAGMENT = graphql`
    fragment ModuleTemplateScreen_ModuleTemplateFragment on ModuleTemplate {
        id
        title
        shortTitle
        internalTitle
        shortDescription
        createdAt
        isExam
        lastUpdate
        trainingDays{
            id
            internalTitle
        }

        ...EditModuleTemplateForm_ModuleTemplateFragment
        ...TemplateHeader_ModuleTemplateFragment
    }
`;

const UPDATE_MUTATION = graphql`
    mutation ModuleTemplateScreen_UpdatingMutation($input: EditModuleTemplateInput!) {
        Admin {
            Template {
                editModuleTemplate(input: $input) {
                    moduleTemplate{
                        node {
                            id
                            ...TemplateHeader_ModuleTemplateFragment
                            ...ModuleTemplateScreen_ModuleTemplateFragment
                        }
                    }
                }
            }
        }
    }
`;

export const ModuleTemplateScreen = () => {
    const {params: {courseTemplateId, moduleTemplateId}} = useMatch(EDIT_MODULE_TEMPLATE_PATH)!
    const query = useLazyLoadQuery<ModuleTemplateScreen_Query>(QUERY, {
        id: moduleTemplateId!,
    }, {fetchPolicy: "network-only"})

    const moduleTemplate = useFragment<ModuleTemplateScreen_ModuleTemplateFragment$key>(MODULE_TEMPLATE_FRAGMENT, query.node || null)

    const [updateModuleTemplate, isUpdatingModule] = useMutation<ModuleTemplateScreen_UpdatingMutation>(UPDATE_MUTATION)

    return <div>
        <TemplateHeader courseTemplateId={courseTemplateId} moduleTemplateFragment={moduleTemplate}/>
        <h2>Modul {moduleTemplate?.shortTitle} bearbeiten</h2>
        <EditModuleTemplateForm
            initialValues={{
                id: moduleTemplate?.id || "",
                internalTitle: moduleTemplate?.internalTitle || "",
                title: moduleTemplate?.title || "",
                shortTitle: moduleTemplate?.shortTitle || "",
                shortDescription: moduleTemplate?.shortDescription || "",
                createdAt: moduleTemplate?.createdAt,
                isExam: moduleTemplate?.isExam || false,
                trainingDays: moduleTemplate?.trainingDays.map(trainingDay => ({
                    id: trainingDay?.id,
                    internalTitle: trainingDay?.internalTitle
                })) || []
            }}
            loading={isUpdatingModule}
            onSubmit={
                (values: FormState) => {
                    if (moduleTemplate) {
                        updateModuleTemplate({
                            variables: {
                                input: {
                                    id: moduleTemplate.id,
                                    internalTitle: values.internalTitle,
                                    title: values.title,
                                    shortTitle: values.shortTitle,
                                    isExam: values.isExam,
                                    shortDescription: values.shortDescription
                                }
                            },
                            onCompleted: () => {
                                toast.success("Module-Template erfolgreich gespeichert")
                            },
                            onError: error => {
                                console.error(error.message)
                            },
                            updater: (store, data) => {
                                if (data.Admin.Template.editModuleTemplate?.moduleTemplate.node.id) store.get(data.Admin.Template.editModuleTemplate?.moduleTemplate.node.id)?.invalidateRecord()
                            }
                        })
                    }
                }
            }
            moduleTemplateFragmentRef={moduleTemplate}/>
    </div>
}
