import {useEffect} from "react";
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {useTypedDispatch} from "../../Store";
import {CurrentUserSetter_Query} from "../../__generated__/CurrentUserSetter_Query.graphql";
import {CurrentUserData, setUserData} from "../../slices/AuthSlice";

const QUERY = graphql`
    query CurrentUserSetter_Query {
        Viewer {
            Auth {
                currentUser {
                    user {
                        id
                    }
                    permissionsInAccount
                }
            }
        }
    }
`

interface OwnProps {
    children: any
}

export const CurrentUserSetter = ({children}: OwnProps) => {
    const dispatch = useTypedDispatch()

    const currentUser = useLazyLoadQuery<CurrentUserSetter_Query>(QUERY, {})

    useEffect(() => console.log("currentUser:", currentUser), [currentUser])

    useEffect(() => {
        if (currentUser.Viewer.Auth.currentUser) {
            dispatch(setUserData(currentUser.Viewer.Auth.currentUser as CurrentUserData))
        }
        // eslint-disable-next-line
    }, [])


    return <>
        {children}
    </>

}
