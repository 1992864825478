/**
 * @generated SignedSource<<744c44ec1c8fe7c4a0eccb91631fc130>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddModuleButton_CourseFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "AddModuleButton_CourseFragment";
};
export type AddModuleButton_CourseFragment$key = {
  readonly " $data"?: AddModuleButton_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddModuleButton_CourseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddModuleButton_CourseFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Course",
  "abstractKey": null
};

(node as any).hash = "d6525299d2fe78e825362611de53d540";

export default node;
