import React, { useContext } from "react";
import { DiscountActionSearchContext } from "./DiscountActionSearchContext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DiscountTypeKind } from "../../../../__generated__/DiscountActionForm_DiscountActionFragment.graphql";
import {InputText} from "primereact/inputtext";

export const DiscountActionFilter = () => {
	const { filters, setFilters, clearFilters } = useContext(DiscountActionSearchContext);

	const options: { label: string; value: DiscountTypeKind }[] = [
		{ label: "System", value: "system" },
		{ label: "Nutzer", value: "user" },
	];

	return (
		<div className="flex flex-wrap align-items-center">
			<div className="mr-2">
				<Dropdown
					value={filters.filterByDiscountTypeKind}
					options={options}
					placeholder="Discount-Typ"
					onChange={(e) =>
						setFilters({
							...filters,
							filterByDiscountTypeKind: e.target.value,
						})
					}
				/>
			</div>
			<div className="mr-2">
				<InputText
					value={filters.filterByActionTitle}
					placeholder="Aktionstitel"
					onChange={(e) =>
						setFilters({
							...filters,
							filterByActionTitle: e.target.value,
						})
					}
				/>
			</div>
			<div className="mr-2">
				<InputText
					value={filters.filterByCode}
					placeholder="Code"
					onChange={(e) =>
						setFilters({
							...filters,
							filterByCode: e.target.value,
						})
					}
				/>
			</div>
			<Button
				className="h-2rem"
				tooltip={"Zurücksetzen"}
				icon="pi pi-times"
				onClick={() => clearFilters()}
			/>
		</div>
	);
};
