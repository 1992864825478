/**
 * @generated SignedSource<<84188f533de4b8b244153cba9b7c029a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChangeActivatedForm_UserFragment$data = {
  readonly activated: boolean;
  readonly email: string;
  readonly id: string;
  readonly " $fragmentType": "ChangeActivatedForm_UserFragment";
};
export type ChangeActivatedForm_UserFragment$key = {
  readonly " $data"?: ChangeActivatedForm_UserFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChangeActivatedForm_UserFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChangeActivatedForm_UserFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activated",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "469bab9b1073bcbf673be7aceb827501";

export default node;
