import React, {useState} from 'react';
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {UserInput} from "./UserInput";
import {toast} from "react-toastify";
import {AttendanceTypeInputDropdown} from "../../ui/AttendanceTypeInput";
import {
    AddParticipantToModuleForm_CourseStateFragment$key
} from "../../../__generated__/AddParticipantToModuleForm_CourseStateFragment.graphql";
import {
    AddParticipantToModuleForm_AddUserToModuleMutation,
    AttendanceTypeInput
} from "../../../__generated__/AddParticipantToModuleForm_AddUserToModuleMutation.graphql";
import {
    FoodCreditStateInput
} from "../../../__generated__/AddParticipantToCourseStateForm_AddUserToCourseStateMutation.graphql";
import {FoodCreditStateInputDropdown} from "../../ui/FoodCreditStateInput";

const ADD_USER_TO_MODULE_MUTATION = graphql`
    mutation AddParticipantToModuleForm_AddUserToModuleMutation($input: AddUserToModuleInput!) {
        Admin {
            States {
                addUserToModule(input: $input) {
                    courseState {
                        node {
                            ... ParticipantsScreen_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment AddParticipantToModuleForm_CourseStateFragment on CourseState {
        id
    }
`

interface OwnProps {
    courseStateFragmentRef: AddParticipantToModuleForm_CourseStateFragment$key
    moduleId: string
    className?: string
}

interface State {
    userId?: string | null
    attendanceType?: AttendanceTypeInput | null
    foodCreditState?: FoodCreditStateInput  | null
}

export const AddParticipantToModuleForm = ({courseStateFragmentRef, moduleId, className}: OwnProps) => {
    const courseState = useFragment<AddParticipantToModuleForm_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)

    const [addUserToTrainingDay, isAdding] = useMutation<AddParticipantToModuleForm_AddUserToModuleMutation>(ADD_USER_TO_MODULE_MUTATION)
    const [state, setState] = useState<State>()


    return <div className={`p-fluid flex justify-content-start align-items-center mb-3 ${className ? className : ""}`}>
        <UserInput
            onSelect={participant => setState({...state, userId: participant === undefined ? null : participant})}
            selectedUser={state?.userId}
            className="h-3rem w-15rem align-items-center"/>
        <AttendanceTypeInputDropdown
            selectedStatus={state?.attendanceType}
            className="h-3rem w-10rem align-items-center"
            onSelect={e => setState({...state, attendanceType: e || null})}
            placeholder={"Art"}/>
        <FoodCreditStateInputDropdown
            selectedStatus={state?.foodCreditState}
            className="h-3rem w-10rem align-items-center"
            onSelect={e => setState({...state, foodCreditState: e || null})}
            placeholder={"Essenskredit"}/>
        <Button
            icon="pi pi-plus"
            type="button"
            disabled={isAdding}
            loading={isAdding}
            className="w-15rem ml-2 h-3rem"
            label={"Teilnehmer hinzufügen"}
            onClick={(e) => {
                if (state && state.userId && state.attendanceType && state.foodCreditState)
                    addUserToTrainingDay({
                        variables: {
                            input: {
                                userId: state.userId,
                                courseStateId: courseState.id,
                                moduleId: moduleId,
                                attendanceType: state.attendanceType,
                                foodCreditState: state.foodCreditState
                            }
                        },
                        onCompleted: () => {
                            toast.success("Nutzer erfolgreich hinzugefügt")
                        }
                    })
            }}
        />
    </div>
}
