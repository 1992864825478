import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderStatusColumn_OrderFragment$key } from "../../../../../__generated__/OrderStatusColumn_OrderFragment.graphql";
import { PaymentStatus } from "../../../../../billing-admin-impl/translations/PaymentStatus";

const ORDER_FRAGMENT = graphql`
	fragment OrderStatusColumn_OrderFragment on Order {
		status
	}
`;

interface OwnProps {
	orderFragmentRef: OrderStatusColumn_OrderFragment$key;
}

export const OrderStatusColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderStatusColumn_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	return <div>{PaymentStatus[order.status]}</div>;
};
