/**
 * @generated SignedSource<<6fb387340aa3fd5a60e6ad8340bf47f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryTable_OrderHistoryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryCreatedAtColumn_OrderHistoryFragment" | "OrderHistoryEventColumn_OrderHistoryFragment" | "OrderHistoryIdColumn_OrderHistoryFragment" | "OrderHistoryStatusColumn_OrderHistoryFragment">;
  readonly " $fragmentType": "OrderHistoryTable_OrderHistoryFragment";
};
export type OrderHistoryTable_OrderHistoryFragment$key = {
  readonly " $data"?: OrderHistoryTable_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryTable_OrderHistoryFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "OrderHistoryTable_OrderHistoryFragment"
};

(node as any).hash = "c08dc1074d10726560f1a73cdfd47d2d";

export default node;
