/**
 * @generated SignedSource<<bcb51f0e5dc8227d625f3f31202d9053>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AttendanceStatus = "Absent" | "CanceledByUser" | "CanceledTooLate" | "CourseCanceledByAdmin" | "NotPresent" | "TookPart" | "Unknown" | "WaitingList" | "%future added value";
export type AttendanceType = "CourseCancel" | "Rebooked" | "Regular" | "WaitingList" | "%future added value";
export type FoodCreditState = "CateringInvoice" | "Expired" | "Open" | "Redeemed" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type EditParticipantsForm_CourseStateFragment$data = {
  readonly id: string;
  readonly moduleStates: ReadonlyArray<{
    readonly moduleId: string;
    readonly trainingDayStates: ReadonlyArray<{
      readonly attendanceListAdminNormal: ReadonlyArray<{
        readonly attendanceStatus: AttendanceStatus;
        readonly attendanceType: AttendanceType;
        readonly foodCreditState: FoodCreditState;
        readonly user: {
          readonly email: string;
          readonly extension: {
            readonly isBlocked?: boolean;
          };
          readonly id: string;
          readonly name: string;
        } | null;
      }>;
      readonly trainingDayId: string;
    }>;
  }>;
  readonly publishedCourse: {
    readonly internalInformation: string | null;
    readonly modulesAdmin: ReadonlyArray<{
      readonly endDate: string;
      readonly id: string;
      readonly startDate: string;
      readonly title: string;
      readonly trainingDays: ReadonlyArray<{
        readonly date: string;
        readonly id: string;
        readonly internalTitle: string;
      }>;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"AddParticipantButton_CourseStateFragment" | "ParticipantsInformation_CourseStateFragment">;
  readonly " $fragmentType": "EditParticipantsForm_CourseStateFragment";
};
export type EditParticipantsForm_CourseStateFragment$key = {
  readonly " $data"?: EditParticipantsForm_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditParticipantsForm_CourseStateFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditParticipantsForm_CourseStateFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishedCourse",
      "kind": "LinkedField",
      "name": "publishedCourse",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalInformation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PublishedModuleAdmin",
          "kind": "LinkedField",
          "name": "modulesAdmin",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endDate",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PublishedTrainingDay",
              "kind": "LinkedField",
              "name": "trainingDays",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internalTitle",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "date",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleState",
      "kind": "LinkedField",
      "name": "moduleStates",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "moduleId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TrainingDayState",
          "kind": "LinkedField",
          "name": "trainingDayStates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "trainingDayId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AttendanceTypeAdmin",
              "kind": "LinkedField",
              "name": "attendanceListAdminNormal",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "extension",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isBlocked",
                              "storageKey": null
                            }
                          ],
                          "type": "UserExtensionImpl",
                          "abstractKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attendanceType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attendanceStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "foodCreditState",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddParticipantButton_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ParticipantsInformation_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};
})();

(node as any).hash = "0848ab76dc377d5c78caabba2f03aef4";

export default node;
