import React from "react";
import { InputText } from "primereact/inputtext";
import { formatDateTime } from "../../../billing-admin-impl/components/ui/DateTimeDisplay";

interface OwnProps {
	label: string;
	value?: string;

	hideTimeZone?: boolean;
}

export const DateTimeDisplayField = ({ label, value }: OwnProps) => {
	if (!value) {
		return null;
	}

	const formattedDateTime = formatDateTime(value);

	return (
		<div className="field col">
			<label htmlFor={value} className="col-fixed">
				{label}
			</label>
			<InputText
				id={value}
				tooltip={formattedDateTime}
				tooltipOptions={{ position: "top" }}
				className="w-full"
				value={formattedDateTime}
			/>
		</div>
	);
};
