import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DateTimeDisplay } from "../../../../../billing-admin-impl/components/ui/DateTimeDisplay";
import { OrderCreatedAtColumn_OrderFragment$key } from "../../../../../__generated__/OrderCreatedAtColumn_OrderFragment.graphql";

const ORDER_FRAGMENT = graphql`
	fragment OrderCreatedAtColumn_OrderFragment on Order {
		createdAt
	}
`;

interface OwnProps {
	orderFragmentRef: OrderCreatedAtColumn_OrderFragment$key;
}

export const OrderCreatedAtColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderCreatedAtColumn_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	return <DateTimeDisplay value={order.createdAt} hideTimezone />;
};
