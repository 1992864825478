import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderPriceColumn_OrderFragment$key } from "../../../../../__generated__/OrderPriceColumn_OrderFragment.graphql";
import { OrderPriceDisplay } from "../../OrderPriceDisplay";

const ORDER_FRAGMENT = graphql`
	fragment OrderPriceColumn_OrderFragment on Order {
		...OrderPriceDisplay_OrderFragment
	}
`;

interface OwnProps {
	orderFragmentRef: OrderPriceColumn_OrderFragment$key;
}

export const OrderPriceColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderPriceColumn_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);

	return <OrderPriceDisplay orderFragmentRef={order} />;
};
