import {useFormik} from "formik";
import {Button} from "primereact/button";
import React from "react";
import * as Yup from "yup";
import {DefaultTextFieldComponent} from "../../ui/DefaultTextInput";
import {ValidatedField} from "../fields/ValidatedField";

interface FormState {
    name?: string
    hotel?: string | null
}

interface OwnProps {
    loading?: boolean
    initialValues?: FormState
    onSubmit: (values: FormState) => void
}

export const EditLocationForm = ({loading, initialValues, onSubmit}: OwnProps) => {
    const formik = useFormik<FormState>({
        initialValues: {
            name: initialValues?.name ?? "Ort/Stadt",
            hotel: initialValues?.hotel
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required("Name wird benötigt")
        }),
        onSubmit: (values, {setSubmitting}) => {
            onSubmit(values)
            setSubmitting(false)
        }
    })

    return <form onSubmit={formik.handleSubmit} className="p-fluid">
        <ValidatedField<FormState, string>
            name={"name"}
            label={"Name"}
            required
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />

        <ValidatedField<FormState, string>
            name={"hotel"}
            label={"Hotel"}
            required={false}
            component={DefaultTextFieldComponent}
            formikConfig={formik}
        />

        <Button
            disabled={loading}
            type="submit"
            label="Speichern"
            className="p-mt-2"/>
    </form>
}
