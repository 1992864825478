/**
 * @generated SignedSource<<46a455555c9b80c01e348ab882e9fe0a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TrainingDayTemplateScreen_TrainingDayTemplateFragment$data = {
  readonly additionalInformation: string | null;
  readonly id: string;
  readonly internalInformation: string | null;
  readonly internalTitle: string;
  readonly requiredAmountOfPreviousTrainingDays: number;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateHeader_TrainingDayTemplateFragment">;
  readonly " $fragmentType": "TrainingDayTemplateScreen_TrainingDayTemplateFragment";
};
export type TrainingDayTemplateScreen_TrainingDayTemplateFragment$key = {
  readonly " $data"?: TrainingDayTemplateScreen_TrainingDayTemplateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"TrainingDayTemplateScreen_TrainingDayTemplateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrainingDayTemplateScreen_TrainingDayTemplateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requiredAmountOfPreviousTrainingDays",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalInformation",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateHeader_TrainingDayTemplateFragment"
    }
  ],
  "type": "TrainingDayTemplate",
  "abstractKey": null
};

(node as any).hash = "5771d5389cbccb2068ac009810db4bfb";

export default node;
