import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { BillingDetailsUpdatePart_BillingDetailsUpdateFragment$key } from "../../../../../../__generated__/BillingDetailsUpdatePart_BillingDetailsUpdateFragment.graphql";

const BILLING_DETAILS_UPDATE_FRAGMENT = graphql`
	fragment BillingDetailsUpdatePart_BillingDetailsUpdateFragment on BillingDetailsUpdate {
		kind
	}
`;

interface OwnProps {
	billingDetailsUpdateFragmentRef: BillingDetailsUpdatePart_BillingDetailsUpdateFragment$key;
}

export const BillingDetailsUpdatePart = ({ billingDetailsUpdateFragmentRef }: OwnProps) => {
	const billingDetails = useFragment<BillingDetailsUpdatePart_BillingDetailsUpdateFragment$key>(
		BILLING_DETAILS_UPDATE_FRAGMENT,
		billingDetailsUpdateFragmentRef,
	);

	return <Fieldset legend={HistoryEventType[billingDetails.kind]}></Fieldset>;
};
