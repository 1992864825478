import React from 'react'
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery, useMutation} from "react-relay";
import graphql from "babel-plugin-relay/macro";
import {EditTrainingDayTemplateForm, FormState} from "../../components/relay/forms/EditTrainingDayTemplateForm";
import {TrainingDayTemplateScreen_Query} from "../../__generated__/TrainingDayTemplateScreen_Query.graphql";
import {TrainingDayTemplateScreen_TrainingDayTemplateFragment$key} from "../../__generated__/TrainingDayTemplateScreen_TrainingDayTemplateFragment.graphql";
import {TrainingDayTemplateScreen_UpdatingMutation} from "../../__generated__/TrainingDayTemplateScreen_UpdatingMutation.graphql";
import {TemplateHeader} from "../../components/relay/header/TemplateHeader";
import {EDIT_TRAINING_DAY_TEMPLATE_PATH} from "../../routes/CourseTemplateRoutes";

const QUERY = graphql`
    query TrainingDayTemplateScreen_Query($id: ID!) {
        node(id: $id) {
            ... on TrainingDayTemplate {
                ...TrainingDayTemplateScreen_TrainingDayTemplateFragment
            }
        }
    }
`

const TRAINING_DAY_TEMPLATE_FRAGMENT = graphql`
    fragment TrainingDayTemplateScreen_TrainingDayTemplateFragment on TrainingDayTemplate {
        id
        internalTitle
        internalInformation
        requiredAmountOfPreviousTrainingDays
        additionalInformation
        ...TemplateHeader_TrainingDayTemplateFragment
    }
`;

const UPDATE_MUTATION = graphql`
    mutation TrainingDayTemplateScreen_UpdatingMutation($input: EditTrainingDayTemplateInput!) {
        Admin {
            Template {
                editTrainingDayTemplate(input: $input){
                    trainingDayTemplate {
                        node {
                            id
                            ...TemplateHeader_TrainingDayTemplateFragment
                            ...TrainingDayTemplateScreen_TrainingDayTemplateFragment
                        }
                    }
                }
            }
        }
    }
`;

export const TrainingDayTemplateScreen = () => {
    const {
        params: {trainingDayTemplateId, courseTemplateId, moduleTemplateId}
    } = useMatch(EDIT_TRAINING_DAY_TEMPLATE_PATH)!

    const query = useLazyLoadQuery<TrainingDayTemplateScreen_Query>(QUERY, {
        id: trainingDayTemplateId!,
    }, {fetchPolicy: "network-only"})

    const trainingDayTemplate = useFragment<TrainingDayTemplateScreen_TrainingDayTemplateFragment$key>(TRAINING_DAY_TEMPLATE_FRAGMENT, query.node || null)

    const [updateModule, isUpdatingModule] = useMutation<TrainingDayTemplateScreen_UpdatingMutation>(UPDATE_MUTATION)

    return <div>
        <TemplateHeader courseTemplateId={courseTemplateId} moduleTemplateId={moduleTemplateId}
                        trainingDayTemplateFragment={trainingDayTemplate}/>
        <h2>Ausbildungstag {trainingDayTemplate?.internalTitle} bearbeiten</h2>
        <EditTrainingDayTemplateForm
            initialValues={{
                id: trainingDayTemplate?.id || null,
                internalTitle: trainingDayTemplate?.internalTitle || "",
                internalInformation: trainingDayTemplate?.internalInformation,
                additionalInformation: trainingDayTemplate?.additionalInformation,
                requiredAmountOfPreviousTrainingDays: trainingDayTemplate?.requiredAmountOfPreviousTrainingDays || 0
            }}
            loading={isUpdatingModule}
            onSubmit={(values: FormState) => {
                if (trainingDayTemplate) {
                    updateModule(
                        {
                            variables: {
                                input: {
                                    id: trainingDayTemplate.id,
                                    internalTitle: values.internalTitle,
                                    internalInformation: !values.internalInformation || values.internalInformation.length === 0 ? null : values.internalInformation,
                                    additionalInformation: !values.additionalInformation || values.additionalInformation.length === 0 ? null : values.additionalInformation,
                                    requiredAmountOfPreviousTrainingDays: values.requiredAmountOfPreviousTrainingDays
                                }
                            }
                        }
                    )
                }
            }}
        />
    </div>

}
