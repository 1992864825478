import {FileUploadBeforeSendParams} from "primereact/fileupload";
import {LoginData} from "../slices/AuthSlice";

export const useFileUpload = () => {
    const loginData: LoginData = localStorage.getItem("tkt-login-data") ? JSON.parse(localStorage.getItem("tkt-login-data")!) : undefined;

    const onBeforeSend = (ev: FileUploadBeforeSendParams) => {
        if (loginData) {
            ev.xhr.setRequestHeader('X-Auth-Token', loginData.token!)
            ev.xhr.setRequestHeader('X-Auth-Account-Id', loginData.groupAssociations.find(_ => true)!.account.id!)
        }
    };

    return {
        onBeforeSend
    }
}
