import {Observable, RecordSource, Store} from 'relay-runtime';
import {
    errorMiddleware,
    loggerMiddleware,
    perfMiddleware,
    RelayNetworkLayer,
    retryMiddleware,
    uploadMiddleware,
    urlMiddleware
} from 'react-relay-network-modern'
import {toast} from "react-toastify";
import RelayModernEnvironment from "relay-runtime/lib/store/RelayModernEnvironment";
import {createClient} from "graphql-ws";
import {ReduxStore} from "./Store";
import {logout} from "./slices/AuthSlice";
import {ErrorMessageI18n} from "./i18n/errorMessage.i18n";

// Export a singleton instance of Relay Environment configured with our network function:
const relayStore = new Store(new RecordSource());


const isDev = process.env.NODE_ENV === "development"
const network = new RelayNetworkLayer([
        urlMiddleware({
            url: () => Promise.resolve(`${process.env.REACT_APP_API_BASE}/api/graphql`),
        }),
        isDev ? loggerMiddleware() : null,
        isDev ? errorMiddleware() : null,
        isDev ? perfMiddleware() : null,
        retryMiddleware({
            fetchTimeout: 15000,
            retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
            beforeRetry: ({abort, attempt}) => {
                if (attempt > 3) {
                    abort();
                    toast.error("Couldn't make connection with API - please wait a few minutes and check your internet connection.")
                }
            },
            statusCodes: [500, 503, 504],
        }),
        uploadMiddleware(),
        // example of the custom inline middleware
        (next) => async (req) => {
            const loginData = ReduxStore.getState().auth.loginData
            if (loginData) {
                req.fetchOpts.headers["X-Auth-Token"] = loginData.token
                req.fetchOpts.headers["X-Auth-Account-Id"] = loginData.groupAssociations.find(_ => true)!.account.id!
            }

            const res = await next(req);

            const LOGOUT_ERRORS = ["auth-token-unknown", "auth-token-not-set-in-headers", "auth_authorisation_exception", "auth_token_unknown_exception"].map(e => e.toLowerCase())
            if (res.errors?.find(e => LOGOUT_ERRORS.includes(e.message?.toLowerCase()))) {
                ReduxStore.dispatch(logout())
            } else if (Array.isArray(res.errors)) {
                res.errors?.forEach(e => {
                    const message = ErrorMessageI18n[e.message.toLowerCase()] ? ErrorMessageI18n[e.message.toLowerCase()] : e.message
                    toast.error(message)
                })
            }

            return res;
        },
    ],
    {
        subscribeFn: (operation, variables) => {
            return Observable.create((sink) => {
                if (!operation.text) {
                    return sink.error(new Error('Operation text cannot be empty'));
                }
                const loginData = ReduxStore.getState().auth.loginData

                const subscriptionClient = createClient({
                    url: `${process.env.REACT_APP_WS_API_BASE}/api/graphql/ws?token=${loginData?.token}&accountId=${loginData?.groupAssociations.find(_ => true)!.account.id!}`,
                    retryAttempts: 3,
                    keepAlive: 10000,
                    lazy: true,
                })

                return subscriptionClient.subscribe(
                    {
                        operationName: operation.name,
                        query: operation.text,
                        variables,
                    },
                    {
                        ...sink,
                        error: (err: any) => {
                            if (Array.isArray(err))
                                // GraphQLError[]
                                return sink.error(
                                    new Error(err.map(({message}) => message).join(', ')),
                                );

                            if (err instanceof CloseEvent)
                                return sink.error(
                                    new Error(
                                        `Socket closed with event ${err.code} ${err.reason || ''}`, // reason will be available on clean closes only
                                    ),
                                );

                            return sink.error(err);
                        },
                    },
                );
            }) as any;
        }
    }
);


export const RelayEnvironment = new RelayModernEnvironment({
    network: network,
    store: relayStore,
});
