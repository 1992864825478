import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { DiscountCodeCodesTableHeader_DiscountActionFragment$key } from "../../../../__generated__/DiscountCodeCodesTableHeader_DiscountActionFragment.graphql";
import { DownloadCodesCsvButton } from "../buttons/DownloadCodesCsvButton";
import { CreateRandomCodesButton } from "../buttons/CreateRandomCodesButton";
import { CreateSingleCodeButton } from "../buttons/CreateSingleCodeButton";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment DiscountCodeCodesTableHeader_DiscountActionFragment on DiscountAction {
		...CreateRandomCodesButton_DiscountActionFragment
		...CreateSingleCodeButton_DiscountActionFragment
		...DownloadCodesCsvButton_DiscountActionFragment
	}
`;

interface OwnProps {
	connectionId: string;

	discountActionFragmentRef: DiscountCodeCodesTableHeader_DiscountActionFragment$key;
}

export const DiscountCodeCodesTableHeader = ({
	connectionId,
	discountActionFragmentRef,
}: OwnProps) => {
	const discountAction = useFragment<DiscountCodeCodesTableHeader_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return (
		<div className="w-full flex flex-row justify-content-between">
			<DownloadCodesCsvButton
				className="ml-auto mr-2"
				discountActionFragmentRef={discountAction}
			/>
			<CreateRandomCodesButton
				className="mr-2"
				connectionId={connectionId}
				discountActionFragmentRef={discountAction}
			/>
			<CreateSingleCodeButton
				connectionId={connectionId}
				discountActionFragmentRef={discountAction}
			/>
		</div>
	);
};
