/**
 * @generated SignedSource<<119ce64ab9b88f15ba17e328c969a7c8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditGroupButton_GroupFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"EditGroupModal_GroupFragment">;
  readonly " $fragmentType": "EditGroupButton_GroupFragment";
};
export type EditGroupButton_GroupFragment$key = {
  readonly " $data"?: EditGroupButton_GroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditGroupButton_GroupFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditGroupButton_GroupFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditGroupModal_GroupFragment"
    }
  ],
  "type": "Group",
  "abstractKey": null
};

(node as any).hash = "342e99b1796e59dc863afc43122c580d";

export default node;
