// @ts-ignore
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { RenderConfig } from "./ValidatedField";
import { InputTextarea } from "primereact/inputtextarea";
import { InputSwitch } from "primereact/inputswitch";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import React from "react";
import { Dropdown } from "primereact/dropdown";

export function DefaultTextFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<string>) {
	const checkIfInputFieldChanged = (e: any) => {
		if (e.target.value !== fieldValue) {
			if (onChange) {
				onChange();
			}
		}
	};
	return (
		<InputText
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) => {
				updateField(e.target.value);
				checkIfInputFieldChanged(e);
			}}
			disabled={disabled}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}

export function DefaultTextAreaComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<string>) {
	return (
		<InputTextarea
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) => {
				updateField(e.target.value);
				if (onChange) {
					onChange();
				}
			}}
			disabled={disabled}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}

export function DefaultCalendarComponent({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
}: RenderConfig<string>) {
	return (
		<Calendar
			name={fieldName}
			showTime
			hourFormat="24"
			dateFormat={"dd.mm.yy"}
			disabled={disabled}
			value={fieldValue ? moment(fieldValue.replace("[UTC]", "")).toDate() : undefined}
			onChange={(e) => {
				updateField(
					e.value ? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ") : undefined,
				);
				if (onChange) {
					onChange();
				}
			}}
		/>
	);
}

export function DefaultSwitchComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<boolean>) {
	return (
		<div>
			<InputSwitch
				id={fieldName}
				name={fieldName}
				checked={fieldValue}
				onChange={(e) => {
					updateField(e.target.value);
					if (onChange) {
						onChange();
					}
				}}
				disabled={disabled}
				className={classNames({ "p-invalid": !isValid })}
			/>
		</div>
	);
}

export function DefaultIntegerFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<number>) {
	return (
		<InputNumber
			step={0}
			id={fieldName}
			name={fieldName}
			value={fieldValue}
			onChange={(e) => {
				updateField(e.value || undefined);
				if (onChange) {
					onChange();
				}
			}}
			disabled={disabled}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}

export function DefaultYesNoFieldComponent({
	fieldName,
	fieldValue,
	updateField,
	isValid,
	disabled,
	onChange,
}: RenderConfig<boolean>) {
	return (
		<Dropdown
			value={fieldValue}
			id={fieldName}
			name={fieldName}
			options={[
				{
					label: "Ja",
					value: true,
				},
				{
					label: "Nein",
					value: false,
				},
			]}
			onChange={(e) => {
				updateField(e.value);
				if (onChange) {
					onChange();
				}
			}}
			disabled={disabled}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
