import React, {Suspense, useState} from 'react'
import {useMatch} from "react-router-dom";
import {useFragment, useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {PUBLISHED_COURSE_OVERVIEW} from "../../routes/CourseRoutes";
import {ParticipantsScreen_Query} from "../../__generated__/ParticipantsScreen_Query.graphql";
import {EditParticipantsForm} from "../../components/relay/forms/EditParticipantsForm";
import {
    ParticipantsScreen_CourseStateFragment$key
} from "../../__generated__/ParticipantsScreen_CourseStateFragment.graphql";
import {AddParticipantButton} from "../../components/relay/buttons/AddParticipantButton";
import {History} from "../../components/relay/History";
import {TabMenu} from "primereact/tabmenu";

const QUERY = graphql`
    query ParticipantsScreen_Query($id: ID!) {
        Admin {
            States {
                CourseStateForPublishedCourse(publishedCourseId: $id) {
                    ... ParticipantsScreen_CourseStateFragment

                }
                HistoryForPublishedCourse(publishedCourseId: $id) {
                    ...History_HistoryFragment
                }
            }
        }
    }
`

const COURSE_STATE_FRAGMENT = graphql`
    fragment ParticipantsScreen_CourseStateFragment on CourseState {
        id
        publishedCourse {
            name
            secondName
        }

        ... EditParticipantsForm_CourseStateFragment
        ... AddParticipantButton_CourseStateFragment
    }
`;

const items = [
    {label: 'Teilnehmer', icon: 'pi pi-fw pi-user'},
    {label: 'Historie', icon: 'pi pi-fw pi-history'},
];

export const ParticipantsScreen = () => {
    const {params: {publishedCourseId}} = useMatch(PUBLISHED_COURSE_OVERVIEW)!
    const [tabIndex, setTabIndex] = useState(0)

    const query = useLazyLoadQuery<ParticipantsScreen_Query>(QUERY, {
        id: publishedCourseId!
    }, {fetchPolicy: "network-only"})

    const courseState = useFragment<ParticipantsScreen_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, query.Admin.States.CourseStateForPublishedCourse || null)
    const publishedCourse = courseState?.publishedCourse
    const history = query.Admin.States.HistoryForPublishedCourse

    let activeTab;

    switch (tabIndex) {
        case 0:
            activeTab = (
                <Suspense fallback={<div>Lade...</div>}>
                    <div className="flex flex-row align-items-center justify-content-center">
                        <h1 className="mr-2">Veröffentlichter Lehrgang {publishedCourse?.name} - {publishedCourse?.secondName} bearbeiten</h1>
                        <AddParticipantButton buttonType="course-state" courseStateFragmentRef={courseState}/>
                    </div>

                    <EditParticipantsForm loading={false} courseStateFragmentRef={courseState!}/>
                </Suspense>
            )
            break;
        case 1:
            activeTab = (
                <Suspense fallback={<div>Lade...</div>}>
                    <div className="flex flex-row align-items-center justify-content-center">
                        <h1 className="mr-2">Historie {publishedCourse?.name} - {publishedCourse?.secondName} bearbeiten</h1>
                    </div>

                    <History historyFragmentRef={history}/>
                </Suspense>
            )
            break;
    }

    return (
        <div>
            <TabMenu model={items} activeIndex={tabIndex} onTabChange={(e) => setTabIndex(e.index)}/>
            <div className="mt-5">
                {activeTab}
            </div>
        </div>
    )
}
