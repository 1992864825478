import {Dropdown} from "primereact/dropdown";
import {FoodCreditState} from "../../__generated__/EditParticipantsForm_CourseStateFragment.graphql";
import {FoodCreditStateI18n} from "../../i18n/foodCreditState.i18n,";

interface OwnProps {
    selectedStatus?: FoodCreditState | null
    onSelect: (foodCreditState: FoodCreditState) => void
    placeholder?: string
    className?: string
}
export const FoodCreditStateInputDropdown = ({selectedStatus, onSelect, placeholder, className}: OwnProps) => {
    const options: { label: string, value: FoodCreditState }[] = [
        {label: FoodCreditStateI18n["Open"], value: "Open"},
        {label: FoodCreditStateI18n["Expired"], value: "Expired"},
        {label: FoodCreditStateI18n["Redeemed"], value: "Redeemed"},
        {label: FoodCreditStateI18n["CateringInvoice"], value: "CateringInvoice"},
    ]

    return (
        <div className="m-1">
            <Dropdown className={className}
                      options={options}
                      placeholder={placeholder}
                      value={selectedStatus}
                      onChange={e => onSelect(e.value)}/>
        </div>
    )

}
