import React, {ReactElement, Suspense, useEffect} from "react"
import {BrowserRouter, NavLink, Route, Routes} from "react-router-dom";
import {Loader} from "../components/ui/Loader";
import {Menubar} from "primereact/menubar";
import {Card} from "primereact/card";
import {MenuItem, MenuItemOptions} from "primereact/menuitem";
import styled from "styled-components";
import {AuthModuleRoutes} from "../routes/DataHandlingModuleRoutes";
import {DashboardScreen} from "../screens/dashboard/DashboardScreen";
import {Button} from "primereact/button";
import {CourseTemplateRoutes} from "../routes/CourseTemplateRoutes";
import {FilesModuleRoutes} from "../routes/FilesModuleRoutes";
import {CourseRoutes} from "../routes/CourseRoutes";
import {BillingRoutes, DISCOUNT_ACTIONS_PATH, ORDERS_PATH} from "../routes/BillingRoutes";
import {EmailModuleRoutes} from "../routes/EmailModuleRoutes";
import {LocationRoutes} from "../routes/LocationRoutes";
import {TrainerRoutes} from "../routes/TrainerRoutes";
import {FaqRoutes} from "../routes/FaqRoutes";
import {useTypedDispatch, useTypedSelector} from "../Store";
import {logout, selectCurrentUser, selectIsLoggedIn, selectPermissionsInAccount} from "../slices/AuthSlice";
import {TheKeyRoutes} from "../routes/TheKeyRoutes";
import {GroupRoutes} from "../routes/GroupRoutes";
import _, {isArray} from "lodash";
import community from '../assets/community.png'
import {Permission} from "../__generated__/PermissionsField_Query.graphql";
import {SecureRouteDefinition} from "../model/SecureRouteDefinition";
import {ForbiddenScreen} from "../screens/forbidden/ForbiddenScreen";

const MenuTemplate = (item: MenuItem, options: MenuItemOptions) => {
    return <NavLink className={options.className} target={item.target} to={item.url!}>{item.label}</NavLink>
};

interface OwnProps {
    forbiddenRoute?: ReactElement
}

export const MainNavigation = ({forbiddenRoute}: OwnProps) => {
    const dispatch = useTypedDispatch()
    const permissions = useTypedSelector(selectPermissionsInAccount)
    const isLoggedIn = useTypedSelector(selectIsLoggedIn)
    const currentUser = useTypedSelector(selectCurrentUser)

    useEffect(() => console.log(permissions), [permissions])
    useEffect(() => console.log(currentUser), [currentUser])

    const items: MenuItem[] = [
        {
            label: 'Benutzer',
            url: "/users",
            template: MenuTemplate
        },
        {
            label: 'TheKey',
            url: "/thekey",
            template: MenuTemplate
        },
        {
            label: 'Gruppen',
            url: "/groups",
            template: MenuTemplate
        },
        {
            label: 'E-Mail',
            url: "/email-templates",
            template: MenuTemplate
        },
        {
            label: "Billing",
            items: [
                {
                    label: "Bestellungen",
                    url: ORDERS_PATH,
                    template: MenuTemplate,
                },
                {
                    label: "Discount-Aktionen",
                    url: DISCOUNT_ACTIONS_PATH,
                    template: MenuTemplate,
                }
            ],
        },
        {
            label: 'Lehrgangsvorlagen',
            url: "/course-templates",
            template: MenuTemplate
        },
        {
            label: 'Lehrgänge',
            url: "/courses",
            template: MenuTemplate
        },
        {
            label: 'Standorte',
            url: "/locations",
            template: MenuTemplate
        },
        {
            label: 'Ausbilder',
            url: "/trainers",
            template: MenuTemplate
        },
        {
            label: 'FAQ',
            url: "/faqs",
            template: MenuTemplate
        },
        {
            label: 'Dateien',
            url: "/files",
            template: MenuTemplate
        }];

    const routes: SecureRouteDefinition[] = [
        {
            path: "/",
            element: <DashboardScreen/>,
            requiredPermissions: "logged-in"
        },
        ...AuthModuleRoutes,
        ...TheKeyRoutes,
        ...GroupRoutes,
        ...BillingRoutes,
        ...CourseTemplateRoutes,
        ...CourseRoutes,
        ...FilesModuleRoutes,
        ...EmailModuleRoutes,
        ...LocationRoutes,
        ...TrainerRoutes,
        ...FaqRoutes
    ]

    return <BrowserRouter>
        <NavLink className="flex align-items-center m-3" to="/">
            <img alt="Community" width={200} src={community}/>
        </NavLink>
        <MenubarContainer model={items}
                          end={<Button label="Ausloggen" onClick={() => dispatch(logout())} className="mr-2"
                                       icon="pi pi-power-off"/>}/>
        <div className="flex justify-content-center align-items-center flex-grow-1 pt-5 pb-5 surface-50">
            <ContentContainer>
                <Suspense fallback={<Loader/>}>
                    <Routes>
                        {filterByPermission(routes, isLoggedIn, permissions).map(prd => {
                            return <Route
                                key={prd.path}
                                path={prd.path}
                                element={prd.element}/>
                        })}
                        <Route key={"verboten"} path={"*"} element={forbiddenRoute ? forbiddenRoute : <ForbiddenScreen />}/>
                    </Routes>
                </Suspense>
            </ContentContainer>
        </div>
    </BrowserRouter>

}

export type RequiredPermissionsType = Permission[] | "logged-in" | "logged-in-and-logged-out" | "only-logged-out"
export type HasRequiredPermissionsType = { requiredPermissions: RequiredPermissionsType }

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(items: ItemType[], isLoggedIn: boolean, permissionsInAccount?: Permission[]) {
    return items.filter(r => {
        const loggedOutAndRouteRequiresLoggedOut = r.requiredPermissions === "only-logged-out" && !isLoggedIn
        const loggedInAndRouteOnlyRequiredLoggedIn = r.requiredPermissions === "logged-in" && isLoggedIn
        const loggedInOrLoggedOut = r.requiredPermissions === "logged-in-and-logged-out"

        // @ts-ignore
        const permissionsAreKnown = permissionsInAccount && isArray(r.requiredPermissions);
        const isLoggedInAndHasAllNecessaryPermissions = permissionsAreKnown && (_.difference(r.requiredPermissions, permissionsInAccount).length === 0 || permissionsInAccount.includes("System_Owner"))

        return loggedInOrLoggedOut || loggedOutAndRouteRequiresLoggedOut || loggedInAndRouteOnlyRequiredLoggedIn || isLoggedInAndHasAllNecessaryPermissions
    })
}


const MenubarContainer = styled(Menubar)`
  border-top: none
`

const ContentContainer = styled(Card)`
  max-width: 1524px;
  height: 100%;
  width: 100%;

`
