import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { CartUpdatePart_CartUpdateFragment$key } from "../../../../../../__generated__/CartUpdatePart_CartUpdateFragment.graphql";

const CART_UPDATE_FRAGMENT = graphql`
	fragment CartUpdatePart_CartUpdateFragment on CartUpdate {
		kind
	}
`;

interface OwnProps {
	cartUpdateFragmentRef: CartUpdatePart_CartUpdateFragment$key;
}

export const CartUpdatePart = ({ cartUpdateFragmentRef }: OwnProps) => {
	const cartUpdate = useFragment<CartUpdatePart_CartUpdateFragment$key>(
		CART_UPDATE_FRAGMENT,
		cartUpdateFragmentRef,
	);

	return <Fieldset legend={HistoryEventType[cartUpdate.kind]}></Fieldset>;
};
