import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { BillingRoutes } from "../../../billing-admin-impl/translations/Routes";

interface OwnProps {
	orderId: string;
}
export const EditOrderButton = ({ orderId }: OwnProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Bestellung bearbeiten"
			onClick={() => navigate(BillingRoutes.editOrder(orderId))}
		/>
	);
};
