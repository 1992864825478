/**
 * @generated SignedSource<<7d24d98d191151f3609c432922b4c256>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$data = {
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment";
};
export type PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$key = {
  readonly " $data"?: PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PaymentInProcessUpdatePart_PaymentInProcessUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "PaymentInProcessUpdate",
  "abstractKey": null
};

(node as any).hash = "fe669441c1f2479ea03e76bd8efca2c4";

export default node;
