import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {ChangeActivatedForm_UserFragment$key} from "../../../__generated__/ChangeActivatedForm_UserFragment.graphql";
import {ChangeActivatedForm_ChangeEmailMutation} from "../../../__generated__/ChangeActivatedForm_ChangeEmailMutation.graphql";
import {Button} from "primereact/button";
import {useFormik} from "formik";
import {RenderConfig, ValidatedField} from "../fields/ValidatedField";
import {InputSwitch} from "primereact/inputswitch";
import {classNames} from "primereact/utils";
import {ResendActivationButton} from "../buttons/ResendActivationButton";

const USER_FRAGMENT = graphql`
    fragment ChangeActivatedForm_UserFragment on User {
        id
        email
        activated
    }
`;

const CHANGE_USER_ACTIVATION_MUTATION = graphql`
    mutation ChangeActivatedForm_ChangeEmailMutation($input: ChangeUserActivationInput!) {
        Admin{
            User {
                changeUserActivation(input: $input) {
                    user {
                        ...SingleUserForm_UserFragment
                    }
                }
            }
        }
    }
`

interface OwnProps {
    userFragmentRef: ChangeActivatedForm_UserFragment$key
}

interface FormState {
    value: boolean
}

export const ChangeActivatedForm = ({userFragmentRef}: OwnProps) => {
    const user = useFragment<ChangeActivatedForm_UserFragment$key>(USER_FRAGMENT, userFragmentRef)
    const [changeActivated, isInFlight] = useMutation<ChangeActivatedForm_ChangeEmailMutation>(CHANGE_USER_ACTIVATION_MUTATION)

    const formik = useFormik<FormState>({
        initialValues: {
            value: user.activated
        },
        onSubmit: values => {
            changeActivated({
                variables: {
                    input: {
                        userId: user.id,
                        isActivated: values.value
                    }
                }
            })
        }
    })


    return <form className="field grid" onSubmit={formik.handleSubmit}>
        <label className="col-fixed font-bold" style={{width: 150}}>
            Ist aktiviert
        </label>
        <div className="col flex align-items-center">
            <ValidatedField
                className="mb-0 mr-2"
                name={"value"}
                formikConfig={formik}
                component={
                    ({fieldName, fieldValue, updateField, isValid, disabled}: RenderConfig<boolean>) => {
                        return <InputSwitch
                            checked={fieldValue}
                            id={fieldName} name={fieldName}
                            onChange={e => updateField(e.value)}
                            disabled={disabled}
                            className={classNames({"p-invalid": !isValid})}/>
                    }}/>

            {!user.activated && <ResendActivationButton className="ml-auto w-auto" email={user.email}/>}
        </div>
        <div className="flex align-items-center">
            <Button
                type="submit"
                disabled={isInFlight || user.activated === formik.values.value}
                className="mr-2" icon={"pi pi-save"}/>
        </div>

    </form>
}
