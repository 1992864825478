import React from "react";
import graphql from "babel-plugin-relay/macro";
import { OrderActionColumn_OrderFragment$key } from "../../../../../__generated__/OrderActionColumn_OrderFragment.graphql";
import { useFragment } from "react-relay";
import { EditOrderButton } from "../../../ui/EditOrderButton";

const ORDER_FRAGMENT = graphql`
	fragment OrderActionColumn_OrderFragment on Order {
		id
	}
`;

interface OwnProps {
	orderFragmentRef: OrderActionColumn_OrderFragment$key;
}

export const OrderActionColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderActionColumn_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	return <EditOrderButton orderId={order.id} />;
};
