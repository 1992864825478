import React from 'react'
import graphql from "babel-plugin-relay/macro";
import {useFragment, useMutation} from "react-relay";
import {Button} from "primereact/button";
import {AddModuleTemplateButton_AddModuleMutation} from "../../../__generated__/AddModuleTemplateButton_AddModuleMutation.graphql";
import {AddModuleTemplateButton_CourseTemplateFragment$key} from "../../../__generated__/AddModuleTemplateButton_CourseTemplateFragment.graphql";

const FRAGMENT = graphql`
    fragment AddModuleTemplateButton_CourseTemplateFragment on CourseTemplate {
        id
    }
`

const CREATE_MUTATION = graphql`
    mutation AddModuleTemplateButton_AddModuleMutation($input: CreateModuleTemplateInput!) {
        Admin {
            Template {
                createModuleTemplate(input: $input){
                    courseTemplate {
                        node {
                            ...CourseTemplateScreen_CourseTemplateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    courseTemplateFragmentRef: AddModuleTemplateButton_CourseTemplateFragment$key | null
}

export const AddModuleTemplateButton = ({courseTemplateFragmentRef}: OwnProps) => {
    const [addTrainingDayTemplate, addTrainingDayTemplateInFlight] = useMutation<AddModuleTemplateButton_AddModuleMutation>(CREATE_MUTATION)
    const courseTemplate = useFragment<AddModuleTemplateButton_CourseTemplateFragment$key>(FRAGMENT, courseTemplateFragmentRef)

    return courseTemplate ?
        <Button
            icon="pi pi-plus"
            className="align-self-end mb-2"
            loading={addTrainingDayTemplateInFlight}
            tooltip="Modul hinzufügen"
            type={"button"}
            onClick={() => {
                addTrainingDayTemplate({
                    variables: {
                        input: {
                            id: courseTemplate.id
                        }
                    }
                })
            }}
        /> :
        null
}
