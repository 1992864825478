import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { Fieldset } from "primereact/fieldset";
import { HistoryEventType } from "../../../../../translations/HistoryEventType";
import { PaymentMethodUpdatePart_PaymentMethodUpdateFragment$key } from "../../../../../../__generated__/PaymentMethodUpdatePart_PaymentMethodUpdateFragment.graphql";

const PAYMENT_METHOD_UPDATE_FRAGMENT = graphql`
	fragment PaymentMethodUpdatePart_PaymentMethodUpdateFragment on PaymentMethodUpdate {
		kind
	}
`;

interface OwnProps {
	paymentMethodUpdateFragmentRef: PaymentMethodUpdatePart_PaymentMethodUpdateFragment$key;
}

export const PaymentMethodUpdatePart = ({ paymentMethodUpdateFragmentRef }: OwnProps) => {
	const paymentMethod = useFragment<PaymentMethodUpdatePart_PaymentMethodUpdateFragment$key>(
		PAYMENT_METHOD_UPDATE_FRAGMENT,
		paymentMethodUpdateFragmentRef,
	);

	return <Fieldset legend={HistoryEventType[paymentMethod.kind]}></Fieldset>;
};
