/**
 * @generated SignedSource<<880c2c41c903631a580570400d39df94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLocationInput = {
  clientMutationId?: string | null;
  data: LocationInput;
};
export type LocationInput = {
  clientMutationId?: string | null;
  hotel?: string | null;
  name: string;
};
export type EditLocationScreen_CreateLocationMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateLocationInput;
};
export type EditLocationScreen_CreateLocationMutation$data = {
  readonly Admin: {
    readonly Location: {
      readonly createLocation: {
        readonly data: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"EditLocationScreen_LocationFragment">;
          };
        };
      } | null;
    };
  };
};
export type EditLocationScreen_CreateLocationMutation = {
  response: EditLocationScreen_CreateLocationMutation$data;
  variables: EditLocationScreen_CreateLocationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditLocationScreen_CreateLocationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLocationPayload",
                "kind": "LinkedField",
                "name": "createLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditLocationScreen_LocationFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "EditLocationScreen_CreateLocationMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LocationAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Location",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLocationPayload",
                "kind": "LinkedField",
                "name": "createLocation",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LocationsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Location",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hotel",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "data",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "82242b4e59b280917ccafc13a18802b3",
    "id": null,
    "metadata": {},
    "name": "EditLocationScreen_CreateLocationMutation",
    "operationKind": "mutation",
    "text": "mutation EditLocationScreen_CreateLocationMutation(\n  $input: CreateLocationInput!\n) {\n  Admin {\n    Location {\n      createLocation(input: $input) {\n        data {\n          node {\n            ...EditLocationScreen_LocationFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment EditLocationScreen_LocationFragment on Location {\n  id\n  name\n  hotel\n}\n"
  }
};
})();

(node as any).hash = "fc205c378c9587c503b67d9af9e47e7b";

export default node;
