import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import { useMatch } from "react-router";
import { useLazyLoadQuery } from "react-relay";
import { BreadCrumb } from "primereact/breadcrumb";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DiscountActionScreen_Query } from "../../__generated__/DiscountActionScreen_Query.graphql";
import { DiscountActionForm } from "../../billing-admin-impl/components/relay/forms/DiscountActionForm";
import { DiscountCodeTable } from "../../billing-admin/components/relay/tables/DiscountCodeTable";
import {EDIT_DISCOUNT_ACTION_PATH} from "../../routes/BillingRoutes";

export interface Account {
	id: string;
	name: string;
}

const QUERY = graphql`
	query DiscountActionScreen_Query($id: ID!) {
		node(id: $id) {
			... on DiscountAction {
				title
				...DiscountActionForm_DiscountActionFragment
			}
		}
		...DiscountCodeTable_QueryFragment @arguments(first: 10, after: null, id: $id)
	}
`;

export const DiscountActionScreen = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const route = useMatch(EDIT_DISCOUNT_ACTION_PATH);
	const discountActionId = route?.params.discountActionId!;

	const query = useLazyLoadQuery<DiscountActionScreen_Query>(QUERY, {
		id: discountActionId,
	});

	const items = [
		{ label: "Alle Aktionen", url: `${window.location.origin}/discount-actions` },
		{
			label: query.node?.title,
			url: `${window.location.origin}/discount-actions/${discountActionId}/edit`,
		},
	];

	return (
		query.node && (
			<div>
				<BreadCrumb className="mb-5" model={items} />
				<Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
					<AccordionTab header="Discount-Aktion">
						<DiscountActionForm discountActionFragmentRef={query.node} />
					</AccordionTab>
					<AccordionTab header="Codes">
						<DiscountCodeTable query={query} discountActionId={discountActionId} />
					</AccordionTab>
				</Accordion>
			</div>
		)
	);
};
