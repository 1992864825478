import React, {Suspense} from 'react';
import * as Sentry from '@sentry/react';
import {Loader} from "./components/ui/Loader";
import {MainNavigation} from "./navigation/MainNavigation";
import {LoginScreen} from "./screens/login/LoginScreen";
import {ToastContainer} from "react-toastify";
import {RelayEnvironmentProvider} from "react-relay";
import {RelayEnvironment} from "./RelayEnvironment";
import {addLocale, locale} from "primereact/api";
import {AuthenticationFallback} from "./components/ui/AuthenticationFallback";
import {Provider} from "react-redux";
import {ReduxStore} from "./Store";
import moment from 'moment';

addLocale('de', {
    firstDayOfWeek: 0,
    dayNames: ['Sonntag', 'Montag', 'Dienst', 'Mittwoche', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: [ 'So', 'Mon', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    dayNamesMin: ['So', 'Mon', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Feburar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'März', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Zurücksetzen'
});
locale("de")
moment().tz("Europe/Berlin").format();

export const theKeyAccountId = "QWNjb3VudDp0aGVrZXk="

function App() {
    return (
        <Provider store={ReduxStore}>
            <ToastContainer autoClose={5000} newestOnTop={true}/>
            <Sentry.ErrorBoundary onError={e => console.error(e)}>
                <Suspense fallback={<Loader/>}>
                    <RelayEnvironmentProvider environment={RelayEnvironment}>
                        <AuthenticationFallback loginFallback={<LoginScreen/>}>
                            <MainNavigation/>
                        </AuthenticationFallback>
                    </RelayEnvironmentProvider>
                </Suspense>
            </Sentry.ErrorBoundary>
        </Provider>
    );
}
export default App;

export interface Tuple<T, K> {
    key: T
    value: K
}

declare global {
    interface Array<T> {
        distinct(): Array<T>;

        distinctBy<K>(key: (i: T) => K): Array<T>

        groupBy<K>(key: (i: T) => K): Tuple<K, T[]>[]
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinct = function () {
    return this.filter((x, i) => i === this.indexOf(x))
}

// eslint-disable-next-line no-extend-native
Array.prototype.distinctBy = function <T, K>(keyFunction: (i: T) => K) {
    let knownKeys: K[] = []
    return this.filter((x) => {
        const key = keyFunction(x)
        if (knownKeys.includes(key)) {
            return false
        } else {
            knownKeys.push(key)
            return true
        }
    })
}

// eslint-disable-next-line no-extend-native
Array.prototype.groupBy = function <T, K>(keyFunction: (i: T) => K): Tuple<K, T[]>[] {
    const groups: Tuple<K, T[]>[] = []

    this.forEach(x => {
        const keyValue = keyFunction(x)
        const existingGroup = groups.find(g => g.key === keyValue)
        if (existingGroup) {
            existingGroup.value.push(x)
        } else {
            groups.push({key: keyValue, value: [x]})
        }
    })
    return groups
}

