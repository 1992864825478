/**
 * @generated SignedSource<<8976666684695c82828bae218e642ca7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageUsedAtColumn_DiscountCodeUsageFragment$data = {
  readonly usedAt: string;
  readonly " $fragmentType": "UsageUsedAtColumn_DiscountCodeUsageFragment";
};
export type UsageUsedAtColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageUsedAtColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageUsedAtColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageUsedAtColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedAt",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "d67b46a7186eba29bb35991999461544";

export default node;
