import {Button} from "primereact/button";
import {graphql} from "babel-plugin-relay/macro";
import {useMutation} from "react-relay";
import {ResendActivationButton_ResendMutation} from "../../../__generated__/ResendActivationButton_ResendMutation.graphql";
import {toast} from "react-toastify";

const RESEND_MUTATION = graphql`
    mutation ResendActivationButton_ResendMutation($input: ResendActivationInput!) {
        Auth {
            resendActivation(input: $input) {
                clientMutationId
            }
        }
    }
`

interface OwnProps {
    email: string
    className?: string
}

export const ResendActivationButton = ({email, className}: OwnProps) => {
    const [resendActivation, isInFlight] = useMutation<ResendActivationButton_ResendMutation>(RESEND_MUTATION)

    return <Button
        type="button"
        className={className}
        label="Aktivierungs-Email erneut senden"
        disabled={isInFlight}
        onClick={() => {
            resendActivation({
                variables: {
                    input: {
                        email
                    }
                },
                onCompleted: () => {
                    toast("E-Mail wurde erfolgreich versendet", {type: "success"})
                }
            })
        }
        }/>
}
