import React from "react";
import graphql from "babel-plugin-relay/macro";
import { DataTable } from "primereact/datatable";
import { readInlineData, usePaginationFragment } from "react-relay";
import { Column } from "primereact/column";
import { DiscountCodeCodesTableHeader } from "../header/DiscountCodeCodesTableHeader";
import { Button } from "primereact/button";
import { DiscountCodeTable_Refetch } from "../../../../__generated__/DiscountCodeTable_Refetch.graphql";
import { CodeCreatedAtColumn } from "../columns/discount-code/CodeCreatedAtColumn";
import { CodeActionColumn } from "../columns/discount-code/CodeActionColumn";
import { CodeNumUsagesColumn } from "../columns/discount-code/CodeNumUsagesColumn";
import { CodeCodeColumn } from "../columns/discount-code/CodeCodeColumn";
import { CodeIdColumn } from "../columns/discount-code/CodeIdColumn";
import { DiscountCodeTable_QueryFragment$key } from "../../../../__generated__/DiscountCodeTable_QueryFragment.graphql";

const QUERY_FRAGMENT = graphql`
	fragment DiscountCodeTable_QueryFragment on Query
	@refetchable(queryName: "DiscountCodeTable_Refetch")
	@argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }, id: { type: "ID!" }) {
		node(id: $id) {
			... on DiscountAction {
				discountCodes(after: $after, first: $first)
					@connection(key: "DiscountCodeTable_discountCodes") {
					__id
					edges {
						node {
							...DiscountCodeTable_DiscountCodeFragment
						}
					}
					pageInfo {
						startCursor
						endCursor
						hasPreviousPage
						hasNextPage
					}
				}
				...DiscountCodeCodesTableHeader_DiscountActionFragment
			}
		}
	}
`;

const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment DiscountCodeTable_DiscountCodeFragment on DiscountCode @inline {
		...CodeIdColumn_DiscountCodeFragment
		...CodeCodeColumn_DiscountCodeFragment
		...CodeNumUsagesColumn_DiscountCodeFragment
		...CodeCreatedAtColumn_DiscountCodeFragment
		...CodeActionColumn_DiscountCodeFragment
	}
`;

interface OwnProps {
	discountActionId: string;
	query: DiscountCodeTable_QueryFragment$key;
}

export const DiscountCodeTable = ({ query, discountActionId }: OwnProps) => {
	const {
		data: code,
		hasNext,
		loadNext,
	} = usePaginationFragment<DiscountCodeTable_Refetch, DiscountCodeTable_QueryFragment$key>(
		QUERY_FRAGMENT,
		query,
	);

	return (
		<>
			<DataTable
				emptyMessage="Keine Codes vorhanden"
				className="mb-3"
				header={() =>
					code?.node && (
						<DiscountCodeCodesTableHeader
							connectionId={code.node.discountCodes?.__id!}
							discountActionFragmentRef={code.node}
						/>
					)
				}
				value={
					code.node?.discountCodes?.edges
						?.filter((e: any) => !!e?.node)
						.map((e: any) => e!.node)
						.map((item: any) => readInlineData(DISCOUNT_CODE_FRAGMENT, item)) || []
				}
			>
				<Column
					header="ID"
					body={(item) => <CodeIdColumn discountCodeFragmentRef={item} />}
				/>
				<Column
					header="Code"
					body={(item) => <CodeCodeColumn discountCodeFragmentRef={item} />}
				/>
				<Column
					header="Verwendungsanzahl"
					body={(item) => <CodeNumUsagesColumn discountCodeFragmentRef={item} />}
				/>
				<Column
					header="Erstellt am"
					body={(item) => <CodeCreatedAtColumn discountCodeFragmentRef={item} />}
				/>
				<Column
					header="Aktionen"
					body={(item) => (
						<CodeActionColumn
							discountCodeFragmentRef={item}
							connectionId={code.node?.discountCodes?.__id!}
							discountActionId={discountActionId}
						/>
					)}
				/>
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					label="Weiter"
					onClick={() => loadNext(10)}
				/>
			</div>
		</>
	);
};
