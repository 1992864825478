import React, {useContext} from "react";
import {Button} from "primereact/button";
import moment from "moment-timezone";
import {Calendar} from "primereact/calendar";
import {PublishedCourseSearchContext} from "./container/PublishedCourseSearchContainer";
import {LocationInput} from "../forms/LocationInput";
import {TrainerInput} from "../forms/TrainerInput";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";

export const PublishedCourseFilter = () => {
    const {filters, setFilters, clearFilters} = useContext(PublishedCourseSearchContext)

    const dropdownOptions = [
        {label: "Ja", value: true},
        {label: "Nein", value: false},
        {label: "Alle", value: null}
    ]


    return <div className="flex mb-3 flex-wrap">
        <div className="m-1">
            <InputText
                placeholder={"Name/Zweitname"}
                value={filters.filterByNameOrSecondName || ""}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByNameOrSecondName: e.target.value
                })}
            />
        </div>
        <div>
            <LocationInput
                placeholder={"Lehrgansort"}
                selectedLocation={filters.filterByCourseLocation}
                onSelect={(locationId) => setFilters({
                    ...filters,
                    filterByCourseLocation: locationId
                })}
            />
        </div>
        <div>
            <LocationInput
                placeholder={"Modulort"}
                selectedLocation={filters.filterByModuleLocation}
                onSelect={(locationId) => setFilters({
                    ...filters,
                    filterByModuleLocation: locationId
                })}
            />
        </div>
        <div>
            <TrainerInput
                placeholder={"Ausbilder"}
                selectedTrainers={filters.filterByTrainers}
                onSelect={(trainerIds) => setFilters({
                    ...filters,
                    filterByTrainers: trainerIds || []
                })}
            />
        </div>
        <div className={"w-2"}>
            <Calendar
                className="m-1"
                showButtonBar
                hourFormat="24"
                dateFormat={"dd.mm.yy"}
                placeholder={"Start am (von)"}
                value={filters.filterByStartDate ? moment(filters.filterByStartDate.replace("[UTC]", "")).toDate() : undefined}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByStartDate: e.value ? (moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")) : undefined
                })}
            />
        </div>
        <div className="m-1">
            <Dropdown
                className="mr-1"
                placeholder={"Reguläre Plätze voll"}
                options={dropdownOptions}
                value={filters.filterByRegularFull}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByRegularFull: e.value
                })}/>
        </div>
        <div className="m-1">
            <Dropdown
                className="mr-1"
                placeholder={"Umbuchende Plätze voll"}
                options={dropdownOptions}
                value={filters.filterByRebookedFull}
                onChange={(e) => setFilters({
                    ...filters,
                    filterByRebookedFull: e.value
                })}/>
        </div>
        <div className="flex align-items-center m-1">
            <Button className="" tooltip={"Zurücksetzen"} icon="pi pi-times" onClick={() => clearFilters()}/>
        </div>
    </div>
}

