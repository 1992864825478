/**
 * @generated SignedSource<<4fb8140f863b047fc815f75dec5cf120>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddParticipantButton_CourseStateFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AddParticipantToCourseStateForm_CourseStateFragment" | "AddParticipantToModuleForm_CourseStateFragment">;
  readonly " $fragmentType": "AddParticipantButton_CourseStateFragment";
};
export type AddParticipantButton_CourseStateFragment$key = {
  readonly " $data"?: AddParticipantButton_CourseStateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddParticipantButton_CourseStateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddParticipantButton_CourseStateFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddParticipantToCourseStateForm_CourseStateFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddParticipantToModuleForm_CourseStateFragment"
    }
  ],
  "type": "CourseState",
  "abstractKey": null
};

(node as any).hash = "c4e5e250bddb2f5044f2af8eec5f670f";

export default node;
