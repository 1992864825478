import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { OrderPaymentProviderColumn_OrderFragment$key } from "../../../../../__generated__/OrderPaymentProviderColumn_OrderFragment.graphql";
import { PaymentProviderLinks } from "../../../../../billing-admin-impl/components/relay/PaymentProviderLink";

const ORDER_FRAGMENT = graphql`
	fragment OrderPaymentProviderColumn_OrderFragment on Order {
		...PaymentProviderLinks_OrderFragment
	}
`;

interface OwnProps {
	orderFragmentRef: OrderPaymentProviderColumn_OrderFragment$key;
}

export const OrderPaymentProviderColumn = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<OrderPaymentProviderColumn_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	return (
		<>
			<PaymentProviderLinks orderFragmentRef={order} />
		</>
	);
};
