/**
 * @generated SignedSource<<aa4c80544196edeff450c5c88ba4ae6d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryStatusColumn_OrderHistoryFragment$data = {
  readonly status: OrderStatus;
  readonly " $fragmentType": "OrderHistoryStatusColumn_OrderHistoryFragment";
};
export type OrderHistoryStatusColumn_OrderHistoryFragment$key = {
  readonly " $data"?: OrderHistoryStatusColumn_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryStatusColumn_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderHistoryStatusColumn_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "OrderHistory",
  "abstractKey": null
};

(node as any).hash = "78b75fd596825edfdeaacc92f05be7bf";

export default node;
