import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import graphql from "babel-plugin-relay/macro";
import {ConnectionHandler, useMutation, usePaginationFragment} from "react-relay";
import {EmailTemplatesTable_Refetch} from "../../../__generated__/EmailTemplatesTable_Refetch.graphql";
import {EmailTemplatesTable_EmailTemplatesListFragment$key} from "../../../__generated__/EmailTemplatesTable_EmailTemplatesListFragment.graphql";
import {useDialogLogic} from "../../../hooks/useDialogLogic";
import {EmailTemplatesTable_DeleteMutation} from "../../../__generated__/EmailTemplatesTable_DeleteMutation.graphql";
import {NewEmailTemplateButton} from "../buttons/NewEmailTemplateButton";
import {EMAIL_TEMPLATE_KEYS} from "../../../i18n/email.i18n";
import {useNavigate} from "react-router-dom";

const EmailTemplates_FRAGMENT = graphql`
    fragment EmailTemplatesTable_EmailTemplatesListFragment on Query @refetchable(queryName: "EmailTemplatesTable_Refetch") @argumentDefinitions(
        first: {type: "Int"},
        after: {type: "String"},
    ){
        Admin {
            Email {
                PersonalizedEmailTemplates( first: $first, after: $after) @connection(key: "EmailTemplatesTable_PersonalizedEmailTemplates") {
                    pageInfo {
                        endCursor
                        hasPreviousPage
                        hasNextPage
                        startCursor
                    }
                    edges {
                        node {
                            id
                            template {
                                key
                                subject
                            }
                        }
                    }
                }
            }
        }
        ...NewEmailTemplateButton_AvailableTemplatesFragment
    }
`

const DELETE_MUTATION = graphql`
    mutation EmailTemplatesTable_DeleteMutation($input: DeletePersonalizedEmailTemplateInput!, $connections: [ID!]!) {
        Admin {
            Email{
                deletePersonalizedEmailTemplate(input: $input) {
                    deletedIds @deleteEdge(connections: $connections)
                }
            }
        }
    }
`

interface OwnProps {
    EmailTemplatesFragmentRef: EmailTemplatesTable_EmailTemplatesListFragment$key
}

export const EmailTemplatesTable = ({EmailTemplatesFragmentRef}: OwnProps) => {
    const navigate = useNavigate();
    const {
        data,
        hasPrevious,
        hasNext,
        loadPrevious,
        loadNext
    } = usePaginationFragment<EmailTemplatesTable_Refetch, EmailTemplatesTable_EmailTemplatesListFragment$key>(EmailTemplates_FRAGMENT, EmailTemplatesFragmentRef)


    const [deleteEMailTemplate, isDeleting] = useMutation<EmailTemplatesTable_DeleteMutation>(DELETE_MUTATION);

    const {showDialog, dialogComponent} = useDialogLogic();

    return <>
        {dialogComponent}
        <div className="flex justify-content-end mb-3">
            <NewEmailTemplateButton availableTemplatesFragmentRef={data}/>
        </div>
        <DataTable
            className="mb-3"
            value={data.Admin.Email.PersonalizedEmailTemplates.edges?.map(b => b!.node!) as any[]}
            emptyMessage={"Keine Vorlagen"}
        >
            <Column header="Schlüssel" body={item => EMAIL_TEMPLATE_KEYS[item.template.key]}/>
            <Column header="Betreff" field="template.subject"/>
            <Column header="Aktionen" style={{width: "20%"}} body={item => <>
                <Button
                    className="mr-2"
                    tooltip="Bearbeiten"
                    onClick={() => {
                        navigate(`/email-templates/${item.id}/edit`)
                    }} icon={"pi pi-pencil"}/>

                <Button
                    disabled={isDeleting}
                    tooltip="Löschen"
                    onClick={() => {
                        showDialog({
                            title: "E-Mail Vorlage löschen",
                            content: "Möchten Sie diese E-Mail-Vorlage wirklich löschen? Das kann nicht rückgängig gemacht werden. Es wird dann die Systemvorlage verwendet, welche eventuell nicht ordentlich funktioniert.",
                            dialogCallback: result => {
                                if (result === "Accept") {
                                    deleteEMailTemplate({
                                        variables: {
                                            input: {
                                                ids: [item.id]
                                            },
                                            connections: [
                                                ConnectionHandler.getConnectionID("client:root:Admin:Email", "EmailTemplatesTable_PersonalizedEmailTemplates")
                                            ]
                                        },
                                    })
                                }
                            }
                        })
                    }} icon={"pi pi-trash"}/>
            </>}/>
        </DataTable>

        <div className="flex justify-content-center align-items-center">
            <Button disabled={!hasPrevious} onClick={() => loadPrevious(20)}
                    className="mr-3 p-button-secondary">Zurück</Button>
            <Button className="p-button-secondary" disabled={!hasNext} onClick={() => loadNext(20)}>Weiter</Button>
        </div>
    </>
}
