/**
 * @generated SignedSource<<ded330613b07e8f9c817fb26a338f299>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageOrderColumn_DiscountCodeUsageFragment$data = {
  readonly order: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "UsageOrderColumn_DiscountCodeUsageFragment";
};
export type UsageOrderColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageOrderColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageOrderColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageOrderColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "a4ad28fff2c428b54c996ff948ef11f0";

export default node;
