/**
 * @generated SignedSource<<71e6c906c9e71c982921a990c01b83b1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditLocationScreen_LocationFragment$data = {
  readonly hotel: string | null;
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "EditLocationScreen_LocationFragment";
};
export type EditLocationScreen_LocationFragment$key = {
  readonly " $data"?: EditLocationScreen_LocationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditLocationScreen_LocationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditLocationScreen_LocationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hotel",
      "storageKey": null
    }
  ],
  "type": "Location",
  "abstractKey": null
};

(node as any).hash = "bc34851ed6ed1c0f581c9487653e904c";

export default node;
