/**
 * @generated SignedSource<<06082ed2ed22c3ccb841e3353faae393>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CourseScreen_CourseFragment$data = {
  readonly amountOfRebookedParticipant: number;
  readonly amountOfRegulatedParticipant: number;
  readonly createdAt: string;
  readonly description: string | null;
  readonly icon: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly internalInformation: string | null;
  readonly locationRef: string;
  readonly modules: ReadonlyArray<{
    readonly endDate: string | null;
    readonly id: string;
    readonly internalTitle: string;
    readonly startDate: string | null;
    readonly trainers: ReadonlyArray<{
      readonly name: string;
    }>;
  }>;
  readonly name: string;
  readonly price: {
    readonly grossPrice: number;
    readonly netPrice: number;
    readonly taxRatePercentage: number;
  };
  readonly secondName: string;
  readonly " $fragmentSpreads": FragmentRefs<"CourseHeader_CourseFragment" | "EditCourseForm_CourseFragment">;
  readonly " $fragmentType": "CourseScreen_CourseFragment";
};
export type CourseScreen_CourseFragment$key = {
  readonly " $data"?: CourseScreen_CourseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CourseScreen_CourseFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CourseScreen_CourseFragment",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "secondName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "icon",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "locationRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalInformation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountOfRebookedParticipant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountOfRegulatedParticipant",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxRatePercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "modules",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "internalTitle",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "startDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "trainers",
          "plural": true,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditCourseForm_CourseFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CourseHeader_CourseFragment"
    }
  ],
  "type": "Course",
  "abstractKey": null
};
})();

(node as any).hash = "72bc6f6417031dc9a8de8b1f9772ca7d";

export default node;
