/**
 * @generated SignedSource<<40bab633a65e8df7b8ed71e4c690dcc1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderHistoryTable_OrderHistoriesFragment$data = {
  readonly node: {
    readonly history?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryTable_OrderHistoryFragment">;
        };
      } | null> | null;
    };
  } | null;
  readonly " $fragmentType": "OrderHistoryTable_OrderHistoriesFragment";
};
export type OrderHistoryTable_OrderHistoriesFragment$key = {
  readonly " $data"?: OrderHistoryTable_OrderHistoriesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderHistoryTable_OrderHistoriesFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "node",
  "history"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "kind": "RootArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./OrderHistoryTable_Refetch.graphql')
    }
  },
  "name": "OrderHistoryTable_OrderHistoriesFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "history",
              "args": null,
              "concreteType": "OrderHistoriesConnection",
              "kind": "LinkedField",
              "name": "__OrderHistoryTable_history_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "OrderHistoriesEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "OrderHistory",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "OrderHistoryTable_OrderHistoryFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "OrderHistoryIdColumn_OrderHistoryFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "OrderHistoryCreatedAtColumn_OrderHistoryFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "OrderHistoryStatusColumn_OrderHistoryFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "OrderHistoryEventColumn_OrderHistoryFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "Order",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "53a0fe3ddc1fec38e62f423d8ed6ebf5";

export default node;
