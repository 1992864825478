import React, {Suspense, useEffect, useState} from 'react'
import {useLazyLoadQuery} from "react-relay";
import {graphql} from "babel-plugin-relay/macro";
import {TabMenu} from "primereact/tabmenu";
import {SingleUserForm} from "../../components/relay/forms/SingleUserForm";
import {SingleUserScreen_Query} from "../../__generated__/SingleUserScreen_Query.graphql";
import {useMatch} from "react-router-dom";
import {EDIT_USER_PATH} from "../../routes/DataHandlingModuleRoutes";
import {UserHistoryScreen} from "./UserHistoryScreen";

const items = [
    {label: 'Nutzerdaten', icon: 'pi pi-fw pi-user-edit'},
    {label: 'Kursstatus', icon: 'pi pi-fw pi-history'}
];

export const SingleUserScreen = () => {
    const {params: {userId, activeIndex}} = useMatch(EDIT_USER_PATH)!

    const [tabIndex, setTagIndex] = useState(1)
    const user = useLazyLoadQuery<SingleUserScreen_Query>(graphql`
        query SingleUserScreen_Query($id: ID!){
            node(id: $id) {
                ...SingleUserForm_UserFragment
                ...UserHistoryScreen_UserFragment
                ... on User {
                    groupAssociations {
                        account {
                            id
                        }
                    }
                }
            }
        }
    `, {
        id: userId!
    }, {fetchPolicy: "network-only"})

    useEffect(() => {
        if (activeIndex) {
            setTagIndex(+activeIndex)
        }
    }, [activeIndex])

    let activeTab;

    switch (tabIndex) {
        case 0:
            activeTab = (
                <Suspense fallback={<div>Lade...</div>}>
                    <SingleUserForm userFragmentRef={user.node}/>
                </Suspense>
            )
            break;
        case 1:
            activeTab = (
                <Suspense fallback={<div>Lade...</div>}>
                    <UserHistoryScreen userFragmentRef={user.node!}/>
                </Suspense>
            )
            break;
    }

    return <div>
        <h1>Lehrgänge</h1>

        <TabMenu model={items} activeIndex={tabIndex} onTabChange={(e) => setTagIndex(e.index)}/>
        <div className="mt-5">
            {activeTab}
        </div>
    </div>
}
