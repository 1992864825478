/**
 * @generated SignedSource<<8fe9634186502244d25571f903dc0c06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PurchaseUpdatePart_PurchaseUpdateFragment$data = {
  readonly kind: HistoryEventType;
  readonly " $fragmentType": "PurchaseUpdatePart_PurchaseUpdateFragment";
};
export type PurchaseUpdatePart_PurchaseUpdateFragment$key = {
  readonly " $data"?: PurchaseUpdatePart_PurchaseUpdateFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PurchaseUpdatePart_PurchaseUpdateFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PurchaseUpdatePart_PurchaseUpdateFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    }
  ],
  "type": "PurchaseUpdate",
  "abstractKey": null
};

(node as any).hash = "32d5d82461b09d0e4f5fbd2799925e7d";

export default node;
