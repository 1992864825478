import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { graphql } from "babel-plugin-relay/macro";
import { readInlineData, usePaginationFragment } from "react-relay";
import { Pagination } from "../../ui/Pagination";
import { OrderHistoryTable_Refetch } from "../../../../__generated__/OrderHistoryTable_Refetch.graphql";
import { OrderHistoryTable_OrderHistoriesFragment$key } from "../../../../__generated__/OrderHistoryTable_OrderHistoriesFragment.graphql";
import { OrderHistoryIdColumn } from "../columns/orderHistory/OrderHistoryIdColumn";
import { OrderHistoryCreatedAtColumn } from "../columns/orderHistory/OrderHistoryCreatedAtColumn";
import { OrderHistoryStatusColumn } from "../columns/orderHistory/OrderHistoryStatusColumn";
import { OrderHistoryEventColumn } from "../columns/orderHistory/OrderHistoryEventColumn";

const QUERY_FRAGMENT = graphql`
	fragment OrderHistoryTable_OrderHistoriesFragment on Query
	@refetchable(queryName: "OrderHistoryTable_Refetch")
	@argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
		node(id: $id) {
			... on Order {
				history(after: $after, first: $first)
					@connection(key: "OrderHistoryTable_history") {
					edges {
						node {
							...OrderHistoryTable_OrderHistoryFragment
						}
					}
				}
			}
		}
	}
`;

export interface OwnProps {
	ordersFragmentRef: OrderHistoryTable_OrderHistoriesFragment$key;
}

const ORDER_HISTORY_FRAGMENT = graphql`
	fragment OrderHistoryTable_OrderHistoryFragment on OrderHistory @inline {
		...OrderHistoryIdColumn_OrderHistoryFragment
		...OrderHistoryCreatedAtColumn_OrderHistoryFragment
		...OrderHistoryStatusColumn_OrderHistoryFragment
		...OrderHistoryEventColumn_OrderHistoryFragment
	}
`;

export const OrderHistoryTable = ({ ordersFragmentRef }: OwnProps) => {
	const {
		data: orders,
		hasNext,
		loadNext,
	} = usePaginationFragment<
		OrderHistoryTable_Refetch,
		OrderHistoryTable_OrderHistoriesFragment$key
	>(QUERY_FRAGMENT, ordersFragmentRef);

	const ordersConnection = orders.node?.history?.edges || [];

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Kein Verlauf"}
				className="p-mb-2"
				value={
					ordersConnection
						.filter((e) => !!e?.node)
						.map((e) => e!.node)
						.map((item: any) => readInlineData(ORDER_HISTORY_FRAGMENT, item)) || []
				}
			>
				<Column
					header={"ID"}
					body={(item) => <OrderHistoryIdColumn orderHistoryFragmentRef={item} />}
				/>
				<Column
					header={"Erstellungsdatum"}
					body={(item) => <OrderHistoryCreatedAtColumn orderHistoryFragmentRef={item} />}
				/>
				<Column
					header={"Ereignis"}
					body={(item) => <OrderHistoryEventColumn orderHistoryFragmentRef={item} />}
				/>
				<Column
					header={"Status"}
					body={(item) => <OrderHistoryStatusColumn orderHistoryFragmentRef={item} />}
				/>
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(10)} />
		</>
	);
};
