import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import React, { useRef, useState } from "react";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { CreateSingleCodeButton_DiscountActionFragment$key } from "../../../../__generated__/CreateSingleCodeButton_DiscountActionFragment.graphql";
import { CreateSingleCodeButton_CreateMutation } from "../../../../__generated__/CreateSingleCodeButton_CreateMutation.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment CreateSingleCodeButton_DiscountActionFragment on DiscountAction {
		id
	}
`;

const CREATE_DISCOUNT_CODE_MUTATION = graphql`
	mutation CreateSingleCodeButton_CreateMutation(
		$input: CreateDiscountCodeInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createDiscountCode(input: $input) {
					edges @prependEdge(connections: $connections) {
						node {
							...DiscountCodeTable_DiscountCodeFragment
						}
					}
				}
			}
		}
	}
`;

interface OwnProps {
	className?: string;
	connectionId: string;
	discountActionFragmentRef: CreateSingleCodeButton_DiscountActionFragment$key;
}

export const CreateSingleCodeButton = ({
	connectionId,
	className,
	discountActionFragmentRef,
}: OwnProps) => {
	const discountAction = useFragment<CreateSingleCodeButton_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);
	const [createSingleCode, isLoading] = useMutation<CreateSingleCodeButton_CreateMutation>(
		CREATE_DISCOUNT_CODE_MUTATION,
	);

	const [codeName, setCodeName] = useState<string>("Neu");

	const overlayPanel = useRef<OverlayPanel>(null);
	const toast = useRef<Toast>(null);

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="Neu"
				loading={isLoading}
				disabled={isLoading}
				className={className}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputText
						id="nameOfCode"
						value={codeName}
						onChange={(e) => setCodeName(e.target.value)}
					/>
					<label htmlFor="nameOfCode">Code</label>
				</span>
				<Button
					label="Code erstellen"
					loading={isLoading}
					disabled={isLoading}
					onClick={() => {
						createSingleCode({
							variables: {
								input: {
									code: codeName,
									discountActionId: discountAction.id,
								},
								connections: [connectionId],
							},
							onCompleted: () => {
								toast.current?.show({
									severity: "success",
									life: 3000,
									summary: "Code erstellt",
									detail: "Discountcode wurde erfolgreich erstellt",
								});
							},
						});
					}}
				/>
			</OverlayPanel>
		</>
	);
};
