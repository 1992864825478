import React, {useState} from 'react'
import {ProgressSpinner} from "primereact/progressspinner";
import {graphql} from 'babel-plugin-relay/macro'
import {useFragment, useMutation} from "react-relay";
import {DateTimeDisplay} from "../../ui/DateTimeDisplay";
import {
    EditParticipantsForm_CourseStateFragment$key
} from "../../../__generated__/EditParticipantsForm_CourseStateFragment.graphql";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {AttendanceStatusI18n} from "../../../i18n/attendanceStatus.i18n,";
import {AttendanceTypeI18n} from "../../../i18n/attendanceType.i18n";
import {AddParticipantButton} from "../buttons/AddParticipantButton";
import {ParticipantsInformation} from "../ParticipantsInformation";
import {Accordion, AccordionTab} from "primereact/accordion";
import styled from "styled-components";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {
    EditParticipantsForm_UpdateUserStateMutation
} from "../../../__generated__/EditParticipantsForm_UpdateUserStateMutation.graphql";
import {toast} from "react-toastify";
import {FoodCreditStateI18n} from "../../../i18n/foodCreditState.i18n,";
import {Tooltip} from "primereact/tooltip";

const COURSE_STATE_FRAGMENT = graphql`
    fragment EditParticipantsForm_CourseStateFragment on CourseState{
        id
        publishedCourse {
            internalInformation
            modulesAdmin {
                id
                title
                startDate
                endDate
                trainingDays {
                    internalTitle
                    id
                    date
                }
            }
        }

        moduleStates {
            moduleId
            trainingDayStates {
                trainingDayId
                attendanceListAdminNormal {
                    user {
                        id
                        email
                        name 
                        extension {
                            ... on UserExtensionImpl {
                                isBlocked
                            }
                        }
                    }
                    attendanceType
                    attendanceStatus
                    foodCreditState
                }
            }
        }

        ... AddParticipantButton_CourseStateFragment
        ... ParticipantsInformation_CourseStateFragment
    }
`

const UPDATE_USER_STATE_MUTATION = graphql`
    mutation EditParticipantsForm_UpdateUserStateMutation($input: UpdateUserStatesForTrainingDayInput!) {
        Admin {
            States {
                updateUserStatesForTrainingDay(input: $input) {
                    courseState {
                        node {
                            ...EditParticipantsForm_CourseStateFragment
                        }
                    }
                }
            }
        }
    }
`

interface OwnProps {
    loading?: boolean

    courseStateFragmentRef: EditParticipantsForm_CourseStateFragment$key
}

interface State {
    moduleId: string,
    trainingDayId: string,
    userIds: string[],
}

export const EditParticipantsForm = ({loading, courseStateFragmentRef}: OwnProps) => {
    const courseState = useFragment<EditParticipantsForm_CourseStateFragment$key>(COURSE_STATE_FRAGMENT, courseStateFragmentRef)
    const [updateUserStates, isUpdating] = useMutation<EditParticipantsForm_UpdateUserStateMutation>(UPDATE_USER_STATE_MUTATION)

    const [usersPresent, setUsersPresent] = useState<State>({moduleId: "", trainingDayId: "", userIds: []})

    if (loading) return <ProgressSpinner/>

    return <Container className="position-relative">
        {courseState.publishedCourse.internalInformation && <Accordion className="mb-5">
            <AccordionTab header="Interne Informationen">
                <p dangerouslySetInnerHTML={{__html: courseState.publishedCourse.internalInformation}}></p>
            </AccordionTab>
        </Accordion>}
        <Accordion>
            {
                courseState?.publishedCourse.modulesAdmin.map((module, index: number) => {
                    const trainingDayStates = courseState.moduleStates.filter(l => l.moduleId === module.id).flatMap(ms => ms.trainingDayStates)

                    return (
                        <AccordionTab key={index} header={
                            <div className="flex flex-row align-items-center justify-content-between w-full">
                                <h3 className="mr-2">{module.title}</h3>
                                <ParticipantsInformation courseStateFragmentRef={courseState!}
                                                         moduleId={module.id}/>
                                <AddParticipantButton moduleId={module.id}
                                                      className="ml-auto"
                                                      buttonType="module"
                                                      courseStateFragmentRef={courseState}/>
                            </div>
                        }>

                            {trainingDayStates.map((tds, index: number) => {
                                const trainingDay = module.trainingDays.find(l => l.id === tds.trainingDayId)
                                return (
                                    <div key={index}>
                                        <div className="flex flex-row align-items-center">
                                            <h4 className="mr-2">
                                                <span>Tag: </span>
                                                <span>{index + 1} - </span>
                                                <span>{trainingDay?.internalTitle}</span>
                                            </h4>
                                            <DateTimeDisplay showTime={false}
                                                             value={trainingDay?.date!}
                                                             hideTimezone/>
                                        </div>
                                        <DataTable
                                            value={tds.attendanceListAdminNormal.map(l => l)}
                                            responsiveLayout="scroll">
                                            <Column
                                                header="E-Mail"
                                                body={l => {
                                                    const name = l.user.name
                                                    const isBlocked = l.user.extension.isBlocked
                                                    return <><Tooltip target={".name"} mouseTrack mouseTrackLeft={10} /> <div
                                                      className={"name"}
                                                      data-pr-tooltip={name}
                                                      data-pr-position="right"
                                                      data-pr-at="right+5 top"
                                                      data-pr-my="left center-2"
                                                      style={{ cursor: 'pointer', textDecoration: "underline" }}
                                                    >{l.user.email}{isBlocked ? " - (Gesperrt)" : ""}</div></>
                                                }}/>
                                            <Column
                                                header="Teilgenommen"
                                                body={l => AttendanceStatusI18n[l.attendanceStatus]}/>
                                            <Column
                                                header="Art"
                                                body={l => AttendanceTypeI18n[l.attendanceType]}/>
                                            <Column
                                                header="Essenskredit"
                                                body={l => FoodCreditStateI18n[l.foodCreditState]}/>
                                            <Column
                                                headerStyle={{width: '8rem'}}
                                                header={() => (
                                                    <div
                                                        className="flex align-items-center justify-content-between w-full">
                                                        <Button
                                                            icon="pi pi-check"
                                                            disabled={isUpdating}
                                                            className="p-button-success ml-auto"
                                                            onClick={() => {
                                                                updateUserStates({
                                                                    variables: {
                                                                        input: {
                                                                            courseStateId: courseState.id,
                                                                            publishedModuleId: usersPresent.moduleId,
                                                                            publishedTrainingDayId: usersPresent.trainingDayId,
                                                                            userIds: usersPresent.userIds
                                                                        }
                                                                    },
                                                                    onCompleted: () => {
                                                                        toast.success("Status wurde erfolgreich übernommen")
                                                                    },
                                                                    onError: () => {
                                                                        toast.error("Leider ist ein Fehler aufgetreten!")
                                                                    }
                                                                })
                                                            }}/>
                                                    </div>
                                                )}
                                                body={user => <div
                                                    className="flex align-items-center justify-content-between">
                                                    <Checkbox
                                                        className="mr-2"
                                                        onChange={(value) => {
                                                            setUsersPresent({
                                                                ...usersPresent,
                                                                moduleId: module.id,
                                                                trainingDayId: trainingDay?.id || "",
                                                                userIds: value.checked ? [...usersPresent.userIds, user.user.id] : usersPresent?.userIds?.filter(u => u !== user.user.id)
                                                            })
                                                        }}
                                                        checked={
                                                            usersPresent.trainingDayId === trainingDay?.id &&
                                                            usersPresent.moduleId === module.id &&
                                                            usersPresent?.userIds.includes(user.user.id)
                                                        }/>
                                                </div>
                                                }/>
                                        </DataTable>
                                    </div>
                                )
                            })}
                        </AccordionTab>
                    )
                })
            }
        </Accordion>
    </Container>
}

const Container = styled.div`
  .p-accordion-header-text {
    width: 100%;
  }

  .p-column-title {
    width: 100%;
  }
`
