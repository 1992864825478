import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { TextDisplayField } from "../../ui/TextDisplayField";
import { Button } from "primereact/button";
import { CreditNoteDataForm_OrderFragment$key } from "../../../../__generated__/CreditNoteDataForm_OrderFragment.graphql";

const ORDER_FRAGMENT = graphql`
	fragment CreditNoteDataForm_OrderFragment on Order {
		creditNoteData {
			creditNoteId
			creditNoteNumber
			creditNoteDataBase64
		}
	}
`;

interface OwnProps {
	orderFragmentRef: CreditNoteDataForm_OrderFragment$key;
}

export const CreditNoteDataForm = ({ orderFragmentRef }: OwnProps) => {
	const order = useFragment<CreditNoteDataForm_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	const downloadCreditNotePDF = () => {
		if (order.creditNoteData?.creditNoteDataBase64) {
			const link = document.createElement("a");
			link.href =
				"data:application/octet-stream;base64," +
				order.creditNoteData?.creditNoteDataBase64;
			link.download = order.creditNoteData?.creditNoteNumber + ".pdf";
			link.click();
		}
	};

	return order.creditNoteData ? (
		<div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Gutschrifts-ID"
					value={order.creditNoteData.creditNoteId}
				/>
				<TextDisplayField
					label="Gutschriftsnummer"
					value={order.creditNoteData.creditNoteId}
				/>
			</div>
			{order.creditNoteData.creditNoteDataBase64 && (
				<Button
					onClick={downloadCreditNotePDF}
					label="PDF Download"
					icon="pi pi-download"
				/>
			)}
		</div>
	) : (
		<h4>Keine Gutschriftsdaten verfügbar</h4>
	);
};
